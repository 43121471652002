import { twMerge } from 'tailwind-merge';

export const DefaultCell = ({
  value,
  row: {
    original: { id, isSummary },
    index
  },
  className
}: any) => {
  return (
    <span
      className={twMerge(
        'text-body-dark text-xs whitespace-pre-wrap',
        isSummary ? 'font-bold summary' : '',
        className ?? ''
      )}
      key={`Cell${id}${index}`}
    >
      {value || '-'}
    </span>
  );
};

export const DefaultCellWithParser =
  (f: (x: any) => any) =>
  ({
    value,
    row: {
      original: { id, isSummary },
      index
    },
    className
  }: any) => {
    return (
      <DefaultCell
        value={f(value)}
        row={{ original: { id, isSummary }, index }}
        className={className}
      />
    );
  };
