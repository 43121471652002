import { parseISO, isWithinInterval, setYear } from 'date-fns';

export const birthdayPortal = {
  start: parseISO('2021-07-05'),
  end: parseISO('2021-07-12')
};

export const isPortalBirthday = isWithinInterval(new Date(), {
  end: setYear(birthdayPortal.end, new Date().getFullYear()),
  start: setYear(birthdayPortal.start, new Date().getFullYear())
});
