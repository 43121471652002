import { addDays, format } from 'date-fns';
import api from './apiPortal';

export const getGrids = async (
  id: string,
  route: string,
  rangeDate?: {
    startDate: string;
    endDate: string;
  },
  page?: number,
  perPage?: number
) => {
  const res = await api.get(
    `oilwells?oilwell=${id}&route=${route}${
      rangeDate ? `&start=${rangeDate.startDate}&end=${rangeDate.endDate}` : ''
    }`,
    {
      params: {
        page,
        per_page: perPage
      }
    }
  );

  return res.data;
};

export const getEquipsActivityByOilwell = async (
  id: string,
  rangeDate?: {
    startDate: string;
    endDate: string;
  },
  filters?: string
) => {
  const res = await api.get(
    `oilwells?route=grid_equip&oilwell=${id}${
      rangeDate ? `&start=${rangeDate.startDate}&end=${rangeDate.endDate}` : ''
    }&${filters ?? ''}`
  );

  return res.data;
};

export const getGridsViewForm = async (customDate?: string) => {
  const date = customDate
    ? format(new Date(customDate?.replaceAll('-', '/')), 'dd/MM/yyyy')
    : format(addDays(new Date(), -1), 'dd/MM/yyyy');
  const res = await api.get(
    `oilwells?route=grids_data_view&start=${date}&end=${date}&per_page=1000`
  );

  return res.data;
};

export const getMatterValues = async () => {
  const { data } = await api.get(`oilwells?route=distinct_values?cf_id=2056`);

  return data;
};
