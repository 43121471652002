import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { postChroma } from '../../../services/oilwells.services';

import Input from '../../../components/atoms/Input';
import Button from '../../../components/atoms/Button';
import { queryClient } from '../../../App';
import SelectInput from '../../../components/atoms/SelectInput';
import MdmLayout from '../../../components/templates/MdmLayout';
import { AxiosError } from 'axios';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import { useMsal } from '@azure/msal-react';

type CreateChromaForm = {
  analysisPercentage: number;
  chromatographyFormulaId: number;
  oilFieldId: number;
};

const CreateChroma: React.FC = () => {
  const { fields, chromaFormula } = useMdmUtils({
    fields: true,
    chromaFormula: true
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<CreateChromaForm>();

  const onSubmit = async (data: CreateChromaForm) => {
    try {
      await postChroma({
        analysisPercentage: Number(data.analysisPercentage),
        chromatographyFormula: {
          connect: { id: Number(data.chromatographyFormulaId) }
        },
        oilField: { connect: { id: Number(data.oilFieldId) } }
      });
      await queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === 'croma-adm'
      });

      toast.success('Cromatografia criada com sucesso');
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(e.response?.data.message);
        if (e.status === 409) {
          toast.error('Cromatografia já cadastrada');
        } else {
          toast.error('Erro ao criar cromatografia');
        }
      } else {
        toast.error(
          'Houve um erro ao criar cromatografia, por favor tente novamente mais tarde'
        );
      }
    }
  };

  return (
    <MdmLayout title={'Criar Cromatografia'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-2 items-center justify-center w-3/4"
      >
        <SelectInput
          label="Campo"
          placeholder="Campo"
          control={control}
          name={'oilFieldId'}
          required
          options={fields.map((field: any) => ({
            value: field.id,
            label: field.name
          }))}
        />

        <Input
          label="Análise (%)"
          placeholder="Análise (%)"
          {...register('analysisPercentage', { required: true })}
        />

        <SelectInput
          label="Fórmula Cromatografia"
          placeholder="Fórmula Cromatografia"
          control={control}
          name={'chromatographyFormulaId'}
          required
          options={chromaFormula.map((formula: any) => ({
            value: formula.id,
            label: formula.chemicalFormula
          }))}
        />

        <Button
          className="w-full h-12 mt-4"
          type="submit"
          title="Criar"
          disabled={!isValid && !errors}
        />
      </form>
    </MdmLayout>
  );
};

export default CreateChroma;
