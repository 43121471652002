import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Poço: '2603',
  'Hora Início': '527',
  'Hora Fim': '528',
  'Pressão de Cabeça Inicial (psi)': '2615',
  'Pressão de Cabeça Final (psi)': '2616',
  'Volume Recuperado (m³)': '832',
  'Local do Tratamento': '833',
  'Pressão de Cabeça Inicial (kgf/cm²)': '830',
  'Pressão de Cabeça Final (kgf/cm²)': '831'
};
const treatment: Column[] = tablefy(items, undefined, undefined, undefined, [
  'Pressão de Cabeça Inicial (psi)',
  'Pressão de Cabeça Final (psi)',
  'Volume Recuperado (m³)',
  'Pressão de Cabeça Inicial (kgf/cm²)',
  'Pressão de Cabeça Final (kgf/cm²)'
]);

export default treatment;
