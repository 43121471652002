import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { postOilwell } from '../../../services/oilwells.services';

import Input from '../../../components/atoms/Input';
import Button from '../../../components/atoms/Button';
import { queryClient } from '../../../App';
import SelectInput from '../../../components/atoms/SelectInput';
import MdmLayout from '../../../components/templates/MdmLayout';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import { mapperOptions } from '../../../utils/mdm';
import {
  injectionTypes,
  targetInstallationCode,
  waterBranch
} from '../../../constants/filters/OilWellsTable';

type CreateOilWellForm = {
  name: string;
  description: string;
  type: string;
  oilFieldId: string;
  statusId: number;
  nameANP: string;
  codeANP: string;
  satelliteId: number;
  fluid: string;
  elevationMethod: string;
  interventionExpense: string;
};

const CreateOilWell: React.FC = () => {
  const { status, satellites, fields, constants, conjugateLine, location } =
    useMdmUtils({
      status: true,
      satellites: true,
      fields: true,
      constants: true,
      conjugateLine: true,
      location: true
    });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<CreateOilWellForm>();

  const onSubmit = async (data: CreateOilWellForm) => {
    try {
      await postOilwell({
        ...data,
        statusId: Number(data.statusId),
        satelliteId: Number(data.satelliteId)
      });
      await queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === 'oilwells-adm'
      });

      toast.success('Poço criado com sucesso');
    } catch (e) {
      toast.error('Erro ao criar poço');
    }
  };

  return (
    <MdmLayout title={'Criar Poço'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-2 items-center justify-center w-3/4"
      >
        <Input
          label="Nome"
          required
          placeholder="Nome"
          {...register('name', { required: true })}
        />

        <div className="flex gap-4 w-full">
          <SelectInput
            label="Tipo"
            placeholder="Tipo"
            control={control}
            required
            name={'type'}
            options={mapperOptions(constants?.OilWellType)}
          />

          <SelectInput
            label="Campo"
            placeholder="Campo"
            control={control}
            name={'oilFieldId'}
            required
            options={fields?.map((field: any) => ({
              value: field.id,
              label: field.name
            }))}
          />
        </div>

        <SelectInput
          label="Status ANP"
          placeholder="Status"
          control={control}
          required
          name={'statusId'}
          options={status?.map((status: any) => ({
            value: status.id,
            label: status.anp
          }))}
        />

        <div className="flex gap-4 w-full">
          <Input
            label="Nome ANP"
            placeholder="Nome ANP"
            required
            {...register('nameANP', { required: true })}
          />

          <Input
            label="Código ANP"
            required
            placeholder="Código ANP"
            {...register('codeANP', { required: true })}
          />
        </div>

        <SelectInput
          label="Satelite"
          placeholder="Satelite"
          control={control}
          name={'satelliteId'}
          options={satellites?.map((satellite: any) => ({
            value: satellite.id,
            label: satellite.name
          }))}
        />

        <div className="flex gap-4 w-full">
          <SelectInput
            label="Locação"
            placeholder="Locação"
            control={control}
            name={'locationId'}
            options={location?.map((location: any) => ({
              value: location.id,
              label: location.name
            }))}
          />

          <SelectInput
            label="Linha Conjugada"
            placeholder="Linha Conjugada"
            control={control}
            name={'conjugateLineId'}
            options={conjugateLine?.map((line: any) => ({
              value: line.id,
              label: line.name
            }))}
          />
        </div>

        <SelectInput
          label="Ramal de Água"
          placeholder="Ramal de Água"
          control={control}
          name={'waterBranch'}
          options={waterBranch}
        />

        <div className="flex gap-4 w-full">
          <SelectInput
            label="Tipo de Injeção"
            placeholder="Tipo de Injeção"
            control={control}
            name={'injectionType'}
            options={
              mapperOptions(constants?.InjectionType).length > 0
                ? mapperOptions(constants?.InjectionType)
                : injectionTypes
            }
          />

          <SelectInput
            label="Instalação de Destino"
            placeholder="Instalação de Destino"
            control={control}
            name={'targetInstallationCode'}
            options={targetInstallationCode}
          />
        </div>

        <div className="flex gap-4 w-full">
          <SelectInput
            label="Fluído"
            placeholder="Fluído"
            required
            control={control}
            name={'fluid'}
            options={mapperOptions(constants?.Fluids)}
          />

          <SelectInput
            options={mapperOptions(constants?.ElevationMethods)}
            label="Método de elevação"
            placeholder="Método de elevação"
            control={control}
            name="elevationMethod"
          />
        </div>

        <SelectInput
          options={mapperOptions(constants?.InterventionExpense)}
          label="Despesa de Intervenção"
          placeholder="Despesa de Intervenção"
          control={control}
          name="interventionExpense"
        />

        <Button
          className="px-24 h-12 mt-4"
          type="submit"
          title="Salvar"
          disabled={!isValid && !errors}
        />
      </form>
    </MdmLayout>
  );
};

export default CreateOilWell;
