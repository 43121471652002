import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

export const useParams = (formatDate?: string) => {
  const [params, setParam] = useSearchParams();

  const tracker = params.get('tracker');
  const currentDate = params.get('date');
  const date = format(
    currentDate ? new Date(currentDate.replaceAll('-', '/')) : new Date(),
    formatDate ?? 'yyyy-MM-dd'
  );

  return {
    tracker,
    date,
    setParam,
    params,
    currentDate
  };
};
