import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const ocBombs: Column[] = [
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: '112',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Bomba'} id="Bomba" />,
    accessor: '1514',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Status'} id="Status" />,
    accessor: '1145',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Categoria do Evento'} id="Categoria do Evento" />,
    accessor: '1146',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Motivo'} id="Motivo" />,
    accessor: '1147',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Descrição do evento'} id="Descrição do evento" />,
    accessor: '1126',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'N° da Nota SAP'} id="N° da Nota SAP" />,
    accessor: '1148',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default ocBombs;
