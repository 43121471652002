import { Column } from 'react-table';
import inputs from '../../tableColumns/bdoTS/inputs';
import changesTS from '../../tableColumns/bdoTS/changes';
import maintenceTS from '../../tableColumns/bdoTS/maintence';
import ptEmitTS from '../../tableColumns/bdoTS/ptEmit';
import emed151 from '../../tableColumns/bdoTS/emed';
import emedFQUIA from '../../tableColumns/bdoTS/emedFQUIA';
import upgnCatu from '../../tableColumns/bdoTS/upgnCatu';
import emedFQUIA400 from '../../tableColumns/bdoTS/emedFQUIA400';
import querera from '../../tableColumns/bdoTS/querera';
import pressureGas from '../../tableColumns/bdoTS/pressureGas';
import chargeBat from '../../tableColumns/bdoTS/chargeBat';
import vecel0006 from '../../tableColumns/bdoTS/vecel0006';
import turn from '../../tableColumns/bdoEC/turn';
import activitiesNotFinished from '../../tableColumns/bdoTS/ativitiesNotFinished';
import anomalies from '../../tableColumns/bdoTS/anomalies';
import activitiesRegistry from '../../tableColumns/bdoTS/activitiesRegistry';
import pressuresTS from '../../tableColumns/bdoTS/pressues';
import pressuresAutoTS from '../../tableColumns/bdoTS/pressuesAuto';
import treatment from '../../tableColumns/bdoTS/treatment';
import echometer from '../../tableColumns/bdoTS/echometer';
import collect from '../../tableColumns/bdoTS/collect';
import rip from '../../tableColumns/bdoTS/rip';
import raspReceive from '../../tableColumns/bdoTS/raspReceive';
import raspOilwell from '../../tableColumns/bdoTS/raspOilwells';
import raspStation from '../../tableColumns/bdoTS/raspStation';
import bast from '../../tableColumns/bdoTS/bast';
import activitiesRegistryM from '../../tableColumns/bdoTS/activitiesRegistryM';
import activitiesNotFinishedM from '../../tableColumns/bdoTS/ativitiesNotFinishedM';
import {
  gridOilwellFieldMapper,
  reportBdoCols
} from '../../tableColumns/reportBdoCols';
import { dateTimeFormat } from '../../../pages/BDO/Reports';
import { convertArrayToNewFormat } from '../../../pages/BDO/ViewForm';

export const bdoTs: (
  | {
      type: 'label';
      id: number;
      name: string;
      size?: 'h1' | 'h2';
      bdoIdx: number;
    }
  | {
      type: 'grid';
      id: number;
      columns: Column[];
      name: string;
      value?: string;
      bdoIdx: number;
      hideLabel?: boolean;
      searchable?: boolean;
      dataFunction?: (data: any) => any;
    }
  | {
      type: 'input' | 'date' | 'number' | 'custom';
      bdoIdx: number;
      id: number;
      name: string;
      value?: string;
      field?: string;
    }
)[] = [
  { id: -10, name: 'Informações Gerais', type: 'label', bdoIdx: 0 },
  { name: 'Responsável - Campo', id: 2600, bdoIdx: 1, type: 'input' },
  { name: 'Responsável - MI', id: 2600, bdoIdx: 2, type: 'input' },

  { id: -4, name: 'Registro de Atividades', type: 'label', bdoIdx: 0 },
  {
    name: 'Equipe Campo',
    id: 2736,
    bdoIdx: 1,
    type: 'input'
  },
  {
    name: 'Registro de Atividades - Campo',
    id: 794,
    type: 'grid',
    bdoIdx: 1,
    columns: activitiesRegistry
  },

  {
    name: 'Registro de Atividades - Supervisório',
    id: 794,
    type: 'grid',
    bdoIdx: 0,
    columns: activitiesRegistry
  },

  { name: 'Equipe – Nome 01 - Manutenção', id: 1444, bdoIdx: 2, type: 'input' },
  {
    name: 'Registro de Atividades - Manutenção',
    id: 2733,
    type: 'grid',
    bdoIdx: 2,
    columns: activitiesRegistryM
  },

  { id: -1, name: 'Atividades Não Realizadas', type: 'label', bdoIdx: 0 },
  {
    name: 'Nome - Operador',
    id: 2737,
    bdoIdx: 1,
    type: 'input'
  },
  {
    name: 'Atividades Não Realizadas - Campo',
    id: 2113,
    type: 'grid',
    bdoIdx: 0,
    columns: activitiesNotFinished
  },

  { name: 'Equipe - Manutenção', id: 2734, bdoIdx: 2, type: 'input' },
  {
    name: 'Atividades Não Realizadas - Manutenção',
    id: 2747,
    type: 'grid',
    bdoIdx: 2,
    columns: activitiesNotFinishedM
  },

  { id: -3, name: 'Registro de Pressão dos Poços', type: 'label', bdoIdx: 0 },
  {
    name: 'Registro de Pressão dos Poços',
    id: 2605,
    type: 'grid',
    bdoIdx: 1,
    columns: pressuresTS
  },
  {
    name: 'Registro de Pressão dos Poços - Automático',
    id: 2606,
    type: 'grid',
    bdoIdx: 1,
    columns: pressuresAutoTS
  },

  {
    name: 'Aberturas e Fechamentos',
    id: 2633,
    type: 'grid',
    bdoIdx: 0,
    columns: reportBdoCols('Tucano Sul'),
    dataFunction: (tableData: any[]) =>
      convertArrayToNewFormat(
        tableData
          .map((it: any) => ({
            ...it,
            poço: it[gridOilwellFieldMapper['Tucano Sul']],
            Hora: dateTimeFormat.test(it['769'])
              ? it['769'].split(' ')[1]
              : it['769'],
            tipo_de_atividade: it['1128']
          }))
          ?.sort(
            (a: any, b: any) =>
              new Date(a.Hora).getTime() - new Date(b.Hora).getTime()
          )
          ?.sort((a: any, b: any) => a.poço - b.poço)
      )
  },

  { id: -2, name: 'Estação Conceição e Quererá', type: 'label', bdoIdx: 0 },
  {
    name: 'EMED-151',
    id: 2644,
    type: 'grid',
    bdoIdx: 0,
    columns: emed151
  },
  { name: 'Total - 2H (m³)', type: 'input', bdoIdx: 0, id: 2658 },
  {
    name: 'EMED FQIA-1001 – Bahia Gás',
    id: 2650,
    type: 'grid',
    bdoIdx: 0,
    columns: emedFQUIA
  },
  {
    name: 'PETROBRÁS – UPGN CATU',
    id: 2651,
    type: 'grid',
    bdoIdx: 0,
    columns: upgnCatu
  },
  {
    name: 'EMED FQIA-400 – COMPRESSOR',
    id: 2657,
    type: 'grid',
    bdoIdx: 0,
    columns: emedFQUIA400
  },
  {
    name: 'Quererá',
    id: 2659,
    type: 'grid',
    bdoIdx: 0,
    columns: querera
  },
  {
    name: 'Pressão dos gasodutos',
    id: 2668,
    type: 'grid',
    bdoIdx: 0,
    columns: pressureGas
  },
  {
    name: 'Carga Bateria',
    id: 2660,
    type: 'grid',
    bdoIdx: 0,
    columns: chargeBat
  },

  {
    name: 'Medição de Condensado - Vaso SD-0006',
    id: 2671,
    type: 'grid',
    bdoIdx: 0,
    columns: vecel0006,
    searchable: true
  },
  { name: 'Lacre Rompido', type: 'input', bdoIdx: 0, id: 510 },
  { name: 'Lacre Atual', type: 'input', bdoIdx: 0, id: 582 },

  {
    name: 'Registro de Tratamentos',
    id: 2614,
    type: 'grid',
    bdoIdx: 1,
    columns: treatment,
    searchable: true
  },

  {
    name: 'Registro de Bastões – Lançamento e Abertura',
    id: 2629,
    type: 'grid',
    bdoIdx: 1,
    columns: bast,
    searchable: true
  },

  {
    name: 'Raspadores - Recebimentos',
    id: 2632,
    type: 'grid',
    bdoIdx: 1,
    columns: raspReceive,
    searchable: true
  },
  {
    name: 'Raspadores – Poços',
    id: 2625,
    type: 'grid',
    bdoIdx: 1,
    columns: raspOilwell,
    searchable: true
  },
  {
    name: 'Raspadores – entre Estações',
    id: 2627,
    type: 'grid',
    bdoIdx: 1,
    columns: raspStation,
    searchable: true
  },

  {
    name: 'Registro de Coletas',
    id: 2620,
    type: 'grid',
    bdoIdx: 1,
    columns: collect,
    searchable: true
  },

  {
    name: 'Operações com Echometer',
    id: 2619,
    type: 'grid',
    bdoIdx: 1,
    columns: echometer,
    searchable: true
  },

  {
    name: 'Controle INP',
    id: 2622,
    type: 'grid',
    bdoIdx: 1,
    columns: rip,
    searchable: true
  },

  {
    name: 'Controle de Mudanças',
    id: 2634,
    type: 'grid',
    bdoIdx: 0,
    columns: changesTS
  },
  {
    name: 'Solicitações de Manutenção',
    id: 2639,
    type: 'grid',
    bdoIdx: 0,
    columns: maintenceTS
  },
  {
    name: 'Controle de Emissão de PTs',
    id: 2640,
    type: 'grid',
    bdoIdx: 0,
    columns: ptEmitTS
  },
  { id: -7, name: 'Eventos (Passagem de Turno)', type: 'label', bdoIdx: 0 },
  { name: 'Turno 01 - Responsável', type: 'input', bdoIdx: 0, id: 492 },
  {
    name: 'Turno 01 - 00h às 07h',
    id: 480,
    type: 'grid',
    bdoIdx: 0,
    columns: turn
  },
  { name: 'Turno 02 - Responsável', type: 'input', bdoIdx: 0, id: 493 },
  {
    name: 'Turno 02 - 07h às 19h',
    id: 481,
    type: 'grid',
    bdoIdx: 0,
    columns: turn
  },
  { name: 'Turno 03 - Responsável', type: 'input', bdoIdx: 0, id: 494 },
  {
    name: 'Turno 03 - 19h às 23h59h',
    id: 482,
    type: 'grid',
    bdoIdx: 0,
    columns: turn
  },
  {
    name: 'Observações e Anomalias',
    id: 2676,
    type: 'grid',
    bdoIdx: 0,
    columns: anomalies,
    searchable: true
  }
];
