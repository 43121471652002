import { format } from 'date-fns';
import { FaUserSecret } from 'react-icons/fa';
import { FiAlertTriangle } from 'react-icons/fi';
import { GiWilliamTellSkull } from 'react-icons/gi';
import { LuUserCheck2 } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import { workflowPhasesEnum } from '../../constants/enums/phases';
import Clock from '../atoms/Clock';
import { WorkflowTestProps } from './WorkflowPhaseCard';

interface TestWorkflowCardProps {
  test: WorkflowTestProps;
  phase: {
    name: string;
    ids: number[];
    isExpectedDate?: boolean;
    forceEndTestDate?: boolean;
    invertDates?: boolean;
    dateLabel?: string;
    urlRedirect?: string;
  };
  alertIcon?: React.ReactNode;
}

const TestWorkflowCard = ({
  test: {
    id,
    name: title,
    shortText,
    isPending,
    obsIsPending,
    tags,
    status,
    expectedTestDate,
    endTestDate,
    isLeadership,
    createdAt,
    isLate,
    isLateText,
    noUseCustomUrl
  },
  alertIcon = <FiAlertTriangle size={16} color="#fff" fill="#FEDD00" />,
  phase
}: TestWorkflowCardProps) => {
  const { forceEndTestDate, isExpectedDate, dateLabel } = phase;
  const date =
    !forceEndTestDate && (isExpectedDate || tags[0] === 'Rápido')
      ? {
          value: new Date(expectedTestDate || createdAt),
          title:
            expectedTestDate !== undefined
              ? 'Data Prevista'
              : 'Data de Solicitação'
        }
      : {
          value: endTestDate,
          title: dateLabel ?? 'Fim do Teste'
        };

  // const [clockColor, setClockColor] = useState("");
  // const [clockText, setClockText] = useState("");

  // useEffect(() => {
  //   if (slaDate) {
  //     let color =
  //       format(slaDate, "yyyy-MM-dd hh mm") <
  //       format(new Date(), "yyyy-MM-dd hh mm")
  //         ? "red"
  //         : "green";
  //     setClockText(`SLA até: ${format(slaDate, "dd/MM/yyyy HH:mm")}`);

  //     if (
  //       phase.includes(workflowPhasesEnum.TesteProgramado) ||
  //       phase.includes(workflowPhasesEnum.ParametrizacaoTeste) ||
  //       phase.includes(workflowPhasesEnum.AlinhamentoCampo)
  //     ) {
  //       if (format(slaDate, "yyyy-MM-dd") == format(new Date(), "yyyy-MM-dd")) {
  //         color = "yellow";
  //       }
  //     }

  //     setClockColor(color);
  //   }
  // }, [slaDate]);

  const isInvalid = [
    workflowPhasesEnum.InvalidadoCPROD,
    workflowPhasesEnum.InvalidadoProducao
  ].includes(status);

  return (
    <Link
      to={
        phase.urlRedirect && !noUseCustomUrl
          ? `${phase.urlRedirect}/${id}`
          : `${import.meta.env.VITE_REDMINE_URL}/issues/${id}`
      }
      className="flex flex-col shadow-light rounded-lg px-4 py-3"
      target="_blank"
    >
      <div className="flex justify-between items-center text-xs">
        <span className="font-bold text-[8px] text-gray">#{id}</span>
        {isLate && (
          <>
            <div
              data-tooltip-id={`clock-${id}`}
              data-tooltip-target="tooltip-default"
            >
              <Clock color={'yellow'} />
            </div>
            {isLateText && (
              <ReactTooltip
                id={`clock-${id}`}
                place="top"
                variant="dark"
                style={{ maxWidth: '250px', textAlign: 'center' }}
                content={String(isLateText)}
              />
            )}
          </>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <span className="font-bold text-xs text-gray flex">
          {title}

          {isPending && (
            <>
              <div
                data-tooltip-id={`card-${id}`}
                data-tooltip-target="tooltip-default"
                className="ml-1"
              >
                {import.meta.env.DEV ? (
                  <GiWilliamTellSkull size={18} color="#3d0066" />
                ) : (
                  alertIcon
                )}
              </div>
              <ReactTooltip
                id={`card-${id}`}
                place="top"
                variant="dark"
                style={{ maxWidth: '250px', textAlign: 'center' }}
                content={`${obsIsPending ? `${obsIsPending}` : ''}`}
              />
            </>
          )}

          {isLeadership && (
            <>
              <div
                className="ml-1 pt-0.5"
                data-tooltip-id={`leadership-${id}`}
                data-tooltip-target="tooltip-default"
              >
                {import.meta.env.DEV ? (
                  <FaUserSecret color="#3a3a3a" />
                ) : (
                  <LuUserCheck2 color="#777" />
                )}
              </div>

              <ReactTooltip
                id={`leadership-${id}`}
                place="top"
                variant="dark"
                style={{ maxWidth: '250px', textAlign: 'center' }}
                content={'Validação da Liderança'}
              />
            </>
          )}
        </span>

        {shortText ? (
          <>
            <span
              className=" text-[9px] text-gray/80"
              data-tooltip-id={`shortext-${id}`}
            >
              {shortText.length > 90
                ? `${shortText.slice(0, 90)}...`
                : shortText}
            </span>

            <ReactTooltip
              id={`shortext-${id}`}
              place="top"
              variant="dark"
              style={{
                maxWidth: '350px',
                fontSize: '12px'
              }}
              content={String(shortText)}
            />
          </>
        ) : null}

        {(tags.length > 0 || isInvalid) && (
          <div
            className="
                flex gap-1
                flex-wrap
                w-3/4
              "
          >
            {[...tags, isInvalid ? 'Invalidado' : undefined] // TODO: REMOVE FIXED COLOR CLASSES
              .filter(it => it)
              .map((tag, idx) => (
                <span
                  className={twMerge(
                    `rounded-[3px] px-2 py-[2px] text-[8px]`,
                    typeof tag === 'string'
                      ? idx == 0
                        ? 'bg-[#D7DFFF] text-primary'
                        : idx == 1
                          ? 'bg-[#FFE8D7] text-[#EC7100]'
                          : idx == 2
                            ? 'bg-[#F7D7FF] text-[#9605BA]'
                            : ''
                      : '',
                    typeof tag === 'string'
                      ? tag == 'Conjugado'
                        ? 'bg-[#A5A900]/20 text-[#A5A900]'
                        : tag == 'Invalidado' || tag == 'Cancelado'
                          ? 'bg-[#e6432a]/20 text-[#e6432a]'
                          : tag == 'Em espera'
                            ? 'bg-[#FFD800]/20 text-[#a39f32]'
                            : ''
                      : tag?.className
                  )}
                  key={`${id}${typeof tag === 'string' ? tag : tag?.text}`}
                >
                  {typeof tag === 'string' ? tag : tag?.text}
                </span>
              ))}
          </div>
        )}

        {date.value ? (
          <span
            data-tooltip-id={`endDate-${id}`}
            className="text-[8px] text-[#999] w-full"
          >
            <span className="text-[#777] text-[10px]">
              {date.title}
              {': '}
            </span>
            {format(new Date(date.value), 'dd/MM/yyyy')}
          </span>
        ) : null}
      </div>
    </Link>
  );
};

export default TestWorkflowCard;
