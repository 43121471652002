import { useParams } from '@/hooks/Common/useParams';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import EmptyMessage from '../../components/atoms/EmptyMessage';
import H1 from '../../components/atoms/H1';
import { PageWrapper } from '../../components/atoms/PageWrapper';
import Spinner from '../../components/atoms/Spinner';
import TabsItem from '../../components/atoms/TabsItem';
import PageNavigation from '../../components/molecules/PageNavigation';
import SideFilter from '../../components/molecules/SideFilter';
import Table from '../../components/organisms/Table';
import { alertFilters } from '../../constants/filters/AlertFilters';
import alertCols from '../../constants/tableColumns/alerts/alerts';
import useAlerts from '../../hooks/Alerts/useAlerts';
import { AlertsAtom } from '../../state/alerts.atom';

export const Alerts = () => {
  const [tab, setTab] = useState(0);
  const filterButtons = ['Recentes', 'Top Óleo', 'Top Gás'].map((it, idx) => ({
    label: it,
    onClick: () => {
      setTab(idx);
    }
  }));

  const { setParam, params } = useParams();
  const [filtersState, setFilterState] = useRecoilState(AlertsAtom);

  // Obter os valores de 'station[]' e converter para letras maiúsculas
  const stations = params
    .getAll('station[]')
    .map((station: string) => station.toUpperCase());

  const paramValues = {
    station: stations
  };

  useEffect(() => {
    setFilterState((old: any) => ({
      ...old,
      ...paramValues
    }));
  }, []);

  const {
    data,
    isFetching,
    isLoading,
    handleFilter,
    perPage,
    currentPage,
    setCurrentPage
  } = useAlerts(tab);

  const componentRef = useRef(null);

  return (
    <PageWrapper className={'p-0'}>
      <div
        className="w-full h-full flex flex-col relative pt-4 px-2 rounded-lg bg-white mt-2"
        ref={componentRef}
      >
        <img
          src="/origem.png"
          alt="Logo Origem"
          className="w-[203px] resize-none max-w-none -ml-5 only-print"
        />

        <H1 className="px-4 whitespace-nowrap">
          <span className="flex gap-4">
            Análise de Vazão
            {isFetching ? <Spinner size={18} /> : null}
          </span>
          <div className="flex gap-2">
            {Object.values(filterButtons).map((it, idx) => (
              <TabsItem key={it.label} {...it} menu={tab} idx={idx} />
            ))}
          </div>
        </H1>
        {isLoading ? (
          <Spinner size={24} />
        ) : (
          <section className="flex flex-col rounded-lg px-2 py-3 pb-0 bg-white">
            <div className="flex w-full overflow-y-auto max-h-[calc(100vh-220px)] scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
              {!data || data?.data?.length < 1 ? (
                <EmptyMessage
                  message="Sem dados disponíveis"
                  className="min-h-[2rem] w-min"
                />
              ) : (
                <Table
                  columns={alertCols}
                  data={data?.data}
                  headerClassName="sticky top-0 bg-white z-10"
                />
              )}
            </div>

            {!!data && (
              <PageNavigation
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                className="mt-2"
                totalPages={Math.ceil(data?.meta?.count / perPage)}
                perPage={perPage}
              />
            )}
          </section>
        )}
      </div>

      <SideFilter
        atom={AlertsAtom}
        filters={alertFilters}
        applyChanges={handleFilter}
      ></SideFilter>
    </PageWrapper>
  );
};
