import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import Input from '../../../components/atoms/Input';
import Button from '../../../components/atoms/Button';
import { queryClient } from '../../../App';
import SelectInput from '../../../components/atoms/SelectInput';
import MdmLayout from '../../../components/templates/MdmLayout';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import { postSatellite } from '../../../services/oilwells.services';

type CreateSatelliteForm = {
  name: string;
  oilFieldId: number;
};

const CreateSatellite: React.FC = () => {
  const { fields } = useMdmUtils({
    fields: true
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<CreateSatelliteForm>();

  const onSubmit = async (data: CreateSatelliteForm) => {
    try {
      await postSatellite({
        ...data,
        oilField: {
          connect: {
            id: Number(data.oilFieldId)
          }
        }
      });
      await queryClient.invalidateQueries(['satellite-adm']);

      toast.success('Poço criado com sucesso');
    } catch (e) {
      toast.error('Erro ao criar poço');
    }
  };

  return (
    <MdmLayout title={'Criar Satelite'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-2 items-center justify-center w-3/4"
      >
        <Input
          label="Nome"
          placeholder="Name"
          {...register('name', { required: true })}
        />

        <SelectInput
          label="Campo"
          placeholder="Campo"
          control={control}
          name={'oilFieldId'}
          required
          options={fields?.map((field: any) => ({
            value: field.id,
            label: field.name
          }))}
        />

        <Button
          className="px-24 h-12 mt-4"
          type="submit"
          title="Criar"
          disabled={!isValid && !errors}
        />
      </form>
    </MdmLayout>
  );
};

export default CreateSatellite;
