import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const opVariables: Column[] = [
  {
    Header: <Header text={'Variável'} id="Variável" />,
    accessor: '1049',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Valores - 1º turno'} id="Valores - 1º turno" />,
    accessor: '1053',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Valores - 2º turno'} id="Valores - 2º turno" />,
    accessor: '1051',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Valores - 3º turno'} id="Valores - 3º turno" />,
    accessor: '1052',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default opVariables;
