import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  TAG: '1779',
  Análise: '1780',
  'Data/Hora da Medição': '1781',
  'Nível (mm)': '1782',
  'Nível (mm) - Declarado': '1783',
  'Pressão (kgf/cm²)': '1784',
  'Pressão (kgf/cm²) - Declarado': '1785',
  'Temperatura (°C)': '1788',
  'Temperatura (°C) - Declarado': '1790',
  'Massa específica p20 (kg/m³, @ 20°C)': '1792',
  'Massa específica pop (kg/m³, @ T,P)': '1793',
  'Volume (m³)': '1794',
  'Massa GLP (Fase Líquida)': '1795',
  'Massa GLP (Fase Vapor)': '1796',
  'Massa GLP Total (ton)': '1797'
};

const bdmfPilarGlp: Column[] = tablefy(items, undefined, Object.keys(items));

export default bdmfPilarGlp;
