import { BiPencil } from 'react-icons/bi';
import { BsTrash3Fill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { queryClient } from '../../../App';
import ModalConfirmation from '../../../components/molecules/ConfirmationModal';
import { deleteOilwell } from '../../../services/oilwells.services';
import SquareButton from '../../../components/atoms/SquareButton';
import { Header } from '../../../components/atoms/table/Header';

const DefaultCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs leading-3 py-2">
      {value || '-'}
    </span>
  );
};

const oilwellsColumns = [
  {
    Header: () => <Header text={'Nome'} id="name" />,
    accessor: 'name',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: () => <Header text={'Nome ANP'} id="nameANP" />,
    accessor: 'nameANP',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: () => <Header text={'Tipo de Fluído'} id="fluid" />,
    accessor: 'fluid',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: () => <Header text={'Tipo de Poço'} id="type" />,
    accessor: 'type',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: () => <Header text={'Código ANP'} id="codeANP" />,
    accessor: 'codeANP',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Campo'} id="oilField" />,
    accessor: 'oilField.name',
    Cell: DefaultCell,
    maxWidth: 120
  },
  {
    Header: () => <Header text={'Linha Conjugada'} id="conjugateLine" />,
    accessor: 'conjugateLine.name',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: () => <Header text={'Locação'} id="location" />,
    accessor: 'location.name',
    Cell: DefaultCell,
    minWidth: 130
  },
  {
    Header: () => <Header text={'Status ANP'} id="status" />,
    accessor: 'status.anp',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Método de elevação'} id="elevationMethod" />,
    accessor: 'elevationMethod',
    Cell: DefaultCell,
    minWidth: 100
  },
  {
    Header: () => <Header text={'Tipo de injeção'} id="injectionType" />,
    accessor: 'injectionType',
    Cell: DefaultCell,
    minWidth: 100
  },
  {
    Header: () => <Header text={'Ramal de água'} id="waterBranch" />,
    accessor: 'waterBranch',
    Cell: DefaultCell,
    minWidth: 100
  },
  {
    Header: () => (
      <Header text={'Despesa de Intervenção'} id="interventionExpense" />
    ),
    accessor: 'interventionExpense',
    Cell: DefaultCell,
    minWidth: 100
  },
  {
    Header: () => <Header text={'Ações'} id="actions" />,
    accessor: 'actions',
    Cell: (props: any) => {
      const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
      const navigate = useNavigate();

      const handleDeleteOilwell = async () => {
        try {
          await deleteOilwell(props.row.original.id);
          toast.success('Poço deletado com sucesso');
          queryClient.invalidateQueries(['oilwells-adm']);
        } catch {
          toast.error('Erro ao deletar poço');
        }
      };

      return (
        <>
          <div className="flex gap-2">
            <SquareButton
              className="bg-red hover:bg-red/75"
              icon={<BsTrash3Fill color="white" size={16} />}
              onClick={() => setModalDeleteIsOpen(old => !old)}
            />

            <SquareButton
              icon={<BiPencil color="white" size={16} />}
              onClick={() => navigate(`/oilwells/${props.row.original.id}`)}
            />
          </div>

          {modalDeleteIsOpen && (
            <ModalConfirmation
              confirmAction={handleDeleteOilwell}
              title="Deletar poço"
              description="Tem certeza que deseja deletar este poço? Essa é uma ação permanente."
              confirmButtonClassName="bg-emerald-600 hover:bg-emerald-400"
              isOpen={true}
              setIsOpen={() => setModalDeleteIsOpen(!modalDeleteIsOpen)}
            />
          )}
        </>
      );
    }
  }
];

export default oilwellsColumns;
