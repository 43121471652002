import { format } from 'date-fns';
import { FaCircleInfo } from 'react-icons/fa6';
import { Column } from 'react-table';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { DefaultCell } from '../../components/atoms/table/DefaultCell';
import { Header } from '../../components/atoms/table/Header';
import { numberFormatter } from '../../utils/utils';
import { BiSolidCommentDots } from 'react-icons/bi';

const InfoTooltip = ({
  value,
  row: {
    original: { id, textComplement }
  }
}: any) => {
  return (
    <span className="text-primary text-xs whitespace-nowrap flex">
      <div
        data-tooltip-id={`oilwell-${id}-${value}`}
        data-tooltip-target="tooltip-default"
      >
        {textComplement ? <FaCircleInfo className="mr-2" size={16} /> : null}
      </div>
      {value}

      {textComplement ? (
        <ReactTooltip
          id={`oilwell-${id}-${value}`}
          place="top"
          variant="dark"
          style={{ zIndex: 99 }}
          render={() => (
            <div className="flex flex-col">
              <span>
                Data:{' '}
                {textComplement?.data_do_teste
                  ? format(
                      new Date(textComplement?.data_do_teste),
                      'dd/MM/yyyy'
                    )
                  : null}
              </span>
              <span>
                Perda gás: {numberFormatter.format(textComplement?.perda_gas)}
              </span>
              <span>
                Perda óleo: {numberFormatter.format(textComplement?.perda_oleo)}
              </span>
              <span>
                Potencial gás:{' '}
                {numberFormatter.format(textComplement?.potencial_gas)}
              </span>
              <span>
                Potencial óleo:{' '}
                {numberFormatter.format(textComplement?.potencial_oleo)}
              </span>
            </div>
          )}
        />
      ) : null}
    </span>
  );
};

export const gridOilwellFieldMapper: Record<string, string> = {
  Furado: '995',
  Pilar: '697',
  'Tucano Sul': '2603'
};

const reportBdoCols = (field: string): Column[] =>
  [
    {
      Header: () => <Header text={'Poço'} id="poço" />,
      accessor: gridOilwellFieldMapper[field],
      Cell: InfoTooltip,
      width: '12%',
      minWidth: 60
    },
    {
      Header: () => <Header text={'Hora Fechamento'} id="Hora Fechamento" />,
      accessor: 'Hora Fechamento',
      Cell: ({
        value,
        row: {
          original: { id, obsFechamento },
          index
        },
        dateFormat
      }: any) => {
        function isDateValid(dateString: string | null | undefined) {
          if (!dateString) return false;

          const date = new Date(dateString);

          if (isNaN(date.getTime())) {
            return false;
          }

          return true;
        }

        return (
          <div className="flex gap-[0.3rem]">
            <span
              className="text-[#191919] text-xs"
              key={`${id}Pressure${index}`}
            >
              {isDateValid(value)
                ? format(
                    new Date(value?.replace('Z', '')?.split('+')[0]),
                    dateFormat ?? 'HH:mm'
                  )
                : value}
            </span>
            {obsFechamento && (
              <BiSolidCommentDots
                className="mr-0.5 opacity-20"
                data-tooltip-id={`tooltip-observation-closed-${index}`}
                size={14}
              />
            )}
            <ReactTooltip
              id={`tooltip-observation-closed-${index}`}
              place="top"
              variant="dark"
              style={{
                maxWidth: '250px',
                textAlign: 'center',
                zIndex: 99,
                fontSize: '13px'
              }}
              content={obsFechamento}
            />
          </div>
        );
      },
      width: '12.5%'
    },
    {
      Header: () => <Header text={'Hora Abertura'} id="Hora Abertura" />,
      accessor: 'Hora Abertura',
      Cell: ({
        value,
        row: {
          original: { id, obsAbertura },
          index
        },
        dateFormat
      }: any) => {
        function isDateValid(dateString: string | null | undefined) {
          if (!dateString) return false;

          const date = new Date(dateString);

          if (isNaN(date.getTime())) {
            return false;
          }

          return true;
        }

        return (
          <div className="flex gap-[0.3rem]">
            <span
              className="text-[#191919] text-xs"
              key={`${id}Pressure${index}`}
            >
              {isDateValid(value)
                ? format(
                    new Date(value?.replace('Z', '')?.split('+')[0]),
                    dateFormat ?? 'HH:mm'
                  )
                : value}
            </span>

            {obsAbertura && (
              <BiSolidCommentDots
                data-tooltip-id={`tooltip-observation-openned-${index}`}
                className="mr-0.5 opacity-20"
                size={14}
              />
            )}

            <ReactTooltip
              id={`tooltip-observation-openned-${index}`}
              place="top"
              variant="dark"
              style={{
                maxWidth: '250px',
                textAlign: 'center',
                zIndex: 99,
                fontSize: '13px'
              }}
              content={obsAbertura}
            />
          </div>
        );
      },
      width: '12.5%'
    },
    {
      Header: () => <Header text={'Motivo'} id="motivo" />,
      accessor: '771',
      Cell: DefaultCell,
      minWidth: 120,
      width: '12.5%'
    },
    {
      Header: () => (
        <Header text={'Sistema de pressão'} id="sistema_de_pressão" />
      ),
      id: 'pressure',
      accessor: field == 'Furado' ? '1494' : '773',
      Cell: DefaultCell,
      width: '12.5%',
      minWidth: 180
    },
    {
      Header: () => <Header text={'Avaliação'} id="avaliacao" />,
      accessor: '772',
      Cell: DefaultCell,
      width: '8%',
      minWidth: 80
    }
  ].filter(it => (field !== 'Tucano Sul' ? true : it.id !== 'pressure'));

export { reportBdoCols };
