import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'N° PT': '841',
  'Tipo de Serviço': '837',
  Poço: '2603',
  'Área Executante': '838',
  'Nome do Emitente': '2641',
  'Nome do Executante': '840'
};
const ptEmitTS: Column[] = tablefy(items);

export default ptEmitTS;
