import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Equipamento: '503',
  'Horímetro Acumulado': '1044',
  'Horas Operando (HO)': '1171',
  'Horás Disponíveis (HD)': '1550',
  'Indisponibilidade de Equipamentos(Horas)': '1551',
  'Indisponibilidade Programada (Horas)': '1552'
};

const horiAndDisp: Column[] = tablefy(items);

export default horiAndDisp;
