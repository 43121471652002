import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const bcpCols: Column[] = [
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'Poço',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: () => <Header text={'Hora'} id="Hora" />,
    accessor: 'Hora',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 150
  },
  {
    Header: () => <Header text={'RPM do Motor'} id="RPM do Motor" />,
    accessor: 'RPM do Motor',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => <Header text={'RPM das Hastes'} id="RPM das Hastes" />,
    accessor: 'RPM das Hastes',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => (
      <Header text={'Torque do Motor (Nm)'} id="Torque do Motor (Nm)" />
    ),
    accessor: 'Torque do Motor (Nm)',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => (
      <Header text={'Torque das Hastes (Nm)'} id="Torque das Hastes (Nm)" />
    ),
    accessor: 'Torque das Hastes (Nm)',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => <Header text={'Corrente (A)'} id="Corrente (A)" />,
    accessor: 'Corrente (A)',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => <Header text={'Frequência (Hz)'} id="Frequência (Hz)" />,
    accessor: 'Frequência (Hz)',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => (
      <Header
        text={'Pressão de cabeça (kgf/cm²)'}
        id="Pressão de cabeça (kgf/cm²)"
      />
    ),
    accessor: 'Pressão de cabeça (kgf/cm²)',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => (
      <Header
        text={'Pressão de linha (kgf/cm²)'}
        id="Pressão de linha (kgf/cm²)"
      />
    ),
    accessor: 'Pressão de linha (kgf/cm²)',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => (
      <Header text={'Pressão anular (kgf/cm²)'} id="Pressão anular (kgf/cm²)" />
    ),
    accessor: 'Pressão anular (kgf/cm²)',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => <Header text={'Observação'} id="Observação" />,
    accessor: 'Observação',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  }
];

export default bcpCols;
