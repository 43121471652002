import { useState } from 'react';
import { BiSolidLock } from 'react-icons/bi';
import { FaChevronRight } from 'react-icons/fa';
import { LiaSkullCrossbonesSolid } from 'react-icons/lia';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

interface BdoCardItemProps {
  to: string;
  title: string;
  icon: React.FC<{ size: number; color?: string }>;
  description?: string;
  disabled?: boolean;
  iconSize?: number;
  alert?: string;
  options?: {
    label: string;
    value: string;
  }[];
}

export const BdoCardItem = ({
  to,
  title,
  icon: Icon,
  description,
  disabled,
  iconSize,
  alert,
  options = []
}: BdoCardItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleSelect = () => {
    if (disabled) return;
    if (options.length === 0) {
      navigate(to);
      return;
    }
    setIsOpen(old => !old);
  };

  return (
    <div
      key={title}
      className={
        'flex flex-col py-3 rounded-md transition-all px-4 bg-[#f4f6ff] w-[240px] gap-1'
      }
    >
      <div className="w-full flex justify-between">
        <div
          className={twMerge(
            'bg-persian-blue-600 rounded-full flex items-center justify-center aspect-square w-[28px] flex-none mb-2',
            disabled && 'bg-[#666]'
          )}
        >
          <Icon size={iconSize || 16} color="white" />
        </div>

        {alert ? (
          <>
            <div
              data-tooltip-id={`alert-${title}`}
              data-tooltip-target="tooltip-default"
            >
              {import.meta.env.DEV ? (
                <LiaSkullCrossbonesSolid
                  size={24}
                  color="#666"
                  className="flex-none"
                />
              ) : (
                <BiSolidLock size={24} color="#666" className="flex-none" />
              )}
            </div>
            <ReactTooltip
              id={`alert-${title}`}
              place="top"
              variant="dark"
              style={{ maxWidth: '250px', textAlign: 'center' }}
              content={alert}
            />
          </>
        ) : null}
      </div>

      <div
        onClick={handleSelect}
        className={twMerge(
          'font-bold flex items-center text-body-dark transition-colors duration-200 cursor-pointer relative w-full select-none',
          disabled ? 'cursor-not-allowed text-gray-lighter' : 'hover:text-body'
        )}
      >
        {title}
        <div className="relative">
          <FaChevronRight size={12} className="ml-2" />
          <div
            className={`
            absolute 
            bg-white 
            -top-[3.4rem] 
            flex flex-col 
            p-2 
            shadow-light
            before:content-[''] before:absolute before:-left-1 before:top-8 before:w-4 before:h-4 before:bg-white before:rotate-45 before:-z-10
            rounded-lg
            z-50
            transition-all
            translate-x-1/2
            ${
              isOpen
                ? 'opacity-100 translate-y-5 visible'
                : 'opacity-0 translate-y-0 invisible'
            }
      `}
          >
            {options.map(it => (
              <Link
                key={it.value}
                to={it.value}
                className="flex items-center gap-6 py-2 px-3 w-[120px] hover:bg-btn-light rounded-md group transition-all"
              >
                <span className="text-sm text-gray group-hover:text-body-dark transition-all">
                  {it.label}
                </span>
              </Link>
            ))}
          </div>
        </div>
      </div>

      <span
        className={twMerge(
          'text-body whitespace-pre-wrap text-sm',
          disabled && 'cursor-not-allowed text-gray-lighter'
        )}
      >
        {description}
      </span>
    </div>
  );
};
