import Modal from '../atoms/Modal';
import Spinner from '../atoms/Spinner';
import TestWorkflowListItem from './TestWorkflowListItem';
import { IoClose } from 'react-icons/io5';
import { WorkflowTestProps } from './WorkflowPhaseCard';

interface WorkflowPhaseModalProps {
  title: string;
  tests: WorkflowTestProps[];
  isOpen: boolean;
  isLoading: boolean;
  setIsOpen: (value: boolean) => void;
  totalCount: number;
  setLoadMore: () => void;
  alertIcon?: React.ReactNode;
  hasShortText?: boolean;
  className?: string;
  phase: {
    name: string;
    ids: number[];
    isExpectedDate?: boolean;
    forceEndTestDate?: boolean;
    invertDates?: boolean;
    dateLabel?: string;
    urlRedirect?: string;
  };
}

const WorkflowPhaseModal: React.FC<WorkflowPhaseModalProps> = ({
  title,
  tests,
  isOpen,
  setIsOpen,
  setLoadMore,
  alertIcon,
  className,
  isLoading,
  totalCount,
  hasShortText,
  phase
}) => {
  const { isExpectedDate = false, forceEndTestDate = false, dateLabel } = phase;

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} className={className}>
      <div className="bg-white rounded-2xl py-3 overflow-hidden">
        <div className="flex items-center justify-between">
          <div className="flex w-full pb-3 pt-2 mx-4 border-gray-200 border-b-[1px] border-[#DFDFDF]">
            <h1 className="text-xs text-primary font-bold">{title}</h1>

            <div className="flex ml-2 items-center justify-center max-h-4 px-[4px] bg-[#758ad5] rounded-full">
              <span className="text-xs text-white">{totalCount}</span>
            </div>

            <IoClose
              size={20}
              className="ml-auto text-primary hover:cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>
        </div>

        <div className="max-h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full">
          <section className="flex flex-col h-full gap-2 flex-shrink flex-grow basis-auto min-h-0 scrollbar-thin pt-[10px] px-4 pb-4">
            {tests?.map(test => (
              <TestWorkflowListItem
                id={test.id}
                title={test.name}
                slaDate={test.slaDate}
                isPending={test.isPending}
                obsIsPending={test.obsIsPending}
                alertIcon={alertIcon}
                tags={test.tags}
                key={test.id}
                urlRedirect={phase.urlRedirect}
                phase={phase.ids}
                hasShortText={hasShortText}
                shortText={test.shortText}
                testPhase={test.status}
                date={
                  !forceEndTestDate &&
                  (isExpectedDate || test.tags[0] === 'Rápido')
                    ? {
                        value: new Date(
                          test.expectedTestDate || test.createdAt
                        ),
                        title:
                          test.expectedTestDate !== undefined
                            ? 'Data Prevista'
                            : 'Data de Solicitação'
                      }
                    : {
                        value: test.endTestDate,
                        title: dateLabel ?? 'Fim do Teste'
                      }
                }
              />
            ))}
            {totalCount > tests.length &&
              (isLoading ? (
                <Spinner />
              ) : (
                <button
                  onClick={setLoadMore}
                  className="text-primary text-xs mt-2 py-1 px-3 bg-btn-light hover:bg-btn-light-2 rounded transition-colors w-min whitespace-nowrap"
                >
                  Ver Mais
                </button>
              ))}
          </section>
        </div>
      </div>
    </Modal>
  );
};

export default WorkflowPhaseModal;
