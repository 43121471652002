import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const changeLine: Column[] = [
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'Poço',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: 'Hora',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Linha Antiga'} id="Linha Antiga" />,
    accessor: 'Linha Antiga',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Linha Nova'} id="Linha Nova" />,
    accessor: 'Linha Nova',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Observação'} id="obss" />,
    accessor: 'obs',
    Cell: DefaultCell,
    width: '8%'
  }
];

export default changeLine;
