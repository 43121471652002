import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const dewPoint: Column[] = [
  {
    Header: <Header text={'Peneira'} id="Peneira" />,
    accessor: '1571',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: '112',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Resultado (mg/m³)'} id="Resultado (mg/m³)" />,
    accessor: '1057',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: '712',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default dewPoint;
