import { format } from 'date-fns';
import { Column } from 'react-table';
import { Header } from '../../components/atoms/table/Header';
import { DefaultCell } from '../../components/atoms/table/DefaultCell';

export const DateHourCell = ({
  value,
  row: {
    original: { id },
    index
  }
}: any) => {
  function isDateValid(dateString: string | null | undefined) {
    if (!dateString) return false;

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return false;
    }

    return true;
  }

  return (
    <span className="text-[#191919] text-xs" key={`${id}Pressure${index}`}>
      {isDateValid(value)
        ? format(new Date(value.split('+')[0]), 'dd/MM/yyyy HH:mm')
        : value}
    </span>
  );
};

const openClosingCols: Column[] = [
  {
    Header: () => <Header text={'ID'} id="id" />,
    accessor: 'id_bdo',
    Cell: ({ value }: any) => {
      return (
        <a
          className="text-primary text-xs font-bold"
          href={`${import.meta.env.VITE_REDMINE_URL}/issues/${value}`}
          target="_blank"
        >
          {value}
        </a>
      );
    },
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Poço'} id="poço" />,
    accessor: 'poço',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Hora Fechamento'} id="Hora Fechamento" />,
    accessor: 'Hora Fechamento',
    Cell: DateHourCell,
    width: '12.5%'
  },
  {
    Header: () => <Header text={'Hora Abertura'} id="Hora Abertura" />,
    accessor: 'Hora Abertura',
    Cell: DateHourCell,
    width: '12.5%'
  },
  {
    Header: () => <Header text={'Motivo'} id="motivo" />,
    accessor: 'motivo',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => (
      <Header text={'Sistema de pressão'} id="sistema_de_pressão" />
    ),
    accessor: 'sistema_de_pressão',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Observação'} id="observação" />,
    accessor: 'observação',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  }
];

export { openClosingCols };
