import { getCustomFields } from '@/services/utils.services';
import { grid2array } from '@/utils/customFields';
import { getNestedValue } from '@/utils/table';
import { useQuery } from '@tanstack/react-query';

export const useRetrieveCustomFields = (
  automaticGridIds: (string | number)[],
  data: any
) => {
  const cfGridIds: string[] = [];
  automaticGridIds.forEach(id => {
    const gridData = Array.isArray(data)
      ? data?.find((it: any) => it.id == id)?.value
      : getNestedValue(data, id.toString());

    if (!gridData) return;
    const arrayGrid = grid2array(gridData);

    if (arrayGrid.length === 0) return;

    Object.keys(arrayGrid?.[0]).forEach(key => {
      if (!cfGridIds.includes(key)) {
        cfGridIds.push(key);
      }
    });
  });

  const { data: gridColumnsCustomFields } = useQuery({
    queryFn: async () => await getCustomFields(cfGridIds),
    queryKey: ['cf_grids', cfGridIds],
    enabled: cfGridIds.length > 0
  });

  return {
    gridCustomFields: gridColumnsCustomFields?.data
  };
};
