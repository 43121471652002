import { format } from 'date-fns';
import { useState } from 'react';
import { BiPencil } from 'react-icons/bi';
import { BsTrash3Fill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { queryClient } from '../../../App';
import SquareButton from '../../../components/atoms/SquareButton';
import ModalConfirmation from '../../../components/molecules/ConfirmationModal';
import { deleteZone } from '../../../services/oilwells.services';
import { NumberCell } from '../../../utils/tablesUtils';
import { Header } from '../../../components/atoms/table/Header';

const DefaultCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs whitespace-nowrap w-full">
      {value || '-'}
    </span>
  );
};

export const TimeCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs whitespace-nowrap">
      {value ? format(new Date(value), 'dd/MM/yyyy') : '-'}
    </span>
  );
};

const volumeInPlaceCols = [
  {
    Header: () => <Header text={'ID'} id="id" />,
    accessor: 'id',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Código'} id="code" />,
    accessor: 'code',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Nome'} id="name" />,
    accessor: 'name',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Sigla'} id="code" />,
    accessor: 'acronym',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Campo'} id="oilfield" />,
    accessor: 'oilField.name',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Volume Gás Associado'} id="gasa" />,
    accessor: 'gasa',
    Cell: NumberCell
  },
  {
    Header: () => <Header text={'Volume Gás Natural'} id="gasn" />,
    accessor: 'gasn',
    Cell: NumberCell
  },
  {
    Header: () => <Header text={'Volume Gás Lift Associado'} id="glas" />,
    accessor: 'glas',
    Cell: NumberCell
  },
  {
    Header: () => <Header text={'Volume Óleo'} id="oleo" />,
    accessor: 'oleo',
    Cell: NumberCell
  },
  {
    Header: () => <Header text={'Volume Condensado'} id="cond" />,
    accessor: 'cond',
    Cell: NumberCell
  },
  {
    Header: () => <Header text={'Data de atualização'} id="updatedAt" />,
    accessor: 'updatedAt',
    Cell: TimeCell
  },
  {
    Header: () => <Header text={'Ações'} id="actions" />,
    accessor: 'actions',
    Cell: (props: any) => {
      const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
      const navigate = useNavigate();

      const handleDeleteZone = async () => {
        try {
          await deleteZone(props.row.original.id);
          toast.success('Zona deletada com sucesso');
          queryClient.invalidateQueries({
            predicate: query => query.queryKey[0] === 'zones-adm'
          });
        } catch {
          toast.error('Erro ao deletar zona');
        }
      };

      return (
        <>
          <div className="flex gap-2">
            <SquareButton
              className="bg-red hover:bg-red/75"
              onClick={() => setModalDeleteIsOpen(old => !old)}
              icon={<BsTrash3Fill color="white" size={16} />}
            />
            <SquareButton
              onClick={() => navigate(`/zones/${props.row.original.id}`)}
              icon={<BiPencil color="white" size={16} />}
            />
          </div>

          {modalDeleteIsOpen && (
            <ModalConfirmation
              confirmAction={handleDeleteZone}
              title="Deletar zona"
              description="Tem certeza que deseja deletar essa zona? Essa é uma ação permanente."
              confirmButtonClassName="bg-emerald-600 hover:bg-emerald-400"
              isOpen={true}
              setIsOpen={() => setModalDeleteIsOpen(!modalDeleteIsOpen)}
            />
          )}
        </>
      );
    }
  }
];

export default volumeInPlaceCols;
