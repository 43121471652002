import { objectify } from './WorflowTests';

const emitGroup = [
  {
    value: '782',
    label: 'CIP'
  },
  {
    value: '777',
    label: 'ESTAÇÃO - P16'
  },
  'FURADO - CAMPO',
  'FURADO - ESTAÇÃO',
  'FURADO - LABORATÓRIO',
  'FURADO - MOV CARGA',
  'FURADO - O&M COMPRESSÃO',
  'FURADO - SOP',
  'FURADO - SUBESTAÇÃO',
  'FURADO - TIC',
  {
    value: '769',
    label: 'MAN - CALDEIRARIA'
  },
  'PILAR - CAMPO',
  'PILAR - ESTAÇÃO',
  'PILAR - FLUIDOS',
  'PILAR - LABORATÓRIO',
  'PILAR - MOV CARGA',
  'PILAR - O&M COMPRESSÃO',
  'PILAR - SOP',
  'PILAR - SUBESTAÇÃO',
  'PILAR - TIC',
  {
    value: '784',
    label: 'SOP - ARMAZENAGEM'
  },
  {
    value: '792',
    label: 'SOP - LIDERANÇA'
  },
  {
    value: '786',
    label: 'SOP - LIMPEZA E CONSERVAÇÃO'
  },
  {
    value: '785',
    label: 'SOP - MANUTENÇÃO PREDIAL'
  },
  {
    value: '440',
    label: 'SOP - TRANSPORTE DE CARGAS'
  },
  {
    value: '856',
    label: 'OFICINA DE POÇOS'
  },
  'TUCANO SUL',
  'UPGN'
].sort((a, b) => {
  const otherValue = typeof b == 'string' ? b : b.label;
  return typeof a == 'string'
    ? a.localeCompare(otherValue)
    : a.label.localeCompare(otherValue);
});

const executionValues = [
  'ESTAÇÃO - P16',
  'FURADO - CAMPO',
  'FURADO - CANTEIRO',
  'FURADO - ESTAÇÃO',
  'FURADO - LABORATÓRIO',
  'FURADO - MOV CARGA',
  'FURADO - O&M COMPRESSÃO',
  'FURADO - SOP',
  'FURADO - SUBESTAÇÃO',
  'FURADO - TIC',
  'PILAR - CAMPO',
  'PILAR - CANTEIRO',
  'PILAR - ESTAÇÃO',
  'PILAR - FLUIDOS',
  'PILAR - LABORATÓRIO',
  'PILAR - MOV CARGA',
  'PILAR - O&M COMPRESSÃO',
  'PILAR - SOP',
  'PILAR - SUBESTAÇÃO',
  'PILAR - TIC',
  'TUCANO SUL',
  'UPGN'
].sort((a, b) => a.localeCompare(b));

const executionArea = executionValues.map(objectify);

const highRiskValues = ['Sim', 'Não'];
const highRisk = highRiskValues.map(objectify);

const activityValues = [
  'Serviços elétricos',
  'Trabalho em altura',
  'Espaço confinado',
  'Trabalho a quente',
  'Movimentação de carga',
  'Energias perigosas (LOTO)',
  'Escavação',
  'Hidrojateamento',
  'Teste hidrostático'
];
const activity = activityValues.map(objectify);

const typeValues = [
  'PT Convencional',
  'PT Renovada',
  'PT Temporária',
  'Área Liberada'
];
const type = typeValues.map(objectify);

export const workPermitFilters = [
  { key: 'type', name: 'Tipo de PT', values: type },
  { key: 'timeRange', name: 'Início da execução', values: [] },
  { key: 'highRisk', name: 'Alto Potencial de Risco', values: highRisk },
  { key: 'executionArea', name: 'Área de Execução', values: executionArea },
  {
    key: 'emitGroup',
    name: 'Grupo Emitente',
    values: emitGroup.map(it =>
      typeof it === 'string'
        ? {
            value: it,
            label: it
          }
        : it
    )
  },
  { key: 'activity', name: 'Tipo de atividade', values: activity }
];
