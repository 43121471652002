import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Data/Hora da Medição': '2643',
  'Vazão Instantânea (m³/d)': '2645',
  'Acumulado (m³)': '2646',
  'Acumulado (m³) - Declarado': '2744',
  'Temperatura (°C)': '1252',
  'Pressão (psi)': '2649',
  'Pressão (kgf/cm²)': '1253'
};
const emedFQUIA: Column[] = tablefy(items, undefined, undefined, undefined, [
  'Vazão Instantânea (m³/d)',
  'Acumulado (m³)',
  'Acumulado (m³) - Declarado',
  'Temperatura (°C)',
  'Pressão (psi)',
  'Pressão (kgf/cm²)'
]);

export default emedFQUIA;
