import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const airCoolers: Column[] = [
  {
    Header: <Header text={'Air Cooler'} id="Air Cooler" />,
    accessor: '1047',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Horímetro'} id="Horímetro" />,
    accessor: '1048',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default airCoolers;
