import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import { Label } from '../../components/atoms/Label';
import Spinner from '../../components/atoms/Spinner';
import { TextField } from '../../components/atoms/TextField';
import SideFilter from '../../components/molecules/SideFilter';
import { useBdoProduction } from '../../hooks/BDO/useBdoProduction';
import { BDOCalendarAtom } from '../../state/bdo.calendar.atom';
import { convertGridToJson } from '../../utils/bdos';
import { numberFormatter } from '../../utils/utils';
import { Column } from 'react-table';
import Table from '../../components/organisms/Table';
import EmptyMessage from '../../components/atoms/EmptyMessage';
import { PageWrapper } from '../../components/atoms/PageWrapper';

const TableGrid = ({
  title,
  cols,
  data,
  children,
  bodyClassName,
  scrollable,
  key
}: {
  title: string;
  cols: Column[];
  data: any;
  key?: string | number;
  children?: React.ReactNode;
  bodyClassName?: string;
  scrollable?: boolean;
}) => (
  <div className="flex flex-col w-full" key={key ?? title}>
    {children}
    {title && (
      <div className="flex w-full text-primary text-lg mb-2">
        <span className="font-bold">{title}</span>
      </div>
    )}

    <div
      className={`w-full ${
        scrollable &&
        `overflow-y-auto max-h-[600px] 
        scrollbar-thin scrollbar-thumb-[#D9D9D9AA] 
        scrollbar-thumb-rounded-full scrollable-table`
      }`}
    >
      {data && data.length > 0 ? (
        <Table
          key={key ?? title}
          columns={cols}
          data={data}
          headerClassName="sticky top-0 bg-white z-10"
          bodyClassName={bodyClassName ?? ''}
        />
      ) : (
        <EmptyMessage
          message="Sem dados disponíveis"
          className="min-h-[2rem] w-min"
        />
      )}
    </div>
  </div>
);

interface BdoViewTanksProps {
  title: string;
  bdoView: any;
}

const BdoViewTanks: React.FC<BdoViewTanksProps> = ({ title, bdoView }) => {
  const [params, setParam] = useSearchParams();

  const tracker = params.get('tracker');
  const currentDate = params.get('date');
  const date = currentDate
    ? format(new Date(currentDate.replaceAll('-', '/')), 'yyyy-MM-dd')
    : undefined;

  const { dataEP } = useBdoProduction(date, tracker ?? '');

  const bdoArray = dataEP?.issues?.[0]?.custom_fields;

  const reportDate = bdoArray?.find((it: any) => it.id == 248)?.value as string;

  return (
    <PageWrapper>
      <div className="flex flex-col w-full flex-wrap bg-white rounded-lg px-4 py-2 flex-1">
        <h2 className="text-2xl text-primary font-bold w-full flex">
          {dataEP?.issues?.[0]?.tracker?.name
            ? `${dataEP?.issues?.[0]?.tracker?.name} -`
            : ''}{' '}
          {title}{' '}
          <span className="text-gray text-sm h-full align-text-bottom pt-[0.6rem] ml-1">
            -{' '}
            {reportDate
              ? format(new Date(reportDate.replaceAll('-', '/')), 'dd/MM/yyyy')
              : null}
          </span>
        </h2>

        {!bdoArray && <Spinner size={28} />}

        <div className="flex flex-wrap gap-8 gap-y-4 overflow-hidden w-full print-element-scroller">
          {bdoView.map(
            (item: any) =>
              bdoArray?.find((it: any) => it.id == item.id)?.value &&
              (item.type == 'label' ? (
                <Label
                  name={item.name}
                  id={item.id}
                  key={item.id + (date ?? '')}
                />
              ) : item.type == 'grid' ? (
                <TableGrid
                  title={item.name}
                  cols={item.columns}
                  key={item.id + (date ?? '')}
                  bodyClassName="h-4"
                  data={Object.values(
                    convertGridToJson(
                      (bdoArray?.find((it: any) => it.id == item.id)
                        ?.value as string) ?? '{}'
                    ) ?? {}
                  )}
                />
              ) : (
                (item.field ? item.field == tracker : true) && (
                  <TextField
                    name={item.name}
                    key={item.id + (date ?? '') + item.name}
                    value={
                      item.type == 'number'
                        ? numberFormatter.format(
                            Number(
                              bdoArray?.find((it: any) => it.id == item.id)
                                ?.value
                            ) ?? ''
                          )
                        : item.type == 'custom'
                          ? numberFormatter.format(
                              Number(
                                Object.values(
                                  convertGridToJson(
                                    (bdoArray?.find(
                                      (it: any) => it.id == item.id
                                    )?.value as string) ?? '{}'
                                  ) ?? {}
                                ).reduce((acc: any, cur: any) => {
                                  return acc + Number(cur[525]);
                                }, 0) ?? 0
                              )
                            )
                          : ((bdoArray?.find((it: any) => it.id == item.id)
                              ?.value as string) ?? '')
                    }
                  />
                )
              ))
          )}
        </div>
      </div>

      <SideFilter
        atom={BDOCalendarAtom}
        filters={[]}
        floatButtonClassName="top-20"
      >
        <div className="flex flex-col gap-1">
          <h3 className="text-sm font-bold text-white">Data</h3>
          <Datepicker
            value={{
              startDate: currentDate ? new Date(currentDate) : new Date(),
              endDate: currentDate ? new Date(currentDate) : new Date()
            }}
            asSingle={true}
            useRange={false}
            primaryColor={'sky'}
            onChange={(val: any) => {
              return setParam({
                date: val?.startDate?.replaceAll('-', '/'),
                tracker: tracker ?? ''
              });
            }}
            i18n="pt-br"
            placeholder="Selecione uma data"
            inputClassName={
              'relative transition-all duration-300 h-[36px] pl-2 pr-10 w-full border-[1px] border-[#cccccc] dark:border-slate-600 rounded-[10px] tracking-wide font-light text-sm text-white placeholder-white bg-primary focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20'
            }
            maxDate={new Date()}
            classNames={{
              toggleButton() {
                return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
              }
            }}
          />
        </div>
      </SideFilter>
    </PageWrapper>
  );
};

export default BdoViewTanks;
