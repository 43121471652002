import { format } from 'date-fns';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { PageWrapper } from '../../components/atoms/PageWrapper';
import SelectInputUncontrolled from '../../components/atoms/SelectInputUncontrolled';
import Spinner from '../../components/atoms/Spinner';
import { BdoCards } from '../../components/molecules/BdoCards';
import { SideBarDateAndTracker } from '../../components/organisms/SideBarDateAndTracker';
import ViewSwitch from '../../components/organisms/ViewSwitch';
import { bdoTs } from '../../constants/view/bdos/bdoTS';
import { useBdoTucano } from '../../hooks/BDO/useBdoTucano';
import { useParams } from '../../hooks/Common/useParams';
import { BDOCalendarAtom } from '../../state/bdo.calendar.atom';
import { generateHashByCFs } from '../../utils/customFields';

const BdoViewTS: React.FC = () => {
  const { date } = useParams();

  const { bdoArray, isLoading, cards, isLoadingCards } = useBdoTucano(date);

  const reportDate = date;

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const [isScrolling, setIsScrolling] = useState(false);
  const selectRef = useRef<any>(null);

  return (
    <PageWrapper>
      <div className="absolute w-72 right-16 -top-10">
        <SelectInputUncontrolled
          isMulti={false}
          ref={selectRef}
          className="z-[11]"
          placeholder="Ir para seção..."
          options={
            bdoTs
              .filter(
                it =>
                  (it.type === 'label' && it.size !== 'h2') ||
                  (it.type === 'grid' && it.searchable)
              )
              .map(it => ({
                value: it.id,
                label: it.name
              })) as any
          }
          onChange={(val: any) => {
            setIsScrolling(true);
            const targetDiv = document.getElementById(val.value);
            if (targetDiv) {
              targetDiv.scrollIntoView({ behavior: 'smooth' });
            }
            setTimeout(() => {
              setIsScrolling(false);
            }, 1000);
          }}
        />
      </div>

      <div
        onScroll={() => {
          if (selectRef.current.getValue() && !isScrolling)
            selectRef.current?.setValue('');
        }}
        className="w-full h-full overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full"
      >
        <div
          className="flex flex-col w-full flex-wrap bg-white rounded-lg px-4 py-3 flex-1"
          ref={componentRef}
        >
          <h2 className="text-2xl text-primary font-bold border-b border-primary pb-4 mb-2 w-full flex justify-between">
            <span>
              BDO Tucano Sul
              <span className="text-gray text-sm h-full align-text-bottom pt-[0.6rem] ml-1">
                -{' '}
                {reportDate
                  ? format(
                      new Date(reportDate.replaceAll('-', '/')),
                      'dd/MM/yyyy'
                    )
                  : null}
              </span>
            </span>

            <button
              onClick={handlePrint}
              className="text-base font-medium px-2 py-1 rounded border no-print"
            >
              Imprimir
            </button>
          </h2>

          {isLoading && <Spinner size={28} className="mt-2" />}

          <div className="flex flex-wrap gap-4 gap-y-3 overflow-hidden w-full print-element-scroller">
            <div className="flex bg-white rounded-lg w-full justify-between gap-4 flex-wrap">
              {cards.map(card => (
                <BdoCards
                  key={card.title}
                  title={card.title}
                  data={card.data}
                  isLoading={isLoadingCards}
                />
              ))}
            </div>

            {bdoArray &&
              bdoArray.length > 0 &&
              bdoTs
                .map((it, idx: number) =>
                  bdoArray[it.bdoIdx].map((issue: any) => {
                    const customFieldValues = generateHashByCFs(
                      issue.custom_fields
                    );

                    return {
                      customFields: customFieldValues,
                      idx,
                      date,
                      name:
                        it?.bdoIdx == 1
                          ? `${it.name} ${customFieldValues[2598]} - ${customFieldValues[2735]}`
                          : it?.bdoIdx == 2
                            ? `${it.name} - Equipe Manutenção ${customFieldValues[2598]}`
                            : it.name,
                      item: it
                    };
                  })
                )
                .flat(1)
                .map(it => <ViewSwitch key={it.name} {...it} />)}
          </div>
        </div>
      </div>

      <SideBarDateAndTracker
        atom={BDOCalendarAtom}
        filters={[]}
        floatButtonClassName="top-20"
      />
    </PageWrapper>
  );
};

export default BdoViewTS;
