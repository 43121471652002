import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const changeSystem: Column[] = [
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'Poço',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: 'Hora',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Linha'} id="Linha" />,
    accessor: 'Linha',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: (
      <Header
        text={'Sistema antigo de pressão'}
        id="Sistema antigo de pressão"
      />
    ),
    accessor: 'Sistema antigo de pressão',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: (
      <Header text={'Sistema novo de pressão'} id="Sistema novo de pressão" />
    ),
    accessor: 'Sistema novo de pressão',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: 'Observação',
    Cell: DefaultCell,
    width: '8%'
  }
];

export default changeSystem;
