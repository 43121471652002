import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';
import { TextCell } from '../../../components/atoms/table/TextCell';
import { Header } from '../../../components/atoms/table/Header';
import { NumberCell2 } from '../../../components/atoms/table/NumberCell';

const items: any = {
  'Ponto de Medição': '1616',
  'TAG do Medidor': 'tag',
  Classificação: '1618',
  'Data de Coleta de Dados do PI': '1619',
  // "Vazão (Mm³/d)": "2684",
  'Pressão (kPa)': '1620',
  'Pressão Diferencial (kPa)': '1621',
  'Temperatura (ºC)': '1622',
  'VBC (Mm³)': '1623',
  'Declarado (Mm³)': '1624'
  // "PCS (KJ/m³)": "2927",
  // "VTC (Mm³)": "1821",
};

const bdmfCustodyPts: Column[] = [
  {
    Header: () => <Header text={'Ponto de Medição'} id="ponto_de_medicao" />,
    accessor: '1616',
    Cell: TextCell,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => (
      <Header className="h-auto" text={'TAG do Medidor'} id="tag_do_medidor" />
    ),
    accessor: 'tag',
    Cell: TextCell,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => <Header text={'Classificação'} id="classificacao" />,
    accessor: '1618',
    Cell: TextCell,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => (
      <Header
        text={'Data de Coleta de Dados do PI'}
        id="data_coleta_dados_pi"
      />
    ),
    accessor: '1619',
    Cell: TextCell,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => <Header text={'Pressão (kPa)'} id="pressao_kpa" />,
    accessor: '1620',
    Cell: (e: any) => <NumberCell2 {...e} minDigit={5} maxDigit={5} />,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => (
      <Header
        className="h-auto"
        text={'Pressão Diferencial (kPa)'}
        id="pressao_diferencial_kpa"
      />
    ),
    accessor: '1621',
    Cell: (e: any) => <NumberCell2 {...e} minDigit={5} maxDigit={5} />,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => <Header text={'Temperatura (ºC)'} id="temperatura" />,
    accessor: '1622',
    Cell: (e: any) => <NumberCell2 {...e} minDigit={5} maxDigit={5} />,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => <Header text={'VBC (Mm³)'} id="vbc" />,
    accessor: '1623',
    Cell: (e: any) => <NumberCell2 {...e} minDigit={5} maxDigit={5} />,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => <Header text={'Declarado (Mm³)'} id="declarado_mm3" />,
    accessor: '1624',
    Cell: (e: any) => <NumberCell2 {...e} minDigit={5} maxDigit={5} />,
    minWidth: 120,
    width: undefined
  }
];

export default bdmfCustodyPts;
