import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Poço: '2603',
  'Tipo de Nível': '821',
  'Local de Medição': '822',
  'Pressão de Cabeça (psi)': '2617',
  'Pressão da Linha (psi)': '2609',
  'Pressão do Anular (psi)': '2618',
  'Nível GNA (m)': '2741',
  Observação: '712',
  'Pressão de Cabeça (kgf/cm²)': '699',
  'Pressão de Linha (kgf/cm²)': '53',
  'Pressão do Anular (kgf/cm²)': '701'
};

const echometer: Column[] = tablefy(items, undefined, undefined, undefined, [
  'Pressão de Cabeça (psi)',
  'Pressão da Linha (psi)',
  'Pressão do Anular (psi)',
  'Nível GNA (m)',
  'Pressão de Cabeça (kgf/cm²)',
  'Pressão de Linha (kgf/cm²)',
  'Pressão do Anular (kgf/cm²)'
]);

export default echometer;
