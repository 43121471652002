import { BiSolidCommentDots } from 'react-icons/bi';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export const TooltipCell = ({
  value,
  row: {
    original: { id }
  },
  ...rest
}: any) => {
  return (
    <div key={id} className="flex items-center justify-center">
      <span data-tooltip-id={`comment-alert-${id}`}>
        <BiSolidCommentDots color={!value ? '#bbbbbb' : '#6d6d6d'} />
      </span>

      <ReactTooltip
        id={`comment-alert-${id}`}
        variant="dark"
        positionStrategy="absolute"
        style={{
          textAlign: 'left',
          whiteSpace: 'pre-wrap',
          maxWidth: '300px',
          zIndex: 20
        }}
        content={value || 'Sem comentários'}
      />
    </div>
  );
};
