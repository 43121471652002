import { Dialog } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  title?: string;
  children?: React.ReactNode;
  className?: string;
  titleClassName?: string;
}

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  return props.isOpen ? (
    <Dialog
      open={true}
      onClose={() => props.setIsOpen(false)}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel
          className={twMerge(
            `w-full max-w-xl rounded-lg bg-white`,
            props.className ?? ''
          )}
        >
          {props.title && (
            <Dialog.Title className="w-full flex flex-row px-8 pt-6">
              <span
                className={twMerge(
                  'font-bold text-2xl text-slate-800',
                  props.titleClassName ?? ''
                )}
              >
                {props.title}
              </span>
            </Dialog.Title>
          )}

          {props.children}
        </Dialog.Panel>
      </div>
    </Dialog>
  ) : null;
};

export default Modal;
