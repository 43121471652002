import { format } from 'date-fns';
import { AlertFilterProps } from '../state/alert.atom';
import { AlertsFilters, PressureAlertsFilters } from '../state/alerts.atom';
import api from './apiPortal';

export const getAlert = async (
  page: number,
  perPage: number,
  oilwell: string,
  filters: AlertFilterProps
) => {
  return (
    await api.get('oilwells', {
      params: {
        route: 'alerts_by_oilwell',
        page,
        per_page: perPage,
        oilwell: oilwell,
        criticity: filters.criticity.length > 0 ? filters.criticity : undefined,
        initial_date:
          filters.timeRange.startDate && filters.timeRange.endDate
            ? filters.timeRange.startDate
            : undefined,
        end_date:
          filters.timeRange.startDate && filters.timeRange.endDate
            ? filters.timeRange.endDate
            : undefined,
        alert_type: filters.alertType.length > 0 ? filters.alertType : undefined
      }
    })
  ).data;
};

export const getAlerts = async (
  page: number,
  perPage: number,
  filters: AlertsFilters,
  best_producers_type?: string
) => {
  const today = new Date();
  return (
    await api.get('oilwells', {
      params: {
        route: 'oilwell_alerts',
        page,
        per_page: perPage,
        oilwell: filters.oilwell.length > 0 ? filters.oilwell : undefined,
        field: filters.field.length > 0 ? filters.field : undefined,
        fluid: filters.fluid.length > 0 ? filters.fluid : undefined,
        station: filters.station.length > 0 ? filters.station : undefined,
        criticity: filters.criticity.length > 0 ? filters.criticity : undefined,
        best_producers_type,
        best_producers_quant: best_producers_type ? 20 : undefined,
        best_producers: !!best_producers_type,
        best_producers_date: best_producers_type
          ? format(today, 'yyyy-MM-dd')
          : undefined,
        elevation_method:
          filters.elevationMethod.length > 0
            ? filters.elevationMethod
            : undefined,
        initial_date:
          filters.timeRange.startDate && filters.timeRange.endDate
            ? filters.timeRange.startDate
            : undefined,
        end_date:
          filters.timeRange.startDate && filters.timeRange.endDate
            ? filters.timeRange.endDate
            : undefined,
        alert_type:
          filters.alertType.length > 0 ? filters.alertType : undefined,
        rga_start: filters.rga.from
          ? +filters.rga.from.replace(',', '.')
          : undefined,
        rga_end: filters.rga.to ? +filters.rga.to.replace(',', '.') : undefined,
        roa_start: filters.roa.from
          ? +filters.roa.from.replace(',', '.')
          : undefined,
        pressure_system:
          filters.pressureSystem.length > 0
            ? filters.pressureSystem
            : undefined,
        roa_end: filters.roa.to ? +filters.roa.to.replace(',', '.') : undefined
      }
    })
  ).data;
};

export const getPressureAlerts = async (
  page: number,
  perPage: number,
  filters: PressureAlertsFilters,
  interval?: string
) => {
  return (
    await api.get('oilwells', {
      params: {
        route: 'pressure_alerts',
        page,
        per_page: perPage,
        interval: interval,
        oilwell: filters.oilwell.length > 0 ? filters.oilwell : undefined,
        field: filters.field.length > 0 ? filters.field : undefined,
        fluid: filters.fluid.length > 0 ? filters.fluid : undefined,
        station: filters.station.length > 0 ? filters.station : undefined,
        criticity: filters.criticity.length > 0 ? filters.criticity : undefined,
        elevation_method:
          filters.elevationMethod.length > 0
            ? filters.elevationMethod
            : undefined,
        initial_date:
          filters.timeRange.startDate && filters.timeRange.endDate
            ? filters.timeRange.startDate
            : undefined,
        end_date:
          filters.timeRange.startDate && filters.timeRange.endDate
            ? filters.timeRange.endDate
            : undefined,
        alert_type: filters.alertType.length > 0 ? filters.alertType : undefined
      }
    })
  ).data;
};
