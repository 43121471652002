import { Controller } from 'react-hook-form';
import { FaCircleInfo } from 'react-icons/fa6';
import Select from 'react-select';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export type SelectOptions = {
  value: string | null;
  label: string;
  order?: number;
};

type Props = {
  options: SelectOptions[];
  className?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  control: any;
  onChange?: () => void;
  name: string;
  isMulti?: boolean;
  disabled?: boolean;
  info?: string;
};

const SelectInput: React.FC<Props> = ({
  label,
  required,
  placeholder,
  control,
  options,
  isMulti = false,
  disabled = false,
  info,
  ...props
}: Props) => {
  return (
    <div className="flex flex-col w-full gap-1">
      {label ? (
        <label className="font-medium text-sm text-primary flex  w-full">
          {label}
          {required && <span className="text-red">*</span>}
          {info ? (
            <FaCircleInfo className="ml-2" size={16} data-tooltip-id={`info`} />
          ) : null}

          <ReactTooltip id={`info`} place="top" variant="dark" content={info} />
        </label>
      ) : null}
      <Controller
        control={control}
        name={props.name}
        render={({ field: { onChange, value, name, ref } }) => (
          <Select
            className={`w-full h-8 rounded-md ${props.className}`}
            options={options}
            placeholder={placeholder}
            onChange={
              isMulti
                ? onChange
                : (selectedOption: any) => {
                    onChange(selectedOption?.value);
                    props?.onChange?.();
                  }
            }
            name={name}
            ref={ref}
            isDisabled={disabled}
            isMulti={isMulti}
            value={
              isMulti
                ? value
                : (options.find(
                    option =>
                      JSON.stringify(option.value) === JSON.stringify(value)
                  ) ?? null)
            }
            menuPosition="fixed"
            styles={{
              control: (provided, state) => ({
                ...provided,
                background: state.isDisabled ? '#e8e8e8' : '#fff',
                borderColor: '#c4c4c4',
                minHeight: '32px',
                minWidth: '150px',
                height: '32px',
                borderRadius: '6px'
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                height: '32px',
                padding: '0 6px'
              }),
              input: (provided, state) => ({
                ...provided,
                margin: '0px'
              }),
              indicatorSeparator: state => ({
                display: 'none'
              }),
              indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '32px'
              }),
              placeholder: (provided, state) => ({
                ...provided,
                color: '#a5a5a5'
              }),
              option: (provided, state) => ({
                ...provided,
                background: state.isSelected ? '#193CB960' : '#fff',
                padding: '0.5rem 1rem',
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
                fontWeight: '400',
                color: state.isSelected ? '#fff' : '#000',
                cursor: 'pointer',
                '&:hover': {
                  background: state.isSelected ? '#193CB999' : '#F3F4F6'
                }
              })
            }}
          />
        )}
      />
    </div>
  );
};

export default SelectInput;
