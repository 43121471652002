import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Análise (Limites)': '1455',
  'ING-C (C-624002 C)': '1457',
  'ING-D (C-624002 D)': '1458',
  'CUB-03 (C-624003)': '2171'
};

const refrigerator: Column[] = tablefy(items);

export default refrigerator;
