import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const turnSup: Column[] = [
  {
    Header: <Header text={'Hora Início'} id="Hora Início" />,
    accessor: '527',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Hora Fim'} id="Hora Fim" />,
    accessor: '528',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Descrição do evento'} id="Descrição do evento" />,
    accessor: '1126',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default turnSup;
