interface MdmLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
}

const MdmLayout: React.FC<MdmLayoutProps> = ({ title, children }) => {
  return (
    <div
      className="
      flex pt-4 justify-center 
      h-[calc(100vh-48px)] 
      overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full
    "
    >
      <section
        className="
          flex flex-col 
          w-2/5 
          h-min
          items-center justify-center 
          px-4 py-6
          pb-8
          bg-white
          rounded-2xl
        "
      >
        <h1 className="text-2xl font-bold mb-4">{title}</h1>
        {children}
      </section>
    </div>
  );
};

export default MdmLayout;
