import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const raspOilwell: Column[] = [
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'Poço',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: 'Hora',
    Cell: DateHourCell,
    width: '8%'
  },
  {
    Header: <Header text={'Satélite'} id="Satélite" />,
    accessor: 'Satélite',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'N° do lacre'} id="N° do lacre" />,
    accessor: 'N° do lacre',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: 'Observação',
    Cell: DefaultCell,
    width: '8%'
  }
];

export default raspOilwell;
