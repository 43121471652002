import { Column } from 'react-table';
import { NumberCell } from '../../../components/atoms/table/NumberCell';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const production: Column[] = [
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: '112',
    Cell: DateHourCell,
    width: '8%'
  },
  {
    Header: <Header text={'Produzido (m³)'} id="Produzido (m³)" />,
    accessor: '525',
    Cell: NumberCell,
    width: '8%'
  },
  {
    Header: <Header text={'Extrapolado 24h (m³)'} id="Extrapolado 24h (m³)" />,
    accessor: '524',
    Cell: NumberCell,
    width: '8%'
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: '712',
    Cell: DefaultCell,
    width: '25%'
  }
];

export default production;
