import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Sistema: '1440',
  'Status - 1º turno': '1441',
  'Status - 2º turno': '1442',
  'Status - 3º turno': '1443',
  Observação: '712'
};

const statusByTurn: Column[] = tablefy(items);

export default statusByTurn;
