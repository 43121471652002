import {
  AuthenticationResult,
  EventType,
  PublicClientApplication
} from '@azure/msal-browser';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { RecoilURLSyncJSON } from 'recoil-sync';
import App from './App';
import { msalConfig } from './auth/authConfig';
import './index.css';
import initSentry from './services/sentry';

initSentry();

export const msalInstance = new PublicClientApplication(msalConfig);

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback(event => {
  const payload = event.payload as AuthenticationResult;
  if (event.eventType === EventType.LOGIN_SUCCESS && payload.account) {
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>
  <RecoilRoot>
    <RecoilURLSyncJSON location={{ part: 'queryParams', param: 'state' }}>
      <App instance={msalInstance} />
    </RecoilURLSyncJSON>
  </RecoilRoot>
  // </React.StrictMode>
);
