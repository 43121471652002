import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const satGeaCols: Column[] = [
  {
    Header: <Header text={'Linha'} id="Linha" />,
    accessor: 'Linha',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'SAT/GEA'} id="SAT_GEA" />,
    accessor: 'SAT_GEA',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Pressão (kgf/cm²)'} id="Pressão (kgf/cm²)" />,
    accessor: 'Pressão (kgf/cm²)',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: 'Observação',
    Cell: DefaultCell,
    width: '8%'
  }
];

export default satGeaCols;
