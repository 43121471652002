import { BsTrash3Fill } from 'react-icons/bs';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import ModalConfirmation from '../../../components/molecules/ConfirmationModal';
import { queryClient } from '../../../App';
import { deleteGasMeter } from '../../../services/oilwells.services';
import SquareButton from '../../../components/atoms/SquareButton';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import { useNavigate } from 'react-router-dom';
import { BiPencil } from 'react-icons/bi';
import { Header } from '../../../components/atoms/table/Header';

const DefaultCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs whitespace-nowrap w-full">
      {value || '-'}
    </span>
  );
};

export const TimeCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs whitespace-nowrap">
      {value ? format(new Date(value), 'dd/MM/yyyy') : '-'}
    </span>
  );
};

const gasMeterColumns = [
  {
    Header: () => <Header text={'ID'} id="id" />,
    accessor: 'id',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Nome'} id="name" />,
    accessor: 'name',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Numero de Série'} id="serialNumber" />,
    accessor: 'serialNumber',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Campo'} id="campo" />,
    accessor: 'oilFieldId',
    Cell: (value: any) => {
      const { fields } = useMdmUtils({
        fields: true
      });

      return (
        <DefaultCell
          value={
            fields?.find(
              (field: any) => field.id === value.row.original.oilFieldId
            )?.name
          }
        />
      );
    }
  },
  {
    Header: () => <Header text={'Tag PI'} id="tag" />,
    accessor: 'tagPi',
    Cell: (value: any) => {
      const { tags } = useMdmUtils({
        tags: true
      });

      const tag = tags?.data?.find(
        (tag: any) => tag.gas_meter === value.row.original.name
      )?.tag;

      return <DefaultCell value={tag} />;
    }
  },
  {
    Header: () => <Header text={'Data de atualização'} id="updatedAt" />,
    accessor: 'updatedAt',
    Cell: TimeCell
  },
  {
    Header: () => <Header text={'Ações'} id="actions" />,
    accessor: 'actions',
    Cell: (props: any) => {
      const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
      const navigate = useNavigate();

      const handleDeleteOilField = async () => {
        try {
          await deleteGasMeter(props.row.original.id);
          toast.success('Medidor de gás deletado com sucesso');
          queryClient.invalidateQueries(['gas-meters-adm']);
        } catch {
          toast.error('Erro ao deletar medidor de gás');
        }
      };

      return (
        <>
          <div className="flex gap-2">
            <SquareButton
              className="bg-red hover:bg-red/75"
              onClick={() => setModalDeleteIsOpen(old => !old)}
              icon={<BsTrash3Fill color="white" size={16} />}
            />
            <SquareButton
              onClick={() => navigate(`/gas-meter/${props.row.original.id}`)}
              icon={<BiPencil color="white" size={16} />}
            />
          </div>

          {modalDeleteIsOpen && (
            <ModalConfirmation
              confirmAction={handleDeleteOilField}
              title="Deletar medidor de gás"
              description="Tem certeza que deseja deletar esse medidor de gás? Essa é uma ação permanente."
              confirmButtonClassName="bg-emerald-600 hover:bg-emerald-400"
              isOpen={true}
              setIsOpen={() => setModalDeleteIsOpen(!modalDeleteIsOpen)}
            />
          )}
        </>
      );
    }
  }
];

export default gasMeterColumns;
