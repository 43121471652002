import { atom } from 'recoil';

export interface BDOFilters {
  date: string[];
}

export const BDOCalendarAtom = atom<BDOFilters>({
  key: 'BDOCalendar',
  default: {
    date: []
  }
});
