import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const c5: Column[] = [
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: '112',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Volume (m³)'} id="Volume (m³)" />,
    accessor: '173',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default c5;
