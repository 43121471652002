import { twMerge } from 'tailwind-merge';
import Spinner from '../atoms/Spinner';

export interface SummaryBigCardsItem {
  title: string;
  priority?: 'high' | 'low' | 'medium';
  value: { label: string; value?: string }[];
  disabled?: boolean;
}

interface SummaryBigCards {
  data: SummaryBigCardsItem[];
  isFetching?: boolean;
  wrapperClassName?: string;
}

export const SummaryBigCards = ({
  data,
  isFetching,
  wrapperClassName
}: SummaryBigCards) => {
  return (
    <div className={twMerge('flex gap-2 flex-wrap', wrapperClassName)}>
      {data.map((info, index) => (
        <div
          className={twMerge(
            'flex flex-col bg-primary px-4 py-3 rounded flex-1 md:min-w-[20%] lg:min-w-[14%] min-w-[12rem]',
            info.value.length > 1
              ? 'flex-grow-[2] col-span-1'
              : 'flex-grow-[1] col-span-1',
            info.disabled ? 'bg-gray' : '',
            info.priority === 'low'
              ? 'bg-white text-primary'
              : info.priority === 'medium'
                ? 'bg-[#D7DFFF] text-primary'
                : ''
          )}
          key={info.title + index}
        >
          <div
            className={twMerge(
              'font-medium text-white text-xl',
              info.priority === 'low'
                ? 'text-primary'
                : info.priority === 'medium'
                  ? 'text-primary'
                  : ''
            )}
          >
            {info.title}
          </div>

          <div className="flex gap-2">
            {info.value?.map((it, idx) => (
              <div
                className="flex flex-col w-full mt-auto"
                key={it.label + idx}
              >
                {it.label ? (
                  <span
                    className={twMerge(
                      'text-sm font-medium text-[#D7DFFF]',
                      info.priority === 'low'
                        ? 'text-primary'
                        : info.priority === 'medium'
                          ? 'text-primary'
                          : ''
                    )}
                  >
                    {it.label}
                  </span>
                ) : null}
                <span
                  className={twMerge(
                    'text-2xl font-bold text-white',
                    info.priority === 'low'
                      ? 'text-primary'
                      : info.priority === 'medium'
                        ? 'text-primary'
                        : ''
                  )}
                >
                  {isFetching ? (
                    <Spinner classNameWrapper="w-min" size={32} color="white" />
                  ) : info.disabled || it.value == 'NaN' || !it.value ? (
                    '-'
                  ) : (
                    it.value
                  )}
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
