import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const equipCols: Column[] = [
  {
    Header: <Header text={'Hora Início'} id="Hora_Início" />,
    accessor: 'Hora_Início',
    Cell: DateHourCell,
    width: '4%'
  },
  {
    Header: <Header text={'Hora Fim'} id="Hora_Fim" />,
    accessor: 'Hora_Fim',
    Cell: DateHourCell,
    width: '4%'
  },
  {
    Header: <Header text={'Duração'} id="Duração" />,
    accessor: 'Duração',
    Cell: DefaultCell,
    width: '4%'
  },
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'Poço',
    Cell: DefaultCell,
    width: '4%'
  },
  {
    Header: <Header text={'Grupo de Atividade'} id="Grupo de Atividade" />,
    accessor: 'Grupo de Atividade',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Atividade'} id="Atividade" />,
    accessor: 'Atividade',
    Cell: DefaultCell,
    width: '12%'
  },
  {
    Header: (
      <Header
        text={'Classificação da Atividade'}
        id="Classificação da Atividade"
      />
    ),
    accessor: 'Classificação da Atividade',
    Cell: DefaultCell,
    width: '9%'
  },
  {
    Header: <Header text={'Solicitante'} id="Solicitante" />,
    accessor: 'Solicitante',
    Cell: DefaultCell,
    width: '8%'
  }
];

export default equipCols;
