import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const openClosingCols: Column[] = [
  {
    Header: () => <Header text={'Poço'} id="poço" />,
    accessor: 'poço',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: () => <Header text={'Hora Fechamento'} id="Hora Fechamento" />,
    accessor: 'Hora Fechamento',
    Cell: DateHourCell,
    width: '12.5%'
  },
  {
    Header: () => <Header text={'Hora Abertura'} id="Hora Abertura" />,
    accessor: 'Hora Abertura',
    Cell: DateHourCell,
    width: '12.5%'
  },
  {
    Header: () => <Header text={'Motivo'} id="motivo" />,
    accessor: 'motivo',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: () => (
      <Header text={'Sistema de pressão'} id="sistema_de_pressão" />
    ),
    accessor: 'sistema_de_pressão',
    Cell: DefaultCell,
    width: '12.5%'
  },
  // {
  //   Header: () => <Header text={"Tipo de atividade"} id="tipo_de_atividade" />,
  //   accessor: "tipo_de_atividade",
  //   Cell: DefaultCell,
  //   width: "12.5%",
  // },
  {
    Header: () => <Header text={'Observação'} id="observação" />,
    accessor: 'observação',
    Cell: DefaultCell,
    width: '8.5%'
  }
];

export { openClosingCols };
