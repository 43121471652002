import { Column } from 'react-table';
import { tablefy } from '../../../../utils/tablesUtils';

const items: any = {
  Poço: '697',
  'Tipo de Teste': '30',
  Status: '1526',
  'Hora Início': '1749',
  'Hora Fim': '1750',
  SGs: '1186',
  'Sistema de pressão testado': '20',
  'Tanque de Teste': '1534',
  'Produção de Óleo (m³)': '1528',
  'Produção Bruta (m³)': '1529',
  'Gás Total (m³)': '1530',
  'Relatório de Teste': '1525',
  'Código Cilindro PVT': '1562',
  Observação: '712'
};

const oilwellTestPir: Column[] = tablefy(items, ['Hora Início', 'Hora Fim']);

export default oilwellTestPir;
