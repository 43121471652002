import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Produto Químico': '1454',
  'Nível (cm)': '1189',
  'Volume Abastecido (L)': '1190'
};

const chemicalLevels: Column[] = tablefy(items);

export default chemicalLevels;
