import { format } from 'date-fns';
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import Datepicker from 'react-tailwindcss-datepicker';
import { useReactToPrint } from 'react-to-print';
import { PageWrapper } from '../../components/atoms/PageWrapper';
import {
  ValueContainer,
  selectInputStyles
} from '../../components/atoms/Select';
import SelectInputUncontrolled from '../../components/atoms/SelectInputUncontrolled';
import Spinner from '../../components/atoms/Spinner';
import SideFilter from '../../components/molecules/SideFilter';
import { SwitchComponentForm } from '../../components/molecules/SwitchComponentForm';
import { bdoCompresssion } from '../../constants/view/bdos/bdoCompression';
import { useBdoEc } from '../../hooks/BDO/useBdoEc';
import { BDOCalendarAtom } from '../../state/bdo.calendar.atom';

const BdoViewEC: React.FC = () => {
  const [params, setParam] = useSearchParams();

  const tracker = params.get('tracker');
  const currentDate = params.get('date');
  const date = currentDate
    ? format(new Date(currentDate.replaceAll('-', '/')), 'yyyy-MM-dd')
    : undefined;

  const { bdoArray, isLoading } = useBdoEc(date, tracker ?? '');

  const reportDate = bdoArray?.find((it: any) => it.id == 248)?.value as string;

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const [isScrolling, setIsScrolling] = useState(false);
  const selectRef = useRef<any>(null);

  return (
    <PageWrapper>
      <div className="absolute w-72 right-16 -top-10">
        <SelectInputUncontrolled
          ref={selectRef}
          isMulti={false}
          className="z-[11]"
          placeholder="Ir para seção..."
          options={
            bdoCompresssion
              .filter(it => it.type === 'label')
              .map(it => ({
                value: it.id,
                label: it.name
              })) as any
          }
          onChange={(val: any) => {
            setIsScrolling(true);
            const targetDiv = document.getElementById(val.value);
            if (targetDiv) {
              targetDiv.scrollIntoView({ behavior: 'smooth' });
            }
            setTimeout(() => {
              setIsScrolling(false);
            }, 1000);
          }}
        />
      </div>

      <div
        onScroll={() => {
          if (selectRef.current.getValue() && !isScrolling)
            selectRef.current?.setValue('');
        }}
        className="w-full h-full overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full"
      >
        <div
          className="flex flex-col w-full flex-wrap bg-white rounded-lg px-4 py-3 flex-1"
          ref={componentRef}
        >
          <h2 className="text-2xl text-primary font-bold border-b border-primary pb-4 mb-2 w-full flex justify-between">
            <span>
              BDO Compressão {tracker ? `- ${tracker}` : ''}
              <span className="text-gray text-sm h-full align-text-bottom pt-[0.6rem] ml-1">
                -{' '}
                {reportDate
                  ? format(
                      new Date(reportDate.replaceAll('-', '/')),
                      'dd/MM/yyyy'
                    )
                  : null}
              </span>
            </span>

            <button
              onClick={handlePrint}
              className="text-base font-medium px-2 py-1 rounded border no-print"
            >
              Imprimir
            </button>
          </h2>

          {isLoading && <Spinner size={28} className="mt-2" />}

          {bdoArray.length > 0 && (
            <SwitchComponentForm data={bdoArray} structure={bdoCompresssion} />
          )}
        </div>
      </div>

      <SideFilter
        atom={BDOCalendarAtom}
        filters={[]}
        floatButtonClassName="top-20"
      >
        <div className="flex flex-col gap-1">
          <h3 className="text-sm font-bold text-white">Data</h3>
          <Datepicker
            value={{
              startDate: currentDate ? new Date(currentDate) : new Date(),
              endDate: currentDate ? new Date(currentDate) : new Date()
            }}
            asSingle={true}
            useRange={false}
            primaryColor={'sky'}
            onChange={(val: any) => {
              return setParam({
                date: val?.startDate?.replaceAll('-', '/'),
                tracker: tracker ?? ''
              });
            }}
            i18n="pt-br"
            placeholder="Selecione uma data"
            inputClassName={
              'relative transition-all duration-300 h-[36px] pl-2 pr-10 w-full border-[1px] border-[#cccccc] dark:border-slate-600 rounded-[10px] tracking-wide font-light text-sm text-white placeholder-white bg-primary focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20'
            }
            maxDate={new Date()}
            classNames={{
              toggleButton() {
                return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
              }
            }}
          />
        </div>

        <div className="flex flex-col gap-1">
          <h3 className="text-sm font-bold text-white">Estação</h3>
          <Select
            className={`w-full rounded-lg font-medium`}
            options={[
              {
                value: 'Pilar',
                label: 'Pilar'
              },
              {
                label: 'Furado',
                value: 'Furado'
              }
            ]}
            onChange={(val: any) =>
              setParam({
                date: currentDate ?? '',
                tracker: val?.value ?? 'Pilar'
              })
            }
            placeholder="Selecione..."
            theme={(theme: any) => ({
              ...theme,
              borderRadius: 6,
              spacing: {
                ...theme.spacing,
                controlHeight: 34
              },
              colors: {
                ...theme.colors,
                primary: '#aabbff',
                neutral60: '#E5E5E5'
              }
            })}
            styles={selectInputStyles}
            components={{ ValueContainer }}
          />
        </div>
      </SideFilter>
    </PageWrapper>
  );
};

export default BdoViewEC;
