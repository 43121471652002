import { useQuery } from '@tanstack/react-query';
import { addHours, format, getHours, isToday } from 'date-fns';
import { toast } from 'react-toastify';
import bestProducersCols from '../../constants/tableColumns/bdmf/bestProducers';
import bdmfSummaryColumns from '../../constants/tableColumns/bdmf/summary';
import { getAllLastBDMF, getBdoChartData } from '../../services/bdos.services';
import { getProductionReportSummary } from '../../services/oilwells.services';
import { numberFormatter } from '../../utils/utils';

const getField = (data: any, fieldId: number) => {
  if (data) {
    return data?.issues?.[0]?.custom_fields?.find(
      (field: any) => field.id === fieldId
    )?.value;
  }
  return null;
};

export const useBdmfSummary = (
  date: string,
  tracker?: string,
  hub?: string
) => {
  const trackersEnum: { [key: string]: number } = {
    'pilar-gas': 22,
    'furado-gas': 23,
    'pilar-oleo': 24,
    'furado-oleo': 25,
    'pilar-glp': 26,
    'pilar-custodia': 27,
    'tucano-diario': 45,
    'tucano-custodia': 46
  };

  const findTrackerIdx = (
    tracker:
      | 'pilar-gas'
      | 'furado-gas'
      | 'pilar-oleo'
      | 'furado-oleo'
      | 'pilar-glp'
      | 'pilar-custodia'
      | 'tucano-diario'
      | 'tucano-custodia'
  ) => Object.keys(trackersEnum).findIndex(key => key === tracker);

  const { data, isLoading } = useQuery(
    ['BdmfReport', date],
    () => getAllLastBDMF(date, Object.values(trackersEnum)),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  const awaitingValidation = data
    ?.filter(it => it.issues.length > 0)
    ?.some(it => {
      const mapHub: Record<string, string[]> = {
        Alagoas: ['pilar', 'furado'],
        'Tucano Sul': ['tucano']
      };

      console.log(
        it?.issues?.[0]?.tracker.name,
        it?.issues?.[0]?.status?.id,
        hub && mapHub[hub],

        (tracker &&
          it?.issues?.[0]?.tracker.name
            .toLocaleLowerCase()
            .includes(tracker?.toLocaleLowerCase())) ||
          (hub &&
            mapHub[hub].some(hubName =>
              it?.issues?.[0]?.tracker.name
                .toLocaleLowerCase()
                .includes(hubName)
            ))
      );

      return (
        it?.issues?.[0]?.status?.id !== 39 &&
        ((tracker &&
          it?.issues?.[0]?.tracker.name
            .toLocaleLowerCase()
            .includes(tracker?.toLocaleLowerCase())) ||
          (hub &&
            mapHub[hub].some(hubName =>
              it?.issues?.[0]?.tracker.name
                .toLocaleLowerCase()
                .includes(hubName)
            )))
      );
    });

  const isEmpty = !data?.some(it => it.issues.length !== 0);

  const latestEmitReport =
    !awaitingValidation && data
      ? data?.reduce(
          (acc: undefined | string, it) =>
            !it?.issues?.[0]?.closed_on
              ? acc
              : (acc ? new Date(acc) : new Date()) >
                  new Date(it?.issues?.[0]?.closed_on)
                ? acc
                : it?.issues?.[0]?.closed_on,
          undefined
        )
      : undefined;

  const { data: summaryData } = useQuery(
    ['BdmfSummary', date],
    async () => {
      const toastId = toast.loading('Buscando dados do resumo de produção...', {
        autoClose: false
      });
      try {
        const data = await getProductionReportSummary(date);
        return data;
      } catch {
        toast.error('Erro ao buscar dados do resumo de produção.');
        throw new Error('Erro ao buscar dados do resumo de produção.');
      } finally {
        toast.dismiss(toastId);
      }
    },

    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  const dateRange = addHours(new Date(date), 3);

  const reportInfo = [
    { name: 'Data do Relatório', value: format(dateRange, 'dd/MM/yyyy') },
    { name: 'Agente Regulado', value: 'Origem Energia S.A.' },
    {
      name: 'Instalação',
      value: `Polo ${hub}`
    },
    {
      name: 'Última Atualização',
      value: latestEmitReport
        ? format(addHours(latestEmitReport, 3), 'dd/MM/yyyy HH:mm:ss')
        : '-'
    }
  ];

  const alagoas = summaryData?.data?.alagoas_production_summary;
  const tucano = summaryData?.data?.tucano_production_summary_data;
  const pilarProd = summaryData?.data?.pilar_production_summary_data;
  const furadoProd = summaryData?.data?.furado_production_summary_data;

  const summaryBigCards = [
    {
      title: 'Gás',
      value: [
        {
          label: 'Bruto (Mm³)',
          value: summaryData
            ? numberFormatter.format(
                hub === 'Tucano Sul'
                  ? tucano?.gas_bruto_tucano || undefined
                  : tracker == 'All' || !tracker
                    ? alagoas?.['gas_natural_Mm3']
                    : (tracker == 'Pilar' ? pilarProd : furadoProd)?.[
                        'Produção Fiscal (Mm³)'
                      ]
              )
            : undefined
        },
        {
          label: 'Processado (Mm³)',
          value:
            hub === 'Tucano Sul'
              ? numberFormatter.format(tucano?.gas_processado_tucano)
              : summaryData
                ? tracker == 'All' || !tracker
                  ? numberFormatter.format(alagoas?.gas_transf_corrigido_Mm3)
                  : undefined
                : undefined,
          info: 'Volume de gás processado corrigido pelo PCS (poder calorífico superior)'
        }
      ]
    },
    {
      title: hub === 'Tucano Sul' ? 'Condensado' : 'Óleo',
      value: [
        {
          label: '(m³)',
          value:
            hub === 'Tucano Sul'
              ? numberFormatter.format(tucano?.condensado_m3_tucano)
              : summaryData
                ? numberFormatter.format(
                    tracker == 'All' || !tracker
                      ? alagoas?.['oleo_condensado_m3']
                      : (tracker == 'Pilar' ? pilarProd : furadoProd)?.[
                          'Produção Fiscal Óleo/Condensado (m³)'
                        ]
                  )
                : undefined
        },
        {
          label: '(bbl)',
          value:
            hub === 'Tucano Sul'
              ? numberFormatter.format(tucano?.condensado_m3_tucano * 6.29)
              : summaryData
                ? numberFormatter.format(
                    (tracker == 'All' || !tracker
                      ? alagoas?.['oleo_condensado_m3']
                      : (tracker == 'Pilar' ? pilarProd : furadoProd)?.[
                          'Produção Fiscal Óleo/Condensado (m³)'
                        ]) * 6.29
                  )
                : undefined
        }
      ]
    },
    {
      title: 'GLP',
      disabled: hub === 'Tucano Sul',
      value: [
        {
          label: '(ton)',
          value: summaryData
            ? tracker == 'All' || !tracker
              ? numberFormatter.format(pilarProd?.['GLP (ton)'])
              : undefined
            : undefined
        }
      ]
    },
    {
      title: 'C5+',
      disabled: hub === 'Tucano Sul',
      value: [
        {
          label: '(bbl)',
          value: summaryData
            ? tracker == 'All' || !tracker
              ? numberFormatter.format(+pilarProd?.['C5+ (m³)'] * 6.29)
              : undefined
            : undefined
        }
      ]
    },
    {
      title: 'Água',
      disabled: hub === 'Tucano Sul',
      value: [
        {
          label: '(m³)',
          value: summaryData
            ? tracker == 'All' || !tracker
              ? numberFormatter.format(alagoas?.agua_m3)
              : numberFormatter.format(
                  (tracker == 'Pilar' ? pilarProd : furadoProd)?.[
                    'Água Produzida (m³)'
                  ]
                )
            : undefined
        }
      ]
    }
  ];

  const { data: bdoPilar } = useQuery(
    ['BDOReportChart', date, 'Pilar'],
    () => getBdoChartData('Pilar', date),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  const { data: bdoFurado } = useQuery(
    ['BDOReportChart', date, 'Furado'],
    () => getBdoChartData('Furado', date),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  const bdoIsToday = date ? isToday(new Date(date.replaceAll('-', '/'))) : true;

  const actualHour = getHours(new Date());

  const lastEvenHour = `${actualHour < 10 ? '0' : ''}${
    actualHour % 2 == 0 ? actualHour : actualHour - 1
  }:00`;

  const lossGasPilar = !bdoIsToday
    ? +bdoPilar?.perdas?.['23:59']?.gas
    : +bdoPilar?.perdas?.[lastEvenHour]?.gas;

  const lossOilPilar = !bdoIsToday
    ? +bdoPilar?.perdas?.['23:59']?.oleo
    : +bdoPilar?.perdas?.[lastEvenHour]?.oleo;

  const lossGasFurado = !bdoIsToday
    ? +bdoFurado?.perdas?.['23:59']?.gas
    : +bdoFurado?.perdas?.[lastEvenHour]?.gas;

  const lossOilFurado = !bdoIsToday
    ? +bdoFurado?.perdas?.['23:59']?.oleo
    : +bdoFurado?.perdas?.[lastEvenHour]?.oleo;

  const summaryMedium = [
    {
      title: 'Exportação (Mm³)',
      value:
        hub === 'Tucano Sul'
          ? numberFormatter.format(tucano?.exportacao_tucano)
          : tracker == 'All' || !tracker
            ? numberFormatter.format(
                +pilarProd?.['Exportação ALGAS (Mm³)'] +
                  +pilarProd?.['Exportação TAG (Mm³)']
              )
            : undefined,
      info:
        hub === 'Tucano Sul'
          ? 'Volume bruto exportado para Bahia Gás e UPGN - Catu'
          : 'Volume bruto exportado para TAG e ALGÁS'
    },
    {
      title: 'Queima (Mm³)',
      value: numberFormatter.format(
        tracker == 'Pilar'
          ? +pilarProd?.['Queima Pilar (Mm³)']
          : tracker == 'Furado'
            ? furadoProd?.['Queima (Mm³)']
            : +alagoas?.queima_Mm3 + +pilarProd?.['Queima UPGN (Mm³)']
      ),
      info: 'Volume de gás queimado (considera UPGN)',
      disabled: hub === 'Tucano Sul'
    },
    {
      title: 'Gás Lift (Mm³)',
      value:
        numberFormatter.format(
          tracker == 'Pilar'
            ? pilarProd?.['Gás Lift Pilar (Mm³)']
            : tracker == 'Furado'
              ? +furadoProd?.['Gás Lift Furado (Mm³)'] +
                +furadoProd?.['Gás Lift Anambé (Mm³)']
              : +furadoProd?.['Gás Lift Furado (Mm³)'] +
                +furadoProd?.['Gás Lift Anambé (Mm³)'] +
                +pilarProd?.['Gás Lift Pilar (Mm³)']
        ) || undefined,
      info: 'Volume total de gás injetado',
      disabled: hub === 'Tucano Sul'
    },
    {
      title: '°API Óleo',
      value: undefined,
      info: 'Média diária do grau API das transferências fiscais ponderada pelo volume líquido transferido (VLT)',
      disabled: hub === 'Tucano Sul'
    },
    {
      title: 'Perda Op. Gás (Mm³)',
      value:
        bdoFurado && bdoPilar
          ? numberFormatter.format(
              tracker == 'Pilar'
                ? lossGasPilar
                : tracker == 'Furado'
                  ? lossGasFurado
                  : lossGasFurado + lossGasPilar
            )
          : undefined,
      info: 'Perda diária de gás de poços fechados por atividades operacionais',
      disabled: hub === 'Tucano Sul'
    },
    {
      title: 'Perda Op. Óleo (bbl)',
      value:
        bdoFurado && bdoPilar
          ? numberFormatter.format(
              (tracker == 'Pilar'
                ? lossOilPilar
                : tracker == 'Furado'
                  ? lossOilFurado
                  : lossOilFurado + lossOilPilar) * 6.29
            )
          : undefined,
      info: 'Perda diária de óleo de poços fechados por atividades operacionais',
      disabled: hub === 'Tucano Sul'
    }
  ];

  const summaryLow = [
    {
      title: 'Fator de Alocação Gás ',
      value: numberFormatter.format(
        hub === 'Tucano Sul'
          ? tucano?.fator_aloc_gas_tucano
          : !tracker || tracker === 'All'
            ? alagoas?.fator_aloc_gas_alagoas
            : (tracker !== 'Furado' ? alagoas?.fator_aloc_gas_pilar : 0) +
              (tracker !== 'Pilar' ? alagoas?.fator_aloc_gas_furado : 0)
      ),
      info: 'Relação entre o somatório dos potenciais de gás (teste de poço) e a produção efetiva realizada no dia'
    },
    {
      title: 'Fator de Alocação Gás Lift',
      value: numberFormatter.format(
        !tracker || tracker === 'All'
          ? alagoas?.fator_aloc_gl_alagoas
          : (tracker !== 'Furado' ? alagoas?.fator_aloc_gl_pilar : 0) +
              (tracker !== 'Pilar' ? alagoas?.fator_aloc_gl_furado : 0)
      ),
      info: 'Relação entre o somatório dos potenciais de gás lift (teste de poço) e a produção efetiva realizada no dia',
      disabled: hub === 'Tucano Sul'
    },
    {
      title: 'Fator de Alocação Óleo',
      value: numberFormatter.format(
        !tracker || tracker === 'All'
          ? alagoas?.fator_aloc_oleo_alagoas
          : (tracker !== 'Furado' ? alagoas?.fator_aloc_oleo_pilar : 0) +
              (tracker !== 'Pilar' ? alagoas?.fator_aloc_oleo_furado : 0)
      ),
      info: 'Relação entre o somatório dos potenciais de óleo (teste de poço) e a produção efetiva realizada no dia',
      disabled: hub === 'Tucano Sul'
    },
    {
      title: 'IUGA',
      value:
        !tracker || tracker === 'All'
          ? numberFormatter.format(+alagoas?.iuca_global)
          : undefined,
      info: 'Índice de Utilização de Gás Associado, representado pela relação entre a produção de gás associado líquida (desconsiderando queima) e a produção de gás associado total. Mínimo tolerado pela ANP de 97%.',
      disabled: hub === 'Tucano Sul'
    }
  ];

  const comments = data && {
    alagoas: [
      {
        title: 'Pilar',
        data: [
          {
            title: 'Gás',
            value: getField(data[findTrackerIdx('pilar-gas')], 1580) || '-'
          },
          {
            title: 'Óleo',
            value: getField(data[findTrackerIdx('pilar-oleo')], 1666)
              ? `${getField(data[findTrackerIdx('pilar-oleo')], 1666)}\n` +
                getField(data[findTrackerIdx('pilar-oleo')], 1679)
              : getField(data[findTrackerIdx('pilar-oleo')], 1679) || '-'
          },
          {
            title: 'Custódia',
            value: getField(data[findTrackerIdx('pilar-custodia')], 1580) || '-'
          },
          {
            title: 'GLP',
            value: getField(data[findTrackerIdx('pilar-glp')], 1754) || '-'
          }
        ]
      },
      {
        title: 'Furado',
        data: [
          {
            title: 'Gás',
            value: getField(data[findTrackerIdx('furado-gas')], 1580) || '-'
          },
          {
            title: 'Óleo',
            value: getField(data[findTrackerIdx('furado-oleo')], 1730)
              ? `${getField(data[findTrackerIdx('furado-oleo')], 1730)}\n` +
                getField(data[findTrackerIdx('furado-oleo')], 1736)
              : getField(data[findTrackerIdx('furado-oleo')], 1736) || '-'
          }
        ]
      }
    ],
    tucano: [
      {
        title: 'Tucano',
        data: [
          {
            title: 'Diário',
            value: getField(data[findTrackerIdx('tucano-diario')], 1580) || '-'
          },
          {
            title: 'Custódia',
            value:
              getField(data[findTrackerIdx('tucano-custodia')], 1580) || '-'
          }
        ]
      }
    ]
  };

  const getBestProducers = (
    key:
      | 'All'
      | 'largest_producing_wells_by_pilar'
      | 'largest_producing_wells_by_furado'
      | 'largest_producing_wells_by_tucano'
  ) => {
    if (!summaryData) return [];

    const allGas = [
      ...summaryData?.data?.largest_producing_wells_by_pilar?.gas?.map(
        (it: any) => ({ ...it, hub: 'Pilar' })
      ),
      ...summaryData?.data?.largest_producing_wells_by_furado?.gas?.map(
        (it: any) => ({ ...it, hub: 'Furado' })
      )
    ].sort((a, b) => b.gas_total - a.gas_total);

    const allOil = [
      ...summaryData?.data?.largest_producing_wells_by_pilar?.oil?.map(
        (it: any) => ({ ...it, hub: 'Pilar' })
      ),
      ...summaryData?.data?.largest_producing_wells_by_furado?.oil?.map(
        (it: any) => ({ ...it, hub: 'Furado' })
      )
    ].sort((a, b) => b.oleo_condensado - a.oleo_condensado);

    const wellHub =
      hub === 'Tucano Sul'
        ? 'Tucano Sul'
        : key.includes('pilar')
          ? 'Pilar'
          : 'Furado';

    if (key == 'All') {
      return allOil
        ?.map((it: any, idx: number) => ({
          oilwellOil: it.nome_anp,
          volumeOil: it?.oleo_condensado * 6.29,
          hub: it.hub,
          volumeHub: allGas?.[idx]?.hub,
          oilwellGas: allGas?.[idx]?.nome_anp,
          volumeGas: allGas?.[idx]?.gas_total
        }))
        ?.slice(0, 20);
    } else {
      return summaryData?.data?.[key]?.oil
        ?.map((it: any, idx: number) => ({
          oilwellOil: it.nome_anp,
          volumeOil: it?.oleo_condensado * 6.29,
          hub: wellHub,
          volumeHub: hub,
          oilwellGas: summaryData?.data?.[key]?.gas?.[idx]?.nome_anp,
          volumeGas: summaryData?.data?.[key]?.gas?.[idx]?.gas_total
        }))
        ?.slice(0, 10);
    }
  };

  const pilarSummaryData = summaryData?.data?.pilar_production_summary_data;
  const furadoSummaryData = summaryData?.data?.furado_production_summary_data;
  const tucanoSummaryData = summaryData?.data?.tucano_production_summary_data;

  const tables = [
    {
      title: 'Melhores Produtores',
      columns: bestProducersCols.filter(it =>
        hub === 'Tucano Sul' ? it.accessor === 'gas' : true
      ),
      data:
        hub === 'Tucano Sul'
          ? getBestProducers('largest_producing_wells_by_tucano')
          : tracker == 'Pilar'
            ? getBestProducers('largest_producing_wells_by_pilar')
            : tracker == 'Furado'
              ? getBestProducers('largest_producing_wells_by_furado')
              : getBestProducers('All')
    },
    {
      title: 'Resumo de Produção',
      columns: bdmfSummaryColumns,
      data:
        hub === 'Tucano Sul'
          ? [
              {
                measurePointGas: 'Volume Produzido de Gás - Fiscal',
                volumeGas: tucanoSummaryData?.gas_bruto_tucano,
                measurePointLiquid: 'Produção Fiscal de Condensado (m³)',
                volumeLiquid: tucanoSummaryData?.condensado_m3_tucano,
                hub: 'Tucano Sul',
                hubLiquid: 'Tucano Sul'
              },
              {
                measurePointGas: 'Volume Enviado para Bahia Gás (Mm³)',
                volumeGas: tucanoSummaryData?.bahia_gas_tucano,
                hub: 'Tucano Sul'
              },
              {
                measurePointGas: 'Consumo Compressor EMED-400 (Mm³)',
                volumeGas: tucanoSummaryData?.consumo_compressor_tucano,
                hub: 'Tucano Sul'
              },
              {
                measurePointGas: 'Volume Enviado para UPGN - CATU (Mm³)',
                volumeGas: tucanoSummaryData?.vol_upgn_tucano,
                hub: 'Tucano Sul'
              }
            ]
          : [
              {
                measurePointGas: 'Produção Fiscal (Mm³)',
                volumeGas: pilarSummaryData?.['Produção Fiscal (Mm³)'],
                measurePointLiquid: 'Produção Fiscal Óleo (m³)',
                volumeLiquid:
                  pilarSummaryData?.['Produção Fiscal Óleo/Condensado (m³)'],
                hub: 'Pilar',
                hubLiquid: 'Pilar'
              },
              {
                measurePointGas: 'Produção Fiscal (Mm³)',
                volumeGas: furadoSummaryData?.['Produção Fiscal (Mm³)'],
                measurePointLiquid: 'Produção Fiscal Óleo (m³)',
                volumeLiquid:
                  furadoSummaryData?.['Produção Fiscal Óleo/Condensado (m³)'],
                hub: 'Furado',
                hubLiquid: 'Furado'
              },
              {
                measurePointGas: 'Gás Lift Pilar (Mm³)',
                volumeGas: pilarSummaryData?.['Gás Lift Pilar (Mm³)'],
                measurePointLiquid: 'C5+ (m³)',
                volumeLiquid: pilarSummaryData?.['C5+ (m³)'],
                hub: 'Pilar',
                hubLiquid: 'Pilar'
              },
              {
                measurePointGas: 'Gás Lift Anambé (Mm³)',
                volumeGas: furadoSummaryData?.['Gás Lift Anambé (Mm³)'],
                measurePointLiquid: 'GLP (m³)',
                volumeLiquid: pilarSummaryData?.['GLP (m³)'],
                hubLiquid: 'Pilar',
                hub: 'Furado'
              },
              {
                measurePointGas: 'Gás Lift Furado (Mm³)',
                volumeGas: furadoSummaryData?.['Gás Lift Furado (Mm³)'],
                measurePointLiquid: 'GLP (ton)',
                volumeLiquid: pilarSummaryData?.['GLP (ton)'],
                hubLiquid: 'Pilar',
                hub: 'Furado'
              },
              {
                measurePointGas: 'Queima Pilar (Mm³)',
                volumeGas: pilarSummaryData?.['Queima Pilar (Mm³)'],
                measurePointLiquid: 'Água Produzida (m³)',
                volumeLiquid: furadoSummaryData?.['Água Produzida (m³)'],
                hubLiquid: 'Furado',
                hub: 'Pilar'
              },
              {
                measurePointGas: 'Queima Furado (Mm³)',
                volumeGas: furadoSummaryData?.['Queima (Mm³)'],
                measurePointLiquid: 'Água Produzida (m³)',
                volumeLiquid: pilarSummaryData?.['Água Produzida (m³)'],
                hubLiquid: 'Pilar',
                hub: 'Furado'
              },
              {
                measurePointGas: 'Queima UPGN (Mm³)',
                volumeGas: pilarSummaryData?.['Queima UPGN (Mm³)'],
                measurePointLiquid: 'Água Injetada para Descarte (m³)',
                volumeLiquid:
                  furadoSummaryData?.['Água Injetada para Descarte (m³)'],
                hubLiquid: 'Furado',
                hub: 'Pilar'
              },
              {
                measurePointGas: 'Consumo UPGN (Mm³)',
                volumeGas: pilarSummaryData?.['Consumo UPGN (Mm³)'],
                measurePointLiquid: 'Água Injetada Recuperação Secundária (m³)',
                volumeLiquid: pilarSummaryData?.['Água Injetada (m³)'],
                hub: 'Pilar',
                hubLiquid: 'Pilar'
              },
              {
                measurePointGas: 'Consumo (Mm³)',
                volumeGas: furadoSummaryData?.['Consumo (Mm³)'],
                measurePointLiquid: 'Água Injetada Recuperação Secundária (m³)',
                volumeLiquid:
                  furadoSummaryData?.['Água Injetada Reservatório (m³)'],
                hubLiquid: 'Furado',
                hub: 'Furado'
              },
              {
                measurePointGas: 'Consumo (Mm³)',
                volumeGas: pilarSummaryData?.['Consumo Pilar (Mm³)'],
                hub: 'Pilar'
              },
              {
                measurePointGas: 'Exportação ALGAS (Mm³)',
                volumeGas: pilarSummaryData?.['Exportação ALGAS (Mm³)'],
                hub: 'Pilar'
              },
              {
                measurePointGas: 'Exportação TAG (Mm³)',
                volumeGas: pilarSummaryData?.['Exportação TAG (Mm³)'],
                hub: 'Pilar'
              },
              {
                measurePointGas: 'Importação TAG (Mm³)',
                volumeGas: pilarSummaryData?.['Importação TAG (Mm³)'],
                hub: 'Pilar'
              },
              {
                measurePointGas: 'Recirculação UPGN (Mm³)',
                volumeGas: pilarSummaryData?.['Recirculação UPGN (Mm³)'],
                hub: 'Pilar'
              }
            ].filter(it =>
              !tracker || tracker === 'All' ? true : tracker === it.hub
            )
    }
  ];

  return {
    data,
    isLoading,
    reportInfo,
    summaryBigCards,
    summaryMedium,
    summaryLow,
    comments,
    tables,
    latestEmitReport,
    isEmpty,
    awaitingValidation
  };
};
