import { ApexOptions } from 'apexcharts';
import pt from 'apexcharts/dist/locales/pt-br.json';

const bdoReportsChartOptions = (
  dataFormatted: any[],
  title: string,
  powerTitle: string,
  lossTitle: string,
  digits = 0,
  labelSeriesPower = 'Potencial',
  labelSeriesLoss = 'Perda'
): ApexOptions => ({
  series: [
    {
      name: labelSeriesPower,
      type: 'column',
      group: '1',
      data: dataFormatted.map(it =>
        !it.power || it.power == '-'
          ? null
          : Number(it.power.replaceAll(',', '.'))
      )
    },
    {
      name: labelSeriesLoss,
      type: 'column',
      group: '1',
      data: dataFormatted.map(it =>
        !it.loss || it.loss == '-' ? null : Number(it.loss.replaceAll(',', '.'))
      )
    }
  ],
  labels: dataFormatted.map(it => it.hour),
  chart: {
    type: 'bar',
    fontFamily: 'Graphie',
    locales: [pt],
    defaultLocale: 'pt-br',
    width: '100%',
    height: '100%'
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'vertical',
      shadeIntensity: 0.25,
      inverseColors: false,
      opacityFrom: 0.95,
      opacityTo: 0.9,
      stops: [0, 100]
    }
  },
  grid: {
    xaxis: {
      lines: {
        show: false
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    }
  },
  colors: [
    '#5A9BD5',
    '#1F4E78',
    '#1298C7',
    '#Ad9fCa',
    '#A5A5A5',
    '#191919',
    '#e5383b'
  ],
  plotOptions: {
    bar: {
      columnWidth: '45%',
      dataLabels: {
        hideOverflowingLabels: true
      }
    }
  },
  stroke: {
    width: [0, 0]
  },
  title: {
    text: title,
    margin: 0,
    style: {
      color: '#193CB9',
      fontSize: '18px'
    },
    align: 'left',
    offsetX: 0,
    offsetY: 0,
    floating: false
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: 1
      }).format(Number(val));
    },
    enabledOnSeries: [0, 1],
    style: {
      fontSize: '10px'
    }
  },
  xaxis: {
    labels: {
      style: {
        fontSize: '10px'
      },
      rotate: 0,
      hideOverlappingLabels: true
    }
  },
  annotations: {
    yaxis: [
      {
        y: 0
      }
    ]
  },
  yaxis: [
    {
      title: {
        text: powerTitle
      },
      tickAmount: 5,
      min: 0,
      labels: {
        formatter: function (val) {
          return val
            ? new Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: digits
              }).format(val)
            : '';
        }
      }
    },
    {
      title: {
        text: lossTitle
      },
      tickAmount: 5,
      min: 0,
      max: 100,
      labels: {
        formatter: function (val) {
          return val
            ? new Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: 2
              }).format(val)
            : '';
        }
      },
      opposite: true
    }
  ]
});

export { bdoReportsChartOptions };
