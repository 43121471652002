import { format } from 'date-fns';

export const DateHourCell = ({
  value,
  row: {
    original: { id },
    index
  },
  dateFormat
}: any) => {
  function isDateValid(dateString: string | null | undefined) {
    if (!dateString) return false;

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return false;
    }

    return true;
  }

  return (
    <span className="text-[#191919] text-xs" key={`${id}Pressure${index}`}>
      {isDateValid(value)
        ? format(
            new Date(value?.replace('Z', '')?.split('+')[0]),
            dateFormat ?? 'HH:mm'
          )
        : value}
    </span>
  );
};
