import { useQuery } from '@tanstack/react-query';
import { getLastBDO, getLastBDOequip } from '../../services/bdos.services';
import { getGridsViewForm } from '../../services/grids.services';

export const useBdoViewForm = (date?: string, tracker?: string) => {
  const { data: dataSup, isFetching: isFetchingBdoSup } = useQuery(
    ['BDOView', date, tracker],
    () => getLastBDO(date, tracker),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  const { data: dataEquip, isFetching: isFetchingBdoEquip } = useQuery(
    ['BDOReportsEquip', date, tracker],
    () => getLastBDOequip(date, tracker),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  const { data: gridsData, isLoading: isLoadingGrids } = useQuery(
    ['BDOGridsViewForm', tracker, date],
    () => getGridsViewForm(date),
    {
      staleTime: 0,
      cacheTime: 0
    }
  );

  return {
    gridsData,
    dataSup,
    dataEquip,
    isLoadingGrids,
    isFetchingBdoEquip,
    isFetchingBdoSup
  };
};
