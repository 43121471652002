import { getAllOilFields } from '../../../services/oilwells.services';
import Button from '../../../components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Table from '../../../components/organisms/Table';
import { HiOutlinePlusSm } from 'react-icons/hi';
import Spinner from '../../../components/atoms/Spinner';
import oilfieldsColumns from '../../../constants/tableColumns/mdm/oilfields';

const OilFields = () => {
  const navigate = useNavigate();

  const handleGetOilField = async () => {
    try {
      const response = await getAllOilFields();
      return response;
    } catch (error) {
      toast.error('Erro ao buscar campos');
    }
  };

  const { data, isLoading } = useQuery(['oilfields-adm'], handleGetOilField, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 3, // 3 minutos
    cacheTime: 1000 * 60 * 7 // 7 minutos
  });

  const handleNavigateToCreateOilField = () => {
    navigate('/oilfields/create');
  };

  return (
    <>
      <div className="flex flex-col gap-4 py-4 px-6 bg-white rounded-lg mt-4 pb-8 relative">
        <Button
          title="Novo Campo"
          type="button"
          className="absolute right-14 -top-[56px]"
          icon={<HiOutlinePlusSm className="w-5 h-5" color="white" />}
          onClick={handleNavigateToCreateOilField}
        />

        <section className="flex justify-between items-center">
          <h1 className="text-gray font-bold text-lg flex">
            Campos
            {isLoading && <Spinner className="ml-2" size={16} />}
          </h1>
        </section>

        {!data && (
          <div className="flex flex-col items-center justify-center">
            <Spinner className="mt-4" size={32} />
          </div>
        )}

        {data && (
          <section className="flex flex-col gap-2">
            <Table columns={oilfieldsColumns} data={data || []} />
          </section>
        )}
      </div>
    </>
  );
};

export default OilFields;
