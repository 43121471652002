import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const xvFollowUpCols: Column[] = [
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: 'Hora',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Pressão da coluna'} id="Pressão da coluna" />,
    accessor: 'Pressão da coluna',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'XV'} id="XV" />,
    accessor: 'XV',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: 'observação',
    Cell: DefaultCell,
    width: '8%'
  }
];

export default xvFollowUpCols;
