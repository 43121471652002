import { useQuery } from '@tanstack/react-query';
import {
  getLastBDOUpgnArea,
  getLastBDOUpgnSup
} from '../../services/bdos.services';

export const useBdoUpgn = (date?: string, tracker?: string) => {
  const { data: dataArea } = useQuery(
    ['BDOUpgnArea', tracker, date],
    () => getLastBDOUpgnArea(date, tracker),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );
  const { data: dataSup } = useQuery(
    ['BDOUpgnSup', tracker, date],
    () => getLastBDOUpgnSup(date, tracker),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  let bdoArray = [];

  if (dataSup?.issues?.[0])
    bdoArray.push(...dataSup?.issues?.[0]?.custom_fields);
  if (dataArea?.issues?.[0])
    bdoArray.push(...dataArea?.issues?.[0]?.custom_fields, {
      id: -245,
      name: 'Emissor - Área',
      value: dataArea?.issues?.[0]?.custom_fields?.find(
        (it: any) => it.id === 245
      )?.value
    });

  return {
    bdoArray
  };
};
