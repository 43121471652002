import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Hora Início': '527',
  'Hora Fim': '528',
  Poço: '1656',
  'Grupo de Atividade': '1627',
  Atividades: '797',
  'Classificação da Atividade': '1628',
  Solicitante: '1629'
};

const activitiesRegistry: Column[] = tablefy(items);

export default activitiesRegistry;
