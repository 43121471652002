import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Bomba: '1388',
  'Pressão (kgf/cm²)': '1253',
  'Vazão (m³/h)': '438',
  'Horímetro Total': '1274'
};

const udgnBomb: Column[] = tablefy(items);

export default udgnBomb;
