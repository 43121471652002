import { twMerge } from 'tailwind-merge';

interface SquareButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon: React.ReactNode;
  disabled?: boolean;
}

const SquareButton: React.FC<SquareButtonProps> = ({ icon, ...rest }) => {
  return (
    <button
      {...rest}
      className={twMerge(
        'w-[32px] h-[32px] bg-primary hover:bg-primary/75 transition-all  flex items-center justify-center rounded flex-none disabled:opacity-40 disabled:cursor-wait ',
        rest.className ?? ''
      )}
      type="button"
    >
      {icon}
    </button>
  );
};

export default SquareButton;
