import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const maintenanceRequest: Column[] = [
  {
    Header: <Header text={'Data'} id="Data" />,
    accessor: 'Data',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Campo'} id="Campo" />,
    accessor: 'Campo',
    Cell: DateHourCell,
    width: '8%'
  },
  {
    Header: <Header text={'Área'} id="Área" />,
    accessor: 'Área',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Nome Operador'} id="Nome Operador" />,
    accessor: 'Nome Operador',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Nota'} id="Nota" />,
    accessor: 'Nota',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: (
      <Header text={'Atividade de Manutenção'} id="Atividade de Manutenção" />
    ),
    accessor: 'Atividade de Manutenção',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Status Validação'} id="Status Validação" />,
    accessor: 'Status Validação',
    Cell: DefaultCell,
    width: '8%'
  }
];

export default maintenanceRequest;
