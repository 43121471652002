import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Pontos de Captação': '1448',
  Status: '1459',
  'Limite de consumo diário (m³/dia)': '1449',
  'Hidrômetro às 23:59 (m³)': '1452',
  'Volume 24h (m³)': '1453'
};

const statusByTurnWaterFu: Column[] = tablefy(items);

export default statusByTurnWaterFu;
