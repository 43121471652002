import { Column } from 'react-table';
import bombs from '../../tableColumns/bdoUpgn/bombs';
import airCoolers from '../../tableColumns/bdoUpgn/airCoolers';
import compressors from '../../tableColumns/bdoUpgn/compressors';
import opVariables from '../../tableColumns/bdoUpgn/opVariables';
import cicleInfo from '../../tableColumns/bdoUpgn/cicleInfo';
import dewPoint from '../../tableColumns/bdoUpgn/dewPoint';
import h2sAnalysis from '../../tableColumns/bdoUpgn/h2sAnalysis';
import emc from '../../tableColumns/bdoUpgn/emc';
import termalOil from '../../tableColumns/bdoUpgn/termalOil';
import nr13 from '../../tableColumns/bdoUpgn/nr13';
import etil from '../../tableColumns/bdoUpgn/etil';
import metanol from '../../tableColumns/bdoUpgn/metanol';
import c5 from '../../tableColumns/bdoUpgn/c5+';
import areaAnalysis from '../../tableColumns/bdoUpgn/areaAnalysis';
import areaCert from '../../tableColumns/bdoUpgn/areaCert';
import cilinderStatus from '../../tableColumns/bdoUpgn/cilinderStatus';
import ocBombs from '../../tableColumns/bdoUpgn/ocBombs';
import ocCompressors from '../../tableColumns/bdoUpgn/ocCompressors';
import ocAirCoolers from '../../tableColumns/bdoUpgn/ocAirCoolers';
import sap from '../../tableColumns/bdoUpgn/sap';
import turnSup from '../../tableColumns/bdoUpgn/turnSup';

export const bdoUpgn: (
  | { type: 'label'; id: number; name: string; size?: 'h1' | 'h2' }
  | {
      type: 'grid';
      id: number;
      columns: Column[];
      name: string;
      value?: string;
    }
  | {
      type: 'input' | 'date' | 'number' | 'custom';
      id: number;
      name: string;
      value?: string;
      field?: string;
    }
)[] = [
  { id: 245, name: 'Emissor - Supervisório', type: 'input' },
  { id: -245, name: 'Emissor - Área', type: 'input' },
  { id: 123, name: 'Horímetros dos Equipamentos', type: 'label' },
  { id: 1013, name: 'Bombas', type: 'grid', columns: bombs },
  { id: 1014, name: 'Compressores', type: 'grid', columns: compressors },
  { id: 1015, name: 'Air Coolers', type: 'grid', columns: airCoolers },
  { id: -123, name: 'Variáveis Operacionais', type: 'label' },
  {
    id: 1016,
    name: '',
    type: 'grid',
    columns: opVariables
  },
  { id: 666, name: 'Peneira Molecular', type: 'label' },
  { id: 1017, name: 'Informações de Ciclo', type: 'grid', columns: cicleInfo },
  {
    id: 1018,
    name: 'Resultado de Ponto de Orvalho',
    type: 'grid',
    columns: dewPoint
  },
  { id: -321, name: 'Análise de H2S', type: 'label' },
  { id: 1019, name: '', type: 'grid', columns: h2sAnalysis },
  { id: -333, name: 'Óleo Térmico', type: 'label' },
  { id: 1021, name: '', type: 'grid', columns: termalOil },
  { id: -666, name: 'Coleta de EMC', type: 'label' },
  { id: 1020, name: '', type: 'grid', columns: emc },
  { id: -999, name: 'Preenchimento de Livros na NR13', type: 'label' },
  {
    id: 1179,
    name: '',
    type: 'grid',
    columns: nr13
  },
  { id: 667, name: 'Odorização', type: 'label' },
  { id: 1025, name: 'Etilmercaptan', type: 'grid', columns: etil },
  { id: 969, name: 'Metanol', type: 'label' },
  { id: 1027, name: 'Tambores cheios', type: 'input' },
  { id: 1028, name: 'Tambores em uso', type: 'input' },
  { id: 1026, name: 'Injeção', type: 'grid', columns: metanol },
  { id: 1029, name: 'Transferência de C5+', type: 'grid', columns: c5 },
  {
    id: 696,
    name: 'Situação do Gerador de Emergência às 23h59',
    type: 'label'
  },
  { id: 1349, name: 'Situação', type: 'input' },
  { id: 1350, name: 'Volume (L)', type: 'input' },
  { id: 1351, name: 'Horas de Operação', type: 'input' },
  { id: 2, name: 'Resultado das Análises de Certificação GLP', type: 'label' },
  { id: -102, name: 'Resultados das Análises de Área', type: 'label' },
  { id: 1033, name: 'Média % C2', type: 'input' },
  { id: 1034, name: 'Média % C5', type: 'input' },
  {
    id: 1032,
    name: '',
    type: 'grid',
    columns: areaAnalysis
  },
  {
    id: 1031,
    name: 'Análises de Certificação',
    type: 'grid',
    columns: areaCert
  },
  {
    id: -1001,
    name: 'Status dos Cilindros às 23h59',
    type: 'label'
  },
  {
    id: 1035,
    name: '',
    type: 'grid',
    columns: cilinderStatus
  },
  { id: 4, name: 'Registro de Ocorrências em Equipamentos', type: 'label' },
  { id: 1377, name: 'Bombas', type: 'grid', columns: ocBombs },
  { id: 1379, name: 'Compressores', type: 'grid', columns: ocCompressors },
  { id: 1380, name: 'Air Coolers', type: 'grid', columns: ocAirCoolers },
  { id: -4000, name: 'Registro de Notas do SAP', type: 'label' },
  { id: 1022, name: '', type: 'grid', columns: sap },
  { id: 5, name: 'Eventos - UPGN Campo', type: 'label' },
  { id: -6, size: 'h2', name: 'Turno 01 - 00h às 07h', type: 'label' },
  { id: 1113, name: 'Responsável', type: 'input' },
  { id: 1106, name: '', type: 'grid', columns: turnSup },
  { id: -5, size: 'h2', name: 'Turno 02 - 07h às 19h', type: 'label' },
  { id: 1115, name: 'Responsável', type: 'input' },
  { id: 1108, name: '', type: 'grid', columns: turnSup },
  { id: -4, size: 'h2', name: 'Turno 03 - 19h às 23h59', type: 'label' },
  { id: 1117, name: 'Responsável', type: 'input' },
  { id: 1110, name: '', type: 'grid', columns: turnSup },
  { id: 6, name: 'Eventos - UPGN Supervisório', type: 'label' },
  { id: -3, size: 'h2', name: 'Turno 01 - 00h às 07h', type: 'label' },
  { id: 1114, name: 'Responsável', type: 'input' },
  { id: 1107, name: '', type: 'grid', columns: turnSup },
  { id: -2, size: 'h2', name: 'Turno 02 - 07h às 19h', type: 'label' },
  { id: 1116, name: 'Responsável', type: 'input' },
  { id: 1109, name: '', type: 'grid', columns: turnSup },
  { id: -1, size: 'h2', name: 'Turno 03 - 19h às 23h59', type: 'label' },
  { id: 1118, name: 'Responsável', type: 'input' },
  { id: 1125, name: '', type: 'grid', columns: turnSup }
];
