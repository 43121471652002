import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const etil: Column[] = [
  {
    Header: <Header text={'Turno'} id="Turno" />,
    accessor: '1068',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Tipo'} id="Tipo" />,
    accessor: '1069',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: '112',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Volume (L)'} id="Volume (L)" />,
    accessor: '1071',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Cilindro'} id="Cilindro" />,
    accessor: '1566',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default etil;
