import { convertGridToJson } from '../bdos';

export const generateHashByCFs = (customFields: any[]) => {
  const customFieldValues: { [key: number]: string } = {};

  customFields.forEach((item: any) => {
    customFieldValues[item.id] = item.value;
  });

  return customFieldValues;
};

export const grid2array = (grid: string) => {
  return Object.values(convertGridToJson(grid ?? '{}') ?? {}).filter(
    (obj: any) => {
      return !Object.values(obj).every(value => value === '');
    }
  );
};
