import { DateValueType } from 'react-tailwindcss-datepicker';
import { atom } from 'recoil';

export interface PiopFilters {
  timeRange: DateValueType;
  station: string[];
  installationLoc: string[];
  equipment: string[];
  priority: string[];
  order: string;
  note: string;
}

export const PiopReportFiltersAtom = atom<PiopFilters>({
  key: 'PiopReportFilters',
  default: {
    timeRange: {
      startDate: null,
      endDate: null
    },
    station: [],
    installationLoc: [],
    equipment: [],
    priority: ['Não Priorizado'],
    order: '',
    note: ''
  }
});
