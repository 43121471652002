import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Data/Hora da Medição': '2643',
  'Consumo Instantâneo (m³/d)': '2652',
  'Consumo Acumulado (m³)': '2653',
  'Acumulado (m³) - Declarado': '2744',
  'Estimativa Compressor (m³/d)': '2654',
  'Abertura de Reciclo (%)': '2655',
  'Temperatura (°C)': '1252',
  'Pressão Descarga (psi)': '710',
  'Pressão de Descarga (kgf/cm²)': '2656'
};

const emedFQUIA400: Column[] = tablefy(items, undefined, undefined, undefined, [
  'Consumo Instantâneo (m³/d)',
  'Consumo Acumulado (m³)',
  'Acumulado (m³) - Declarado',
  'Estimativa Compressor (m³/d)',
  'Abertura de Reciclo (%)',
  'Temperatura (°C)',
  'Pressão Descarga (psi)',
  'Pressão de Descarga (kgf/cm²)'
]);

export default emedFQUIA400;
