import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Hora: '112',
  'Injeção (cm³/min)': '1198',
  'Injeção (L/dia)': '588',
  'Taxa (L/h)': '589',
  'Acumulado Anterior (L)': '590',
  'Acumulado (L)': '591'
};

const sgInjection: Column[] = tablefy(items);

export default sgInjection;
