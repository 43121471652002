import { HiOutlineArrowsExpand } from 'react-icons/hi';
import Spinner from '../atoms/Spinner';

interface WorkflowListProps {
  title: string;
  phase: {
    name: string;
    ids: number[];
    isExpectedDate?: boolean;
    forceEndTestDate?: boolean;
    invertDates?: boolean;
  };
  data: any[];
  handleLoadMore: () => void;
  handleExpandList: () => void;
  totalCount: number;
  testsData?: any;
  invertDate?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  emptyMessage: string;
  item: React.FC<any>;
  alertIcon?: React.ReactNode;
}

export const WorkflowList = ({
  title,
  phase,
  data,
  handleExpandList,
  item: CardItem,
  handleLoadMore,
  totalCount,
  emptyMessage,
  isLoading = false,
  isFetching = false,
  alertIcon
}: WorkflowListProps) => {
  return (
    <div className="bg-white rounded-2xl min-h-[250px] py-3 overflow-hidden">
      <div className="flex items-center justify-between">
        <div className="flex w-full pb-3 pt-2 mx-4 border-gray-200 items-center border-b-[1px] border-[#DFDFDF]">
          <h1 className="text-xs text-primary font-bold">{title}</h1>

          {isLoading || isFetching ? (
            <Spinner size={12} classNameWrapper="inline w-min ml-auto" />
          ) : totalCount > 0 ? (
            <span className="text-[10px] text-white ml-2 items-center justify-center max-h-[13px] px-[4px] bg-[#758ad5] rounded-full">
              {totalCount}
            </span>
          ) : null}

          {totalCount > 0 && (
            <HiOutlineArrowsExpand
              size={16}
              className="ml-auto text-primary hover:cursor-pointer"
              onClick={handleExpandList}
            />
          )}
        </div>
      </div>

      {isLoading && !data ? (
        <div className="flex justify-center items-center h-full">
          <Spinner />
        </div>
      ) : (
        <section
          className="
              flex flex-col 
              h-full gap-2 
              overflow-y-scroll 
              flex-shrink flex-grow basis-auto 
              min-h-0 
              pt-[10px] px-4 pb-10
            "
        >
          {data?.map(test => (
            <CardItem
              alertIcon={alertIcon}
              key={test.id}
              test={test}
              phase={phase}
            />
          ))}

          {data.length < totalCount &&
            (isLoading || isFetching ? (
              <Spinner />
            ) : (
              <button
                onClick={handleLoadMore}
                className="
                    text-primary text-xs 
                    mt-2 py-1 px-3 
                    bg-btn-light hover:bg-btn-light-2 
                    rounded 
                    transition-colors 
                    w-min 
                    whitespace-nowrap
                  "
              >
                Ver Mais
              </button>
            ))}

          {data.length === 0 && !isLoading && !isFetching && (
            <div className="flex justify-center items-center h-full">
              <span className="text-[#888] text-xs">{emptyMessage}</span>
            </div>
          )}
        </section>
      )}
    </div>
  );
};
