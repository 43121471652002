import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const areaAnalysis: Column[] = [
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: '112',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'C2 (% mol)'} id="C2 (% mol)" />,
    accessor: '1077',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'C5 (% mol)'} id="C5 (% mol)" />,
    accessor: '1078',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Cilindro'} id="Cilindro" />,
    accessor: '1070',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: '712',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default areaAnalysis;
