import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const sampleCollectionCols: Column[] = [
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'Poço',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: () => <Header text={'Tipo de Fluído'} id="Tipo de Fluído" />,
    accessor: 'Tipo de Fluído',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => <Header text={'Hora'} id="Hora" />,
    accessor: 'Hora',
    Cell: DateHourCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Tipo de Amostra'} id="Tipo de Amostra" />,
    accessor: 'Tipo de Amostra',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'Volume (m³)'} id="Volume (m³)" />,
    accessor: 'Volume (m³)',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => (
      <Header
        text={'Pressão de coleta (kgf/cm²)'}
        id="Pressão de coleta (kgf/cm²)"
      />
    ),
    accessor: 'Pressão de coleta (kgf/cm²)',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => (
      <Header
        text={'Temperatura de coleta (°C)'}
        id="Temperatura de coleta (°C)"
      />
    ),
    accessor: 'Temperatura de coleta (°C)',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => (
      <Header text={'Ponto de Amostragem'} id="Ponto de Amostragem" />
    ),
    accessor: 'Ponto de Amostragem',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  }
];

export default sampleCollectionCols;
