export function convertGridToJson(
  inputString: string
): [{ [key: string]: string }] | null {
  if (!inputString) return null;
  const jsonString = inputString.replaceAll('=>', ':');

  try {
    const jsonObject = JSON.parse(jsonString);

    return jsonObject;
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null;
  }
}
