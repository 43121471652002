import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { Header } from '../../../components/atoms/table/Header';

const weeklyReport = [
  {
    Header: () => <Header text={'Id'} id="Id" />,
    accessor: 'issue_id',
    Cell: ({ value }: any) => {
      return (
        <a
          className="text-primary text-xs font-bold"
          href={`${import.meta.env.VITE_REDMINE_URL}/issues/${value}`}
          target="_blank"
        >
          {value}
        </a>
      );
    }
  },
  {
    Header: () => <Header text={'Ordem'} id="Ordem" />,
    accessor: 'order',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Descrição Breve'} id="Descrição Breve" />,
    accessor: 'description',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header text={'Descrição Operação'} id="Descrição Operação" />
    ),
    accessor: 'descriptionOp',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Disciplina'} id="Disciplina" />,
    accessor: 'discipline',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Equipamento'} id="Equipamento" />,
    accessor: 'equipment',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Inicio Programado'} id="Inicio Programado" />,
    accessor: 'startScheduled',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Poço Afetado'} id="Poço Afetado" />,
    accessor: 'wellAffected',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Priorização'} id="Priorização" />,
    accessor: 'priority',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Tipo de Pendência'} id="pendencyType" />,
    accessor: 'pendencyType',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header text={'Detalhamento de Pendências'} id="pendencyDetails" />
    ),
    accessor: 'pendencyDetails',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Status da Pendência'} id="pendencyStatus" />,
    accessor: 'pendencyStatus',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Área Responsável'} id="areaResponsible" />,
    accessor: 'areaResponsible',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Responsável'} id="responsible" />,
    accessor: 'responsible',
    Cell: DefaultCell
  }
];

export default weeklyReport;
