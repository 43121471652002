import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  required?: boolean;
  isError?: boolean;
}

const Input: React.FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  ({ label, required, isError, ...rest }, ref) => {
    return (
      <div className="flex flex-col w-full gap-1">
        {label ? (
          <label className="font-medium text-sm text-primary">
            {label}
            {required && <span className="text-red">*</span>}
          </label>
        ) : null}

        <input
          {...rest}
          ref={ref}
          className={twMerge(
            `w-full h-8 border px-3 border-[#D8D8D8] active:border-[#cbcbcb] disabled:bg-[#f1f1f1] rounded-md`,
            rest.className ?? '',
            isError ? 'border-red' : ''
          )}
        />
      </div>
    );
  }
);

export default Input;
