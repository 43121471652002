import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Hora: '112',
  Compressores: '1467',
  Status: '1468',
  'Categoria do Evento': '1469',
  Motivo: '1470',
  'Descrição do Evento': '1471',
  'Nº da Note ou OM (SAP)': '1472',
  'Solicitante da nota': '1473'
};

const ocCompressionFu: Column[] = tablefy(items);

export default ocCompressionFu;
