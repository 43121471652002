import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Tipo de Registro': '1180',
  'Equipamento/ N° PSV': '1181',
  Observação: '712'
};

const nr13: Column[] = tablefy(items);

export default nr13;
