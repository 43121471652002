import { Column } from 'react-table';
import { tablefy } from '../../../../utils/tablesUtils';

const items: any = {
  Poço: '697',
  Turno: '1068',
  Status: '1329',
  'GNA-01': '1554',
  'GNA-02': '1555',
  'Manifold de Óleo': '1556',
  'V-122102': '1557',
  'V-122104': '1558'
};

const mani01: Column[] = tablefy(items);

export default mani01;
