import { BsTrash3Fill } from 'react-icons/bs';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import ModalConfirmation from '../../../components/molecules/ConfirmationModal';
import { queryClient } from '../../../App';
import { deleteSatellite } from '../../../services/oilwells.services';
import SquareButton from '../../../components/atoms/SquareButton';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import { Header } from '../../../components/atoms/table/Header';

const DefaultCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs whitespace-nowrap w-full">
      {value || '-'}
    </span>
  );
};

export const TimeCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs whitespace-nowrap">
      {value ? format(new Date(value), 'dd/MM/yyyy') : '-'}
    </span>
  );
};

const satelliteColumns = [
  {
    Header: () => <Header text={'ID'} id="id" />,
    accessor: 'id',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Nome'} id="name" />,
    accessor: 'name',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Campo'} id="Campo" />,
    accessor: 'oilFieldId',
    Cell: (value: any) => {
      const { fields } = useMdmUtils({
        fields: true
      });

      return (
        <DefaultCell
          value={
            fields?.find(
              (field: any) => field.id === value.row.original.oilFieldId
            )?.name
          }
        />
      );
    }
  },
  {
    Header: () => <Header text={'Data de atualização'} id="updatedAt" />,
    accessor: 'updatedAt',
    Cell: TimeCell
  },
  {
    Header: () => (
      <Header text={'Deletar'} id="actions" className="max-w-[60px]" />
    ),
    accessor: 'actions',
    Cell: (props: any) => {
      const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);

      const handleDeleteSatellite = async () => {
        try {
          await deleteSatellite(props.row.original.id);
          toast.success('Satélite deletada com sucesso');
          queryClient.invalidateQueries(['satellite-adm']);
        } catch {
          toast.error('Erro ao deletar satélite');
        }
      };

      return (
        <>
          <SquareButton
            className="bg-red hover:bg-red/75"
            icon={<BsTrash3Fill color="white" size={16} />}
            onClick={() => setModalDeleteIsOpen(old => !old)}
          />

          {modalDeleteIsOpen && (
            <ModalConfirmation
              confirmAction={handleDeleteSatellite}
              title="Deletar satélite"
              description="Tem certeza que deseja deletar este satélite? Essa é uma ação permanente."
              confirmButtonClassName="bg-emerald-600 hover:bg-emerald-400"
              isOpen={true}
              setIsOpen={() => setModalDeleteIsOpen(!modalDeleteIsOpen)}
            />
          )}
        </>
      );
    }
  }
];

export default satelliteColumns;
