import { twMerge } from 'tailwind-merge';
import { BdoCardItem } from './BdoCardItem';

interface BdoCardProps {
  title: string;
  redirect?: {
    to: string;
    title: string;
    icon: React.FC<{ size: number; color?: string }>;
    description?: string;
    disabled?: boolean;
    iconSize?: number;
    options?: {
      label: string;
      value: string;
    }[];
    alert?: string;
  }[];
  className?: string;
  disabled?: boolean;
}

const BdoCard: React.FC<BdoCardProps> = ({ title, redirect, className }) => {
  return (
    <div
      className={twMerge(
        'flex gap-4 bg-white flex-col px-7 py-5 pr-5 rounded-xl',
        className ?? ''
      )}
    >
      <h2 className="text-2xl text-primary font-bold whitespace-pre-wrap">
        {title}
      </h2>
      <section className={'flex gap-4 flex-wrap'}>
        {redirect?.map(it => (
          <BdoCardItem key={it.title} {...it} />
        ))}
      </section>
    </div>
  );
};

export default BdoCard;
