import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Compressor/Equipamento': '1438',
  'Diferencial de Pressão (kgf/cm²) - 1º Turno': '1439',
  'Diferencial de Pressão (kgf/cm²) - 2º Turno': '1560',
  'Diferencial de Pressão (kgf/cm²) - 3º Turno': '1559'
};

const pressureDif: Column[] = tablefy(items);

export default pressureDif;
