import { twMerge } from 'tailwind-merge';
import Spinner from '../atoms/Spinner';

interface BdoCards {
  title: string;
  data: Record<string, any>;
  className?: string;
  isLoading?: boolean;
}

export const BdoCards = ({ title, data, className, isLoading }: BdoCards) => {
  return (
    <div
      key={title}
      className={twMerge(
        'px-4 py-2 bg-[#f5f5f5] flex flex-col items-center rounded-lg w-full max-w-[250px]',
        className ?? ''
      )}
    >
      <span className="text-primary font-bold">{title}</span>

      <div className="w-full mx-auto border-b my-2 border-slate-300 h-px" />

      <div className="flex gap-1 w-full justify-evenly">
        {Object.keys(data)?.map(it => (
          <div
            className="flex flex-col items-center min-w-[95px] text-body-dark"
            key={it}
          >
            <span>{it}</span>

            <strong>
              {isLoading ? (
                <Spinner size={16} />
              ) : !Number.isNaN(Number(data[it])) ? (
                new Intl.NumberFormat('pt-BR', {
                  currency: 'BRL',
                  maximumFractionDigits: 2
                }).format(Number(data[it]))
              ) : (
                '-'
              )}
            </strong>
          </div>
        ))}
      </div>
    </div>
  );
};
