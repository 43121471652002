import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Hora: '1667',
  Duto: '2628',
  'N° do Lacre': '842',
  Operador: '2626',
  Observação: '712'
};

const raspStation: Column[] = tablefy(items);

export default raspStation;
