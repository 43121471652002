import { addDays, addHours, isPast } from 'date-fns';
import { workPermitWorkflowPhasesEnum } from '../constants/enums/phases';
import { Issue } from '../types/redmine';
import { WorkPermitDataProps } from '../components/molecules/WorkPermitPhaseCard';

const getByCustomField = (data: Issue, id: number) =>
  data.custom_fields.find(field => field.id === id)?.value;

export const formatWorkPermit = (data: Issue[] = [], status: number[]) =>
  data
    ?.filter((test: any) => status.includes(test.status.id))
    ?.map((test: any) => {
      const tags = [];
      const ptType = getByCustomField(test, 1873);

      if (ptType) {
        tags.push(ptType);
      }

      if (test.status.id === workPermitWorkflowPhasesEnum.Cancelado) {
        tags.push('Cancelado');
      } else if (test.status.id === workPermitWorkflowPhasesEnum.Coemitente) {
        tags.push({
          text: 'Coemitente',
          className: 'bg-[#FFE8D7] text-[#EC7100]'
        });
      } else if (test.status.id === workPermitWorkflowPhasesEnum.Correcao) {
        tags.push({
          text: 'Correção',
          className: 'bg-[#FFE8D7] text-[#EC7100]'
        });
      }

      if (test?.parent?.id) {
        tags.push({
          text: 'Reprogramada',
          className: 'bg-[#d5f6e3] text-[#157453]'
        });
      }

      if (
        test?.status?.id == workPermitWorkflowPhasesEnum.RenovaçãoRequisitante
      ) {
        tags.push({
          text: 'Renovação Requisitante',
          className: 'bg-[#FFE8D7] text-[#EC7100]'
        });
      } else if (
        test?.status?.id == workPermitWorkflowPhasesEnum.RenovaçãoRequisitante
      ) {
        tags.push({
          text: 'Renovação Emitente',
          className: 'bg-[#B4F8E2] text-[#136A4D]'
        });
      }

      const expectedTestDate = getByCustomField(test, 1863)
        ? addHours(new Date(`${getByCustomField(test, 1863)}`), 3)
        : undefined;

      const endTestDate = getByCustomField(test, 2126)
        ? addHours(new Date(`${getByCustomField(test, 2126)}`), 3)
        : undefined;

      const shortText = getByCustomField(test, 2058);

      return {
        name: test.subject.split(' - ')?.[1] ?? test.subject,
        shortText,
        id: test.id as number,
        tags,
        expectedTestDate,
        endTestDate,
        obsIsPending: 'Atividade de Alto Risco',
        isPending:
          (getByCustomField(test, 1946) as unknown as string[]).length > 0,
        createdAt: new Date(test.created_on),
        status: test.status.id as number,
        isLeadership:
          test.status.id === workPermitWorkflowPhasesEnum.ValidacaoLideranca,
        isLateText: 'PT Atrasada',
        isLate: endTestDate
          ? (getByCustomField(test, 1873) === 'PT Convencional'
              ? isPast(addDays(endTestDate, 1))
              : getByCustomField(test, 1873) === 'PT Renovada'
                ? isPast(addDays(endTestDate, 7))
                : getByCustomField(test, 1873) === 'PT Temporária'
                  ? isPast(addDays(endTestDate, 30))
                  : false) &&
            [
              workPermitWorkflowPhasesEnum.ExecucaodoServico,
              workPermitWorkflowPhasesEnum.QuitacaoEmitente,
              workPermitWorkflowPhasesEnum.QuitacaoTecSeguranca
            ].includes(test.status.id)
          : false
      } as WorkPermitDataProps;
    });
