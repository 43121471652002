import { useAccount } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import { format, startOfMonth, subMonths } from 'date-fns';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import { toast } from 'react-toastify';
import Button from '../../../components/atoms/Button';
import Checkbox from '../../../components/atoms/Checkbox';
import Input from '../../../components/atoms/Input';
import SelectInputUncontrolled from '../../../components/atoms/SelectInputUncontrolled';
import Spinner from '../../../components/atoms/Spinner';
import Table from '../../../components/organisms/Table';
import oilwellZoneAllocationEdit from '../../../constants/tableColumns/mdm/oilwellZonesAllocationEdit';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import {
  getAllOilwellZoneAllocationByOilwellAndApplication,
  updateAllocation
} from '../../../services/oilwells.services';

const OilwellZoneAllocation = () => {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState({
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  });
  const [updateNext, setUpdateNext] = useState(false);
  const [currentHour, setCurrentHour] = useState<string>('00:00');
  const [lastApplicationDate, setLastApplicationDate] = useState<string[]>([]);
  const { id: oilwellId } = useParams<{ id: string }>();

  const user = useAccount();

  const handleGetAllocation = async () => {
    if (!oilwellId) return;
    try {
      const response = await getAllOilwellZoneAllocationByOilwellAndApplication(
        oilwellId,
        `${currentDate.startDate}T${currentHour}`
      );
      setLastApplicationDate(response?.map((it: any) => it.applicationDate));
      return response;
    } catch (error) {
      toast.error('Erro ao buscar zonas');
    }
  };

  const { data, isLoading } = useQuery(
    ['oilwells-zones-id-adm-by-application', oilwellId, currentDate.startDate],
    handleGetAllocation,
    {
      staleTime: 0,
      cacheTime: 0,
      enabled: !!oilwellId,
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );

  const methods = useForm({
    defaultValues: {
      data: data
        ?.map((it: any) => ({ ...it.data, id: it.relationId }))
        ?.map((item: any) => {
          return {
            identifier: item?.id,
            oilwell: item?.oilwell,
            zone: item?.zone,
            approprietionOil: item?.approprietionOil,
            approprietionCondensed: item?.approprietionCondensed,
            approprietionAssociatedGas: item?.approprietionAssociatedGas,
            approprietionNaturalGas: item?.approprietionNaturalGas,
            approprietionWater: item?.approprietionWater,
            injectionWater: item?.injectionWater,
            injectionGas: item?.injectionGas
          };
        })
    }
  });

  useEffect(() => {
    if (data)
      methods.reset({
        data: data
          ?.map((it: any) => ({ ...it.data, id: it.relationId }))
          .map((item: any) => ({
            identifier: item.id,
            oilwell: item.oilwell,
            zone: item.zone,
            approprietionOil: item.approprietionOil,
            approprietionCondensed: item.approprietionCondensed,
            approprietionAssociatedGas: item.approprietionAssociatedGas,
            approprietionNaturalGas: item.approprietionNaturalGas,
            approprietionWater: item.approprietionWater,
            injectionWater: item.injectionWater,
            injectionGas: item.injectionGas,
            injectionType: item.injectionType
          }))
      });
  }, [data]);

  const onSubmit = async (formData: any) => {
    if (Object.values(total).some(it => it != 100 && it != 0))
      return toast.error(
        'A soma dos valores da coluna podem ser apenas 100 ou 0'
      );

    for (const it of formData?.data) {
      const { identifier, oilwell, zone, ...rest } = it;
      try {
        await updateAllocation(
          identifier,
          {
            injectionType: rest.injectionType,
            approprietionOil: Number(rest.approprietionOil) || 0,
            approprietionCondensed: Number(rest.approprietionCondensed) || 0,
            approprietionAssociatedGas:
              Number(rest.approprietionAssociatedGas) || 0,
            approprietionNaturalGas: Number(rest.approprietionNaturalGas) || 0,
            approprietionWater: Number(rest.approprietionWater) || 0,
            injectionWater: Number(rest.injectionWater) || 0,
            injectionGas: Number(rest.injectionGas) || 0
          },
          `${currentDate.startDate}T${currentHour}`,
          updateNext
        );
      } catch (e) {
        toast.error('Erro ao atualizar alocação');
      }
    }

    toast.success('Alocação atualizada com sucesso');
  };

  const { oilwells } = useMdmUtils({
    oilwells: true
  });

  const [total, setTotal] = useState({
    approprietionOil: 0,
    approprietionCondensed: 0,
    approprietionAssociatedGas: 0,
    approprietionNaturalGas: 0,
    approprietionWater: 0,
    injectionWater: 0,
    injectionGas: 0
  });

  const values = useWatch({
    control: methods.control,
    name: 'data'
  });

  useEffect(() => {
    if (values && values.length > 0) {
      const res = values.reduce(
        (acc: any, item: any) => {
          return {
            approprietionOil:
              acc.approprietionOil +
              Number(item.approprietionOil) +
              Number(item.approprietionCondensed),
            approprietionAssociatedGas:
              acc.approprietionAssociatedGas +
              Number(item.approprietionAssociatedGas) +
              Number(item.approprietionNaturalGas),
            approprietionWater:
              acc.approprietionWater + Number(item.approprietionWater),
            injectionWater: acc.injectionWater + Number(item.injectionWater),
            injectionGas: acc.injectionGas + Number(item.injectionGas)
          };
        },
        {
          approprietionOil: 0,
          approprietionCondensed: 0,
          approprietionAssociatedGas: 0,
          approprietionNaturalGas: 0,
          approprietionWater: 0,
          injectionWater: 0,
          injectionGas: 0
        }
      );
      setTotal(res);
    }
  }, [values]);

  const handleNavigateToCreateOilWellAllocation = () => {
    navigate('/oilwell-zone-allocation/create');
  };

  const today = new Date();

  return (
    <>
      <div className="flex flex-col gap-4 py-4 px-6 bg-white rounded-lg mt-4 pb-8 relative">
        <Button
          title="Nova alocação"
          type="button"
          className="absolute right-14 -top-[56px]"
          icon={<HiOutlinePlusSm className="w-5 h-5" color="white" />}
          onClick={handleNavigateToCreateOilWellAllocation}
        />

        <section className="flex justify-between items-center">
          <h1 className="text-gray font-bold text-lg flex">
            Percentual de Apropriação por Fluido
            {isLoading && <Spinner className="ml-2" size={16} />}
          </h1>

          <div className="flex gap-2">
            <div className="flex flex-col gap-1 flex-end w-3/4 mb-2 max-w-[280px]">
              <label className="font-medium text-sm text-primary">
                Data de Aplicação
              </label>
              <Datepicker
                value={currentDate}
                asSingle={true}
                useRange={false}
                onChange={(val: any) => {
                  if (!val.startDate) return;

                  setCurrentDate(val);
                }}
                i18n="pt-br"
                placeholder="Selecione uma data"
                inputClassName={
                  'relative transition-all duration-300 h-[40px] pl-2 pr-10 w-full border-[1px] border-[#cccccc] dark:border-slate-600 rounded-md tracking-wide text-sm focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20'
                }
                classNames={{
                  toggleButton() {
                    return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
                  }
                }}
                maxDate={today}
                minDate={
                  user?.idTokenClaims?.roles?.includes('Portal.SuperAdmin')
                    ? null
                    : today.getDate() <= 15
                      ? startOfMonth(subMonths(today, 1))
                      : startOfMonth(today)
                }
              />
              {/* 
              {lastApplicationDate &&
                lastApplicationDate.length > 0 &&
                lastApplicationDate.map((it, idx) => (
                  <div
                    className="w-full flex items-center text-xs font-normal text-[#666] mt-2"
                    key={idx}
                  >
                    <FaCircleInfo className="mr-2" size={12} />
                    <strong className="font-bold mr-1">
                      Data do último registro:
                    </strong>
                    {it !== "present"
                      ? format(
                          new Date(it.replace("Z", "")),
                          "dd/MM/yyyy HH:mm"
                        )
                      : "Sem registros"}
                  </div>
                ))} */}
            </div>

            <Input
              label="Hora de Aplicação"
              required
              placeholder="Hora de Aplicação"
              value={currentHour}
              onChange={e => setCurrentHour(e.target.value)}
              type="time"
              className="h-10"
            />

            <SelectInputUncontrolled
              value={oilwells?.data
                ?.map((it: any) => ({ value: it.id, label: it.name }))
                ?.find((item: any) => item.value === oilwellId)}
              onChange={(e: any) => {
                navigate(`/oilwell-zone-allocation/${e.value}`);
              }}
              label="Editar por Poço"
              options={oilwells?.data?.map((item: any) => ({
                value: item.id,
                label: item.name
              }))}
              placeholder="Editar por Poço"
              className="w-72 min-w-72"
            />
          </div>
        </section>

        {!data && (
          <div className="flex flex-col items-center justify-center">
            <Spinner className="mt-4" size={32} />
          </div>
        )}

        {data && !isLoading && (
          <FormProvider {...methods}>
            <form
              className="flex flex-col gap-2"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <Table
                columns={oilwellZoneAllocationEdit}
                key={oilwellId}
                data={
                  [
                    ...data?.map((it: any) => ({
                      ...it.data,
                      id: it.relationId,
                      applicationDate: it.applicationDate
                    })),
                    {
                      id: 'Total',
                      oilwell: '',
                      zone: '',
                      ...total
                    }
                  ] || []
                }
              />

              <div className="w-full justify-between">
                <Checkbox
                  checked={updateNext}
                  onChange={() => setUpdateNext(old => !old)}
                  label="Atualizar próximas aplicações."
                />

                <div className="flex justify-end gap-2 mt-4">
                  <Button
                    title="Limpar"
                    type="button"
                    className="bg-transparent hover:bg-primary/10"
                    classNameSpan="text-body-dark font-medium underline"
                    onClick={() => {
                      methods.reset();
                    }}
                  />

                  <Button
                    title="Salvar"
                    type="submit"
                    className="w-min"
                    disabled={!methods.formState.isDirty}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        )}
      </div>
    </>
  );
};

export default OilwellZoneAllocation;
