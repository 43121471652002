import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const bmCols: Column[] = [
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'Poço',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: () => <Header text={'Data'} id="Data" />,
    accessor: 'Data',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 150
  },
  {
    Header: () => <Header text={'Hora'} id="Hora" />,
    accessor: 'Hora',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 150
  },
  {
    Header: () => (
      <Header
        text={'Pressão de cabeça (kgf/cm²)'}
        id="Pressão de cabeça (kgf/cm²)"
      />
    ),
    accessor: 'Pressão de cabeça (kgf/cm²)',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => (
      <Header
        text={'Pressão de linha (kgf/cm²)'}
        id="Pressão de linha (kgf/cm²)"
      />
    ),
    accessor: 'Pressão de linha (kgf/cm²)',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => (
      <Header text={'Pressão anular (kgf/cm²)'} id="Pressão anular (kgf/cm²)" />
    ),
    accessor: 'Pressão anular (kgf/cm²)',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'Corrente Motor'} id="Corrente Motor" />,
    accessor: 'Corrente Motor',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'Tensão Motor'} id="Tensão Motor" />,
    accessor: 'Tensão Motor',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'Curso UB'} id="Curso UB" />,
    accessor: 'Curso UB',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'CPM UB'} id="CPM UB" />,
    accessor: 'CPM UB',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'Torque da UB'} id="Torque da UB" />,
    accessor: 'Torque da UB',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'MPRL'} id="MPRL" />,
    accessor: 'MPRL',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  }
];

export default bmCols;
