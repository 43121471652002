import { FaTools } from 'react-icons/fa';
import { FaMagnifyingGlassChart, FaOilWell } from 'react-icons/fa6';
import {
  GiAztecCalendarSun,
  GiBulletBill,
  GiGoat,
  GiTank,
  GiWaterPolo
} from 'react-icons/gi';
import { HiFolder } from 'react-icons/hi';
import { LuCalendarDays, LuCalendarHeart } from 'react-icons/lu';
import { MdOutlineTableView, MdWaterDrop } from 'react-icons/md';
import BdoCard from '../../components/molecules/BdoCard';
import { endOfTomorrow, format, subDays } from 'date-fns';
import { TbReportAnalytics } from 'react-icons/tb';
import { BiTask } from 'react-icons/bi';

const BdoMenu: React.FC = () => {
  const yesterday = format(subDays(new Date(), 1), 'yyyy/MM/dd');
  const cards = [
    {
      title: 'Visão Geral',
      redirect: [
        {
          to: '/home/bdo/bdo-view',
          title: 'Repositório de BDOs',
          icon: import.meta.env.DEV ? GiGoat : HiFolder,
          description: `Arquivos em PDF dos BDOs consolidados.
          `,
          options: [
            {
              label: 'Campo',
              value: `/home/bdo/bdo-view?tracker=Pilar&date=${yesterday}`
            },
            {
              label: 'Estação de Produção',
              value: `/home/bdo/bdo-ep?tracker=Pilar&date=${yesterday}`
            },
            {
              label: 'Estação de Compressão',
              value: `/home/bdo/bdo-ec?tracker=Pilar&date=${yesterday}`
            },
            {
              label: 'UPGN',
              value: `/home/bdo/upgn?date=${yesterday}`
            },
            {
              label: 'Tucano Sul',
              value: `/home/bdo/bdo-ts?date=${yesterday}`
            }
          ]
        },
        {
          to: '/home/bdo/bdo-history',
          title: 'Histórico de BDO',
          description: 'Formulários de BDOs em preenchimento.',
          icon: import.meta.env.DEV ? LuCalendarHeart : LuCalendarDays,
          options: [
            {
              label: 'PAL',
              value: `/home/bdo/bdo-history`
            },
            {
              label: 'PTS',
              value: `/home/bdo/bdo-pts-history`
            }
          ],
          alert:
            'Acesso restrito: necessário login para edição de dados dos boletins'
        }
      ],
      className: 'col-span-4 row-span-4'
    },
    {
      title: 'Relatórios',
      redirect: [
        {
          to: '/home/bdo/bdo-reports',
          title: 'Informe de Produção',
          icon: import.meta.env.DEV
            ? GiAztecCalendarSun
            : FaMagnifyingGlassChart,
          iconSize: 14,
          description:
            'Impacto de aberturas e fechamentos no potencial e perdas.',
          options: [
            {
              label: 'Pilar',
              value: '/home/bdo/bdo-reports?tracker=Pilar'
            },
            {
              label: 'Furado',
              value: '/home/bdo/bdo-reports?tracker=Furado'
            },
            {
              label: 'Tucano Sul',
              value: '/home/bdo/bdo-reports?tracker=Tucano+Sul'
            }
          ]
        },
        {
          to: '/home/bdo/production-view',
          title: 'Tanques Fiscais',
          className:
            'from-persian-blue-400 to-persian-blue-300 text-persian-blue-900',
          icon: import.meta.env.DEV ? GiTank : MdOutlineTableView,
          description:
            'Estimativa de produção de óleo de acordo com o nível de tanques fiscais.',
          options: [
            {
              label: 'Pilar',
              value: '/home/bdo/production-view?tracker=Pilar'
            },
            {
              label: 'Furado',
              value: '/home/bdo/production-view?tracker=Furado'
            }
          ]
        },
        {
          to: '/home/bdo/water-view',
          title: 'Tanques de Água',
          className:
            'from-persian-blue-400 to-persian-blue-300 text-persian-blue-900',
          icon: import.meta.env.DEV ? GiWaterPolo : MdWaterDrop,
          description:
            'Estimativas de tempo de transbordo dos tanques de acordo com os níveis.',
          options: [
            {
              label: 'Pilar',
              value: '/home/bdo/water-view?tracker=Pilar'
            },
            {
              label: 'Furado',
              value: '/home/bdo/water-view?tracker=Furado'
            }
          ]
        },
        {
          to: '/home/bdo/drilling?tracker=PR4',
          title: 'Boletim de Perfuração',
          className:
            'from-persian-blue-400 to-persian-blue-300 text-persian-blue-900',
          icon: import.meta.env.DEV ? FaOilWell : FaOilWell,
          description: 'Relatório diário de perfuração de novos poços.',
          options: []
        },
        {
          to: '/home/bdo/production-analysis-2h',
          title: 'Relatórios Supervisório Matriz',
          className:
            'from-persian-blue-400 to-persian-blue-300 text-persian-blue-900',
          icon: import.meta.env.DEV ? GiBulletBill : TbReportAnalytics,
          description: 'Relatórios de perdas e ganhos de produção.',
          options: [
            {
              label: 'Análise de Produção 2h - Alagoas',
              value: '/home/bdo/production-analysis-2h'
            },
            // {
            //   label: "Analise de Produção 2h - Tucano Sul",
            //   value: "/home/bdo/production-analysis-pts-2h",
            // },
            {
              label: 'RAOpe',
              value: '/home/bdo/raope'
            }
          ]
        }
      ],
      className: 'col-span-4 row-span-12 w-[550px]'
    },
    {
      title: 'Slickline e UCAQ',
      className: 'col-span-4 row-span-4',
      redirect: [
        {
          to: '/home/bdo/slickline',
          title: 'Acompanhamento de Atividades',
          description: 'Operações programadas de slickline e UCAQ.',
          className:
            'from-persian-blue-400 to-persian-blue-300 text-persian-blue-900',
          icon: BiTask,
          iconSize: 14,
          options: [
            {
              label: 'Kanban',
              value: '/home/slickline'
            },
            {
              label: 'Calendário',
              value: '/home/slickline/calendar'
            },
            {
              label: 'Programação diária',
              value: `/home/slickline/dailytasks?plannedDate=${format(
                endOfTomorrow(),
                'yyyy-MM-dd'
              )}`
            }
          ]
        },
        {
          to: '/home/bdo/slickline',
          title: 'Boletins',
          description:
            'Histórico dos boletins de operação e medição de slickline e UCAQ.',
          className:
            'from-persian-blue-400 to-persian-blue-300 text-persian-blue-900',
          icon: HiFolder,
          iconSize: 14,
          options: [
            {
              label: 'BDO Individual',
              value: '/home/bdo/slickline'
            },
            {
              label: 'BDO Consolidado',
              value: '/home/bdo/slickline/consolidated'
            }
            // {
            //   label: 'BM Slickline - EQUISERV',
            //   value: '/home/slickline/BM/equiserv/slickline'
            // },
            // {
            //   label: 'BM Slickline - PERBRAS',
            //   value: '/home/slickline/BM/perbras/slickline'
            // },
            // {
            //   label: 'BM UCAQ',
            //   value: '/home/slickline/BM/perbras/ucaq'
            // }
          ]
        }
      ]
    },
    {
      title: 'Integridade',
      className: 'col-span-4 row-span-4',
      redirect: [
        {
          to: '/home/bdo/integrity-form',
          title: 'Formulários de Integridade',
          description:
            'Histórico dos Formulários de Integridade em preenchimento.',
          className:
            'from-persian-blue-400 to-persian-blue-300 text-persian-blue-900',
          icon: FaTools,
          iconSize: 14
          // disabled: true,
        }
      ]
    }
  ];

  return (
    <div className="w-full h-[calc(100%-45.6px)] flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full">
      <div className="grid grid-cols-8 grid-rows-12 gap-2 w-[1100px] m-auto h-[750px]">
        {cards.map(card => (
          <BdoCard
            key={card.title}
            title={card.title}
            redirect={card?.redirect}
            className={card?.className}
            disabled={!card?.redirect}
          />
        ))}
      </div>
    </div>
  );
};

export default BdoMenu;
