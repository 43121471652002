import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Flare: '1346',
  'Valor às 12:00h': '1344',
  'Valor às 23:59h': '1345'
};

const regFlare: Column[] = tablefy(items);

export default regFlare;
