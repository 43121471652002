import { useQuery } from '@tanstack/react-query';
import { format, parseISO, subHours } from 'date-fns';
import EmptyMessage from '../../components/atoms/EmptyMessage';
import H1 from '../../components/atoms/H1';
import { PageWrapper } from '../../components/atoms/PageWrapper';
import Spinner from '../../components/atoms/Spinner';
import Table from '../../components/organisms/Table';
import refreshTimes from '../../constants/tableColumns/bdmf/refreshTimes';
import { getRefreshMaterialized } from '../../services/bdos.services';

const RefreshTimes: React.FC = () => {
  const { data, isLoading } = useQuery({
    queryFn: async () => {
      const res = await getRefreshMaterialized();

      const response: { [key: string]: string } = {};
      res.forEach((it: any) => {
        response[it.mv_name.replace('public.', '')] = it?.refresh_mv_last
          ? format(
              subHours(parseISO(it.refresh_mv_last), 3),
              'dd/MM/yyyy HH:mm'
            )
          : '-';
      });
      return response;
    },
    queryKey: ['refreshTimes']
  });

  const order: { [key: string]: string } = {
    out_view_bdmf_alocacao_mensal:
      'Alocação de Poços Produtores por Dia no BMP',
    out_view_bdmf_alocacao_mensal_zona:
      'Alocação de Poços Produtores por zona no BMP',
    out_view_bdmf_injecao_mensal_zona:
      'Alocação de Poços Injetores por zona no BMP',
    out_view_bmp_injecoes_poco: 'Aba de Injeções Poços no BMP',
    out_view_bmp_injecoes_zona: 'Aba de Injeções Zona no BMP',
    out_view_bmp_movimentacoes: 'Aba de Movimentações no BMP',
    out_view_bmp_producoes_poco: 'Aba de Produções Poços no BMP',
    out_view_bmp_producoes_zona: 'Aba de Produções Zona no BMP',
    out_view_controle_producao_informacoes:
      'Informações de Produção de Óleo e BSW'
  };

  return (
    <PageWrapper className={'p-0'}>
      <div className="w-full h-full flex flex-col relative pt-4 px-2 rounded-lg bg-white bdmf-print mt-2">
        <img
          src="/origem.png"
          alt="Logo Origem"
          className="w-[203px] resize-none max-w-none -ml-5 only-print"
        />

        <H1 className="px-4 whitespace-nowrap">
          <span className="flex gap-4">Atualização de Views</span>
        </H1>
        {isLoading ? (
          <Spinner size={24} />
        ) : (
          <section className="flex flex-col rounded-lg px-2 py-3 scroll-height-auto">
            <div className="flex w-full overflow-y-auto max-h-[600px] scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
              {!data ? (
                <EmptyMessage
                  message="Sem dados disponíveis"
                  className="min-h-[2rem] w-min"
                />
              ) : (
                <Table
                  columns={refreshTimes}
                  data={Object.keys(order).map(it => ({
                    name: order[it],
                    date: data[it]
                  }))}
                  headerClassName="sticky top-0 bg-white z-10"
                />
              )}
            </div>
          </section>
        )}
      </div>
    </PageWrapper>
  );
};

export default RefreshTimes;
