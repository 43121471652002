import { BiErrorAlt } from 'react-icons/bi';
import { GiTerror } from 'react-icons/gi';
import { twMerge } from 'tailwind-merge';

interface EmptyMessageProps {
  message: string;
  className?: string;
}

const EmptyMessage: React.FC<EmptyMessageProps> = ({ message, className }) => {
  return (
    <div
      className={twMerge(
        'flex w-full h-full items-center justify-center gap-2 min-h-[10rem]',
        className ?? ''
      )}
    >
      {import.meta.env.DEV ? (
        <GiTerror size={40} color="gray" />
      ) : (
        <BiErrorAlt size={20} color="gray" />
      )}
      <span className="text-lg text-gray whitespace-nowrap">{message}</span>
    </div>
  );
};

export default EmptyMessage;
