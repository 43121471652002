import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { twMerge } from 'tailwind-merge';
import EmptyMessage from '../../../components/atoms/EmptyMessage';
import { PageWrapper } from '../../../components/atoms/PageWrapper';
import Spinner from '../../../components/atoms/Spinner';
import { SideBarDateAndTracker } from '../../../components/organisms/SideBarDateAndTracker';
import { useBdmf } from '../../../hooks/BDMF/useBdmf';
import { useParams } from '../../../hooks/Common/useParams';
import { BDOCalendarAtom } from '../../../state/bdo.calendar.atom';
import { BdmfReport } from './BdmfReport';

const BdmfLayout = () => {
  const { date, tracker } = useParams();

  const { data, isLoading } = useBdmf(date, tracker ?? '');

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const styles = `
    @media only print {
      @page { 
        size: 400mm 550mm !important;
      }

      .bdmf-print {
        padding: 0 2rem;
      }
      
      body {
        background-color: #F4F7FE;
        -webkit-print-color-adjust: exact;
      }
    }
  `;

  return (
    <PageWrapper className={'p-0'}>
      <style>{styles}</style>

      {!isLoading ? (
        data?.issues?.length == 0 ? (
          <EmptyMessage message="Sem dados para esta data" />
        ) : (
          <div
            className="w-full h-full flex flex-col relative pt-4 px-2 bg-background bdmf-print"
            ref={componentRef}
          >
            <BdmfReport handlePrint={handlePrint} />
          </div>
        )
      ) : (
        <Spinner color="blue" size={24} />
      )}

      <SideBarDateAndTracker atom={BDOCalendarAtom} filters={[]} />
    </PageWrapper>
  );
};

export default BdmfLayout;
