import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Fonte: '904',
  Tanques: '1037',
  'Hora Início': '527',
  'Hora Fim': '528',
  'Lacre Caminhão Rompido': '536',
  'Lacre Caminhão Atual': '537',
  'Lacre Escotilha Rompido': '586',
  'Lacre Escotilha Atual': '587',
  'Placa Caminhão': '538',
  'NE/Emissão': '539',
  Condutor: '540',
  Observação: '712'
};

const oilTransp: Column[] = tablefy(items);

export default oilTransp;
