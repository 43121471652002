import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  getOneZoneByApplicationDate,
  patchZone
} from '../../../services/oilwells.services';

import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { FaCircleInfo } from 'react-icons/fa6';
import { useParams } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import { queryClient } from '../../../App';
import Button from '../../../components/atoms/Button';
import Input from '../../../components/atoms/Input';
import SelectInput from '../../../components/atoms/SelectInput';
import MdmLayout from '../../../components/templates/MdmLayout';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';

type UpdateOilwellZonesAllocationForm = {
  code: string;
  name: string;
  acronym: string;
  oilFieldId: string;
  gasa: number;
  gasn: number;
  glas: number;
  oleo: number;
  cond: number;
};

const UpdateOilwellZonesAllocation: React.FC = () => {
  const [currentDate, setCurrentDate] = useState({
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  });
  const [currentHour, setCurrentHour] = useState<string>('00:00');
  const [lastApplicationDate, setLastApplicationDate] = useState<string>('');
  const { fields } = useMdmUtils({
    fields: true
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid }
  } = useForm<UpdateOilwellZonesAllocationForm>();

  const { id } = useParams<{ id: string }>();

  const loadZone = async () => {
    if (!id) return toast.error('Selecione um poço para atualizar');
    try {
      const { data, applicationDate } = await getOneZoneByApplicationDate(id, {
        date: `${currentDate.startDate}T${currentHour}`
      });
      reset({
        code: data.code,
        name: data.name,
        acronym: data.acronym,
        oilFieldId: data.oilFieldId,
        gasa: data.gasa,
        gasn: data.gasn,
        glas: data.glas,
        oleo: data.oleo,
        cond: data.cond
      });

      setLastApplicationDate(applicationDate);
    } catch (e) {
      toast.error('Erro ao carregar zona');
    }
  };

  useEffect(() => {
    loadZone();
  }, [id, currentDate]);

  const onSubmit = async (data: UpdateOilwellZonesAllocationForm) => {
    if (!id) return toast.error('Selecione um poço para atualizar');
    try {
      await patchZone(
        id,
        {
          ...data,
          oilField: { connect: { id: Number(data.oilFieldId) } },
          gasa: data.gasa ? Number(data.gasa) : null,
          gasn: data.gasn ? Number(data.gasn) : null,
          glas: data.glas ? Number(data.glas) : null,
          oleo: data.oleo ? Number(data.oleo) : null,
          cond: data.cond ? Number(data.cond) : null
        },
        `${currentDate.startDate}T${currentHour}`
      );
      await queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === 'zones-adm'
      });

      toast.success('Zona criado com sucesso');
    } catch (e) {
      toast.error('Erro ao criar Zona');
    }
  };

  return (
    <MdmLayout title={'Criar Zona'}>
      <div className="flex flex-col flex-end w-3/4 mb-2">
        <label className="font-medium text-sm text-primary">
          Data de Aplicação
        </label>
        <Datepicker
          value={currentDate}
          asSingle={true}
          useRange={false}
          onChange={(val: any) => {
            setCurrentDate(val);
          }}
          i18n="pt-br"
          placeholder="Selecione uma data"
          inputClassName={
            'relative transition-all duration-300 h-[36px] pl-2 pr-10 w-full border-[1px] border-[#cccccc] dark:border-slate-600 rounded-md tracking-wide text-sm focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20 mb-2'
          }
          classNames={{
            toggleButton() {
              return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
            }
          }}
          maxDate={new Date()}
        />

        <Input
          label="Hora de Aplicação"
          required
          placeholder="Hora de Aplicação"
          value={currentHour}
          onChange={e => setCurrentHour(e.target.value)}
          type="time"
        />

        {lastApplicationDate && (
          <div className="w-full flex items-center text-xs font-normal text-[#666] mt-2">
            <FaCircleInfo className="mr-2" size={12} />
            <strong className="font-bold mr-1">Data do último registro:</strong>
            {lastApplicationDate !== 'present'
              ? format(
                  new Date(lastApplicationDate.replace('Z', '')),
                  'dd/MM/yyyy HH:mm'
                )
              : 'Sem registros'}
          </div>
        )}
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-2 items-center justify-center w-3/4"
      >
        <Input
          label="Código"
          required
          placeholder="Código"
          {...register('code', { required: true })}
        />

        <Input
          label="Nome"
          required
          placeholder="Nome"
          {...register('name', { required: true })}
        />

        <Input
          label="Sigla"
          required
          placeholder="Sigla"
          {...register('acronym', { required: true })}
        />

        <SelectInput
          label="Campo"
          placeholder="Campo"
          control={control}
          name={'oilFieldId'}
          required
          options={fields?.map((field: any) => ({
            value: field.id,
            label: field.name
          }))}
        />

        <div className="flex gap-4 w-full">
          <Input
            label="Gás Associado"
            required
            placeholder="Gás Associado"
            {...register('gasa', { required: true })}
          />

          <Input
            label="Gás Natural"
            required
            placeholder="Gás Natural"
            {...register('gasn', { required: true })}
          />
        </div>

        <div className="flex gap-4 w-full">
          <Input
            label="GLAs"
            required
            placeholder="GLAs"
            {...register('glas', { required: true })}
          />

          <Input
            label="Óleo"
            required
            placeholder="Óleo"
            {...register('oleo', { required: true })}
          />
        </div>

        <Input
          label="Condensado"
          required
          placeholder="Condensado"
          {...register('cond', { required: true })}
        />

        <Button
          className="px-24 h-12 mt-4"
          type="submit"
          title="Salvar"
          disabled={!isValid && !errors}
        />
      </form>
    </MdmLayout>
  );
};

export default UpdateOilwellZonesAllocation;
