import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';
import pages from './pages';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(pages);

export default router;
