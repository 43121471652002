import batelada from '../../tableColumns/bdoEC/batelada';
import dewPoint from '../../tableColumns/bdoEC/dewPoint';
import horiAndDisp from '../../tableColumns/bdoEC/horiAndDisp';
import nr13 from '../../tableColumns/bdoEC/nr13';
import ocCompression from '../../tableColumns/bdoEC/ocCompression';
import ocCompressionFu from '../../tableColumns/bdoEC/ocCompressionFu';
import oilAndLub from '../../tableColumns/bdoEC/oilAndLub';
import pressureDif from '../../tableColumns/bdoEC/pressureDif';
import refrigerator from '../../tableColumns/bdoEC/refrigerator';
import statusByTurn from '../../tableColumns/bdoEC/statusByTurn';
import statusByTurnWater from '../../tableColumns/bdoEC/statusByTurnWater';
import statusByTurnWaterFu from '../../tableColumns/bdoEC/statusByTurnWaterFu';
import turn from '../../tableColumns/bdoEC/turn';
import waterFresh from '../../tableColumns/bdoEC/waterFresh';
import sap from '../../tableColumns/bdoUpgn/sap';
import { StructureType } from '../types/structure.type';

export const bdoCompresssion: StructureType = [
  {
    id: 245,
    name: 'Emissor',
    type: 'input'
  },
  {
    id: 1353,
    name: 'Situação dos Equipamentos',
    type: 'label'
  },
  {
    id: 1354,
    name: 'Horímetro e Disponibilidade',
    type: 'grid',
    columns: horiAndDisp
  },
  {
    id: 1403,
    name: 'Horímetro e Disponibilidade',
    type: 'grid',
    columns: horiAndDisp
  },
  {
    id: 1355,
    name: 'Compressores - Consumo de Óleo Lubrificante',
    type: 'grid',
    columns: oilAndLub
  },
  {
    id: 1404,
    name: 'Compressores - Consumo de Óleo Lubrificante',
    type: 'grid',
    columns: oilAndLub
  },
  {
    id: 1357,
    name: 'Filtros de Processo',
    type: 'label'
  },
  {
    id: 1405,
    name: 'Diferencial de Pressão (kgf/cm²)',
    type: 'grid',
    columns: pressureDif
  },
  {
    id: 1358,
    name: 'Diferencial de Pressão (kgf/cm²)',
    type: 'grid',
    columns: pressureDif
  },
  {
    id: 1406,
    name: 'Ponto de Orvalho',
    type: 'grid',
    columns: dewPoint
  },
  {
    id: 1359,
    name: 'Ponto de Orvalho',
    type: 'grid',
    columns: dewPoint
  },
  {
    id: 1360,
    name: 'Situação do Gerador de Emergência às 25h59',
    type: 'label'
  },
  {
    id: 594,
    name: 'Situação',
    type: 'input'
  },
  {
    id: 1362,
    name: 'Nivel (%)',
    type: 'input'
  },
  {
    id: 1363,
    name: 'Horas de Operação',
    type: 'input'
  },
  {
    id: 1364,
    name: 'Drenagem de Header dos Compressores',
    type: 'label'
  },
  {
    id: 1365,
    name: 'Status por Turno',
    type: 'grid',
    columns: statusByTurn
  },
  {
    id: 1366,
    name: 'Captação de Água',
    type: 'label'
  },
  {
    id: 1367,
    name: 'Status por turno e Consumo',
    type: 'grid',
    columns: statusByTurnWater
  },
  {
    id: 1407,
    name: 'Status por turno e Consumo',
    type: 'grid',
    columns: statusByTurnWaterFu
  },
  {
    id: 1368,
    name: 'Análises Químicas',
    type: 'label'
  },
  {
    id: 1408,
    name: 'Água de resfriamento dos compressores',
    type: 'grid',
    columns: waterFresh
  },
  {
    id: 1374,
    name: 'Situação dos Produtos Químicos',
    type: 'label'
  },
  {
    id: 1389,
    name: 'Inibidor de Corrosão Resfriamento - KURIZET S-609 (cm)',
    type: 'input'
  },
  {
    id: 1399,
    name: 'Biocida - KURIZET A-511',
    type: 'input'
  },
  {
    id: 1390,
    name: 'Hipoclorito de sódio (cm)',
    type: 'input'
  },
  {
    id: 1391,
    name: 'Bateladas de Carbonato',
    type: 'grid',
    columns: batelada
  },
  {
    id: 1392,
    name: 'Água de Refrigeração',
    type: 'label'
  },
  {
    id: 1409,
    name: 'Água de Refrigeração ING - C/D e CUB-03 (Recirculação)',
    type: 'grid',
    columns: refrigerator
  },
  {
    id: 1394,
    name: 'Inibidor de Corrosão Compressores - KURITA LX-1909 (Bombona)',
    type: 'input'
  },
  {
    id: 1395,
    name: 'Preenchimento de Livros',
    type: 'label'
  },
  {
    id: 1179,
    name: 'Registros NR13',
    type: 'grid',
    columns: nr13
  },
  {
    id: 1397,
    name: 'Registro de Ocorrências em Equipamentos',
    type: 'label'
  },
  {
    id: 1398,
    name: 'Ocorrências Compressores',
    type: 'grid',
    columns: ocCompression
  },
  {
    id: 1410,
    name: 'Ocorrências Compressores',
    type: 'grid',
    columns: ocCompressionFu
  },
  {
    id: 1096,
    name: 'Demais registros de Notas',
    type: 'label'
  },
  {
    id: 1022,
    name: 'Acompanhamento Notas SAP',
    type: 'grid',
    columns: sap
  },
  {
    id: 479,
    name: 'Eventos (Passagem de Turno)',
    type: 'label'
  },
  {
    id: -3,
    name: 'Turno 01 - 00h às 07h',
    type: 'label',
    size: 'h2'
  },
  {
    id: 492,
    name: 'Responsável',
    type: 'input'
  },
  {
    id: 480,
    name: '',
    type: 'grid',
    columns: turn
  },
  {
    id: -2,
    size: 'h2',
    name: 'Turno 02 - 07h às 19h',
    type: 'label'
  },
  {
    id: 493,
    name: 'Responsável',
    type: 'input'
  },
  {
    id: 481,
    name: '',
    type: 'grid',
    columns: turn
  },
  {
    id: -5,
    size: 'h2',
    name: 'Turno 03 - 19h às 23h59h',
    type: 'label'
  },
  {
    id: 494,
    name: 'Responsável',
    type: 'input'
  },
  {
    id: 482,
    name: '',
    type: 'grid',
    columns: turn
  }
];
