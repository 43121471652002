import axios from 'axios';
import { msalInstance } from '../main';

const api = axios.create({
  baseURL: import.meta.env.VITE_PORTAL_URL,
  headers: {
    'Content-type': 'application/json'
  }
});

api.interceptors.request.use(async (config: any) => {
  const accessTokenRequest = {
    scopes: ['api://cc9de33b-0dff-4458-b352-e813186cdcfa/Portal.User'],
    account: msalInstance.getAllAccounts()[0]
  };

  try {
    const accessToken = (
      await msalInstance.acquireTokenSilent(accessTokenRequest)
    ).accessToken;

    return {
      ...config,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
  } catch (e) {
    return msalInstance.acquireTokenRedirect(accessTokenRequest);
  }
});

export default api;
