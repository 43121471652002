import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const changeBean: Column[] = [
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'Poço',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: 'Hora',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Linha'} id="Linha" />,
    accessor: 'Linha',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Abertura Utilizada Antiga'} id="Abertura antiga" />,
    accessor: 'Abertura antiga',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Abertura Utilizada Nova'} id="Abertura nova" />,
    accessor: 'Abertura nova',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: (
      <Header text={'Abertura Máxima Antiga'} id="Abertura Máxima antiga" />
    ),
    accessor: 'Abertura Máxima antiga',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Abertura Máxima Nova'} id="Abertura Máxima nova" />,
    accessor: 'Abertura Máxima nova',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: (
      <Header
        text={'Pressão de Cabeça antiga (Kgf/cm²)'}
        id="Pressão de Cabeça antiga (Kgf/cm²)"
      />
    ),
    accessor: 'Pressão de Cabeça antiga (Kgf/cm²)',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: (
      <Header
        text={'Pressão de Cabeça nova (Kgf/cm²)'}
        id="Pressão de Cabeça nova (Kgf/cm²)"
      />
    ),
    accessor: 'Pressão de Cabeça nova (Kgf/cm²)',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: (
      <Header
        text={'Pressão de Linha antiga (Kgf/cm²)'}
        id="Pressão de Linha antiga (Kgf/cm²)"
      />
    ),
    accessor: 'Pressão de Linha antiga (Kgf/cm²)',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: (
      <Header
        text={'Pressão de Linha nova (Kgf/cm²)'}
        id="Pressão de Linha nova (Kgf/cm²)"
      />
    ),
    accessor: 'Pressão de Linha nova (Kgf/cm²)',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: 'Observação',
    Cell: DefaultCell,
    width: '8%'
  }
];

export default changeBean;
