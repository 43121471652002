import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Poço: '2603',
  'Tipo de Amostra': '713',
  Hora: '1667',
  'Pressão de Coleta (psi)': '2621',
  'Temperatura de Coleta (°C)': '715',
  'Ponto de Amostragem': '2739',
  Observação: '712',
  'Pressão de Coleta (kgf/cm²)': '714'
};

const collect: Column[] = tablefy(items, undefined, undefined, undefined, [
  'Pressão de Coleta (psi)',
  'Temperatura de Coleta (°C)',
  'Ponto de Amostragem',
  'Pressão de Coleta (kgf/cm²)'
]);

export default collect;
