import { convertGridToJson } from '../../utils/bdos';
import { numberFormatter } from '../../utils/utils';
import { Label } from '../atoms/Label';
import { TableGrid } from '../atoms/TableGrid';
import { TextField } from '../atoms/TextField';

interface ViewSwitchProps {
  item: any;
  customFields: any;
  date: string;
  name: string;
  idx: number;
}

const ViewSwitch = ({
  item,
  customFields,
  date,
  idx,
  name
}: ViewSwitchProps) => {
  const gridData =
    item.type == 'grid'
      ? Object.values(
          convertGridToJson(customFields?.[item.id] ?? '{}') ?? {}
        ).filter((obj: any) => {
          return !Object.values(obj).every(value => value === '');
        })
      : null;

  return customFields ? (
    item.type == 'label' ? (
      <Label
        name={name}
        id={item.id}
        key={item.id + (date ?? '') + item.bdoIdx}
        secondary={item.size == 'h2'}
        className={idx === 0 ? 'border-0 p-0' : ''}
      />
    ) : customFields?.[item.id] && item.type == 'grid' ? (
      <TableGrid
        title={name}
        cols={item.columns}
        id={String(item.id)}
        key={item.id + (date ?? '') + item.bdoIdx}
        data={item.dataFunction ? item.dataFunction(gridData) : gridData}
      />
    ) : customFields?.[item.id] ? (
      <TextField
        name={name}
        key={item.id + (date ?? '') + item.name + item.bdoIdx}
        isCol
        value={
          item.type == 'number'
            ? numberFormatter.format(Number(customFields?.[item.id]) ?? '')
            : (customFields?.[item.id] ?? '')
        }
      />
    ) : null
  ) : null;
};

export default ViewSwitch;
