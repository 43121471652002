import EmptyMessage from './EmptyMessage';

const EMPTY_MESSAGE = 'Sem dados disponíveis';

const TextComponent = (props: { value?: string }) => {
  return (
    <>
      <section className="flex flex-col flex-1 rounded-lg bg-white px-4 py-3 gap-2 whitespace-pre-wrap">
        {props.value ? (
          props.value
        ) : (
          <EmptyMessage
            message={EMPTY_MESSAGE}
            className="min-h-[2rem] w-min"
          />
        )}
      </section>
    </>
  );
};

export default TextComponent;
