import { Column } from 'react-table';
import { tablefy } from '../../../../utils/tablesUtils';

const items: any = {
  Destinatário: '905',
  Tanque: '584',
  'Hora Início': '527',
  'Hora Fim': '528',
  'Lacre Rompido': '510',
  'Lacre Atual': '582',
  'Nível Inicial (cm)': '529',
  'Nível Final (cm)': '535',
  'Volume Inicial (m³)': '531',
  'Volume Final (m³)': '534',
  'Volume Total (m³)': '533',
  Observação: '712'
};

const transferPir: Column[] = tablefy(items);

export default transferPir;
