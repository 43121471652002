import { msalInstance } from '../main';
import api from '../services/apiPortal';

export const checkSSO = async () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  return api.get('/');
};
