import { format } from 'date-fns';
import { Column } from 'react-table';
import { Header } from '../../components/atoms/table/Header';
import { NumberCell } from '../../components/atoms/table/NumberCell';
import { DefaultCell } from '../../components/atoms/table/DefaultCell';

const DateCell = ({
  value,
  row: {
    original: { id },
    index
  }
}: any) => {
  function isDateValid(dateString: string | null | undefined) {
    if (!dateString) return false;

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return false;
    }

    return true;
  }

  return (
    <span className="text-[#191919] text-xs" key={`${id}Pressure${index}`}>
      {isDateValid(value)
        ? format(new Date(value.split('+')[0]), 'dd/MM/yyyy')
        : value}
    </span>
  );
};

const bdoProdCols: Column[] = [
  {
    Header: () => <Header text={'Poço'} id="poço" />,
    accessor: 'poço',
    Cell: DefaultCell,
    width: '12%',
    minWidth: 100
  },
  {
    Header: () => <Header text={'Último teste'} id="Data" />,
    accessor: 'date',
    Cell: DateCell,
    width: '12.5%',
    minWidth: 110
  },
  {
    Header: () => <Header text={'Perda Gás (Mm³)'} id="perda_gas" />,
    accessor: 'perda_gas',
    Cell: NumberCell,
    minWidth: 110,
    width: '12.5%'
  },
  {
    Header: () => <Header text={'Perda Óleo (m³)'} id="Perda Óleo" />,
    accessor: 'perda_oleo',
    Cell: NumberCell,
    minWidth: 110,
    width: '12.5%'
  },
  {
    Header: () => <Header text={'Potencial Gás (Mm³/d)'} id="potencial_gas" />,
    accessor: 'potencial_gas',
    Cell: NumberCell,
    width: '12.5%',
    minWidth: 110
  },
  {
    Header: () => <Header text={'Potencial Óleo (m³/d)'} id="Potencial Óleo" />,
    accessor: 'potencial_oleo',
    Cell: NumberCell,
    width: '12.5%',
    minWidth: 110
  }
];

export { bdoProdCols };
