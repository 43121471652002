import { Column } from 'react-table';

export const bdoView: (
  | { type: 'label'; id: number; name: string }
  | {
    type: 'grid';
    id: number;
    columns: Column[];
    name: string;
    value?: string;
  }
  | {
    type: 'input' | 'date' | 'number';
    id: number;
    name: string;
    value?: string;
    field?: string;
  }
)[] = [
    { id: 248, name: 'Data do boletim', type: 'date' },
    { id: 793, name: 'Supervisor de Campo', type: 'input' },
    {
      id: 803,
      name: 'Acompanhamento Nascente (Locação PIR-13)',
      field: 'Pilar',
      type: 'number'
    }
  ];
