import { twMerge } from 'tailwind-merge';

interface TabsItemProps {
  label: string;
  onClick(): void;
  menu: number;
  idx: number;
}

const TabsItem: React.FC<TabsItemProps> = ({ label, onClick, idx, menu }) => {
  return (
    <button
      key={label}
      onClick={onClick}
      className={twMerge(
        'font-medium bg-background text-primary text-sm px-5 py-2 rounded-full hover:bg-backgrond-btn-hover transition-colors',
        idx == menu
          ? 'bg-primary border text-white hover:bg-primary/70 font-bold'
          : ''
      )}
    >
      {label}
    </button>
  );
};

export default TabsItem;
