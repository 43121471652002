import { addHours, format } from 'date-fns';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { Header } from '../../../components/atoms/table/Header';
import { Column } from 'react-table';
import { TooltipCell } from '../../../components/atoms/table/TooltipCell';

const numberFormatter = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const numberFormatter2Decimal = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const TooltipCardItem = ({
  label,
  value
}: {
  label: string;
  value?: string;
}) => {
  return (
    <div className="flex flex-col text-xs">
      <span className="font-medium">{label}</span>
      <span className="font-normal">{value || ''}</span>
    </div>
  );
};

export const AlertCell = ({
  value,
  row: { index, original },
  column: { id },
  className,
  hideNumber = false
}: any) => {
  const classes: Record<string, { card: string; text: string }> = {
    1: { card: 'bg-red/25 text-red', text: 'text-red' },
    2: { card: 'bg-[#FFF069]/50 text-[#AD953C]', text: 'text-[#AD953C]' },
    3: { card: 'bg-[#10AE88]/25 text-[#0C8A6C]', text: 'text-[#0C8A6C]' }
  };

  const currentClasses = classes[value?.criticity];
  var old_test_date = original?.old_test_date;
  if (original?.fast_test && ['gas', 'choke'].includes(id)) {
    old_test_date = original?.fast_test_date;
  }

  const AlertCard = ({ className }: { className?: string }) => {
    return (
      <div
        className={twMerge(
          'flex flex-row gap-0.5 rounded px-1 py-1 min-w-16 items-center justify-center flex-none',
          currentClasses?.card,
          !value?.percentage && !hideNumber ? 'hidden' : '',
          className,
          hideNumber ? 'min-w-min' : ''
        )}
        data-tooltip-id={`alert-${id}${index}`}
      >
        {!value?.percentage ? (
          <span className="text-xl w-3 h-2 pb-3 pr-2 pl-px leading-3 ">•</span>
        ) : value?.percentage > 0 ? (
          <FaArrowUp size={12} className={currentClasses?.text} />
        ) : (
          <FaArrowDown size={12} className={currentClasses?.text} />
        )}
        {!hideNumber && value?.percentage ? (
          <span
            className={twMerge(
              'font-bold text-[10px] pt-[1px]',
              currentClasses?.text
            )}
          >
            {`${
              Math.abs(value?.percentage) > 0 && Math.abs(value?.percentage) < 1
                ? '>'
                : ''
            } ${numberFormatter.format(
              Math.floor(Math.abs(value?.percentage))
            )}%`}
          </span>
        ) : null}
      </div>
    );
  };

  return (
    <div
      className={twMerge(
        'text-[#191919] text-xs whitespace-pre-wrap flex flex-row gap-2 items-center',
        className ?? ''
      )}
      key={`Cell${index}`}
    >
      <span className="mr-auto">
        {value?.new_value !== undefined
          ? numberFormatter2Decimal.format(value?.new_value)
          : '-'}
      </span>

      <AlertCard />

      <ReactTooltip
        id={`alert-${id}${index}`}
        place="bottom"
        variant="light"
        opacity={0.95}
        className="shadow-lg"
      >
        <div className="">
          <span className="font-bold text-sm display flex gap-3 items-center h-8">
            Variação do Teste: <AlertCard className="py-0.5" />
          </span>
          <div className="flex gap-4 my-2 ">
            <div className="flex flex-col gap-2">
              <TooltipCardItem
                label="Data do teste atual:"
                value={
                  original?.new_test_date
                    ? format(addHours(original?.new_test_date, 3), 'dd/MM/yyyy')
                    : original?.new_test_date
                }
              />

              <TooltipCardItem
                label="Data do teste anterior:"
                value={
                  old_test_date
                    ? format(addHours(old_test_date, 3), 'dd/MM/yyyy')
                    : undefined
                }
              />
            </div>
            <div className="flex flex-col  gap-2">
              <TooltipCardItem
                label="Resultado:"
                value={numberFormatter2Decimal.format(value?.new_value)}
              />

              <TooltipCardItem
                label="Resultado anterior:"
                value={numberFormatter2Decimal.format(value?.old_value)}
              />
            </div>
          </div>
        </div>
      </ReactTooltip>
    </div>
  );
};

const alertCols: Column[] = [
  {
    Header: () => (
      <Header className="h-12 items-start text-start" text={'ID'} id="id" />
    ),
    accessor: 'new_issue_id',
    Cell: ({ value }: any) => {
      return (
        <a
          className="text-primary text-xs font-bold"
          href={`${import.meta.env.VITE_REDMINE_URL}/issues/${value}`}
          target="_blank"
        >
          {value}
        </a>
      );
    },
    width: '6%'
  },
  {
    Header: () => (
      <Header className="h-12 items-start text-start" text={'Poço'} id="poço" />
    ),
    accessor: 'oilwell',
    Cell: ({ value }: any) => {
      return (
        <a
          className="text-[#191919] hover:underline text-xs"
          href={`/home/oilwell-info/${value}/tests`}
          target="_blank"
        >
          {value}
        </a>
      );
    },
    minWidth: 105
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'Fluido'}
        id="Fluido"
      />
    ),
    accessor: 'fluid',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'Método de Elevação'}
        id="elevationMethod"
      />
    ),
    accessor: 'elevation_method',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'Sistema de Pressão'}
        id="pressure-system"
      />
    ),
    accessor: 'pressure_system',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'Data do Teste'}
        id="Data do Teste"
      />
    ),
    accessor: 'new_test_date',
    Cell: (e: any) => (
      <DefaultCell
        {...e}
        value={e.value ? format(addHours(e.value, 3), 'dd/MM/yyyy') : e.value}
      />
    )
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'Tipo de Teste'}
        id="pressure-system"
      />
    ),
    accessor: 'test_type',
    Cell: DefaultCell,
    minWidth: 114
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'Vazão de Óleo (m³/d)'}
        id="oil"
        info="Vermelho: > 20% de variação
Amarelo: entre 10 a 20% de variação 
Verde: < 10% de variação"
      />
    ),
    accessor: 'oil',
    Cell: AlertCell,
    minWidth: 107
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'Vazão de Gás (m³/d)'}
        id="gas"
        info="Vermelho: > 10% de variação
Amarelo: entre 5 a 10% de variação 
Verde: < 5% de variação"
      />
    ),
    accessor: 'gas',
    Cell: AlertCell,
    minWidth: 107
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'Vazão de Água (m³/d)'}
        id="water"
        info="Vermelho: > 20% de variação
Amarelo: entre 10 a 20% de variação 
Verde: < 10% de variação"
      />
    ),
    accessor: 'water',
    Cell: AlertCell,
    minWidth: 107
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'Vazão de Gás Lift (m³/d)'}
        id="gasLift"
        info="Vermelho: > 20% de variação
Amarelo: entre 5 a 20% de variação 
Verde: < 5% de variação"
      />
    ),
    accessor: 'gas_lift',
    Cell: AlertCell,
    minWidth: 107
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'BSW'}
        id="bsw"
        info="Vermelho: > 10% de variação
Amarelo: entre 5 a 10% de variação 
Verde: < 5% de variação"
      />
    ),
    accessor: 'bsw',
    Cell: AlertCell
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'RGA'}
        id="rga"
        info="Vermelho: RGA < 2000 ou vazão de água ou de gás = 0
Verde: RGA > 2000"
      />
    ),
    accessor: 'rga',
    Cell: AlertCell
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'ROA'}
        id="roa"
        info="Vermelho: ROA < 1 ou vazão de água ou de óleo = 0
Verde: ROA > 1"
      />
    ),
    accessor: 'roa',
    Cell: AlertCell
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'Choke/Bean'}
        id="choke"
        info="Vermelho: Choke/Bean diferente do último teste
Verde: Choke/Bean igual ao último teste"
      />
    ),
    accessor: 'choke',
    Cell: (e: any) => <AlertCell {...e} hideNumber />
  },
  {
    Header: () => (
      <Header
        className="h-12 items-start text-start"
        text={'Comentário'}
        id="observation"
      />
    ),
    accessor: 'observation',
    Cell: TooltipCell
  }
];

export default alertCols;
