import { NestedItemComponentProps } from '@/constants/view/types/structure.type';
import { twMerge } from 'tailwind-merge';

const SimpleCards = (
  props: NestedItemComponentProps & {
    backgroundColor: string;
    textColor: string;
  }
) => {
  return (
    <div className="flex gap-3 w-full">
      {props.nestedValues.map((info, index) => (
        <div
          className={twMerge(
            'flex flex-col px-4 py-3 rounded-lg w-full',
            props.className,
            props.backgroundColor
          )}
          key={info.name}
        >
          <div
            className={twMerge('font-medium', info.className, props.textColor)}
          >
            {info.name}
          </div>

          <span
            className={twMerge(
              'text-xl font-bold',
              info.className,
              props.textColor
            )}
          >
            {info.value ? info.value : '-'}
          </span>
        </div>
      ))}
    </div>
  );
};

export default SimpleCards;
