import { useState, ReactNode, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';

interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  children: ReactNode;
  className?: string;
  icon?: ReactNode;
  buttonClassName?: string;
  childrenClassName?: string;
  offset?: string;
  forceClose?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  buttonClassName,
  icon,
  childrenClassName,
  offset,
  forceClose = false,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = () => {
    setIsOpen(old => !old);
  };

  useEffect(() => {
    if (forceClose) {
      setIsOpen(false);
    }
  }, [forceClose]);

  return (
    <div {...rest}>
      <button
        className={twMerge(
          `w-full 
          min-h-[40px]
          px-3 py-2 
          flex items-center gap-2
          hover:bg-[#f5f5f5]
          text-primary font-bold whitespace-nowrap
          rounded`,
          buttonClassName ?? ''
        )}
        onClick={handleChange}
      >
        {icon}
        {!forceClose && title}
        {!forceClose && (
          <FaChevronDown
            className={`fill-primary ml-auto transition-all ${
              isOpen && 'rotate-180'
            }`}
          />
        )}
      </button>

      <section
        className={twMerge(
          `${
            isOpen && !forceClose
              ? 'max-h-96 opacity-100 visible'
              : 'max-h-0 opacity-0 invisible'
          } overflow-hidden transition-all duration-500 ease-in-out`,
          childrenClassName
        )}
        style={{ marginTop: offset ?? '0' }}
      >
        {children}
      </section>
    </div>
  );
};

export default Accordion;
