import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Poço: '2603',
  'Hora de Lançamento': '847',
  Quantidade: '848',
  'Tipo de Bastão': '849',
  'Pressão Antes do Bastão (psi)': '2630',
  'Hora de Abertura': '846',
  'Abertura Utilizada (Choker/Bean)': '816',
  'Pressão Final Antes da Abertura (psi)': '2631',
  Observação: '712',
  'Pressão Antes do Bastão (kgf/cm²)': '850',
  'Pressão Final Antes da Abertura (kgf/cm²)': '852'
};

const bast: Column[] = tablefy(items, undefined, undefined, undefined, [
  'Pressão Antes do Bastão (psi)',
  'Abertura Utilizada (Choker/Bean)',
  'Pressão Final Antes da Abertura (psi)',
  'Pressão Antes do Bastão (kgf/cm²)',
  'Pressão Final Antes da Abertura (kgf/cm²)'
]);

export default bast;
