import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const pressureCols: Column[] = [
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'poço',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Hora'} id="112" />,
    accessor: 'hora',
    Cell: DateHourCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Abertura Utilizada'} id="46" />,
    accessor: 'bean_utilizada',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Abertura Máxima'} id="45" />,
    accessor: 'bean_máxima',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => <Header text={'Pressão da coluna (kgf/cm²)'} id="812" />,
    accessor: 'pcoluna',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Pressão da linha (kgf/cm²)'} id="866" />,
    accessor: 'plinha',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Temperatura de Cabeça (C°)'} id="866" />,
    accessor: 'tcabeca',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Temperatura de Linha (C°)'} id="866" />,
    accessor: 'tlinha',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Pressão da anular A (kgf/cm²)'} id="813" />,
    accessor: 'panulara',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Pressão da anular B (kgf/cm²)'} id="814" />,
    accessor: 'panularb',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Status'} id="Status" />,
    accessor: 'Status',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => (
      <Header
        text={'Abertura Utilizada Bean de Injeção'}
        id="Abertura Utilizada Bean de Injeção"
      />
    ),
    accessor: 'beanInjection',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => (
      <Header
        text={'Abertura Máxima Bean de Injeção'}
        id="Abertura Máxima Bean de Injeção"
      />
    ),
    accessor: 'beanInjectionMax',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Observação'} id="712" />,
    accessor: 'observação',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  }
];

export default pressureCols;
