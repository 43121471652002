import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Input from '../../../components/atoms/Input';
import { queryClient } from '../../../App';
import MdmLayout from '../../../components/templates/MdmLayout';
import SelectInput from '../../../components/atoms/SelectInput';
import { mapperOptions } from '../../../utils/mdm';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import {
  getOneOilField,
  updateOilField
} from '../../../services/oilwells.services';
import Button from '../../../components/atoms/Button';

type UpdateOilFieldForm = {
  name: string;
  type: string;
  hub: string;
  oilwells: string;
};

type Params = {
  id: string;
};

const UpdateOilField: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<Params>();
  const { constants } = useMdmUtils({
    constants: true
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid }
  } = useForm<UpdateOilFieldForm>();

  const getOilField = async () => {
    if (!id) return toast.error('Selecione um campo para atualizar');
    try {
      const data = await getOneOilField(id);

      reset({
        name: data.name,
        type: data.type,
        hub: data.hub,
        oilwells: data.oilwells
      });
    } catch {
      toast.error('Erro ao buscar cromatografia');
    }
  };

  useEffect(() => {
    getOilField();
  }, []);

  const onSubmit = async (data: UpdateOilFieldForm) => {
    if (!id) return toast.error('Selecione um cromatografia para atualizar');

    try {
      await updateOilField(id, {
        ...data
      });
      await queryClient.invalidateQueries(['oilfields-adm']);

      toast.success('Campo atualizado com sucesso');
      navigate('/oilfields');
    } catch (e) {
      toast.error('Erro ao atualizar Campo');
    }
  };

  return (
    <MdmLayout title={'Atualizar Campo'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-2 items-center justify-center w-3/4"
      >
        <Input
          label="Nome"
          placeholder="Nome"
          required
          {...register('name', { required: true })}
        />

        <div className="flex flex-row gap-2 w-full">
          <SelectInput
            label="Tipo"
            placeholder="Tipo"
            control={control}
            name={'type'}
            required
            options={mapperOptions(constants?.OilFieldType)}
          />

          <SelectInput
            label="Polo"
            placeholder="Polo"
            control={control}
            name={'hub'}
            required
            options={mapperOptions(constants?.OilFieldHub)}
          />
        </div>

        <Button
          className="px-24 h-12 mt-4"
          type="submit"
          title="Atualizar"
          disabled={!isValid && !errors}
        />
      </form>
    </MdmLayout>
  );
};

export default UpdateOilField;
