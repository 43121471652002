import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  updateGasMeter,
  getOneGasMeter
} from '../../../services/oilwells.services';

import Input from '../../../components/atoms/Input';
import Button from '../../../components/atoms/Button';
import { queryClient } from '../../../App';
import SelectInput from '../../../components/atoms/SelectInput';
import MdmLayout from '../../../components/templates/MdmLayout';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import { mapperOptions } from '../../../utils/mdm';

type UpdateGasMeterForm = {
  name: string;
  serialNumber: string;
  type: string;
  oilFieldId: string;
};

type Params = {
  id: string;
};

const UpdateGasMeter: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<Params>();
  const { fields, constants } = useMdmUtils({
    fields: true,
    constants: true
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid }
  } = useForm<UpdateGasMeterForm>();

  const getGasMeter = async () => {
    if (!id) return toast.error('Selecione um Medidor de Gás para atualizar');
    try {
      const data = await getOneGasMeter(id);
      reset({
        name: data.name,
        serialNumber: data.serialNumber,
        type: data.type,
        oilFieldId: data.oilFieldId
      });
    } catch {
      toast.error('Erro ao buscar Medidor de Gás');
    }
  };

  useEffect(() => {
    getGasMeter();
  }, []);

  const onSubmit = async (data: UpdateGasMeterForm) => {
    if (!id) return toast.error('Selecione um Medidor de Gás para atualizar');

    try {
      await updateGasMeter(id, {
        ...data
      });
      await queryClient.invalidateQueries(['gas-meters-adm']);

      toast.success('Medidor de Gás atualizado com sucesso');
      navigate('/gas-meter');
    } catch (e) {
      toast.error('Erro ao Medidor de Gás');
    }
  };

  return (
    <MdmLayout title={'Atualizar Medidor de Gás'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-2 items-center justify-center w-3/4"
      >
        <Input
          label="Nome"
          required
          placeholder="Nome"
          {...register('name')}
          disabled
        />

        <Input
          label="Numero de Série"
          placeholder="NS"
          {...register('serialNumber')}
        />

        <SelectInput
          label="Tipo"
          placeholder="Tipo"
          control={control}
          name={'type'}
          options={
            constants?.GasMeterType?.map((type: string) => ({
              // label should format SEPARADOR_GRAVITACIONAL to "Separador Gravitacional"
              value: type,
              label: type
                .split('_')
                .map(
                  (word: string) => word.charAt(0) + word.slice(1).toLowerCase()
                )
                .join(' ')
            })) ?? []
          }
        />

        <SelectInput
          label="Campo"
          placeholder="Campo"
          control={control}
          name={'oilFieldId'}
          required
          options={fields?.map((field: any) => ({
            value: field.id,
            label: field.name
          }))}
        />

        <Button
          className="px-24 h-12 mt-4"
          type="submit"
          title="Atualizar"
          disabled={!isValid && !errors}
        />
      </form>
    </MdmLayout>
  );
};

export default UpdateGasMeter;
