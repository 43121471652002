import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Data/Hora da Medição': '2643',
  'Conjunto-01 (%)': '2661',
  'Conjunto-02 (%)': '2662',
  'Os dois conjuntos': '2663'
};

const chargeBat: Column[] = tablefy(items, undefined, undefined, undefined, [
  'Conjunto-01 (%)',
  'Conjunto-02 (%)',
  'Os dois conjuntos'
]);

export default chargeBat;
