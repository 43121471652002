interface PrintStyleProps {
  multiplier?: number;
  customStyle?: string;
  backgroundColor?: string;
  landscape?: boolean;
}

export const PrintStyle = ({
  multiplier = 1.5,
  customStyle = '',
  backgroundColor = '#F4F7FE',
  landscape = false
}: PrintStyleProps) => {
  const styles = `
    @media only print {
      @page { 
        size: ${Math.floor(210 * multiplier)}mm ${Math.floor(297 * multiplier)}mm;
      }

      .bdmf-print {
        padding: 0 2rem;
      }

      body, .bdmf-print {
        background-color: ${backgroundColor} !important; 
        -webkit-print-color-adjust: exact !important;
      }
        
      ${customStyle}
    }
  `;
  return <style>{styles}</style>;
};
