import { useEffect } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

interface PageNavigationProps {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  totalPages: number;
  totalItems?: number;
  perPage: number;
  className?: string;
}

const PageNavigation: React.FC<PageNavigationProps> = ({
  setCurrentPage,
  currentPage,
  totalPages,
  totalItems,
  perPage,
  ...rest
}) => {
  if (totalPages == null) {
    return null;
  }

  useEffect(() => {
    if (totalPages !== 0 && currentPage > totalPages) {
      setCurrentPage(totalPages);
    }

    if (currentPage < 1) {
      setCurrentPage(1);
    }
  }, [currentPage, totalPages, setCurrentPage]);
  const fromItem = (currentPage - 1) * perPage + 1;
  const toItem =
    totalItems && (currentPage - 1) * perPage + perPage > totalItems
      ? totalItems
      : (currentPage - 1) * perPage + perPage;

  return (
    <div
      className={`flex flex-row justify-end items-center pb-2 gap-2 h-10 ${
        rest.className ?? ''
      }`}
    >
      <button
        onClick={() => setCurrentPage(prev => prev - 1)}
        disabled={currentPage == 1}
        className="disabled:cursor-not-allowed"
        type="button"
      >
        <FiChevronLeft
          size={16}
          className={`${
            currentPage == 1 ? 'text-[#a9a9a9]' : 'text-[#616161]'
          }`}
        />
      </button>
      <span className="text-xs text-[#616161]">
        <strong>{`${fromItem} - ${toItem} `}</strong>
        de
        <strong>{` ${totalItems || totalPages * perPage}`}</strong>
      </span>
      <button
        onClick={() => setCurrentPage(prev => prev + 1)}
        disabled={currentPage == totalPages}
        className="disabled:cursor-not-allowed"
        type="button"
      >
        <FiChevronRight
          size={16}
          className={`${
            currentPage == totalPages ? 'text-[#a9a9a9]' : 'text-[#616161]'
          }`}
        />
      </button>
    </div>
  );
};

export default PageNavigation;
