import { twMerge } from 'tailwind-merge';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { Header } from '../../../components/atoms/table/Header';
import { NumberCellFC } from '../../../components/atoms/table/NumberCell';

const Podium = ({ value, row: { index } }: any) => {
  return (
    <span className="flex items-center gap-2 text-[#191919] text-xs whitespace-pre-wrap relative w-fit">
      {value}

      <span
        className={twMerge(
          'font-bold rounded-full h-5 w-5 text-white flex items-center justify-center absolute -right-8',
          index == 0
            ? 'bg-[#d4af37]'
            : index == 1
              ? 'bg-[#c0c0c0]'
              : index == 2
                ? 'bg-[#cd7f32]'
                : 'hidden'
        )}
      >
        {index < 3 ? `${index + 1}°` : null}
      </span>
    </span>
  );
};

const bestProducersCols = [
  {
    Header: () => <Header text={'Gás'} id="gas" secondary />,
    accessor: 'gas',
    columns: [
      {
        Header: () => <Header text={'Poço'} id="poço" />,
        accessor: 'oilwellGas',
        Cell: DefaultCell
      },
      {
        Header: () => <Header text={'Volume (m³)'} id="volume" />,
        accessor: 'volumeGas',
        Cell: NumberCellFC(2)
      },
      {
        Header: () => <Header text={'Campo'} id="campo" />,
        accessor: 'volumeHub',
        Cell: DefaultCell
      }
    ]
  },
  {
    Header: () => <Header text={'Óleo'} id="oleo" secondary />,
    accessor: 'oleo',
    columns: [
      {
        Header: () => <Header text={'Poço'} id="poço" />,
        accessor: 'oilwellOil',
        Cell: DefaultCell
      },
      {
        Header: () => <Header text={'Volume (bbl)'} id="volume" />,
        accessor: 'volumeOil',
        Cell: NumberCellFC(2)
      },
      {
        Header: () => <Header text={'Campo'} id="campo" />,
        accessor: 'hub',
        Cell: DefaultCell
      }
    ]
  }
];

export default bestProducersCols;
