import { workPermitWorkflowPhasesEnum } from '../enums/phases';

const workPermitWorkflowPhases = [
  {
    name: 'Requisição',
    ids: [
      workPermitWorkflowPhasesEnum.Requisicao,
      workPermitWorkflowPhasesEnum.Correcao
    ],
    isExpectedDate: true,
    urlRedirect: '/home/work-permit'
  },
  {
    name: 'Elaboração',
    ids: [
      workPermitWorkflowPhasesEnum.Emissao,
      workPermitWorkflowPhasesEnum.Coemitente,
      workPermitWorkflowPhasesEnum.ValidacaoLideranca
    ],
    isExpectedDate: true,
    urlRedirect: '/home/work-permit'
  },
  {
    name: 'Validação QSMS',
    ids: [workPermitWorkflowPhasesEnum.ValidacaoQSM],
    isExpectedDate: true,
    urlRedirect: '/home/work-permit'
  },
  {
    name: 'Emissão em Campo',
    ids: [
      workPermitWorkflowPhasesEnum.EmissaoEmCampo,
      workPermitWorkflowPhasesEnum.RenovaçãoRequisitante,
      workPermitWorkflowPhasesEnum.RenovaçãoEmitente
    ],
    isExpectedDate: true,
    urlRedirect: '/home/work-permit'
  },
  {
    name: 'Aprovação Téc. Segurança',
    ids: [workPermitWorkflowPhasesEnum.AprovacaoTecSeguranca],
    isExpectedDate: true,
    urlRedirect: '/home/work-permit'
  },
  {
    name: 'Execução do Serviço',
    ids: [workPermitWorkflowPhasesEnum.ExecucaodoServico],
    isExpectedDate: true,
    urlRedirect: '/home/work-permit'
  },
  {
    name: 'Quitação',
    ids: [
      workPermitWorkflowPhasesEnum.QuitacaoEmitente,
      workPermitWorkflowPhasesEnum.QuitacaoCoemitente
    ],
    forceEndTestDate: true,
    dateLabel: 'Ínicio Real',
    urlRedirect: '/home/work-permit'
  },
  {
    name: 'Quitação Téc. Segurança',
    ids: [workPermitWorkflowPhasesEnum.QuitacaoTecSeguranca],
    forceEndTestDate: true,
    dateLabel: 'Ínicio Real',
    urlRedirect: '/home/work-permit'
  },
  {
    name: 'Reprogramação',
    ids: [workPermitWorkflowPhasesEnum.Reprogramacao],
    forceEndTestDate: true,
    dateLabel: 'Ínicio Real',
    urlRedirect: '/home/work-permit'
  },
  {
    name: 'Concluído',
    ids: [
      workPermitWorkflowPhasesEnum.Concluido,
      workPermitWorkflowPhasesEnum.Cancelado
    ],
    forceEndTestDate: true,
    dateLabel: 'Ínicio Real',
    invertDates: true,
    urlRedirect: '/home/work-permit'
  }
];

export { workPermitWorkflowPhases };
