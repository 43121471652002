import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const oilwellTreatmentCols: Column[] = [
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'Poço',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: () => <Header text={'Data'} id="Data" />,
    accessor: 'Data',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 150
  },
  {
    Header: () => (
      <Header
        text={'Pressão de cabeça inicial (kgf/cm²)'}
        id="Pressão de cabeça inicial (kgf/cm²)"
      />
    ),
    accessor: 'Pressão de cabeça inicial (kgf/cm²)',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => <Header text={'Hora Início'} id="Hora Início" />,
    accessor: 'Hora Início',
    Cell: DateHourCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Hora Fim'} id="Hora Fim" />,
    accessor: 'Hora Fim',
    Cell: DateHourCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => (
      <Header
        text={'Pressão de cabeça final (kgf/cm²)'}
        id="Pressão de cabeça final (kgf/cm²)"
      />
    ),
    accessor: 'Pressão de cabeça final (kgf/cm²)',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => (
      <Header text={'Volume recuperado (m³)'} id="Volume recuperado (m³)" />
    ),
    accessor: 'Volume recuperado (m³)',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => (
      <Header text={'Local do Tratamento'} id="Local do Tratamento" />
    ),
    accessor: 'Local do Tratamento',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  }
];

export default oilwellTreatmentCols;
