import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Poço: '2603',
  Área: '835',
  Operador: '2626',
  Nota: '1002',
  'Atividade de Manutenção': '1004'
};

const maintenceTS: Column[] = tablefy(items);

export default maintenceTS;
