import { getHours, isToday } from 'date-fns';
import { convertGridToJson } from '../../utils/bdos';
import { useQuery } from '@tanstack/react-query';
import { getBdoChartData, getLastBDO } from '../../services/bdos.services';

interface useBdoReportsProps {
  date?: string;
  tracker?: string;
}

export const useBdoReports = ({ date, tracker }: useBdoReportsProps) => {
  const {
    data,
    isLoading: isLoadingBdo,
    isFetching: isFetchingBdo
  } = useQuery(['BDOReports', date, tracker], () => getLastBDO(date, tracker), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: false
  });

  const bdoData = data?.issues?.[0]?.custom_fields;

  const { data: bdoChartData, isLoading: isLoadingCharts } = useQuery(
    ['BDOReportChart', date, tracker],
    () => getBdoChartData(tracker, date),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  const bdoIsToday = date
    ? isToday(new Date(date.split('+')[0].replaceAll('-', '/')))
    : true;

  const actualHour = getHours(new Date());

  const lastEvenHour = `${actualHour < 10 ? '0' : ''}${
    actualHour % 2 == 0 ? actualHour : actualHour - 1
  }:00`;

  const cards = [
    {
      title: 'Poços de gás',
      data: {
        Operando: bdoChartData?.qtde_pocos?.gas_open,
        Fechado: bdoChartData?.qtde_pocos?.gas_closed
      }
    },
    tracker === 'Tucano Sul'
      ? {
          title: 'Poços de Captação',
          data: {
            Operando: bdoChartData?.qtde_pocos?.capt_open,
            Fechado: bdoChartData?.qtde_pocos?.capt_closed
          }
        }
      : {
          title: 'Poços de óleo',
          data: {
            Operando: bdoChartData?.qtde_pocos?.oil_open,
            Fechado: bdoChartData?.qtde_pocos?.oil_closed
          }
        },
    {
      title: 'Potencial diário',
      data: {
        'Gás (Mm³/d)': !bdoIsToday
          ? bdoChartData?.potencial?.['23:59']?.gas
          : bdoChartData?.potencial?.[lastEvenHour]?.gas,
        'Óleo (m³/d)': !bdoIsToday
          ? bdoChartData?.potencial?.['23:59']?.oleo
          : bdoChartData?.potencial?.[lastEvenHour]?.oleo
      }
    },
    {
      title: 'Perda diária',
      data: {
        'Gás (Mm³)': !bdoIsToday
          ? bdoChartData?.perdas?.['23:59']?.gas
          : bdoChartData?.perdas?.[lastEvenHour]?.gas,
        'Óleo (m³)': !bdoIsToday
          ? bdoChartData?.perdas?.['23:59']?.oleo
          : bdoChartData?.perdas?.[lastEvenHour]?.oleo
      }
    },
    {
      title: 'Perda acumulada do mês',
      data: {
        'Gás (Mm³)': bdoData?.find((it: any) => it.id == 762)?.value,
        'Óleo (m³)': bdoData?.find((it: any) => it.id == 763)?.value
      }
    }
  ];

  const openCloseCFMap: Record<string, number> = {
    Pilar: 764,
    Furado: 983,
    'Tucano Sul': 2633
  };

  const tableData = tracker
    ? bdoData?.find((it: any) => it.id === openCloseCFMap[tracker])?.value
    : null;

  const tableDataFormatted: object[] | null = tableData
    ? Object.values(convertGridToJson(tableData as string) ?? {})
    : null;

  const tableDataAnomaliesFormatted: object[] | null = tableData
    ? Object.values(
        convertGridToJson(
          bdoData?.find((it: any) => it.id === 2676)?.value as string
        ) ?? {}
      ).map((it: any) => ({ ...it, '1667': it?.['1667']?.split(' ')?.[1] }))
    : null;

  const formatDataChart = (data: any, type: 'gas' | 'oleo') =>
    data
      ? Object.keys(data?.potencial || data?.perdas || {})
          ?.map((it: any) => {
            const currHour = Number(it.split(':')[0]);
            if (bdoIsToday && currHour > actualHour)
              return {
                loss: 0,
                power: 0,
                hour: it
              };

            return {
              loss: data?.perdas?.[it]?.[type],
              power: data?.potencial?.[it]?.[type],
              hour: it
            };
          })
          ?.filter((_, idx) => idx % 2 == 1)
      : [];

  const prodTsDataChart = Object.values(
    convertGridToJson(
      bdoData?.find((it: any) => it.id === 2644)?.value as string
    ) ?? {}
  ).map((it: any) => ({
    power: String(it['2646'] / 1000).replace('.', ','),
    loss: String(it['2647'] / 1000).replace('.', ','),
    hour: it['2643']?.split(' ')?.[1]
  }));

  const gasDataChart = formatDataChart(bdoChartData, 'gas');

  const oilDataChart = formatDataChart(bdoChartData, 'oleo');

  return {
    data,
    updatedOn: data?.issues?.[0]?.updated_on,
    bdoChartData,
    field: tracker,
    cards,
    isLoadingBdo,
    isLoadingCharts,
    isFetchingBdo,
    tableDataFormatted,
    tableDataAnomaliesFormatted,
    gasDataChart,
    prodTsDataChart,
    oilDataChart
  };
};
