import { HiOutlinePlusSm } from 'react-icons/hi';

import Button from '../../components/atoms/Button';

import WorkPermitPhaseCard from '../../components/molecules/WorkPermitPhaseCard';
import { workPermitWorkflowPhases } from '../../constants/workflows/workPermitWorkflow';
import SideFilter from '../../components/molecules/SideFilter';
import { workPermitFilters } from '../../constants/filters/WorkPermitFilters';
import {
  workPermitAtom,
  workPermitFilterType
} from '../../state/workPermit.filters';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { objectify } from '../../constants/filters/WorflowTests';
import { getMatterValues } from '../../services/grids.services';
import { useQuery } from '@tanstack/react-query';

const WorkPermitWorkflowPhases = () => {
  const [filters, setFilters] = useRecoilState(workPermitAtom);
  const [usedFilters, setUsedFilters] = useState<
    workPermitFilterType | undefined
  >(filters);

  const applyChanges = (isClearing?: boolean) => {
    if (isClearing) {
      setUsedFilters(undefined);
      return;
    }

    setUsedFilters(filters);
  };

  const getAsyncValues = async () => {
    const values = await getMatterValues();

    return values
      ?.sort((a: string, b: string) => a.localeCompare(b))
      ?.filter((it: any) => it)
      ?.map(objectify);
  };

  const { data: matterWorkPermit } = useQuery({
    queryFn: getAsyncValues,
    queryKey: ['Matter-WorkPermit'],
    initialData: []
  });

  return (
    <>
      <div className="w-full h-[calc(100%-45.6px)] flex flex-col relative">
        {/* <Link
          to="calendar"
          className="flex gap-2 absolute right-60 -top-[38px] text-sm font-bold text-primary items-center px-3 py-2 rounded-md hover:bg-btn-light-2 hover:text-primary-dark transition-all"
        >
          <BiCalendar className="w-4 h-4 transition-colors" />
          <span className="transition-colors">Calendário</span>
        </Link> */}
        <a
          className="w-fit absolute right-16 -top-[40px]"
          href={`${import.meta.env.VITE_REDMINE_URL}/my/page`}
          target="_blank"
        >
          <Button
            title="Minhas Pendências"
            type="button"
            icon={<HiOutlinePlusSm className="w-5 h-5" color="white" />}
          />
        </a>

        <section className="flex-1 grid grid-cols-5 grid-rows-2 gap-4 mt-4 min-h-0 min-w-0">
          {workPermitWorkflowPhases.map((phase, idx) => (
            <WorkPermitPhaseCard
              key={phase.name}
              title={`${idx + 1}. ${phase.name}`}
              phase={phase}
              filters={usedFilters}
            />
          ))}
        </section>
      </div>

      <SideFilter
        filters={[
          {
            key: 'matter',
            name: 'Disciplina',
            values: matterWorkPermit || []
          },
          ...workPermitFilters
        ]}
        atom={workPermitAtom}
        applyChanges={applyChanges}
      >
        <div className="flex flex-col gap-1">
          <h3 className="text-sm font-bold text-white">Ordem</h3>

          <input
            className="h-9 w-full border border-px border-white rounded-lg px-3 bg-transparent text-white font-medium placeholder-white placeholder:font-thin"
            value={filters.order}
            onChange={e => {
              setFilters((old: any) => ({
                ...old,
                order: e.target.value
              }));
            }}
            placeholder="Ordem"
          />
        </div>
      </SideFilter>
    </>
  );
};

export default WorkPermitWorkflowPhases;
