import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'LC1 3"': '1383',
  'LC2 6"': '1384',
  'LC 3"': '1385',
  'LC 4"': '1386',
  'LT 3"': '1387',
  Satélites: '958'
};

const manifold: Column[] = tablefy(items);

export default manifold;
