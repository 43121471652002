import { BiPencil } from 'react-icons/bi';
import { BsTrash3Fill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { TimeCell } from './satellite';
import { queryClient } from '../../../App';
import ModalConfirmation from '../../../components/molecules/ConfirmationModal';
import { deleteOilField } from '../../../services/oilwells.services';
import SquareButton from '../../../components/atoms/SquareButton';
import { Header } from '../../../components/atoms/table/Header';

const DefaultCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs whitespace-nowrap">
      {value || '-'}
    </span>
  );
};

const oilfieldsColumns = [
  {
    Header: () => <Header text={'ID'} id="id" />,
    accessor: 'id',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Nome'} id="name" />,
    accessor: 'name',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Tipo'} id="type" />,
    accessor: 'type',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Polo'} id="Polo" />,
    accessor: 'hub',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Última atualização'} id="updatedAt" />,
    accessor: 'updatedAt',
    Cell: TimeCell
  },
  {
    Header: () => <Header text={'Ações'} id="actions" />,
    accessor: 'actions',
    Cell: (props: any) => {
      const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
      const navigate = useNavigate();

      const handleDeleteOilField = async () => {
        try {
          await deleteOilField(props.row.original.id);
          toast.success('Campo deletado com sucesso');
          queryClient.invalidateQueries(['oilfields-adm']);
        } catch {
          toast.error('Erro ao deletar campo');
        }
      };

      return (
        <>
          <div className="flex gap-2">
            <SquareButton
              className="bg-red hover:bg-red/75"
              onClick={() => setModalDeleteIsOpen(old => !old)}
              icon={<BsTrash3Fill color="white" size={16} />}
            />

            <SquareButton
              onClick={() => navigate(`/oilfields/${props.row.original.id}`)}
              icon={<BiPencil color="white" size={16} />}
            />
          </div>

          {modalDeleteIsOpen && (
            <ModalConfirmation
              confirmAction={handleDeleteOilField}
              title="Deletar campo"
              description="Tem certeza que deseja deletar esse Campo? Essa é uma ação permanente."
              confirmButtonClassName="bg-emerald-600 hover:bg-emerald-400"
              isOpen={true}
              setIsOpen={() => setModalDeleteIsOpen(!modalDeleteIsOpen)}
            />
          )}
        </>
      );
    }
  }
];

export default oilfieldsColumns;
