import { useQuery } from '@tanstack/react-query';
import { addDays, addHours, format } from 'date-fns';
import bdmfCustodyPilar from '../../constants/tableColumns/bdmf/custodyPilar';
import bdmfCustodyPilarPoint from '../../constants/tableColumns/bdmf/custodyPilarPoint';
import bdmfGasFurado from '../../constants/tableColumns/bdmf/gasFurado';
import bdmfGasFuradoPoint from '../../constants/tableColumns/bdmf/gasFuradoPoint';
import bdmfGasPilar from '../../constants/tableColumns/bdmf/gasPilar';
import bdmfGasPilarPoint from '../../constants/tableColumns/bdmf/gasPilarPoint';
import bdmfPilarGlp from '../../constants/tableColumns/bdmf/glpPilar';
import bdmfOilFurado from '../../constants/tableColumns/bdmf/oilFurado';
import bdmfOilPilar from '../../constants/tableColumns/bdmf/oilPilar';
import bdmfOilPilarPoint from '../../constants/tableColumns/bdmf/oilPilarPoint';
import { getLastBDMF } from '../../services/bdos.services';
import { convertGridToJson } from '../../utils/bdos';
import { getField } from '../../utils/tablesUtils';
import bdmfCustodyPts from '../../constants/tableColumns/bdmf/custodyPts';
import bdmfOilPts from '../../constants/tableColumns/bdmf/oilPts';
import bdmfMeasurePointsPts from '../../constants/tableColumns/bdmf/measurePointsPts';
import { getIssueRedmine } from '../../services/utils.services';

type SumaryInfoProps = {
  data: { label: string; value: string; legend?: string; unit?: string }[];
  obs?: string;
};

export const useBdmf = (date: string, tracker: string) => {
  const trackersEnum: { [key: string]: number } = {
    'pilar-gas': 22,
    'furado-gas': 23,
    'pilar-oleo': 24,
    'furado-oleo': 25,
    'pilar-glp': 26,
    'pilar-custodia': 27,
    'pts-diario': 45,
    'pts-custodia': 46
  };

  const { data, isLoading } = useQuery(
    ['BdmfReport', tracker, date],
    () => getLastBDMF(trackersEnum[tracker], date),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  const issueId = data?.issues?.[0]?.id;

  const { data: journal } = useQuery(
    ['BdmfReport Journal', issueId],
    () => {
      if (!issueId) throw new Error('ID is required');
      return getIssueRedmine(issueId);
    },
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: !!issueId
    }
  );

  let legend = null;

  const dateRange = addHours(new Date(date), 3);

  const rangeDate = format(dateRange, 'dd/MM/yyyy 00:00:00');
  const rangeDate2 = format(addDays(dateRange, 1), 'dd/MM/yyyy 00:00:00');

  const approver = ['pilar-gas', 'furado-gas', 'pts-diario'].some(
    it => tracker == it
  )
    ? getField(data, 1574)
    : journal?.issue?.journals?.find((it: any) =>
      it.details.find(
        (d: any) =>
          ['1592', '1663', '1773', '1772'].includes(d.name) &&
          d.new_value === 'Sim'
      )
    )?.user?.name;

  const reportInfo = [
    { label: 'Agente Regulado', value: 'Origem Energia S.A.' },
    {
      label: 'Instalação',
      value:
        tracker === 'pilar-custodia'
          ? 'UPGN - Alagoas'
          : tracker.includes('pilar')
            ? 'Pilar'
            : tracker.includes('pts')
              ? 'Tucano Sul'
              : 'Furado'
    },
    {
      label: 'Código da instalação',
      value:
        tracker === 'pilar-custodia'
          ? '10901'
          : tracker.includes('pilar')
            ? '10125'
            : tracker.includes('pts')
              ? '10189'
              : '10124'
    },
    { label: 'Período de Medição', value: `${rangeDate} - ${rangeDate2}` }
  ];

  let summaryInfo: SumaryInfoProps = {
    data: []
  };

  let measureInfo: { label: string; value: string; unit?: string }[] = [];

  const formatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3
  });

  const c5 =
    tracker == 'pilar-glp'
      ? [
        {
          label: 'Produção (m³)',
          value: formatter.format(getField(data, 1765))
        },
        {
          label: 'Estoque (m³)',
          value: formatter.format(getField(data, 1767))
        }
      ]
      : null;

  let measureTable: any = null;

  let obs = '';

  let measurePointTable: any = null;

  let water: any = null;

  let oilAnalysis: any = null;

  if (!data)
    return {
      reportInfo: [],
      summaryInfo,
      measureInfo,
      measureTable,
      isLoading
    };

  if (tracker === 'pilar-gas') {
    summaryInfo = {
      data: [
        {
          label: 'Medição de Gás - Total',
          value: getField(data, 1585),
          legend: 'Fechamento da produção: (PU+PG+Q+C1) - [UG+UQ+UPG-PI]',
          unit: 'Mm³'
        },
        {
          label: 'Piloto (PIL) - TAG EST-PIL-001 - VBC',
          value: getField(data, 1577),
          unit: 'Mm³'
        },
        {
          label: 'Piloto (PIL) - TAG EST-PIL-001 - Declarado',
          value: getField(data, 1578),
          unit: 'Mm³'
        }
      ]
    };

    measureInfo = [
      {
        label: 'Consumo Gás Processado',
        value: getField(data, 1588),
        unit: 'Mm³'
      },
      {
        label: 'Recirculação UPGN',
        value: getField(data, 1587),
        unit: 'Mm³'
      },
      {
        label: 'Gás Lift Pilar (GEA Total)',
        value: getField(data, 1589),
        unit: 'Mm³'
      },
      { label: 'Consumo Pilar', value: getField(data, 1718), unit: 'Mm³' },
      {
        label: 'Gás Lift Pilar (GEA Total)',
        value: getField(data, 1589),
        unit: 'Mm³'
      }
    ];

    measureTable = {
      columns: bdmfGasPilar,
      data:
        Object.values(convertGridToJson(getField(data, 1596)) ?? {})
          .filter((it: any) => it?.['1618'] !== 'O')
          .map((it: any) => ({
            ...it,
            '1624': it?.['1624'] !== '' ? it?.['1624'] : it?.['1623']
          })) ?? []
    };

    legend = (
      <>
        <li>VBC - Volume Bruto Corrigido</li>
        <li>Declarado - Volume Declarado</li>
      </>
    );

    obs = getField(data, 1580);

    measurePointTable = {
      columns: bdmfGasPilarPoint,
      data: Object.values(convertGridToJson(getField(data, 1597)) ?? {}) ?? []
    };
  } else if (tracker === 'furado-gas') {
    summaryInfo = {
      data: [
        {
          label: 'Medição de Gás - Total',
          value: getField(data, 1608),
          legend: 'Fechamento da produção: (FUG+Q+C) - (GAN+GFU)',
          unit: 'Mm³'
        }
      ]
    };

    measureInfo = [
      { label: 'Gás Lift - Total', value: getField(data, 1605), unit: 'Mm³' },
      {
        label: 'Purga (PUR) - TAG EST-FU-002 - VBC ',
        value: getField(data, 1604),
        unit: 'Mm³'
      },
      {
        label: 'Purga (PUR) - TAG EST-FU-002 - Declarado',
        value: getField(data, 1603),
        unit: 'Mm³'
      }
    ];

    measureTable = {
      columns: bdmfGasFurado,
      data: Object.values(convertGridToJson(getField(data, 1609)) ?? {})
        .filter((it: any) => it?.['1618'] !== 'O')
        .map((it: any) => ({
          ...it,
          '1624': it?.['1624'] !== '' ? it?.['1624'] : it?.['1623']
        }))
    };

    legend = (
      <>
        <li>VBC - Volume Bruto Corrigido</li>
        <li>Declarado Volume Declarado</li>
      </>
    );

    obs = getField(data, 1580);

    measurePointTable = {
      columns: bdmfGasPilarPoint,
      data: Object.values(convertGridToJson(getField(data, 1610)) ?? {}) ?? []
    };
  } else if (tracker === 'pilar-oleo') {
    const apiDegrees = Object.values(
      convertGridToJson(getField(data, 1635)) ?? {}
    ).map(it => {
      return {
        ...it
      };
    });

    const tanks = Object.values(convertGridToJson(getField(data, 1634)) ?? {});

    summaryInfo = {
      data: [
        {
          label: 'Volume Total de Produção Fiscal',
          value: getField(data, 1659) ?? 0,
          unit: 'm³'
        },
        {
          label: 'Volume Recebido Óleo',
          value: getField(data, 1660) ?? 0,
          unit: 'm³'
        },
        {
          label: 'Volume Transferido de Terceiros',
          value: getField(data, 1661) ?? 0,
          unit: 'm³'
        }
      ],
      obs: 'Condições de Referência: 200°C e 101,325 kPa'
    };

    water = [
      {
        label: 'Água Injetada de Recuperação Secundária',
        value: getField(data, 1832),
        unit: 'm³'
      }
    ];

    const measureData = Object.values(
      convertGridToJson(getField(data, 1669)) ?? {}
    )
      .filter(it => it?.['1701'] === 'TCF' || it?.['1701'] === 'M')
      .map(it => {
        return {
          ...it,
          '1707': it?.['1707'] !== '' ? it?.['1707'] : it?.['1706'],
          api: apiDegrees.find(api => {
            return (
              api[1648] ==
              tanks.find(
                tank => tank[1638] == it[1700] && tank[1829] == it[1702]
              )?.[1647]
            );
          })?.[1655]
        };
      });

    measureTable = {
      columns: bdmfOilPilar,
      data: measureData
    };

    legend = (
      <>
        <li>TC - Transferência de Custódia</li>
        <li>TCF - Transferência de Custódia de Produção Fiscal</li>
        <li>M - Medição em Tanque</li>
        <li>F - Produção Fiscal</li>
        <li>RBFU - Volume Recebido de Furado</li>
        <li>RBC5+ - Volume Recebido C5+</li>
        <li>RBTR - Volume Recebido de Terceiros</li>
        <li>BSW - % de Água da Emulsão</li>
      </>
    );

    obs =
      (getField(data, 1666) ? `${getField(data, 1666)}\n` : '') +
      getField(data, 1679);

    measurePointTable = {
      columns: bdmfOilPilarPoint,
      data: Object.values(convertGridToJson(getField(data, 1714)) ?? {}) ?? []
    };
  } else if (tracker === 'furado-oleo') {
    const apiDegrees = Object.values(
      convertGridToJson(getField(data, 1635)) ?? {}
    ).map(it => {
      return {
        ...it
      };
    });

    const tanks = Object.values(convertGridToJson(getField(data, 1721)) ?? {});

    summaryInfo = {
      data: [
        {
          label: 'Volume Produzido Óleo',
          value: getField(data, 1731),
          unit: 'm³'
        },
        {
          label: 'Volume Transferido Óleo',
          value: getField(data, 1732),
          unit: 'm³'
        }
      ],
      obs: 'Condições de Referência: 20°C e 101,325 kPa'
    };

    water = [
      {
        label: 'Água Injetada de Recuperação Secundária',
        value: getField(data, 1832) ?? 0,
        unit: 'm³'
      },
      {
        label: 'Água Injetada Descarte',
        value: getField(data, 1834) ?? 0,
        unit: 'm³'
      },
      {
        label: 'Água Injetada Total',
        value: getField(data, 1833) ?? 0,
        unit: 'm³'
      }
    ];

    const measureData = Object.values(
      convertGridToJson(getField(data, 1734)) ?? {}
    )
      .filter(it => it?.['1701'] === 'TCF' || it?.['1701'] === 'M')
      .map(it => {
        return {
          ...it,
          '1707': it?.['1707'] !== '' ? it?.['1707'] : it?.['1706'],
          api: apiDegrees.find(api => {
            return (
              api[1648] ==
              tanks.find(
                tank => tank[1720] == it[1700] && tank[1829] == it[1702]
              )?.[1647]
            );
          })?.[1655]
        };
      });

    measureTable = {
      columns: bdmfOilFurado,
      data: measureData
    };

    obs =
      (getField(data, 1730) ? `${getField(data, 1730)}\n` : '') +
      getField(data, 1736);

    legend = (
      <>
        <li>TC - Transferência de Custódia</li>
        <li>TCF - Transferência de Custódia de Produção Fiscal</li>
        <li>M - Medição em Tanque</li>
        <li>F - Produção Fiscal</li>
        <li>RBFU - Volume Recebido de Furado</li>
        <li>RBC5+ - Volume Recebido C5+</li>
        <li>RBTR - Volume Recebido de Terceiros</li>
        <li>BSW - % de Água da Emulsão</li>
      </>
    );

    measurePointTable = {
      columns: bdmfGasFuradoPoint,
      data: Object.values(convertGridToJson(getField(data, 1714)) ?? {}) ?? []
    };
  } else if (tracker === 'pilar-glp') {
    summaryInfo = {
      data: [
        {
          label: 'Estoque Dia Anterior',
          value: getField(data, 1756),
          unit: 'ton'
        },
        {
          label: 'Estoque Hoje',
          value: getField(data, 1757),
          unit: 'ton'
        },
        {
          label: 'Produção',
          value: getField(data, 1758),
          unit: 'ton'
        },
        {
          label: 'Produção',
          value: getField(data, 1759),
          unit: 'm³'
        },
        {
          label: 'Total Carregamentos',
          value: getField(data, 1760),
          unit: 'ton'
        }
      ]
    };

    measureTable = {
      columns: bdmfPilarGlp,
      data: Object.values(convertGridToJson(getField(data, 1775)) ?? {})
    };

    obs = getField(data, 1754);
  } else if (tracker === 'pilar-custodia') {
    measureInfo = [
      {
        label: 'Medição de Gás Recebido',
        value: getField(data, 1786),
        unit: 'Mm³'
      },
      {
        label: 'Medição de Gás Transferido',
        value: getField(data, 1787),
        unit: 'Mm³'
      }
    ];

    measureTable = {
      columns: bdmfCustodyPilar,
      data:
        Object.values(convertGridToJson(getField(data, 1812)) ?? {}).map(
          it =>
            ({
              ...it,
              '1822': it?.['1822'] !== '' ? it?.['1822'] : it?.['1820']
            }) ?? {}
        ) ?? []
    };

    obs = getField(data, 1580);

    measurePointTable = {
      columns: bdmfCustodyPilarPoint,
      data: Object.values(convertGridToJson(getField(data, 1815)) ?? {}) ?? []
    };
  } else if (tracker === 'pts-custodia') {
    measureInfo = [
      {
        label: 'Volume Total de Líquido Transferido',
        value: getField(data, 2968),
        unit: 'm³'
      },
      {
        label: 'Volume Total de Óleo Transferido',
        value: getField(data, 2959),
        unit: 'm³'
      }
    ];

    measureTable = {
      columns: bdmfOilPts,
      data:
        Object.values(convertGridToJson(getField(data, 2712)) ?? {}).map(
          it => ({
            ...it,
            '1733': it['1733'] || it['1703'],
            api: getField(data, 1655),
            bsw: getField(data, 1651),
            density: getField(data, 1654)
          })
        ) ?? []
    };

    obs = getField(data, 1580);

    oilAnalysis = {
      data: Object.values(convertGridToJson(getField(data, 2711)) ?? {}).map(
        it => it['1648']
      )
    };

    measurePointTable = {
      columns: bdmfMeasurePointsPts,
      data: Object.values(convertGridToJson(getField(data, 2685)) ?? {}) ?? []
    };
  } else if (tracker === 'pts-diario') {
    measureInfo = [
      {
        label: 'Medição de Gás - Produção Fiscal',
        value: getField(data, 2689),
        unit: 'm³'
      },
      {
        label: 'Medição de Gás - Total Exportado',
        value: +getField(data, 2690) + +getField(data, 2691),
        unit: 'm³'
      },
      {
        label: 'Medição de Gás - Exportação Bahia Gás',
        value: getField(data, 2690),
        unit: 'm³'
      }
    ];

    measureTable = {
      columns: bdmfCustodyPts,
      data:
        Object.values(convertGridToJson(getField(data, 2683)) ?? {})
          ?.filter((_, idx) => idx < 2)
          ?.map((it, idx) => ({
            ...it,
            tag: idx == 0 ? 'EMED-3165.01-151' : 'EMED-01001'
          })) ?? []
    };

    obs = getField(data, 1580);

    measurePointTable = {
      columns: bdmfMeasurePointsPts,
      data: Object.values(convertGridToJson(getField(data, 2685)) ?? {}) ?? []
    };
  }

  return {
    data,
    reportInfo: reportInfo ?? [],
    summaryInfo,
    measureInfo,
    water,
    c5,
    measureTable,
    measurePointTable,
    approver,
    obs,
    oilAnalysis,
    legend,
    isLoading
  };
};
