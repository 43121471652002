import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Hora: '1667',
  'Poço/Duto': '2738',
  'Condição do Raspador': '2740',
  'N° do Lacre': '842',
  Operador: '2626',
  Observação: '712'
};

const raspReceive: Column[] = tablefy(items);

export default raspReceive;
