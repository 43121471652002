import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface LinkFieldProps {
  name: string;
  value: string | string[];
  to: string;
  key?: string;
  isCol?: boolean;
  isFullWidth?: boolean;
}

export const LinkField = ({
  name,
  value,
  isCol,
  isFullWidth,
  to
}: LinkFieldProps) => {
  return (
    <div
      className={twMerge(
        `flex w-[calc(50%-2rem)] p-1`,
        isCol ? 'flex flex-col' : '',
        isFullWidth ? 'w-full' : ''
      )}
    >
      <span className="font-bold text-body">{name}: </span>
      <Link
        to={to}
        className="text-blue-700 font-bold ml-1 whitespace-pre-wrap"
        target="_blank"
      >
        {value ? value : '-'}
      </Link>
    </div>
  );
};
