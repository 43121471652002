import { useQuery } from '@tanstack/react-query';
import { getBdmfAllocationPilar } from '../../services/bdos.services';

interface Props {
  date: string;
  tracker: string;
  oilwell?: string;
  page?: number;
  perPage?: number;
}

export const useBdmfDailyAllocation = ({
  date,
  tracker,
  page,
  oilwell,
  perPage
}: Props) => {
  const { data, isLoading, isFetching } = useQuery(
    ['bdmf-daily-allocation', tracker, date, page],
    () => getBdmfAllocationPilar(date, tracker, oilwell, page, perPage),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: !!date
    }
  );

  return {
    data,
    isLoading,
    isFetching
  };
};
