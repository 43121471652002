import { atom } from 'recoil';

export interface AlertsFilters {
  timeRange: {
    endDate?: Date | string;
    startDate?: Date | string;
  };
  oilwell: string[];
  field: string[];
  roa: { from?: string; to?: string };
  rga: { from?: string; to?: string };
  station: string[];
  fluid: string[];
  criticity: string[];
  elevationMethod: string[];
  alertType: string[];
  status: string[];
  pressureSystem: string[];
}

export interface PressureAlertsFilters {
  timeRange: {
    endDate?: Date | string;
    startDate?: Date | string;
  };
  oilwell: string[];
  field: string[];
  station: string[];
  fluid: string[];
  criticity: string[];
  elevationMethod: string[];
  alertType: string[];
  status: string[];
}

export const PressureAlertsAtom = atom<PressureAlertsFilters>({
  key: 'PressureAlertsAtom',
  default: {
    timeRange: {
      endDate: undefined,
      startDate: undefined
    },
    oilwell: [],
    fluid: [],
    alertType: [],
    field: [],
    station: [],
    criticity: [],
    elevationMethod: [],
    status: []
  }
});

export const AlertsAtom = atom<AlertsFilters>({
  key: 'AlertsAtom',
  default: {
    timeRange: {
      endDate: undefined,
      startDate: undefined
    },
    oilwell: [],
    fluid: [],
    pressureSystem: [],
    roa: {
      from: undefined,
      to: undefined
    },
    rga: {
      from: undefined,
      to: undefined
    },
    alertType: [],
    field: [],
    station: [],
    criticity: [],
    elevationMethod: [],
    status: []
  }
});
