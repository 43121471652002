import { Column } from 'react-table';
import { NumberCell } from '../../../components/atoms/table/NumberCell';
import { Header } from '../../../components/atoms/table/Header';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const water: Column[] = [
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: '112',
    Cell: DateHourCell,
    width: '8%'
  },
  {
    Header: <Header text={'Volume total (m³)'} id="Produzido (m³)" />,
    accessor: '1331',
    Cell: NumberCell,
    width: '8%'
  },
  {
    Header: (
      <Header
        text={'Tempo para transbordo (h)'}
        id="Tempo para transbordo (h)"
      />
    ),
    accessor: '1332',
    Cell: NumberCell,
    width: '8%'
  }
];

export default water;
