import { IoMdEye } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import SquareButton from '../../../components/atoms/SquareButton';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { Header } from '../../../components/atoms/table/Header';

const ripControlCols: Column[] = [
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'Poço',
    Cell: ({
      value,
      row: {
        original: { url }
      }
    }: any) => {
      return (
        <a
          className="text-primary text-xs font-bold"
          href={url}
          target="_blank"
        >
          {value}
        </a>
      );
    },
    width: '15%'
  },
  {
    Header: <Header text={'Data'} id="Data" />,
    accessor: 'Data',
    Cell: DefaultCell,
    width: '15%'
  },
  {
    Header: <Header text={'Tipo de teste'} id="Tipo de teste" />,
    accessor: 'Tipo de teste',
    Cell: DefaultCell,
    width: '15%'
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: 'Observação',
    Cell: DefaultCell,
    width: '50%'
  },
  {
    Header: <Header text={'Relatório'} id="Print" />,
    accessor: 'print',
    Cell: ({ value, row: { original } }) => {
      return (
        <>
          <Link to={`${value}`}>
            <SquareButton icon={<IoMdEye size={16} color="white" />} />
          </Link>
        </>
      );
    }
  }
];

export default ripControlCols;
