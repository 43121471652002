import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Poço: '2603',
  Hora: '1667',
  'Abertura Utilizada (Choker/Bean)': '816',
  'Pressão da Coluna (psi)': '2610',
  'Pressão da Linha (psi)': '2609',
  'Pressão Header (psi)': '2608',
  'Pressão Anular A (psi)': '2611',
  'Pressão Anular B (psi)': '2612',
  'Pressão Anular C (psi)': '2613',
  'Pressão DHSV (psi)': '2742',
  Status: '1329',
  Observação: '712'
};

const pressuresTS: Column[] = tablefy(items, undefined, undefined, undefined, [
  'Abertura Utilizada (Choker/Bean)',
  'Pressão da Coluna (psi)',
  'Pressão da Linha (psi)',
  'Pressão Header (psi)',
  'Pressão Anular A (psi)',
  'Pressão Anular B (psi)',
  'Pressão Anular C (psi)',
  'Pressão DHSV (psi)'
]);

export default pressuresTS;
