import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { TimeCell } from './gasmeter';
import { Header } from '../../../components/atoms/table/Header';

const DefaultCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs whitespace-nowrap">
      {value || '-'}
    </span>
  );
};

const chromaColumns = [
  {
    Header: () => <Header text={'ID'} id="id" />,
    accessor: 'id',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Campo'} id="oilField" />,
    accessor: 'oilField.name',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Analise (%)'} id="analysisPercentage" />,
    accessor: 'analysisPercentage',
    Cell: ({ row: { id }, value }: any) => {
      const { register } = useFormContext();
      const [searchParams] = useSearchParams();
      const oilFieldId = searchParams.get('oilFieldId');
      return (
        <span className="text-[#191919] text-xs whitespace-nowrap">
          {oilFieldId ? (
            <input
              {...register(`analysisPercentage.${id}.value`)}
              type="text"
              autoFocus
              className="bg-transparent h-full w-full"
            />
          ) : (
            <span className="text-[#191919] text-xs whitespace-nowrap">
              {value}
            </span>
          )}
        </span>
      );
    }
  },
  {
    Header: () => <Header text={'Elemento Cromatografia'} id="Fórmula" />,
    accessor: 'chromatographyFormula.fieldChromatography',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Elemento Químico'} id="Fórmula" />,
    accessor: 'chromatographyFormula.chemicalFormula',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Data de atualização'} id="updatedAt" />,
    accessor: 'updatedAt',
    Cell: TimeCell
  },
  {
    Header: () => <Header text={'Data de Aplicação'} id="updatedAt" />,
    accessor: 'applicationDate',
    Cell: DefaultCell
  }
];

export default chromaColumns;
