import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { getAlerts } from '../../services/alerts.services';
import { AlertsAtom } from '../../state/alerts.atom';
import { useParams } from '../Common/useParams';

const useAlerts = (tab: number) => {
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 15;

  const { setParam, params } = useParams();

  const stations = params
    .getAll('station[]')
    .map((station: string) => station.toUpperCase());

  const filtersState = useRecoilValue(AlertsAtom);
  const [filteredData, setFilteredData] = useState({
    ...filtersState,
    station: stations
  });

  const getData = async () => {
    try {
      const res = await getAlerts(
        currentPage,
        perPage,
        filteredData,
        tab ? (tab == 2 ? 'gas' : 'oil') : undefined
      );

      return {
        meta: res.meta,
        data: res.data?.map((it: any) => ({
          ...it,
          id: it.new_issue_id
        }))
      };
    } catch (e) {
      toast.error('Erro ao buscar dados');
      throw new Error('Erro ao buscar dados');
    }
  };

  const { data, isFetching, isLoading } = useQuery({
    queryFn: getData,
    queryKey: ['Alerts', currentPage, filteredData, tab],
    keepPreviousData: true,
    refetchOnWindowFocus: false
  });

  const handleFilter = (isClearing?: boolean) => {
    setCurrentPage(1);

    const hasNullValues = (obj: any) => {
      return Object.keys(obj).some(
        k => obj[k] === null || obj[k] === undefined
      );
    };

    if (!isClearing) {
      const value_stations = (filtersState as any)?.station;
      if (value_stations && value_stations.length > 0) {
        value_stations.forEach((station: string) => {
          params.delete('station[]');
          params.append('station[]', station);
        });
      } else {
        params.delete('station[]');
      }
      setParam(params);

      setFilteredData(old => ({
        ...filtersState
      }));
    } else {
      setParam(new URLSearchParams());
      setFilteredData({
        field: [],
        status: [],
        criticity: [],
        elevationMethod: [],
        pressureSystem: [],
        alertType: [],
        station: [],
        rga: { from: undefined, to: undefined },
        roa: { from: undefined, to: undefined },
        fluid: [],
        oilwell: [],
        timeRange: {
          endDate: undefined,
          startDate: undefined
        }
      });
    }
  };

  return {
    data: data ?? null,
    isFetching,
    handleFilter,
    isLoading,
    setCurrentPage,
    perPage,
    filteredData,
    setFilteredData,
    currentPage
  };
};

export default useAlerts;
