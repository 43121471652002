import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface PageWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export const PageWrapper = forwardRef<HTMLDivElement, PageWrapperProps>(
  ({ children, className }, ref) => {
    return (
      <div
        className={twMerge(
          'w-full h-[calc(100%-45.6px)] flex flex-col relative pt-4',
          className
        )}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);
