import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Data/Hora da Medição': '2643',
  'Vazão Instantânea (m³/d)': '2645',
  'Acumulado (m³)': '2646'
};
const upgnCatu: Column[] = tablefy(items, undefined, undefined, undefined, [
  'Acumulado (m³)',
  'Vazão Instantânea (m³/d)'
]);

export default upgnCatu;
