import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Data/Hora da Medição': '2643',
  'QE/CON (psi)': '2664',
  'CON/PAN (psi)': '2665',
  'QE/CON (kgf/cm²)': '2666',
  'CON/PAN (kgf/cm²)': '2667'
};

const pressureGas: Column[] = tablefy(items, undefined, undefined, undefined, [
  'QE/CON (psi)',
  'CON/PAN (psi)',
  'QE/CON (kgf/cm²)',
  'CON/PAN (kgf/cm²)'
]);

export default pressureGas;
