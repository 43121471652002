import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Ponto de Medição': '1763',
  TAG: '663',
  Classificação: '1762',
  Origem: '500',
  Destino: '499',
  'Data de Coleta de Dados do PI': '1823',
  'Pressão (kPa)': '1791',
  'Temperatura (°C)': '1789',
  'VBC (Mm³)': '1820',
  'Declarado (Mm³)': '1822',
  'PCS (kcal/m³)': '1764',
  'VTC (Mm³)': '1821'
};
const bdmfCustodyPilar: Column[] = tablefy(
  items,
  undefined,
  Object.keys(items)
);

export default bdmfCustodyPilar;
