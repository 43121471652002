import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const EstanqueidadeCols: Column[] = [
  {
    Header: <Header text={'Poço'} id="poço" />,
    accessor: 'poço',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Hora Início'} id="Hora_Início" />,
    accessor: 'Hora_Início',
    Cell: DateHourCell,
    width: '8%'
  },
  {
    Header: <Header text={'Hora Fim'} id="Hora_Fim" />,
    accessor: 'Hora_Fim',
    Cell: DateHourCell,
    width: '8%'
  },
  {
    Header: (
      <Header
        text={'Pressão de injeção inicial'}
        id="Pressão de injeção inicial"
      />
    ),
    accessor: 'Pressão de injeção inicial',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: (
      <Header text={'Pressão de injeção final'} id="Pressão de injeção final" />
    ),
    accessor: 'Pressão de injeção final',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: (
      <Header
        text={'Resultado do Teste de Estanqueidade'}
        id="Resultado do Teste de Estanqueidade"
      />
    ),
    accessor: 'Resultado do Teste de Estanqueidade',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: 'Observação',
    Cell: DefaultCell,
    width: '8%'
  }
];

export default EstanqueidadeCols;
