import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Hora: '1667',
  Poço: '2603',
  'N° do Lacre': '842',
  Operador: '2626',
  Observação: '712'
};

const raspOilwell: Column[] = tablefy(items);

export default raspOilwell;
