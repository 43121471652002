import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Equipamento: '1046',
  'Lubrificação Forçada - Valor do Totalizador às 23h59': '1416',
  'Lubrificação Forçada - Consumo (L)': '1417',
  'Compressor - Valor do Totalizador às 23h59': '1418',
  'Compressor - Consumo (L)': '1419'
};

const oilAndLub: Column[] = tablefy(items);

export default oilAndLub;
