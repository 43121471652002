import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Poço: '2603',
  Hora: '1667',
  'Abertura Utilizada Antiga (Choke/Bean)': '784',
  'Abertura Utilizada Nova (Choke/Bean)': '785',
  'Pressão de Cabeça Antiga (psi)': '2635',
  'Pressão de Cabeça Nova (psi)': '2636',
  'Pressão de Linha Antiga (psi)': '2637',
  'Pressão de Linha Nova (psi)': '2638',
  'Pressão de Cabeça Antiga (kgf/cm²)': '786',
  'Pressão de Cabeça Nova (kgf/cm²)': '787',
  'Pressão de Linha Antiga (kgf/cm²)': '788',
  'Pressão de Linha Nova (kgf/cm²)': '789'
};

const changesTS: Column[] = tablefy(items, undefined, undefined, undefined, [
  'Abertura Utilizada Antiga (Choke/Bean)',
  'Abertura Utilizada Nova (Choke/Bean)',
  'Pressão de Cabeça Antiga (psi)',
  'Pressão de Cabeça Nova (psi)',
  'Pressão de Linha Antiga (psi)',
  'Pressão de Linha Nova (psi)',
  'Pressão de Cabeça Antiga (kgf/cm²)',
  'Pressão de Cabeça Nova (kgf/cm²)',
  'Pressão de Linha Antiga (kgf/cm²)',
  'Pressão de Linha Nova (kgf/cm²)'
]);

export default changesTS;
