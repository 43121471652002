import { NestedItemComponentProps } from '@/constants/view/types/structure.type';
import { twMerge } from 'tailwind-merge';

export const ReportHeader = ({
  nestedValues: reportInfo,
  className
}: NestedItemComponentProps) => {
  return (
    <section
      className={twMerge(
        'flex rounded-lg justify-between bg-white px-4 py-3 gap-2 w-full',
        className
      )}
    >
      {reportInfo.map((info, idx) => (
        <div className="flex flex-col" key={info.name + idx}>
          <h3 className="text-sm font-medium text-gray">{info.name}</h3>
          <p
            className={twMerge(
              'text-lg font-semibold text-slate-900',
              idx == 0 ? 'text-primary' : ''
            )}
          >
            {info.value}
          </p>
        </div>
      ))}
    </section>
  );
};
