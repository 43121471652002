import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Local: '2745',
  'Atividades Não Realizadas': '2746',
  'Motivo da Não Realização': '2112',
  Observação: '712'
};

const activitiesNotFinishedM: Column[] = tablefy(items);

export default activitiesNotFinishedM;
