import { useQuery } from '@tanstack/react-query';
import { getBdoChartData, getLastBDOTS } from '../../services/bdos.services';
import { getHours, isToday } from 'date-fns';

export const useBdoTucano = (date?: string) => {
  const {
    data: issues,
    isLoading,
    isFetching: isFetchingBdoSup
  } = useQuery(['BDO-TS', date], () => getLastBDOTS(date), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: false
  });

  const findValueById = (id: number) =>
    issues?.[0]?.[0]?.custom_fields?.find((it: any) => it.id == id)?.value;

  const { data: cardsData, isLoading: isLoadingCards } = useQuery(
    ['BDOReportChart', date, 'Tucano Sul'],
    () => getBdoChartData('Tucano Sul', date),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  const bdoIsToday = date
    ? isToday(new Date(date.split('+')[0].replaceAll('-', '/')))
    : true;

  const actualHour = getHours(new Date());

  const lastEvenHour = `${actualHour < 10 ? '0' : ''}${
    actualHour % 2 == 0 ? actualHour : actualHour - 1
  }:00`;

  const cards = [
    {
      title: 'Poços de gás',
      data: {
        Operando: cardsData?.qtde_pocos?.gas_open,
        Fechado: cardsData?.qtde_pocos?.gas_closed
      }
    },
    {
      title: 'Poços de Captação',
      data: {
        Operando: cardsData?.qtde_pocos?.capt_open,
        Fechado: cardsData?.qtde_pocos?.capt_closed
      }
    },
    {
      title: 'Potencial diário',
      data: {
        'Gás (Mm³/d)': !bdoIsToday
          ? cardsData?.potencial?.['23:59']?.gas
          : cardsData?.potencial?.[lastEvenHour]?.gas,
        'Óleo (m³/d)': !bdoIsToday
          ? cardsData?.potencial?.['23:59']?.oleo
          : cardsData?.potencial?.[lastEvenHour]?.oleo
      }
    },
    {
      title: 'Perda diária',
      data: {
        'Gás (Mm³)': !bdoIsToday
          ? cardsData?.perdas?.['23:59']?.gas
          : cardsData?.perdas?.[lastEvenHour]?.gas,
        'Óleo (m³)': !bdoIsToday
          ? cardsData?.perdas?.['23:59']?.oleo
          : cardsData?.perdas?.[lastEvenHour]?.oleo
      }
    },
    {
      title: 'Perda acumulada do mês',
      data: {
        'Gás (Mm³)': findValueById(762),
        'Óleo (m³)': findValueById(763)
      }
    }
  ];

  return {
    bdoArray: issues,
    isLoading,
    isFetchingBdoSup,
    isLoadingCards,
    cards
  };
};
