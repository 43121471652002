import { TbClockHour8 } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';

interface ClockProps {
  className?: string;
  color: 'red' | 'green' | 'yellow';
}

const Clock: React.FC<ClockProps> = ({ color, className }) => {
  return (
    <TbClockHour8
      color="#fff"
      className={twMerge(
        `${
          color === 'red'
            ? 'bg-red'
            : color === 'green'
              ? 'bg-green'
              : color === 'yellow'
                ? 'bg-yellow-400'
                : undefined
        } rounded-full p-[2px] w-5 h-5`,
        className
      )}
    />
  );
};

export default Clock;
