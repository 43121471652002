import { useAccount } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import Button from '../../components/atoms/Button';
import EmptyMessage from '../../components/atoms/EmptyMessage';
import { PageWrapper } from '../../components/atoms/PageWrapper';
import Spinner from '../../components/atoms/Spinner';
import PageNavigation from '../../components/molecules/PageNavigation';
import SideFilter from '../../components/molecules/SideFilter';
import Table from '../../components/organisms/Table';

import { PriorityPageFilter } from '@/constants/filters/PriorityPageFilter';
import priority from '../../constants/tableColumns/piop/priority';
import { getPiopOrder, updatePiopPriority } from '../../services/bdos.services';
import { PiopReportFiltersAtom } from '../../state/piop.report.filters';

export const EditTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filtersState, setFilters] = useRecoilState(PiopReportFiltersAtom);
  const [filteredData, setFilteredData] = useState(filtersState);
  const perPage = 30;

  const user = useAccount();

  const handleFilter = (isClearing?: boolean) => {
    !isClearing
      ? setFilteredData(filtersState)
      : setFilteredData({
          station: [],
          equipment: [],
          installationLoc: [],
          priority: [],
          timeRange: {
            endDate: null,
            startDate: null
          },
          order: '',
          note: ''
        });
  };

  const methods = useForm();

  const { data: issues, isLoading } = useQuery({
    queryFn: async () => {
      const res = await getPiopOrder(currentPage, perPage, filteredData);
      methods.reset({
        data: res.data
      });
      return res;
    },
    queryKey: ['Piop-report', filteredData, currentPage]
  });

  const onSubmit = async (data: any) => {
    try {
      const res = await updatePiopPriority(data.data);

      toast.success('Prioridades alteradas com sucesso!');
    } catch (e) {
      toast.error('Erro ao atualizar prioridade');
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !['Portal.Admin', 'Portal.Piop', 'Portal.PiopAdmin'].some(it =>
        user?.idTokenClaims?.roles?.includes(it)
      )
    )
      navigate('/home/piop');
  }, []);

  return (
    <>
      <PageWrapper>
        <div className="bg-white rounded-[10px] py-4 px-6 h-full mt-4">
          <h1 className="text-gray font-bold text-lg mb-4 w-full flex justify-between">
            Comitê de Priorização
          </h1>

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="h-full max-h-[calc(100vh-280px)] pb-6 overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full">
                {isLoading ? (
                  <Spinner size={36} />
                ) : !issues.data.length ? (
                  <EmptyMessage message="Nenhum dado foi encontrado" />
                ) : (
                  <Table
                    key="priority"
                    columns={priority}
                    headerClassName="sticky top-0 z-10 bg-white"
                    data={issues.data?.map((it: any) => ({
                      ...it,
                      priority: it.prioridade,
                      nota: it.nota?.replace('.0', '')
                    }))}
                  />
                )}
              </div>

              {!!issues && (
                <PageNavigation
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  className="mt-3"
                  totalPages={Math.ceil(issues.total / perPage)}
                  perPage={perPage}
                />
              )}

              <div className="w-full flex justify-end">
                <Button title="Salvar" type="submit" />
              </div>
            </form>
          </FormProvider>
        </div>
      </PageWrapper>

      <SideFilter
        atom={PiopReportFiltersAtom}
        filters={PriorityPageFilter}
        applyChanges={handleFilter}
        floatButtonClassName="top-28"
      >
        <div className="flex flex-col gap-1">
          <h3 className="text-sm font-bold text-white">Ordem</h3>

          <input
            className="h-9 w-full border border-px border-white rounded-lg px-3 bg-transparent text-white font-medium placeholder-white placeholder:font-thin"
            value={filtersState.order}
            onChange={e => {
              setFilters((old: any) => ({
                ...old,
                order: e.target.value
              }));
            }}
            placeholder="Ordem"
          />
        </div>

        <div className="flex flex-col gap-1">
          <h3 className="text-sm font-bold text-white">Nota</h3>

          <input
            className="h-9 w-full border border-px border-white rounded-lg px-3 bg-transparent text-white font-medium placeholder-white placeholder:font-thin"
            value={filtersState.note}
            onChange={e => {
              setFilters((old: any) => ({
                ...old,
                note: e.target.value
              }));
            }}
            placeholder="Ordem"
          />
        </div>
      </SideFilter>
    </>
  );
};
