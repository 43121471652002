import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  postAllocation,
  postGasMeter,
  postZone
} from '../../../services/oilwells.services';

import Input from '../../../components/atoms/Input';
import Button from '../../../components/atoms/Button';
import { queryClient } from '../../../App';
import SelectInput from '../../../components/atoms/SelectInput';
import MdmLayout from '../../../components/templates/MdmLayout';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import { isToday, parseISO, format, isBefore, parse } from 'date-fns';
import Datepicker from 'react-tailwindcss-datepicker';
import { useState } from 'react';
import { injectionTypes } from '../../../constants/filters/OilWellsTable';

type CreateOilwellZonesAllocationForm = {
  oilwellId: string;
  zoneId: string;
  approprietionOil: number;
  approprietionCondensed: number;
  approprietionAssociatedGas: number;
  approprietionNaturalGas: number;
  approprietionWater: number;
  injectionWater: number;
  injectionGas: number;
  injectionType: string;
};

const CreateOilwellZonesAllocation: React.FC = () => {
  const [currentDate, setCurrentDate] = useState({
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  });
  const [currentHour, setCurrentHour] = useState<string>(
    isToday(parseISO(currentDate.startDate))
      ? format(new Date(), 'HH:mm')
      : '23:59'
  );

  const { oilwells, zones } = useMdmUtils({
    oilwells: true,
    zones: true
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<CreateOilwellZonesAllocationForm>();

  const onSubmit = async (oldData: CreateOilwellZonesAllocationForm) => {
    const { zoneId, oilwellId, ...data } = oldData;
    try {
      await postAllocation(
        {
          ...data,
          zone: {
            connect: {
              id: zoneId
            }
          },
          oilwell: {
            connect: {
              id: oilwellId
            }
          },
          injectionType: data.injectionType,
          approprietionOil: data.approprietionOil
            ? Number(data.approprietionOil)
            : null,
          approprietionCondensed: data.approprietionCondensed
            ? Number(data.approprietionCondensed)
            : null,
          approprietionAssociatedGas: data.approprietionAssociatedGas
            ? Number(data.approprietionAssociatedGas)
            : null,
          approprietionNaturalGas: data.approprietionNaturalGas
            ? Number(data.approprietionNaturalGas)
            : null,
          approprietionWater: data.approprietionWater
            ? Number(data.approprietionWater)
            : null,
          injectionWater: data.injectionWater
            ? Number(data.injectionWater)
            : null,
          injectionGas: data.injectionGas ? Number(data.injectionGas) : null
        },
        {
          date: `${currentDate.startDate}T${currentHour}:00.000Z`
        }
      );
      await queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === 'oilwells-zones-adm'
      });

      toast.success('Alocação criada com sucesso');
    } catch (e) {
      toast.error('Erro ao criar alocação');
    }
  };

  return (
    <MdmLayout title={'Criar Alocação Poço-Zona'}>
      <div className="flex flex-col flex-end w-3/4 mb-2">
        <label className="font-medium text-sm text-primary">
          Data de Aplicação
        </label>
        <Datepicker
          value={currentDate}
          asSingle={true}
          useRange={false}
          onChange={(val: any) => {
            setCurrentDate(val);
          }}
          i18n="pt-br"
          placeholder="Selecione uma data"
          inputClassName={
            'relative transition-all duration-300 h-[36px] pl-2 pr-10 w-full border-[1px] border-[#cccccc] dark:border-slate-600 rounded-md tracking-wide text-sm focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20 mb-2'
          }
          classNames={{
            toggleButton() {
              return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
            }
          }}
          maxDate={new Date()}
        />

        <Input
          label="Hora de Aplicação"
          required
          placeholder="Hora de Aplicação"
          value={currentHour}
          onChange={e => setCurrentHour(e.target.value)}
          type="time"
          max={
            isToday(parseISO(currentDate.startDate))
              ? format(new Date(), 'HH:mm')
              : undefined
          }
          isError={
            isToday(parseISO(currentDate.startDate))
              ? isBefore(new Date(), parse(currentHour, 'HH:mm', new Date()))
              : false
          }
        />
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-2 items-center justify-center w-3/4"
      >
        {oilwells?.data && (
          <SelectInput
            label="Poço"
            control={control}
            name={'oilwellId'}
            required
            options={oilwells?.data?.map((field: any) => ({
              value: field.id,
              label: field.name
            }))}
          />
        )}

        {zones?.data && (
          <SelectInput
            label="Zona"
            control={control}
            name={'zoneId'}
            required
            options={zones?.data?.map((field: any) => ({
              value: field.id,
              label: field.code
            }))}
          />
        )}

        <SelectInput
          label="Tipo de Injeção"
          placeholder="Tipo de Injeção"
          control={control}
          name={'injectionType'}
          options={injectionTypes}
        />

        <span
          className="
          font-bold
          text-persian-blue-900
          text-lg
          w-full
          flex
          mt-2
        "
        >
          Percentuais
        </span>

        <div className="flex gap-4 w-full">
          <Input
            label="Apropriação Óleo"
            required
            {...register('approprietionOil')}
          />

          <Input
            label="Apropriação Cond"
            required
            {...register('approprietionCondensed')}
          />
        </div>

        <div className="flex gap-4 w-full">
          <Input
            label="Apropriação Gás Associado"
            required
            {...register('approprietionAssociatedGas')}
          />

          <Input
            label="Apropriação Gás natural"
            required
            {...register('approprietionNaturalGas')}
          />
        </div>

        <div className="flex gap-4 w-full">
          <Input
            label="Apropriação Água"
            required
            {...register('approprietionWater')}
          />

          <Input
            label="Injeção Água"
            required
            {...register('injectionWater')}
          />
        </div>

        <Input label="Injeção Gás" required {...register('injectionGas')} />

        <Button
          className="px-24 h-12 mt-4"
          type="submit"
          title="Salvar"
          disabled={!isValid && !errors}
        />
      </form>
    </MdmLayout>
  );
};

export default CreateOilwellZonesAllocation;
