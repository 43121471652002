import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Hi-Lo': '1316',
  'Pressão (kgf/cm²) – às 12:00h': '1314',
  'Pressão (kgf/cm²) – às 23:59h': '1315'
};

const pressureHilo: Column[] = tablefy(items);

export default pressureHilo;
