import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const h2sAnalysis: Column[] = [
  {
    Header: <Header text={'Ponto de Coleta'} id="Ponto de Coleta" />,
    accessor: '1565',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: '112',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: '1058',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default h2sAnalysis;
