import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

import { TextCell } from '../../../components/atoms/table/TextCell';
import { Header } from '../../../components/atoms/table/Header';
import { NumberCell2 } from '../../../components/atoms/table/NumberCell';

const items: any = {
  Tipo: '1699',
  'Ponto de Medição / TAG': '1700',
  Classificação: '1701',
  'Hora da Medição': '1702',
  'Nível de Líquido (cm)': '1703',
  'VB (m³)': '1704',
  'VBC (m³)': '1705',
  'VLO (m³)': '1706',
  'Declarado (m³)': '1707',
  FCC: '1708',
  FCV: '1709',
  'VLT (m³)': '1710',
  ºAPI: 'api'
};

const bdmfOilPilar: Column[] = [
  {
    Header: () => <Header text={'Tipo'} id="tipo" />,
    accessor: '1699',
    Cell: TextCell,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => (
      <Header
        className="h-auto"
        text={'Ponto de Medição / TAG'}
        id="ponto_de_medicao"
      />
    ),
    accessor: '1700',
    Cell: TextCell,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => <Header text={'Classificação'} id="classificacao" />,
    accessor: '1701',
    Cell: TextCell,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => <Header text={'Hora da Medição'} id="hora_da_medicao" />,
    accessor: '1702',
    Cell: TextCell,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => (
      <Header text={'Nível de Líquido (cm)'} id="nivel_do_liquido" />
    ),
    accessor: '1703',
    Cell: (e: any) => <NumberCell2 {...e} minDigit={1} maxDigit={5} />,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => <Header text={'VB (m³)'} id="vb" />,
    accessor: '1704',
    Cell: (e: any) => <NumberCell2 {...e} minDigit={5} maxDigit={5} />,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => <Header text={'VBC (m³)'} id="vbc" />,
    accessor: '1705',
    Cell: (e: any) => <NumberCell2 {...e} minDigit={5} maxDigit={5} />,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => <Header text={'VLO (m³)'} id="vlo" />,
    accessor: '1706',
    Cell: (e: any) => <NumberCell2 {...e} minDigit={5} maxDigit={5} />,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => <Header text={'Declarado (m³)'} id="declarado" />,
    accessor: '1707',
    Cell: (e: any) => <NumberCell2 {...e} minDigit={5} maxDigit={5} />,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => <Header text={'FCC'} id="fcc" />,
    accessor: '1708',
    Cell: (e: any) => <NumberCell2 {...e} minDigit={5} maxDigit={5} />,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => <Header text={'FCV'} id="fcv" />,
    accessor: '1709',
    Cell: (e: any) => <NumberCell2 {...e} minDigit={5} maxDigit={5} />,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => <Header text={'VLT (m³)'} id="vlt" />,
    accessor: '1710',
    Cell: (e: any) => <NumberCell2 {...e} minDigit={5} maxDigit={5} />,
    minWidth: 120,
    width: undefined
  },
  {
    Header: () => <Header text={'ºAPI'} id="api" />,
    accessor: 'api',
    Cell: (e: any) => <NumberCell2 {...e} minDigit={1} maxDigit={5} />,
    minWidth: 120,
    width: undefined
  }
];

export default bdmfOilPilar;
