import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Hora: '1667',
  Poço: '2603',
  'Tipo de Atividade': '984'
};

const rip: Column[] = tablefy(items);

export default rip;
