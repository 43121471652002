import { Column, useTable } from 'react-table';
import { twMerge } from 'tailwind-merge';

const RawTable = ({
  columns,
  data,
  tableClassName,
  headerClassName,
  bodyClassName,
  rowClassName
}: {
  columns: Column[];
  data: object[];
  tableClassName?: string;
  headerClassName?: string;
  bodyClassName?: string;
  rowClassName?: string;
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<object>({
      columns: columns,
      data: data
    });
  return (
    <div
      className={twMerge('flex-row w-full', tableClassName)}
      {...getTableProps()}
    >
      <div className={twMerge('flex-row', headerClassName)}>
        {headerGroups.map(headerGroup => {
          const { key, ...restHeaderGroupProps } =
            headerGroup.getHeaderGroupProps();
          return (
            <div
              key={key}
              className={twMerge('flex', restHeaderGroupProps.className)}
              {...restHeaderGroupProps}
            >
              {headerGroup.headers.map(column => {
                const { key, ...restColumn } = column.getHeaderProps({
                  style: {
                    minWidth: column.minWidth,
                    width: column.width,
                    maxWidth: column.maxWidth
                  }
                });

                return (
                  <div key={key} {...restColumn}>
                    {column.render('Header')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div
        className={twMerge('flex-row', bodyClassName)}
        {...getTableBodyProps()}
      >
        {rows.map(row => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <div
              key={key}
              {...restRowProps}
              className={twMerge('flex', rowClassName)}
            >
              {row.cells.map(cell => {
                const { key, ...restCellProps } = cell.getCellProps({
                  style: {
                    minWidth: cell.column.minWidth,
                    width: cell.column.width,
                    maxWidth: cell.column.maxWidth
                  }
                });
                return (
                  <div key={key} {...restCellProps}>
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RawTable;
