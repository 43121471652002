import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Data/Hora da Medição': '2643',
  'Nível da Régua (cm)': '2672',
  'Temperatura (°C)': '1252',
  'Acumulado do Tanque (m³)': '2673',
  'Acumulado do Dia (m³)': '2674'
};

const vecel0006: Column[] = tablefy(items, undefined, undefined, undefined, [
  'Nível da Régua (cm)',
  'Temperatura (°C)',
  'Acumulado do Tanque (m³)',
  'Acumulado do Dia (m³)'
]);

export default vecel0006;
