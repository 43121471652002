import { Dialog } from '@headlessui/react';
import Modal from '../atoms/Modal';
import Button from '../atoms/Button';
import { twMerge } from 'tailwind-merge';

interface ModalConfirmationProps {
  title: string;
  description?: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  confirmAction: () => void | Promise<void>;
  discartAction?: () => void;
  confirmButtonType?: 'button' | 'submit' | 'reset';
  confirmButtonClassName?: string;
  children?: React.ReactNode;
  modalClassName?: string;
}

const ModalConfirmation: React.FC<ModalConfirmationProps> = (
  props: ModalConfirmationProps
) => {
  const confirmAction = async () => {
    await props.confirmAction();
    props.setIsOpen(false);
  };

  const discartAction = () => {
    props.discartAction && props.discartAction();
    props.setIsOpen(false);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title={props.title}
      className={twMerge('max-w-lg', props.modalClassName)}
    >
      <Dialog.Description className="w-full flex flex-col pt-2">
        <span className="font-medium text-base px-8 text-slate-600 max-w-[90%]">
          {props.description}
        </span>
      </Dialog.Description>

      {props.children}

      <div className="flex w-full gap-2 justify-end h-fit py-6 px-8">
        <Button
          title="Cancelar"
          onClick={discartAction}
          className="bg-transparent hover:bg-primary/10"
          classNameSpan="text-body-dark font-medium underline"
        />

        <Button
          title="Confirmar"
          onClick={confirmAction}
          type={props.confirmButtonType ?? 'button'}
          className={props.confirmButtonClassName ?? ''}
          classNameSpan="font-medium"
        />
      </div>
    </Modal>
  );
};

export default ModalConfirmation;
