import { twMerge } from 'tailwind-merge';

interface Props extends React.InputHTMLAttributes<HTMLHeadingElement> {
  children?: React.ReactNode;
  small?: boolean;
}

const H1: React.FC<Props> = props => {
  return (
    <h1
      {...props}
      className={twMerge(
        props.small
          ? 'font-bold text-xl text-primary'
          : 'font-bold text-2xl text-primary mb-2 flex justify-between',
        props.className
      )}
    >
      {props.children}
    </h1>
  );
};

export default H1;
