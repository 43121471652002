import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Poço: '2677',
  Hora: '2678',
  'Abertura Utilizada (Choker/Bean)': '2679',
  'Abertura Máxima  (Choker/Bean)': '45',
  'Pressão Header (kgf/cm²)': '2607',
  'Pressão da Linha (kgf/cm²)': '866',
  'Pressão da Coluna (kgf/cm²) *': '812',
  'Pressão Anular A (kgf/cm²)': '813',
  'Pressão Anular B (kgf/cm²)': '814',
  'Pressão Anular C (kgf/cm²)': '815',
  'Pressão DHSV (kgf/cm²)': '2743',
  Status: '2680',
  Observação: '2681'
};

const pressuresAutoTS: Column[] = tablefy(
  items,
  undefined,
  undefined,
  undefined,
  [
    'Abertura Utilizada (Choker/Bean)',
    'Abertura Máxima  (Choker/Bean)',
    'Pressão Header (kgf/cm²)',
    'Pressão da Linha (kgf/cm²)',
    'Pressão da Coluna (kgf/cm²) *',
    'Pressão Anular A (kgf/cm²)',
    'Pressão Anular B (kgf/cm²)',
    'Pressão Anular C (kgf/cm²)',
    'Pressão DHSV (kgf/cm²)'
  ]
);

export default pressuresAutoTS;
