import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const BcsCols: Column[] = [
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'Poço',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: 'Hora',
    Cell: DateHourCell,
    width: '8%'
  },
  {
    Header: <Header text={'Frequência (Hz)'} id="Frequência (Hz)" />,
    accessor: 'Frequência (Hz)',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Tensão VSD (V)'} id="Tensão VSD (V)" />,
    accessor: 'Tensão VSD (V)',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Corrente VSD (A)'} id="Corrente VSD (A)" />,
    accessor: 'Corrente VSD (A)',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: (
      <Header text={'Temperatura Motor (°C)'} id="Temperatura Motor (°C)" />
    ),
    accessor: 'Temperatura Motor (°C)',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: (
      <Header text={'Temperatura intake (°C)'} id="Temperatura intake (°C)" />
    ),
    accessor: 'Temperatura intake (°C)',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: (
      <Header text={'Pressão descarga (psi)'} id="Pressão descarga (psi)" />
    ),
    accessor: 'Pressão descarga (psi)',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Pressão intake (psi)'} id="Pressão intake (psi)" />,
    accessor: 'Pressão intake (psi)',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: 'Observação',
    Cell: DefaultCell,
    width: '8%'
  }
];

export default BcsCols;
