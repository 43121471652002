import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  id: 'id_teste',
  Dia: 'dia',
  Poço: 'poço',
  'Código ANP': 'cód_anp',
  'Nome ANP': 'nome_anp',
  Fluido: 'fluido',
  Runtime: 'runtime',
  'Gás Lift': 'gas_lift',
  Óleo: 'oleo_condensado',
  Água: 'agua',
  'Gás Total': 'gas_total',
  'co₂': 'co2',
  'n₂': 'n2',
  'Fim do Teste': 'data_fim_teste',
  'Data de Aplicação': 'data_aplicação'
};

const bdmfDiaryAllocation: Column[] = tablefy(
  items,
  ['Fim do Teste', 'Data de Aplicação'],
  ['Runtime', 'Gás Lift', 'Óleo Condesado', 'Água', 'Gás Total', 'co₂', 'n₂']
);

export default bdmfDiaryAllocation;
