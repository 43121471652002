import { useQuery } from '@tanstack/react-query';
import { addHours, format, isToday, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import { toast } from 'react-toastify';
import { queryClient } from '../../../App';
import Button from '../../../components/atoms/Button';
import Input from '../../../components/atoms/Input';
import SelectInputUncontrolled from '../../../components/atoms/SelectInputUncontrolled';
import Spinner from '../../../components/atoms/Spinner';
import PageNavigation from '../../../components/molecules/PageNavigation';
import Table from '../../../components/organisms/Table';
import chromaColumns from '../../../constants/tableColumns/mdm/chroma';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import {
  getAllChroma,
  updateManyChroma
} from '../../../services/oilwells.services';

const Chroma = () => {
  const [currentDate, setCurrentDate] = useState({
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  });
  const [currentHour, setCurrentHour] = useState<string>(
    isToday(parseISO(currentDate.startDate))
      ? format(new Date(), 'HH:mm')
      : '23:59'
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const oilFieldId = searchParams.get('oilFieldId') ?? '';

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 15;

  const [oilField, setOilField] = useState(
    oilFieldId !== '' ? Number(oilFieldId) : null
  );

  const { fields } = useMdmUtils({
    fields: true
  });

  useEffect(() => {
    setOilField(oilFieldId !== '' ? Number(oilFieldId) : null);
  }, [searchParams, oilFieldId]);

  const methods = useForm();

  const handleGetChroma = async () => {
    try {
      const response = await getAllChroma(
        oilFieldId,
        `${currentDate.startDate}T${currentHour}:00.000Z`,
        perPage * (currentPage - 1),
        perPage
      );
      return response;
    } catch (error) {
      toast.error('Erro ao buscar cromatografias');
    }
  };

  const { data, isLoading, isFetching } = useQuery(
    ['croma-adm', oilFieldId, currentPage, currentDate, currentHour],
    handleGetChroma,
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 3, // 3 minutos
      cacheTime: 1000 * 60 * 7 // 7 minutos
    }
  );

  useEffect(() => {
    methods.reset({
      analysisPercentage: data?.data?.map((item: any) => ({
        id: item?.data?.id,
        value: item?.data?.analysisPercentage
      }))
    });
  }, [data]);

  const onSubmit = async (data: any) => {
    if (total.toFixed(2) !== '100.00')
      return toast.error('A soma das análises deve ser igual a 100');

    try {
      await updateManyChroma(
        data.analysisPercentage?.map((item: any) => ({
          id: item.id,
          analysisPercentage: Number(`${item.value}`?.replaceAll(',', '.'))
        })),
        `${currentDate.startDate}T${currentHour}:00.000Z`
      );
      await queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === 'croma-adm'
      });

      toast.success('cromatografia atualizado com sucesso');
    } catch (e) {
      toast.error('Erro ao atualizar cromatografia');
    }
  };

  const analysisPercentage = useWatch({
    control: methods.control,
    name: 'analysisPercentage'
  });

  useEffect(() => {
    if (analysisPercentage && analysisPercentage.length > 0) {
      const total = analysisPercentage.reduce(
        (acc: number, item: { value: string }) => {
          const value = Number(`${item.value}`?.replaceAll(',', '.'));
          return isNaN(value) ? acc : acc + value;
        },
        0
      );
      setTotal(total);
    }
  }, [analysisPercentage]);

  return (
    <>
      <div className="flex flex-col gap-4 py-4 px-6 bg-white rounded-lg mt-4 pb-8 relative">
        {/* <Button
          title="Nova Cromatografia"
          type="button"
          className="absolute right-14 -top-[56px]"
          icon={<HiOutlinePlusSm className="w-5 h-5" color="white" />}
          onClick={handleNavigateToCreateOilWell}
        /> */}

        <section className="flex justify-between items-center w-full mb-4">
          <h1 className="text-gray font-bold text-lg flex whitespace-nowrap">
            Cromatografia do gás lift
            {isLoading ||
              (isFetching && <Spinner className="ml-2" size={16} />)}
          </h1>

          <div className="flex gap-2">
            {oilFieldId ? (
              <>
                <div className="flex flex-col gap-1">
                  <label className="font-medium text-sm text-primary">
                    Data de Aplicação
                  </label>
                  <Datepicker
                    value={currentDate}
                    asSingle={true}
                    useRange={false}
                    onChange={(val: any) => {
                      setCurrentDate(val);
                    }}
                    i18n="pt-br"
                    placeholder="Selecione uma data"
                    inputClassName={
                      'relative transition-all duration-300 h-[36px] pl-2 pr-10 w-full border-[1px] border-[#cccccc] dark:border-slate-600 rounded-md tracking-wide text-sm focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20'
                    }
                    classNames={{
                      toggleButton() {
                        return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
                      }
                    }}
                    maxDate={new Date()}
                  />
                </div>
                <div className="flex">
                  <Input
                    label="Hora de Aplicação"
                    placeholder="Hora de Aplicação"
                    value={currentHour}
                    onChange={e => setCurrentHour(e.target.value)}
                    type="time"
                    className="h-9"
                    max={
                      isToday(parseISO(currentDate.startDate))
                        ? format(new Date(), 'HH:mm')
                        : undefined
                    }
                  />
                </div>
              </>
            ) : null}
            <div className="flex flex-col gap-1">
              <label className="font-medium text-sm text-primary">
                Data de Aplicação
              </label>
              <SelectInputUncontrolled
                value={fields
                  ?.map((it: any) => ({ value: it.id, label: it.name }))
                  ?.find((item: any) => item.value === oilField)}
                onChange={(e: any) => {
                  setOilField(e.value);
                  setSearchParams({ oilFieldId: e.value });
                }}
                options={fields?.map((item: any) => ({
                  value: item.id,
                  label: item.name
                }))}
                placeholder="Editar por campo"
                className="w-72 min-w-72 h-8"
              />
            </div>
          </div>
        </section>
        {!data && (
          <div className="flex flex-col items-center justify-center">
            <Spinner className="mt-4" size={32} />
          </div>
        )}
        {data && (
          <FormProvider {...methods}>
            <form
              className="flex flex-col gap-2"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <Table
                columns={chromaColumns.filter(it =>
                  oilFieldId ? true : it.accessor !== 'applicationDate'
                )}
                data={
                  oilFieldId
                    ? data.data?.map((it: any) => ({
                        ...it.data,
                        applicationDate: it.applicationDate
                          ? format(
                              addHours(it.applicationDate, 3),
                              'dd/MM/yyyy HH:mm'
                            )
                          : ''
                      })) || []
                    : data.data
                }
              />

              {!oilFieldId && (
                <PageNavigation
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  className="mt-3"
                  totalPages={Math.ceil(data.total / perPage)}
                  perPage={perPage}
                />
              )}

              {methods.formState.isDirty && (
                <div className="flex justify-end gap-2 mt-4">
                  <strong>Analise (%) Total:</strong>
                  <span>{total.toFixed(2)}</span>
                </div>
              )}

              {oilFieldId !== '' && (
                <div className="flex justify-end gap-2 mt-4">
                  <Button
                    title="Voltar"
                    type="button"
                    className="bg-transparent hover:bg-primary/10"
                    classNameSpan="text-body-dark font-medium underline"
                    onClick={() => {
                      setSearchParams();
                      setOilField(null);
                      methods.reset({
                        analysisPercentage: data?.data?.map((item: any) => ({
                          id: item.id,
                          value: item.analysisPercentage
                        }))
                      });
                    }}
                  />

                  <Button
                    title="Salvar"
                    type="submit"
                    className="w-min"
                    disabled={!methods.formState.isDirty}
                  />
                </div>
              )}
            </form>
          </FormProvider>
        )}
      </div>
    </>
  );
};

export default Chroma;
