import { format } from 'date-fns';
import { useState } from 'react';
import Chart from 'react-apexcharts';
import { FaCircleInfo } from 'react-icons/fa6';
import { useSearchParams } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import Select from 'react-select';
import Button from '../../components/atoms/Button';
import Modal from '../../components/atoms/Modal';
import Spinner from '../../components/atoms/Spinner';
import { BdoCards } from '../../components/molecules/BdoCards';
import SideFilter from '../../components/molecules/SideFilter';
import Table from '../../components/organisms/Table';
import { bdoProdCols } from '../../constants/tableColumns/bdoProdCols';
import {
  gridOilwellFieldMapper,
  reportBdoCols
} from '../../constants/tableColumns/reportBdoCols';
import { useBdoReports } from '../../hooks/BDO/useBdoReports';
import { BDOCalendarAtom } from '../../state/bdo.calendar.atom';
import { bdoReportsChartOptions } from '../../utils/bdoReports';
import { convertArrayToNewFormat } from './ViewForm';
import {
  selectInputStyles,
  ValueContainer
} from '../../components/atoms/Select';
import { useParams } from '../../hooks/Common/useParams';
import { objectify } from '../../constants/filters/WorflowTests';
import anomalies from '../../constants/tableColumns/bdoTS/anomalies';
import { twMerge } from 'tailwind-merge';

export const dateTimeFormat = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/;

export const BDOReports = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [params, setParam] = useSearchParams();

  const { tracker, currentDate, date } = useParams();

  const {
    field,
    cards,
    updatedOn,
    isLoadingCharts,
    isLoadingBdo,
    tableDataFormatted,
    tableDataAnomaliesFormatted,
    gasDataChart,
    oilDataChart,
    prodTsDataChart,
    bdoChartData
  } = useBdoReports({
    date,
    tracker: tracker ? tracker : undefined
  });

  return (
    <div className="w-full h-[calc(100%-45.6px)] flex flex-col relative">
      <h2 className="flex text-xl text-primary font-bold mb-2 gap-2">
        Informe Produção {tracker}{' '}
        <span className="text-gray text-sm h-full align-text-bottom pt-[0.3rem]">
          -{' '}
          {currentDate
            ? format(new Date(currentDate.replaceAll('-', '/')), 'dd/MM/yyyy')
            : format(new Date(), 'dd/MM/yyyy')}
        </span>
        {updatedOn && (
          <span className="ml-auto flex items-center text-sm font-normal text-[#888]">
            <FaCircleInfo className="mr-2" size={16} />
            <strong className="font-bold mr-1">Última atualização:</strong>
            {format(new Date(updatedOn), 'dd/MM/yyyy HH:mm')}
          </span>
        )}
      </h2>

      <div className="flex w-full justify-between gap-3">
        {cards.map((card: any) => (
          <div className="bg-white flex-1 rounded-md">
            <BdoCards
              key={card.title}
              title={card.title}
              data={card.data}
              className="bg-white mx-auto"
            />
          </div>
        ))}
      </div>

      <div className="flex gap-3 h-full mt-3 overflow-hidden">
        <div className="flex gap-3 w-full flex-grow-[3] flex-col max-w-[calc(50dvw-1rem)]">
          <div className="h-[400px] bg-white rounded-lg flex-1 px-4 py-3">
            {isLoadingCharts && (
              <div className="flex w-full justify-center items-center h-full">
                <Spinner />
              </div>
            )}
            {gasDataChart && (
              <Chart
                options={bdoReportsChartOptions(
                  gasDataChart,
                  'Potencial e perda de gás',
                  'Potencial de Gás (Mm³/d)',
                  'Perda de Gás (Mm³)'
                )}
                series={
                  bdoReportsChartOptions(
                    gasDataChart,
                    'Potencial e perda de gás',
                    'Potencial de Gás (Mm³/d)',
                    'Perda de Gás (Mm³)'
                  ).series
                }
                width={'100%'}
                height={'100%'}
              />
            )}
          </div>
          <div className="h-[400px] bg-white rounded flex-1 px-4 py-3">
            {isLoadingCharts && (
              <div className="flex w-full justify-center items-center h-full">
                <Spinner />
              </div>
            )}
            {oilDataChart && (
              <Chart
                options={
                  tracker === 'Tucano Sul'
                    ? bdoReportsChartOptions(
                        prodTsDataChart,
                        'Produção de Gás (Mm³) - EMED-151',
                        'Acumulado (Mm³)',
                        '2H (Mm³)',
                        1,
                        'Acumulado (Mm³)',
                        '2H (Mm³)'
                      )
                    : bdoReportsChartOptions(
                        oilDataChart,
                        'Potencial e perda de óleo',
                        'Potencial de Óleo (m³/d)',
                        'Perda de Óleo (m³)'
                      )
                }
                series={
                  bdoReportsChartOptions(
                    tracker === 'Tucano Sul' ? prodTsDataChart : oilDataChart,
                    'Potencial e perda de óleo',
                    'Potencial de Óleo (m³/d)',
                    'Perda de Óleo (m³)',
                    0,
                    tracker === 'Tucano Sul' ? 'Acumulado (Mm³)' : undefined,
                    tracker === 'Tucano Sul' ? '2H (Mm³)' : undefined
                  ).series
                }
                width={'100%'}
                height={'100%'}
              />
            )}
          </div>
        </div>

        <div className="flex flex-col gap-3 flex-grow-[2] min-w-[50dvw]">
          <div
            className={twMerge(
              ' bg-white rounded-lg flex-1 px-4 py-3 overflow-y-auto',
              tracker === 'Tucano Sul' ? 'max-h-[calc(50%-6px)]' : ''
            )}
          >
            <h1 className="text-gray font-bold text-lg mb-2 w-full flex justify-between">
              Aberturas e fechamentos
              <button
                className="text-xs border-primary text-primary font-medium px-3 py-2 rounded border no-print"
                onClick={() => setIsOpen(true)}
              >
                Resumo de Perdas
              </button>
            </h1>
            {isLoadingBdo && (
              <div className="flex w-full justify-center items-center h-full">
                <Spinner />
              </div>
            )}
            <div className="h-[calc(100%-3rem)] pb-6 overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full">
              {tableDataFormatted && (
                <Table
                  key="flowRate"
                  columns={field ? reportBdoCols(field) : []}
                  headerClassName="sticky top-0 z-10 bg-white"
                  data={
                    tracker && tableDataFormatted
                      ? convertArrayToNewFormat(
                          tableDataFormatted
                            .map((it: any) => ({
                              ...it,
                              poço: it[gridOilwellFieldMapper[tracker]],
                              Hora: dateTimeFormat.test(it['769'])
                                ? it['769'].split(' ')[1]
                                : it['769'],
                              tipo_de_atividade: it['1128'],
                              obs: it['712'] // 712 = observacao
                            }))
                            ?.sort(
                              (a: any, b: any) =>
                                new Date(a.Hora).getTime() -
                                new Date(b.Hora).getTime()
                            )
                            ?.sort((a: any, b: any) => a.poço - b.poço)
                        ).map(it => ({
                          ...it,
                          textComplement: bdoChartData?.resumo?.[it.poço]
                        }))
                      : []
                  }
                />
              )}
            </div>
          </div>
          <div
            className={twMerge(
              'flex flex-col bg-white rounded-lg flex-1 px-4 py-3 max-h-[calc(50%-6px)]',
              tracker !== 'Tucano Sul' ? 'hidden' : 'h-full'
            )}
          >
            <h1 className="text-gray font-bold text-lg mb-2 w-full flex justify-between">
              Observações e Anomalias
            </h1>

            <div className="h-[calc(100%-3rem)] pb-6 overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full">
              {tableDataAnomaliesFormatted && (
                <Table
                  columns={anomalies}
                  headerClassName="sticky top-0 z-10 bg-white"
                  data={tableDataAnomaliesFormatted}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <SideFilter
        atom={BDOCalendarAtom}
        filters={[]}
        floatButtonClassName="top-20"
      >
        <div className="flex flex-col gap-1">
          <h3 className="text-sm font-bold text-white">Data</h3>
          <Datepicker
            value={{
              startDate: currentDate
                ? new Date(currentDate.split('+')[0])
                : new Date(),
              endDate: currentDate
                ? new Date(currentDate.split('+')[0])
                : new Date()
            }}
            asSingle={true}
            useRange={false}
            primaryColor={'sky'}
            onChange={(val: any) => {
              return setParam({
                date: val.startDate.replaceAll('-', '/'),
                tracker: tracker ?? ''
              });
            }}
            i18n="pt-br"
            placeholder="Selecione uma data"
            inputClassName={
              'relative transition-all duration-300 h-[36px] pl-2 pr-10 w-full border-[1px] border-[#cccccc] dark:border-slate-600 rounded-[10px] tracking-wide font-light text-sm text-white placeholder-white bg-primary focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20'
            }
            maxDate={new Date()}
            classNames={{
              toggleButton() {
                return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
              }
            }}
          />
        </div>

        <div className="flex flex-col gap-1">
          <h3 className="text-sm font-bold text-white">Estação</h3>
          <Select
            className={`w-full rounded-lg font-medium`}
            options={['Pilar', 'Furado', 'Tucano Sul'].map(objectify)}
            onChange={(val: any) =>
              setParam({
                date: currentDate ?? '',
                tracker: val?.value ?? 'Pilar'
              })
            }
            placeholder="Selecione..."
            theme={(theme: any) => ({
              ...theme,
              borderRadius: 6,
              spacing: {
                ...theme.spacing,
                controlHeight: 34
              },
              colors: {
                ...theme.colors,
                primary: '#aabbff',
                neutral60: '#E5E5E5'
              }
            })}
            styles={selectInputStyles}
            components={{ ValueContainer }}
          />
        </div>
      </SideFilter>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Perdas por poço"
        className="max-w-[50vw] overflow-hidden"
      >
        <div className="px-6 my-4 max-h-[60vh] overflow-y-scroll">
          <Table
            data={
              bdoChartData?.resumo
                ? Object.keys(bdoChartData.resumo).map(it => ({
                    poço: it,
                    ...bdoChartData?.resumo?.[it],
                    date: bdoChartData?.resumo?.[it]?.data_do_teste
                      ?.substring(0, 10)
                      ?.replaceAll('-', '/')
                  }))
                : []
            }
            headerClassName="sticky top-0 z-10 bg-white"
            columns={bdoProdCols}
          />
        </div>

        <Button
          title="Fechar"
          className="mx-6 my-4 ml-auto"
          classNameSpan="font-medium"
          onClick={() => setIsOpen(false)}
        />
      </Modal>
    </div>
  );
};
