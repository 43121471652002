import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const turn: Column[] = [
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: '112',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Hora Fim'} id="Hora Fim" />,
    accessor: '528',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Descrição do evento'} id="Descrição do evento" />,
    accessor: '502',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default turn;
