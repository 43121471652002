import { Link, LinkProps } from 'react-router-dom';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';

const ActiveLink: React.FC<LinkProps> = ({ children, ...rest }) => {
  const isActive = useLocation().pathname == rest.to;

  return (
    <Link
      {...rest}
      className={twMerge(
        `${
          isActive ? 'text-primary font-medium' : 'text-secondary'
        } no-underline hover:text-primary-dark transition-colors duration-200 whitespace-nowrap`,
        rest.className ?? ''
      )}
    >
      {children}
    </Link>
  );
};

export default ActiveLink;
