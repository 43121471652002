import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Ponto de Medição': '1715',
  TAG: '1716',
  Tipo: '1717'
};

const bdmfOilPilarPoint: Column[] = tablefy(
  items,
  undefined,
  Object.keys(items)
);

export default bdmfOilPilarPoint;
