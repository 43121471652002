import { useQuery } from '@tanstack/react-query';
import { getLastBDOEc } from '../../services/bdos.services';

export const useBdoEc = (date?: string, tracker?: string) => {
  const { data, isLoading } = useQuery(
    ['BDOLastEC', tracker, date],
    () => getLastBDOEc(date, tracker),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  return {
    bdoArray: data?.issues?.[0] ? data?.issues?.[0]?.custom_fields : [],
    isLoading
  };
};
