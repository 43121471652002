import { Column } from 'react-table';
import production from '../../tableColumns/bdoView/production';

export const bdoProduction: (
  | { type: 'label'; id: number; name: string }
  | {
      type: 'grid';
      id: number;
      columns: Column[];
      name?: string;
      value?: string;
    }
  | {
      type: 'input' | 'date' | 'number' | 'custom';
      id: number;
      name: string;
      value?: string;
      field?: string;
    }
)[] = [
  { id: 526, name: 'Volume Total Produzido (m³)', type: 'custom' },
  { id: 522, name: 'Volume Total Extrapolado', type: 'number' },
  {
    id: 526,
    type: 'grid',
    columns: production
  }
];
