import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const compressors: Column[] = [
  {
    Header: <Header text={'Equipamento'} id="Equipamento" />,
    accessor: '1046',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Horímetro Acumulado'} id="Horímetro Acumulado" />,
    accessor: '1044',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Horímetro do dia'} id="Horímetro do dia" />,
    accessor: '1045',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default compressors;
