import { bdoView } from '../../constants/view/bdos/bdo';
import { useBdoReports } from '../../hooks/BDO/useBdoReports';
import { BdoCards } from '../../components/molecules/BdoCards';
import Table from '../../components/organisms/Table';
import { convertGridToJson } from '../../utils/bdos';
import { useBdoViewForm } from '../../hooks/BDO/useBdoViewForm';
import BcsCols from '../../constants/tableColumns/bdoView/bcs';
import satGeaCols from '../../constants/tableColumns/bdoView/satGea';
import EstanqueidadeCols from '../../constants/tableColumns/bdoView/estanqueidade';
import EchometerCols from '../../constants/tableColumns/bdoView/echometer';
import xvFollowUpCols from '../../constants/tableColumns/bdoView/xvFollowUp';
import ripControlCols from '../../constants/tableColumns/bdoView/ripControl';
import raspOilwell from '../../constants/tableColumns/bdoView/raspOilwell';
import raspOrigin from '../../constants/tableColumns/bdoView/raspOrigin';
import raspReceipt from '../../constants/tableColumns/bdoView/raspReceipt';
import EmptyMessage from '../../components/atoms/EmptyMessage';
import equipCols from '../../constants/tableColumns/bdoView/equip';
import { Column } from 'react-table';
import { ReactNode, useRef, useState } from 'react';
import stickCols from '../../constants/tableColumns/bdoView/stick';
import pressureCols from '../../constants/tableColumns/bdoView/pressures';
import injectionCols from '../../constants/tableColumns/bdoView/injection';
import oilwellTreatmentCols from '../../constants/tableColumns/bdoView/oilwellTreatmentCols';
import sampleCollectionCols from '../../constants/tableColumns/bdoView/sampleCollection';
import glcCols from '../../constants/tableColumns/bdoView/glc';
import { openClosingCols } from '../../constants/tableColumns/bdoView/openClosing';
import { useReactToPrint } from 'react-to-print';
import maintenanceRequest from '../../constants/tableColumns/bdoView/maintenanceRequest';
import { addDays, format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import ptCols from '../../constants/tableColumns/bdoView/ptCols';
import changeLine from '../../constants/tableColumns/bdoView/changeLine';
import changeBean from '../../constants/tableColumns/bdoView/changeBean';
import changeSystem from '../../constants/tableColumns/bdoView/changeSystem';
import bmCols from '../../constants/tableColumns/bdoView/bm';
import bcpCols from '../../constants/tableColumns/bdoView/bcp';
import Datepicker from 'react-tailwindcss-datepicker';
import SideFilter from '../../components/molecules/SideFilter';
import { BDOCalendarAtom } from '../../state/bdo.calendar.atom';
import {
  ValueContainer,
  selectInputStyles
} from '../../components/atoms/Select';
import Select from 'react-select';
import SelectInputUncontrolled from '../../components/atoms/SelectInputUncontrolled';
import { PageWrapper } from '../../components/atoms/PageWrapper';

interface LabelProps {
  name: string;
  id: number;
}

const Label = ({ name, id }: LabelProps) => {
  return (
    <div
      className={
        'flex w-full text-primary text-xl border-b border-primary pb-4 mb-2 mt-4'
      }
      key={id}
    >
      <span className="font-bold">
        {name} - {id}
      </span>
    </div>
  );
};

interface InputProps {
  name: string;
  id: number | string;
  value: string;
  key?: string;
}

const Input = ({ id, name, value }: InputProps) => {
  return (
    <div className={'flex flex-col w-[calc(50%-2rem)]'}>
      <span className="font-bold text-body">{name}</span>
      <span className="text-gray">{value ?? '-'}</span>
    </div>
  );
};

const TableGrid = ({
  title,
  cols,
  data,
  children
}: {
  title: string;
  cols: Column[];
  data: any;
  key?: string | number;
  children?: ReactNode;
}) => (
  <div className="flex flex-col w-full" id={title}>
    <span className="font-bold text-gray mb-4 text-xl pt-2 border-t border-[#ddd]">
      {title}
    </span>

    {children}

    <div className="w-full overflow-y-auto max-h-[600px] scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table">
      {data && data.length > 0 ? (
        <Table
          columns={cols}
          data={data}
          headerClassName="sticky top-0 bg-white z-10"
        />
      ) : (
        <EmptyMessage
          message="Sem dados disponíveis"
          className="min-h-[2rem] w-min"
        />
      )}
    </div>
  </div>
);

export function convertArrayToNewFormat(arr: any[] | undefined) {
  if (!arr) return [];

  const res: any[] = [];
  const idxVisited: number[] = [];

  arr.forEach((it, cur_idx) => {
    if (it.tipo_de_atividade == 'Fechamento') {
      const abertura = arr.find((f, idx) => {
        if (
          f.poço == it.poço &&
          f.tipo_de_atividade == 'Abertura' &&
          idx > cur_idx
        ) {
          idxVisited.push(idx);
          return true;
        }
        return false;
      });

      res.push({
        ...it,
        motivo: it?.motivo ?? abertura?.motivo,
        'Hora Fechamento': it.Hora,
        obsFechamento: it?.obs,
        'Hora Abertura': abertura?.Hora,
        obsAbertura: abertura?.obs,
        observação: it?.observação
      });
    } else {
      if (idxVisited.includes(cur_idx)) return;
      res.push({
        ...it,
        'Hora Abertura': it.Hora,
        obsAbertura: it?.obs,
        'Hora Fechamento': null
      });
    }
  });

  return res;
}

interface hasAllowedField {
  allowedFields: string[];
}

const filterField = <T extends hasAllowedField>(
  grids: T[],
  actualField: string | null
) => {
  if (actualField === null) return grids;

  return grids.filter(grid => {
    if (grid.allowedFields.length === 0) return true;

    return grid.allowedFields.includes(actualField);
  });
};

export const ViewForm = () => {
  const [params, setParams] = useSearchParams();

  const tracker = params.get('tracker');
  const currentDate = params.get('date');
  const currentDateFormatted = currentDate
    ? format(new Date(currentDate), 'yyyy-MM-dd')
    : undefined;

  const { data, cards } = useBdoReports({
    date: currentDateFormatted || format(addDays(new Date(), -1), 'yyyy-MM-dd'),
    tracker: tracker ? tracker : undefined
  });

  const { gridsData, dataEquip } = useBdoViewForm(
    currentDateFormatted || format(addDays(new Date(), -1), 'yyyy-MM-dd'),
    tracker ? tracker : undefined
  );

  const bdoArray = data?.issues?.[0]?.custom_fields;

  const equipsFormatted: any[] = Array.from(
    Array(tracker == 'Pilar' ? 8 : 6).keys()
  ).map(it => it + 1);

  if (tracker == 'Furado') {
    equipsFormatted.push('Paru');
  }
  equipsFormatted.unshift('Supervisório');

  const equips = equipsFormatted.map(item => ({
    title: `Equipe ${item}`,
    cols: equipCols,
    data: gridsData?.equip
      ?.filter((it: any) => it.Equipe == `Equipe ${item}`)
      ?.filter((it: any) => it.Campo == tracker),
    resp1: gridsData?.equip?.find(
      (it: any) => it.Equipe == `Equipe ${item}` && it.Campo == tracker
    )?.['Responsável 1'],
    resp2: gridsData?.equip?.find(
      (it: any) => it.Equipe == `Equipe ${item}` && it.Campo == tracker
    )?.['Responsável 2'],
    resp1label: item == 'Supervisório' ? 'Responsável - Dia' : 'Responsável 1',
    resp1label2:
      item == 'Supervisório' ? 'Responsável - Noite' : 'Responsável 2'
  }));

  const grids = [
    {
      title: 'Registro de Pressão dos Poços',
      cols: pressureCols,
      data: gridsData?.pressures
        ?.filter((it: any) => it.Campo == tracker || it.campo == tracker)
        ?.map((it: any) => ({
          ...it,
          hora: it?.hora || it.hora2,
          observação: it?.observação?.replaceAll(',', '.')
        }))
        ?.reverse(),
      allowedFields: new Array<string>()
    },
    {
      title: 'Registro dos Poços de Injeção',
      cols: injectionCols,
      data: gridsData?.injection?.filter(
        (it: any) => it.Campo == tracker || it.campo == tracker
      ),
      allowedFields: new Array<string>()
    },
    {
      title: 'Aberturas e fechamentos',
      cols: openClosingCols,
      data: gridsData?.open_close_all
        ? convertArrayToNewFormat(
            gridsData?.open_close_all
              ?.sort(
                (a: any, b: any) =>
                  new Date(a.Hora).getTime() - new Date(b.Hora).getTime()
              )
              ?.sort((a: any, b: any) => a.poço - b.poço)
          )?.filter((it: any) => it.Campo == tracker || it.campo == tracker)
        : [],
      allowedFields: new Array<string>()
    },
    {
      title: 'Controle de Mudanças no Campo - Linhas',
      cols: changeLine,
      data: gridsData?.change_line
        ?.filter((it: any) => it.Polo == tracker)
        ?.map((it: any) => ({
          ...it,
          obs: it['[GRID] Observação']
        })),
      allowedFields: new Array<string>()
    },
    {
      title: 'Controle de Mudanças no Campo - Bean',
      cols: changeBean,
      data: gridsData?.change_bean?.filter((it: any) => it.Polo == tracker),
      allowedFields: new Array<string>()
    },
    {
      title: 'Controle de Mudanças no Campo - Sistema',
      cols: changeSystem,
      data: gridsData?.change_system?.filter((it: any) => it.Polo == tracker),
      allowedFields: new Array<string>()
    },
    {
      title: 'SAT-GEA',
      cols: satGeaCols,
      data: gridsData?.sat_gea?.filter(
        (it: any) => it.Campo == tracker || it.campo == tracker
      ),
      allowedFields: new Array<string>()
    },
    {
      title: 'Testes de Estanqueidade de Linhas',
      cols: EstanqueidadeCols,
      data: gridsData?.tightness?.filter(
        (it: any) => it.Campo == tracker || it.campo == tracker
      ),
      allowedFields: new Array<string>()
    },
    {
      title: 'Echometer',
      cols: EchometerCols,
      data: gridsData?.echometer?.filter(
        (it: any) => it.Campo == tracker || it.campo == tracker
      ),
      allowedFields: new Array<string>()
    },
    {
      title: 'GLC/GLI/BPZ',
      cols: glcCols,
      data: gridsData?.glc?.filter(
        (it: any) => it.Campo == tracker || it.campo == tracker
      ),
      allowedFields: new Array<string>()
    },
    {
      title: 'Acompanhamento BCS',
      cols: BcsCols,
      data: gridsData?.bcs
        ?.filter((it: any) => it.Campo == tracker || it.campo == tracker)
        ?.sort(
          (a: any, b: any) =>
            new Date(a.Hora).getTime() - new Date(b.Hora).getTime()
        ),
      allowedFields: new Array<string>()
    },
    {
      title: 'BCP',
      cols: bcpCols,
      data: gridsData?.bcp
        ?.filter((it: any) => it.Campo == tracker || it.campo == tracker)
        ?.sort(
          (a: any, b: any) =>
            new Date(a.Hora).getTime() - new Date(b.Hora).getTime()
        ),
      allowedFields: new Array<string>()
    },
    {
      title: 'BM',
      cols: bmCols,
      data: gridsData?.bm
        ?.filter((it: any) => it.Campo == tracker || it.campo == tracker)
        ?.sort(
          (a: any, b: any) =>
            new Date(a.Hora).getTime() - new Date(b.Hora).getTime()
        ),
      allowedFields: new Array<string>()
    },
    {
      title: 'Coleta de Amostras',
      cols: sampleCollectionCols,
      data: gridsData?.sample_collection?.filter(
        (it: any) => it.Campo == tracker || it.campo == tracker
      ),
      allowedFields: new Array<string>()
    },
    {
      title: 'Tratamento de poços',
      cols: oilwellTreatmentCols,
      data: gridsData?.oilwell_treatment?.filter(
        (it: any) => it.Campo == tracker || it.campo == tracker
      ),
      allowedFields: new Array<string>()
    },
    {
      title: 'Acompanhamento de XV',
      cols: xvFollowUpCols,
      data: gridsData?.xv_follow_up
        ?.filter((it: any) => it.Campo == tracker || it.campo == tracker)
        ?.map((it: any) => {
          return {
            ...it,
            Hora: it?.Hora
              ? format(
                  new Date(it?.Hora?.split('+')?.[0]?.replaceAll('-', '/')),
                  'HH:mm'
                )
              : ''
          };
        })
        .reverse(),
      allowedFields: new Array<string>()
    },
    {
      title: 'Solicitações de manutenção',
      cols: maintenanceRequest,
      data: gridsData?.maintenance_request?.filter(
        (it: any) => it.Campo == tracker || it.campo == tracker
      ),
      allowedFields: new Array<string>()
    },
    {
      title: 'Controle de Emissão de PTs',
      cols: ptCols(tracker), // 689
      data:
        bdoArray &&
        Object.values(
          convertGridToJson(
            (bdoArray?.find((it: any) => it.id == 689 || it.id == 978)
              ?.value as string) ?? '{}'
          ) ?? {}
        ),
      allowedFields: new Array<string>()
    },
    {
      title: 'Controle de RIP',
      cols: ripControlCols.filter(col => col.accessor != 'print'),
      data: gridsData?.rip_control?.filter(
        (it: any) => it.Campo == tracker || it.campo == tracker
      ),
      allowedFields: new Array<string>()
    },
    {
      title: 'Raspadores - Poços para Satélites',
      cols: raspOilwell,
      data: gridsData?.rasp_oilwell
        ?.filter((it: any) => it.Campo == tracker || it.campo == tracker)
        ?.sort(
          (a: any, b: any) =>
            new Date(a.Hora).getTime() - new Date(b.Hora).getTime()
        ),
      allowedFields: new Array<string>()
    },
    {
      title: 'Raspadores - Origem nos Satélites',
      cols: raspOrigin,
      data: gridsData?.rasp_origin
        ?.filter((it: any) => it.Campo == tracker || it.campo == tracker)
        ?.sort(
          (a: any, b: any) =>
            new Date(a.Hora).getTime() - new Date(b.Hora).getTime()
        ),
      allowedFields: new Array<string>()
    },
    {
      title: 'Raspadores - Estação para Estação',
      cols: raspOrigin,
      data: gridsData?.rasp_station
        ?.filter((it: any) => it.Campo == tracker || it.campo == tracker)
        ?.sort(
          (a: any, b: any) =>
            new Date(a.Hora).getTime() - new Date(b.Hora).getTime()
        ),
      allowedFields: new Array<string>('Furado')
    },
    {
      title: 'Raspadores - Recebimentos',
      cols: raspReceipt,
      data: gridsData?.rasp_receipt
        ?.filter((it: any) => it.Campo == tracker || it.campo == tracker)
        ?.sort(
          (a: any, b: any) =>
            new Date(a.Hora).getTime() - new Date(b.Hora).getTime()
        ),
      allowedFields: new Array<string>()
    },
    {
      title: 'Controle de Lançamentos de Bastões',
      cols: stickCols,
      children: (
        <span className="text-gray font-bold">
          Total de bastões:{' '}
          <span className="text-body">
            {gridsData?.stick
              ?.map((it: any) => ({
                ...it,
                Campo: it.Campo == 'PIR' ? 'Pilar' : 'Furado'
              }))
              ?.filter((it: any) => it.Campo == tracker || it.campo == tracker)
              .reduce((acc: number, cur: any) => {
                return acc + Number(cur?.['Quantidade']);
              }, 0) ?? 0}
          </span>
        </span>
      ),
      data: gridsData?.stick
        ?.map((it: any) => ({
          ...it,
          Campo: it.Campo == 'PIR' ? 'Pilar' : 'Furado'
        }))
        ?.filter((it: any) => it.Campo == tracker || it.campo == tracker)
        ?.sort(
          (a: any, b: any) =>
            new Date(a?.['Hora de Lançamento']).getTime() -
            new Date(b?.['Hora de Lançamento']).getTime()
        ),
      allowedFields: new Array<string>()
    }
  ];

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const [isScrolling, setIsScrolling] = useState(false);
  const selectRef = useRef<any>(null);

  return (
    <PageWrapper>
      <div className="absolute w-72 right-16 -top-10">
        <SelectInputUncontrolled
          isMulti={false}
          className="z-[11]"
          placeholder="Ir para seção..."
          ref={selectRef}
          options={
            [
              ...bdoView
                .filter(it => it.type === 'label')
                .map(it => ({
                  value: it.id,
                  label: it.name
                })),
              {
                label: 'Registro de atividades',
                value: 'Equipe 1'
              },
              ...filterField(grids, tracker).map(it => ({
                value: it.title,
                label: it.title
              }))
            ] as any
          }
          onChange={(val: any) => {
            setIsScrolling(true);
            const targetDiv = document.getElementById(val.value);
            if (targetDiv) {
              targetDiv.scrollIntoView({ behavior: 'smooth' });
            }
            setTimeout(() => {
              setIsScrolling(false);
            }, 1000);
          }}
        />
      </div>

      <div
        onScroll={() => {
          if (selectRef.current.getValue() && !isScrolling)
            selectRef.current?.setValue('');
        }}
        className="w-full h-full overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full"
      >
        <div
          className="flex flex-col w-full flex-wrap gap-4 bg-white rounded-lg px-4 py-3"
          ref={componentRef}
        >
          <h2 className="text-2xl text-primary font-bold border-b border-primary pb-4 mb-2 w-full flex justify-between">
            BDO Campo - {tracker}
            <button
              onClick={handlePrint}
              className="text-base font-medium px-2 py-1 rounded border no-print"
            >
              Imprimir
            </button>
          </h2>

          <div className="flex bg-white rounded-lg w-full justify-between gap-4 flex-wrap">
            {cards.map(card => (
              <BdoCards key={card.title} title={card.title} data={card.data} />
            ))}
          </div>

          <div className="flex flex-wrap gap-8 gap-y-3 overflow-hidden w-full print-element-scroller">
            {bdoView.map(
              item =>
                bdoArray?.find((it: any) => it.id == item.id)?.value &&
                (item.type == 'label' ? (
                  <Label
                    name={item.name}
                    id={item.id}
                    key={item.id + (currentDateFormatted ?? '')}
                  />
                ) : item.type == 'grid' ? (
                  <TableGrid
                    title={item.name}
                    cols={item.columns}
                    key={item.id + (currentDateFormatted ?? '')}
                    data={Object.values(
                      convertGridToJson(
                        (bdoArray?.find((it: any) => it.id == item.id)
                          ?.value as string) ?? '{}'
                      ) ?? {}
                    )}
                  />
                ) : (
                  (item.field ? item.field == tracker : true) && (
                    <Input
                      id={item.id}
                      name={item.name}
                      key={item.id + (currentDateFormatted ?? '')}
                      value={
                        item.type === 'date' &&
                        data?.issues &&
                        dataEquip?.issues
                          ? format(
                              new Date(
                                (
                                  bdoArray?.find((it: any) => it.id == item.id)
                                    ?.value as string
                                )
                                  ?.split('+')[0]
                                  ?.replaceAll('-', '/')
                              ),
                              'dd/MM/yyyy'
                            )
                          : item.type == 'number'
                            ? new Intl.NumberFormat('pt-BR').format(
                                Number(
                                  bdoArray?.find((it: any) => it.id == item.id)
                                    ?.value
                                ) ?? ''
                              )
                            : ((bdoArray?.find((it: any) => it.id == item.id)
                                ?.value as string) ?? '')
                      }
                    />
                  )
                ))
            )}

            {equips.map(it => (
              <>
                <TableGrid
                  cols={it.cols}
                  data={it.data}
                  title={it.title}
                  key={it.title + currentDateFormatted}
                >
                  <div className="flex gap-8 mb-4">
                    <Input
                      id={it.title + it.resp2}
                      name={it.resp1label}
                      value={it.resp1}
                    />
                    <Input
                      id={it.title + it.resp1}
                      name={it.resp1label2}
                      value={it.resp2}
                    />
                  </div>
                </TableGrid>
              </>
            ))}

            {filterField(grids, tracker).map(it => (
              <TableGrid {...it} key={it.title + currentDateFormatted} />
            ))}
          </div>
        </div>
      </div>

      <SideFilter atom={BDOCalendarAtom} filters={[]}>
        <div className="flex flex-col gap-1">
          <h3 className="text-sm font-bold text-white">Data</h3>
          <Datepicker
            value={{
              startDate: currentDate
                ? new Date(currentDate.split('+')[0])
                : addDays(new Date(), -1),
              endDate: currentDate
                ? new Date(currentDate.split('+')[0])
                : addDays(new Date(), -1)
            }}
            asSingle={true}
            useRange={false}
            primaryColor={'sky'}
            onChange={(val: any) => {
              return setParams({
                date: val.startDate.replaceAll('-', '/'),
                tracker: tracker ?? 'Pilar'
              });
            }}
            i18n="pt-br"
            placeholder="Selecione uma data"
            inputClassName={
              'relative transition-all duration-300 h-[36px] pl-2 pr-10 w-full border-[1px] border-[#cccccc] dark:border-slate-600 rounded-[10px] tracking-wide font-light text-sm text-white placeholder-white bg-primary focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20'
            }
            maxDate={new Date()}
            classNames={{
              toggleButton() {
                return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
              }
            }}
          />
        </div>

        <div className="flex flex-col gap-1">
          <h3 className="text-sm font-bold text-white">Estação</h3>
          <Select
            className={`w-full rounded-lg font-medium`}
            options={[
              {
                value: 'Pilar',
                label: 'Pilar'
              },
              {
                label: 'Furado',
                value: 'Furado'
              }
            ]}
            onChange={(val: any) =>
              setParams({
                date: currentDate ?? '',
                tracker: val?.value ?? 'Pilar'
              })
            }
            placeholder="Selecione..."
            theme={(theme: any) => ({
              ...theme,
              borderRadius: 6,
              spacing: {
                ...theme.spacing,
                controlHeight: 34
              },
              colors: {
                ...theme.colors,
                primary: '#aabbff',
                neutral60: '#E5E5E5'
              }
            })}
            styles={selectInputStyles}
            components={{ ValueContainer }}
          />
        </div>
      </SideFilter>
    </PageWrapper>
  );
};
