import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const metanol: Column[] = [
  {
    Header: <Header text={'Hora Início'} id="Hora Início" />,
    accessor: '527',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Hora Fim'} id="Hora Fim" />,
    accessor: '528',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Ponto de injeção'} id="Ponto de injeção" />,
    accessor: '1348',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Tipo'} id="Tipo" />,
    accessor: '1124',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Volume (L)'} id="Volume (L)" />,
    accessor: '1071',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Vazão (L/h)'} id="Vazão (L/h)" />,
    accessor: '1510',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default metanol;
