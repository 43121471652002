import { twMerge } from 'tailwind-merge';

const defaultFractionDigits = 3;

const NumberCell = ({
  value,
  row: {
    original: { id, isSummary },
    index
  },
  digits,
  className
}: any) => {
  if (
    value === '-' ||
    value === null ||
    value === undefined ||
    Number.isNaN(value)
  ) {
    return (
      <span
        className={twMerge(
          'text-body-dark text-xs whitespace-nowrap',
          isSummary && 'summary'
        )}
      >
        -
      </span>
    );
  }
  const newValue = new Intl.NumberFormat('pt-BR', {
    maximumFractionDigits: digits || defaultFractionDigits,
    minimumFractionDigits: digits || defaultFractionDigits
  }).format(value !== '-' ? (value ?? 0) : 0);

  return (
    <span
      key={`${id}Pressure${index}`}
      className={twMerge(
        'text-body-dark text-xs whitespace-nowrap',
        isSummary && 'summary font-bold',
        (!isSummary && className) ?? ''
      )}
    >
      {newValue || '-'}
    </span>
  );
};

const NumberCellFC = (digits: number) => (e: any) => (
  <NumberCell {...e} digits={digits} />
);

// TODO: substitute the old numbercell to this generic
// number cell with max digit and min digit
const NumberCell2 = ({
  value,
  row: {
    original: { id, isSummary },
    index
  },
  maxDigit,
  minDigit = 0,
  className
}: any) => {
  if (
    value === '-' ||
    value === null ||
    value === undefined ||
    value === '' ||
    Number.isNaN(value)
  ) {
    return (
      <span
        className={twMerge(
          'text-body-dark text-xs whitespace-nowrap',
          isSummary && 'summary'
        )}
      >
        -
      </span>
    );
  }
  const newValue = new Intl.NumberFormat('pt-BR', {
    maximumFractionDigits: maxDigit || defaultFractionDigits,
    minimumFractionDigits: minDigit
  }).format(value !== '-' ? (value ?? 0) : 0);

  return (
    <span
      key={`${id}Pressure${index}`}
      className={twMerge(
        'text-body-dark text-xs whitespace-nowrap',
        isSummary && 'summary font-bold',
        (!isSummary && className) ?? ''
      )}
    >
      {newValue || '-'}
    </span>
  );
};

export { NumberCell, NumberCellFC, NumberCell2 };
