import Button from '../../components/atoms/Button';
import { loginRequest } from '../../auth/authConfig';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';
import Spinner from '../../components/atoms/Spinner';
import { twMerge } from 'tailwind-merge';

const Login = ({ redirectHome = true, isLoading = false }) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: 'create',
        redirectStartPage: window.location.href
      })
      .catch((error: any) => console.error(error));
  };

  if (isAuthenticated && redirectHome) {
    return <Navigate to="/home" />;
  }

  return (
    <div className="flex items-center justify-center h-screen bg-cover bg-[url(/login_background_workflow.jpg)]">
      <form
        className="
          flex flex-col 
          w-[30%] 
          items-center justify-center 
          gap-6 px-10 py-10
          bg-white
          rounded-2xl
          bg-contain
        "
        onSubmit={e => e.preventDefault()}
      >
        <img src="/logo.png" alt="Logo" className="w-1/2" />
        <h1
          className={twMerge(
            'text-xl font-bold text-[#3F3F3F] text-center max-w-[200px]',
            isLoading && 'max-w-[250px]'
          )}
        >
          {isLoading
            ? 'Seu usuário está sendo autenticado via SSO'
            : 'Faça o seu login com Origem SSO'}
        </h1>

        {isLoading ? (
          <Spinner />
        ) : (
          <Button
            onClick={handleRedirect}
            title="ACESSAR MINHA CONTA ORIGEM"
            className="mt-4 text-base"
            classNameSpan="px-4"
          />
        )}
      </form>
    </div>
  );
};

export default Login;
