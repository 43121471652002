import { useQuery } from '@tanstack/react-query';
import { getLastBDOEP } from '../../services/bdos.services';

export const useBdoProduction = (date?: string, tracker?: string) => {
  const {
    data: dataEP,
    isLoading,
    isFetching: isFetchingBdoSup
  } = useQuery(
    ['BDOProduction', tracker, date],
    () => getLastBDOEP(date, tracker),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  return {
    dataEP,
    bdoArray: dataEP?.issues?.[0] ? dataEP?.issues?.[0]?.custom_fields : [],
    isLoading,
    isFetchingBdoSup
  };
};
