import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FiAlertTriangle } from 'react-icons/fi';
import { GiWilliamTellSkull } from 'react-icons/gi';
import { workflowPhasesEnum } from '../../constants/enums/phases';
import { twMerge } from 'tailwind-merge';

interface TestWorkflowListItemProps {
  id: number;
  title: string;
  slaDate?: Date;
  isPending?: boolean;
  tags: (
    | string
    | {
        text: string;
        className: string;
      }
  )[];
  testPhase: number;
  date: {
    value?: Date;
    title: string;
  };
  obsIsPending?: string;
  phase: number[];
  urlRedirect?: string;
  alertIcon?: React.ReactNode;
  shortText?: string;
  hasShortText?: boolean;
}

const TestWorkflowListItem = ({
  id,
  title,
  slaDate,
  isPending,
  shortText,
  hasShortText,
  obsIsPending,
  testPhase,
  tags,
  date,
  alertIcon = <FiAlertTriangle size={16} color="#fff" fill="#FEDD00" />,
  urlRedirect
}: TestWorkflowListItemProps) => {
  // const [clockColor, setClockColor] = useState("");
  // const [clockText, setClockText] = useState("");

  // useEffect(() => {
  //   if (slaDate) {
  //     let color =
  //       format(slaDate, "yyyy-MM-dd hh mm") <
  //       format(new Date(), "yyyy-MM-dd hh mm")
  //         ? "red"
  //         : "green";
  //     setClockText(`SLA até: ${format(slaDate, "dd/MM/yyyy HH:mm")}`);

  //     if (
  //       phase.includes(workflowPhasesEnum.TesteProgramado) ||
  //       phase.includes(workflowPhasesEnum.ParametrizacaoTeste) ||
  //       phase.includes(workflowPhasesEnum.AlinhamentoCampo)
  //     ) {
  //       if (format(slaDate, "yyyy-MM-dd") == format(new Date(), "yyyy-MM-dd")) {
  //         color = "yellow";
  //       }
  //     }

  //     setClockColor(color);
  //   }
  // }, [slaDate]);

  const isInvalid = [
    workflowPhasesEnum.InvalidadoCPROD,
    workflowPhasesEnum.InvalidadoProducao
  ].includes(testPhase);

  return (
    <a
      className="flex justify-between items-center shadow-light rounded-lg px-4 py-3 gap-1"
      href={
        urlRedirect
          ? `${urlRedirect}/${id}`
          : `${import.meta.env.VITE_REDMINE_URL}/issues/${id}`
      }
      target="_blank"
    >
      <div className="flex w-1/4 justify-between">
        <span className="font-normal text-xs text-gray">#{id}</span>
      </div>

      <div className="flex w-2/5">
        <span className="font-bold text-xs text-gray flex items-center">
          {title}
          {isPending && (
            <>
              <div
                data-tooltip-id={`card-${id}`}
                className="ml-1"
                data-tooltip-target="tooltip-default"
              >
                {import.meta.env.DEV ? (
                  <GiWilliamTellSkull size={18} color="#666" />
                ) : (
                  alertIcon
                )}
              </div>
              <ReactTooltip
                id={`card-${id}`}
                place="top"
                variant="dark"
                content={obsIsPending}
              />
            </>
          )}
        </span>
      </div>

      <div className="flex w-1/3">
        <span className="font-bold flex-col text-xs text-gray flex">
          {date.value ? (
            <>
              <span className=" text-[#999] font-normal">{date.title}</span>
              <span className="text-xs">{date.value.toLocaleDateString()}</span>
            </>
          ) : (
            <span className="text-xs text-gray">-</span>
          )}
        </span>
      </div>

      {hasShortText ? (
        <>
          <span
            className=" text-[9px] w-1/2 text-gray/80"
            data-tooltip-id={`shortext-${id}`}
          >
            {shortText
              ? shortText.length > 90
                ? `${shortText.slice(0, 90)}...`
                : shortText
              : ''}
          </span>

          <ReactTooltip
            id={`shortext-${id}`}
            place="top"
            variant="dark"
            style={{
              maxWidth: '350px',
              fontSize: '12px'
            }}
            content={String(shortText)}
          />
        </>
      ) : null}

      {(tags.length > 0 || isInvalid) && (
        <div
          className="
                flex gap-1
                flex-wrap
                w-3/4
              "
        >
          {[...tags, isInvalid ? 'Invalidado' : undefined]
            .filter(it => it)
            .map((tag, idx) => (
              <span
                className={twMerge(
                  `rounded-[3px] px-2 py-[2px] text-[8px]`,
                  typeof tag === 'string'
                    ? idx == 0
                      ? 'bg-[#D7DFFF] text-primary'
                      : idx == 1
                        ? 'bg-[#FFE8D7] text-[#EC7100]'
                        : idx == 2
                          ? 'bg-[#F7D7FF] text-[#9605BA]'
                          : ''
                    : '',
                  typeof tag === 'string'
                    ? tag == 'Conjugado'
                      ? 'bg-[#A5A900]/20 text-[#A5A900]'
                      : tag == 'Invalidado' || tag == 'Cancelado'
                        ? 'bg-[#e6432a]/20 text-[#e6432a]'
                        : tag == 'Em espera'
                          ? 'bg-[#FFD800]/20 text-[#a39f32]'
                          : ''
                    : tag?.className
                )}
                key={`${id}${typeof tag === 'string' ? tag : tag?.text}`}
              >
                {typeof tag === 'string' ? tag : tag?.text}
              </span>
            ))}
        </div>
      )}

      {/* {clockColor !== '' ?
        <>
          <div
            data-tooltip-id={`clock-${id}`}
            data-tooltip-target="tooltip-default"
          >
            <Clock color={clockColor} />
          </div>
          <ReactTooltip
            id={`clock-${id}`}
            place="top"
            variant="dark"
            style={{ maxWidth: "250px", textAlign: "center" }}
            content={clockText}
          />
        </> : <div className="w-5 h-5"></div>
      } */}
    </a>
  );
};

export default TestWorkflowListItem;
