import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import EmptyMessage from '../../../components/atoms/EmptyMessage';
import { PageWrapper } from '../../../components/atoms/PageWrapper';
import Spinner from '../../../components/atoms/Spinner';
import PageNavigation from '../../../components/molecules/PageNavigation';
import { SideBarDateAndTracker } from '../../../components/organisms/SideBarDateAndTracker';
import Table from '../../../components/organisms/Table';
import bdmfDiaryAllocation from '../../../constants/tableColumns/bdmf/diaryAllocation';
import { useBdmfDailyAllocation } from '../../../hooks/BDMF/useBdmfAllocation';
import { useParams } from '../../../hooks/Common/useParams';
import { BDOCalendarAtom } from '../../../state/bdo.calendar.atom';
import H1 from '../../../components/atoms/H1';

export const BdmfDaily = () => {
  const { date, tracker } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 50;

  const { data, isLoading, isFetching } = useBdmfDailyAllocation({
    date,
    tracker: tracker ?? '',
    page: currentPage,
    perPage
  });

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const styles = `
    @media only print {
      @page { 
        size: A4!important;
      }
    }
  `;

  return (
    <PageWrapper className={'p-0'}>
      <style>{styles}</style>

      <div
        className="w-full h-full flex flex-col relative pt-4 px-2 rounded-lg bg-white bdmf-print mt-2"
        ref={componentRef}
      >
        <img
          src="/origem.png"
          alt="Logo Origem"
          className="w-[203px] resize-none max-w-none -ml-5 only-print"
        />

        <H1 className="px-4 whitespace-nowrap">
          <span className="flex gap-4">
            Alocação Diária - BDMF {tracker == 'pilar' ? 'Pilar' : 'Furado'}
            {isFetching ? <Spinner size={18} /> : null}
          </span>
          <button
            onClick={handlePrint}
            className="text-base font-medium px-2 py-1 rounded border no-print"
          >
            Imprimir
          </button>
        </H1>
        {isLoading ? (
          <Spinner size={24} />
        ) : (
          <section className="flex flex-col rounded-lg px-2 py-3 scroll-height-auto">
            <div className="flex w-full overflow-y-auto max-h-[600px] scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
              {!data || data?.data?.length < 1 ? (
                <EmptyMessage
                  message="Sem dados disponíveis"
                  className="min-h-[2rem] w-min"
                />
              ) : (
                <Table
                  columns={bdmfDiaryAllocation.map(it => {
                    if (it.accessor == 'id_teste') {
                      return {
                        ...it,
                        Cell: ({ value }: any) => {
                          return (
                            <a
                              className="text-primary text-xs font-bold"
                              href={`${
                                import.meta.env.VITE_REDMINE_URL
                              }/issues/${value}`}
                              target="_blank"
                            >
                              {value}
                            </a>
                          );
                        }
                      };
                    } else {
                      return it;
                    }
                  })}
                  data={data?.data}
                  headerClassName="sticky top-0 bg-white z-10"
                />
              )}
            </div>
            {!!data && (
              <PageNavigation
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                className="mt-3"
                totalPages={Math.ceil(data?.meta?.count / perPage)}
                perPage={perPage}
              />
            )}
          </section>
        )}
      </div>

      <SideBarDateAndTracker atom={BDOCalendarAtom} filters={[]} />
    </PageWrapper>
  );
};
