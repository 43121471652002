import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const nr13: Column[] = [
  {
    Header: <Header text={'Tipo de Registro'} id="Tipo de Registro" />,
    accessor: '1180',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Equipamento/ N° PSV'} id="Equipamento/ N° PSV" />,
    accessor: '1181',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: '712',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default nr13;
