import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const areaCert: Column[] = [
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: '112',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Cilindro'} id="Cilindro" />,
    accessor: '1516',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Densidade (kg/m³)'} id="Densidade (kg/m³)" />,
    accessor: '1076',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'C2 (% mol)'} id="C2 (% mol)" />,
    accessor: '1077',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'C5 (% mol)'} id="C5 (% mol)" />,
    accessor: '1078',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: (
      <Header text={'Etilmercaptan (mg/kg)'} id="Etilmercaptan (mg/kg)" />
    ),
    accessor: '1079',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: (
      <Header
        text={'Enxofre total - H2S (mg/kg)'}
        id="Enxofre total - H2S (mg/kg)"
      />
    ),
    accessor: '1080',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Corrosividade'} id="Corrosividade" />,
    accessor: '1081',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Status'} id="Status" />,
    accessor: '1075',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default areaCert;
