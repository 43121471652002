export const equipment = [
  `Pilar`,
  `Instalações Elétricas de Pilar`,
  `ECPIR-Estação Compressores Pilar`,
  `LABORATÓRIO DE FLUIDOS PILAR`,
  `Sist. BCIs_Geradores_Ar Comp_Prédios PIR`,
  `UPGN Alagoas`,
  `EPPIR-Estação Coletora de Pilar`,
  `EEGN-Estação de estocagem de gás natural`,
  `Furado`,
  `Instalações Elétricas de Furado`,
  `GASODUTO FURADO ROBALO`,
  `Sist. BCIs_Geradores_Ar Comp._Prédios FU`,
  `ESTAÇÃO COLETORA DE FURADO`,
  `Estação de Compr de Furado`,
  `ESTAÇÃO INJEÇÃO DE ÁGUA`,
  `Locação Furado`,
  `Área de Furado / Fazenda Tomada`,
  `Área de Anambé - ANB`,
  `Área de Cidade de São Miguel - CSMC`,
  `Área de Arapaçu - ARP`,
  `Área de São Miguel dos Campos - SMC`,
  `Área de Paru`,
  `Locação Pilar`,
  `UMTP - ALAGOAS`,
  `Unidade de Circulação de Água Quente AL`,
  `Unidade Operações com Arame Wireline AL`,
  `Área de Pilar/Rio Remédio`,
  `Área do ALS-32`,
  `Área de Fazenda Pau Brasil`,
  `Área de de Japuaçu- JAU`,
  `Dutos`,
  `Malha de Escoamento de Gás do ATP-AL`,
  `Malha de Petróleo de Alagoas`,
  `Equipamentos`,
  `POLO ALAGOAS`,
  `SIST. DISTRIBUIÇÃO ENERGIA ELÉTRICA - PIR`,
  `LINHA DISTRIBUIÇÃO 13,8KV`,
  `LINHA DISTRIBUIÇÃO 2,40KV`,
  `LINHA DISTRIBUIÇÃO 4,16KV`,
  `LINHA DISTRIBUIÇÃO 480V`,
  `LINHA TRANSMISSÃO 69KV`,
  `PAINEÍS ELÉTRICOS ILUMINAÇÃO PILAR/UPGN`,
  `SUBESTAÇÃO PILAR 69KV`,
  `SE-13.8kV - SUBESTAÇÃO PILAR 13.8kV`,
  `SE-125101 - SUBESTAÇÃO PILAR 4.16KV`,
  `PONTO DE ENTREGA/RECEPÇÃO GÁS`,
  `FILTRO GASALP UPGN`,
  `VASO COALESCEDOR`,
  `SIST. DE AR COMPRIMIDO (PIR)`,
  `COMPRESSORES AR C-513401A/B/C PILAR`,
  `C-513401A ATLAS COPCO - (PIR)`,
  `C-513401B ATLAS COPCO - (PIR)`,
  `C-513401C ATLAS COPCO - (PIR)`,
  `FT-513401 FILTRO DE AR - (PIR)`,
  `LINHA AR P/ ABASTECIMENTO ÓLEO CPIR-1~5`,
  `SECADOR AR COMPRIMIDO - PIR`,
  `C-513402A SECADOR "A" PILAR`,
  `C-513402B SECADOR "B" PILAR`,
  `S-513401C SECADOR "C" PILAR`,
  `S-513401D SECADOR "D" PILAR`,
  `V-513401 - VASO PULMÃO AR - PILAR`,
  `V-513403 VASO PRESSÃO AR COMP PIR`,
  `V-513404 VASO PRESSÃO AR COMP PIR`,
  `V-513405 VASO PRESSÃO AR COMP PIR`,
  `V-513406 VASO PRESSÃO AR COMP PIR`,
  `C-624001A CUB-1 COMPRESSOR GÁS ALTERNATI`,
  `V-513407 VASO PRESSÃO AR COMP PIR`,
  `V-513408 VASO PRESSÃO AR COMP PIR`,
  `V-513409 VASO PRESSÃO AR COMP PIR`,
  `C-624001B CUB-2 COMPRESSOR GÁS ALTERNATI`,
  `INSTALAÇÃO DE AR COMPRIMIDO (IAC) PILAR`,
  `PAINEL -  SISTEMA COMPRESSOR AR - PILAR`,
  `LN COMBATE À INCÊNDIO - PIR`,
  `SIST. GÁS LIFT / GEA / INJEÇÃO - PIR`,
  `MANIFOLD GÁS LIFT - PIR`,
  `LINHAS GÁS LIFT - SAT-05 (PILAR)`,
  `C-624002D INGERSOLL COMP GÁS ALTERNATIVO`,
  `LINHAS GÁS LIFT - SAT-69 (PILAR)`,
  `LINHAS GÁS LIFT - SAT-92 (PILAR)`,
  `LINHAS GÁS LIFT - SAT-07 (PILAR)`,
  `LINHAS GÁS LIFT - SAT-66 (PILAR)`,
  `LINHAS GÁS LIFT - GEA TOTAL (PILAR)`,
  `SIST. RESFRIAMENTO DE ÁGUA (PILAR)`,
  `TORRE ARREFECIM ALPINA ÁGUA INDUSTRIAL (PILAR)`,
  `TORRES ARREFECIMENTO ÁGUA INDUSTRIAL (PILAR)`,
  `BOMBA RECIRC ÁGUA TORRE RESF "A"`,
  `BOMBA RECIRC ÁGUA TORRE RESF "B"`,
  `BOMBA RECIRC ÁGUA TORRE RESF "C"`,
  `LINHAS ÁGUA REFRIGERAÇÃO - (PIR)`,
  `TORRE DE ARREFECIMENTO ÁGUA INDUSTRIAL "A"`,
  `TORRE DE ARREFECIMENTO ÁGUA INDUSTRIAL "B"`,
  `TORRE DE ARREFECIMENTO ÁGUA INDUSTRIAL "C"`,
  `SIST. COMPRESSSÃO DE GÁS - PIR`,
  `BOMBA ABASTECIMENTO ÓLEO COMPRESSORES (PILAR)`,
  `B-624001A/B BB ABAST RESER OL CPIR 1 À 5`,
  `B-624002 BB ABAST ÁGUA REFRIG CUB-1/2/3`,
  `BOMBA ABASTEC RESERVAT ÓLEO CPIR-1 À 5`,
  `BOMBA ABASTEC ÁGUA REFRIG CUB-1/2/3`,
  `DETECTORES DE GÁS`,
  `C-624003 CUB-3 COMPRESSOR GÁS ALTERNATIV`,
  `C-624002C INGERSOLL COMP GÁS ALTERNATIVO`,
  `C-624004B CPIR-1 COMP GÁS ALTERNAT BAIXA`,
  `C-624004A CPIR-2 COMP GÁS ALTERNAT BAIXA`,
  `C-624005C CPIR-3 COMP GÁS ALTERNAT MÉDIA`,
  `C-624005B CPIR-4 COMP GÁS ALTERNAT MÉDIA`,
  `C-624005A CPIR-5 COMP GÁS ALTERNAT MÉDIA`,
  `C-624006 CPIR-6 COMP GÁS ALTERNAT`,
  `C-624007B CPIR-7 MOTOR/COMPRESSOR LP2`,
  `C-624007C CPIR-8 MOTOR/COMPRESSOR LP1`,
  `C-624008A CPIR-9 MOTOR/COMPRESSOR HP`,
  `FILTROS CARTUCHO GÁS COMPRESSORES - PIR`,
  `FT-C-624002-01 FTR CART GÁS COMP INGER`,
  `FILTRO CARTUCHO GÁS COMP PIR-1 E 2`,
  `FILTRO CARTUCHO GÁS COMP PIR-3,4 E 5`,
  `FILTRO CARTUCHO GÁS COMP PIR-7,8 E 9`,
  `INSTALAÇÃO DE GÁS (IG) - PILAR`,
  `SISTEMA GNA-1 (PILAR) COMPRESSÃO`,
  `SISTEMA GNA-2 PIR (PRÓX SG-122101)`,
  `SIST. CARREG/DESCARREG OLEO/ÁGUA`,
  `AFLORAMENTOS GASODUTOS - PIR`,
  `LINHAS COMPRESSÃO DE GÁS - PIR`,
  `LINHA COMPRESSÃO GÁS DE BAIXA - PIR`,
  `LINHA COMPRESSÃO GÁS DE MÉDIA - PIR`,
  `LINHA SUCÇÃO COMPRESSOR INGERSOLL - PIR`,
  `LINHAS VÁLVULAS EMERGÊNCIA COMPRESSÃO - PIR`,
  `LINHA DE ALÍVIO CUB-3`,
  `PROTECAO CATÓDICA - PIR`,
  `DIVERSOS COMPRESSÃO GÁS - PIR`,
  `COMPUTADORES VAZÃO - FQI (PILAR)`,
  `FQIT-SG-122104/0 - FLOBOSS 3 PILAR`,
  `PONTES ROLANTES COMPRESSORES DE GÁS (PILAR)`,
  `TN-624001 PONTES ROLANTES COMPRESS CUB's`,
  `TN-624002 PONTE ROLANT COMPRESS CPIR-1~5`,
  `TN-624003 PONT ROLANTE COMPRES INGERSOLL`,
  `VASOS DEPURADOR GÁS  - PILAR`,
  `V-624001 DEPURADOR GÁS - PILAR`,
  `V-624002 DEPURADOR GÁS - PILAR`,
  `V-624003 DEPURADOR GÁS - PILAR`,
  `V-624004 DEPURADOR GÁS - PILAR`,
  `V-624005 DEPURADOR GÁS - PILAR`,
  `V-624006 DEPURADOR GÁS - PILAR`,
  `V-624007 DEPURADOR GÁS - PILAR`,
  `V-624008 DEPURADOR GÁS - PILAR`,
  `SISTEMA DO FLARE - PIR`,
  `VASO BLOW DOWN [PIR]`,
  `VASO PRESS GAS TOCHA QUEIMADOR [PIR]`,
  `PAINEL - SISTEMA DO FLARE [PIR]`,
  `PAINEL FLARE (TOCHA) PILAR`,
  `FLARE (TOCHA) ESTAÇÃO DE PILAR`,
  `LINHAS DISTRIB GÁS P/ INJEÇÃO EMED-12`,
  `SALA ACUMULADOR DE ENEG ELÉTRICA DO GÁS`,
  `SALA EQUIPAMENTOS DO ABRIGO-03 CUB'S`,
  `SALA EQUIPAMENTOS DO ABRIGO-04`,
  `SALA EQUIPAMENTOS DO ABRIGO-CPIR6`,
  `SALA EQUIPAMENTOS DO ABRIGO-07`,
  `SIST. TRAT ÁGUA POTÁVEL - PIR`,
  `BOMBAS ÁGUA CASTELO A/B - PILAR`,
  `BOMBA ÁGUA CASTELO "A"`,
  `BOMBA ÁGUA CASTELO "B"`,
  `FILTROS/BOMBAS DE ÁGUA POTÁVEL - PILAR`,
  `BOMBA DOSAD HIPOCLORITO FILTRO AGUA`,
  `BOMBA DOSADORA DIANODIC FILTRO AGUA`,
  `BOMBA DOSADORA PRODUTO QUIMICO`,
  `FILTRO ANTRÁCITO - FT-512101`,
  `FILTRO ÁGUA (FT-07) - FT-512102 - PILAR`,
  `TQ-512101-01 TQ AERAÇÃO`,
  `UDGN - TRAT. DE GÁS (PIR)`,
  `UDGN - BOMBA ALTERNATIVA DE GLICOL`,
  `UDGN - TORRE ABSORVEDORA GLICOL`,
  `UDGN - FILTRO CARTUCHO`,
  `UDGN - INSTALAÇÃO DE GÁS (IG)`,
  `UDGN - PERMUTADORES PILAR`,
  `UDGN - PAINEL PILAR`,
  `UDGN - DEPURADOR DE GÁS`,
  `UDGN - VASO EXPANSAO GLICOL`,
  `UDGN - VASO DRENAGEM LIQUIDOS`,
  `PSV ALMOXARIF. OPF COMP/OFICINA EXT`,
  `TUBULAÇÕES / INSTALAÇÕES ECPIR - PILAR`,
  `EQUIP. SEM TAGS - ECPIR`,
  `LABORATÓRIO DE FLUIDOS - PILAR`,
  `SIST. ENER. P/ TELECOMUNICAÇÕES`,
  `RF/BT-526401 RET/BCO BATERIA - TIC-PIR`,
  `PREDIOS DA ESTAÇÃO DE PILAR`,
  `EQUIP. SEG - PILAR`,
  `MISCELÂNEA - SEGURANÇA (EPPIR)`,
  `SISTEMA DE MANUTENÇÃO (OFICINA)`,
  `COMPRESSORES DE AR - SMS`,
  `C-513401A COMPRESSOR 1 DE AR - SMS`,
  `C-513401B COMPRESSOR 2 DE AR - SMS`,
  `C-513401C COMPRESSOR 3 DE AR - SMS`,
  `SIST. AR CONDICIONADO - EPPIR`,
  `BOMBAS CAPTAÇÃO ÁGUA PIR-13A/B`,
  `B-511201A - BOMBA CAPTACAO AGUA PIR-13A`,
  `B-511201B - BOMBA CAPTACAO AGUA PIR-13B`,
  `SIST. COMBATE À INCÊNDIO (PIR)`,
  `B-542001B- BOMBA DIESEL  PILAR`,
  `B-542301A/B- BOMBAS COMBATE  PILAR`,
  `B-542301A - COMBATE DIESEL`,
  `B-542301B - COMBATE ELÉTRICA`,
  `EXTINTOR COMBATE A INCÊNDIO - PILAR`,
  `HIDRANTE COMBATE A INCÊNDIO - PILAR`,
  `PAINEL -  SISTEMA COMBATE INCÊNDIO PILAR`,
  `TANQUE ÁGUA INCÊNDIO/INDUSTRIAL - PILAR`,
  `TANQUE SISTEMA DE ESPUMA  - PILAR`,
  `VÁLV BLOQ SIST COMBATE INCÊNDIO - PILAR`,
  `GERADOR EMERG. - EPPPIR`,
  `GERADOR DE EMERGÊNCIA - EPPIR`,
  `GERADOR DE EMERGÊNCIA - EST. JUNQ.`,
  `GE-526103 PIR`,
  `PLACA ORIF/TRECH RET DEPÓSITO USUÁRIO MI`,
  `FAIXA DE DUTOS - ATP/AL`,
  `FAIXA DE DUTO PARU - ENTR.FURADO_ROBALO`,
  `FAIXA DE DUTO FURADO-ROBALO`,
  `FAIXA DE DUTO CSMC17-ENTR.FURADO/ROBALO`,
  `FAIXA DE DUTO PILAR-FURADO`,
  `FAIXA DE DUTO SMC12-CSMC17`,
  `FAIXA DE DUTO ANAMBÉ-ENTR. PILAR_FURADO`,
  `FAIXA DE DUTO ARAPAÇU-ENTR.FURADO/ROBALO`,
  `ANÁLISE DE RESÍDUOS E FLUÍDOS - ATP/AL`,
  `INSPEÇÃO GEOLÓGICA GEOTÉCNICA - ATP/AL`,
  `GN-14 PILAR/FURADO (km 4,25) - PT 002`,
  `GN-14 PILAR/FURADO (km 19,7) - PT 003`,
  `GN-10 SMC-12/CSMC-17 (BX) PONTO-075`,
  `GN-10 SMC-12/CSMC-17 (MÉDIA) PONTO-009`,
  `GN-8 SMC-12/CSMC-17 (AL) PONTO-008`,
  `GN-14 CSMC-17/FURADO (BX) PONTO-099`,
  `GN-12-CSMC-17/FURADO (MÉDIA) PONTO-112`,
  `GN-10-CSMC-17/FURADO (AL) PONTO-113`,
  `PE-6 FURADO/PILAR - PONTO-081`,
  `GN-10 FURADO/ROBALO (válvula 2) - PT 096`,
  `GN-10 FURADO/ROBALO (válvula 6)- PT 007`,
  `GN-10 FURADO/ROBALO (válvula 8) - PT 017`,
  `GN-10 FURADO/ROBALO (válvula 4) - PT 006`,
  `PE-8 ANAMBÉ/FURADO - PONTO-095`,
  `GN-8 PARU / FUR-ROB (EST.) - PT 097`,
  `GN-8 PARU / FUR-ROB (ENTR.) - PT 098`,
  `GN-14-FURADO-PILAR (km 4,25-GS) PT 137`,
  `GN-14-FURADO/PILAR (KM 19,7-GS) PT 138`,
  `GN-10-SMC-12/CSMC17(BXA)(CSMC17-GI)PT139`,
  `GN-10-SMC-12/CSMC17(MÉD)(CSMC17-GI)PT140`,
  `GN-8-SMC-12/CSMC-17(ALT)(CSMC17-GI)PT141`,
  `GN-14-CSMC-17/FUR(BXA)(CSMC 17- GI)PT142`,
  `GN-12-CSMC-17/FUR(MÉD)(CSMC17-GI) PT 143`,
  `GN-10-FURADO/ ROBALO (VÁLV 2-GI) PT 144`,
  `GN-10-CSMC-17/FUR(ALT)(CSMC17-GI) PT 145`,
  `GN-10-FURADO/ ROBALO (VÁLV 4-GI) PT 146`,
  `GN-8-PARU(ALS-39)FU-ROB(EST.PAR-GS)PT147`,
  `GN-8-PARU (ALS-39)FUR-ROB(ENTR-GS) PT148`,
  `GN-10 FU/ROB (KM 40,33 válv 3) - PT 154`,
  `PIG INSTRUMENTADO - ATP/AL`,
  `RETIFICADORES SIST PROT CATÓDICA ATP-AL`,
  `CHUVEIROS LAVA-OLHOS (PILAR)`,
  `LAVA-OLHO INGERSOLL (PILAR)`,
  `PSV - NÃO LOCALIZADA`,
  `PSV - DEPÓSITO USUÁRIO MI`,
  `RAQUETE DEPÓSITO CALDEIRARIA MI`,
  `TUBULAÇÕES/INSTALAÇÕES EPPIR - PILAR`,
  `SIST. PROTEÇÃO PASSIVA (FIRE PROOF)`,
  `SIST. AR COMPRIMIDO - UPGN`,
  `C-513402A/B COMPRESSOR AR INSTRUMENT`,
  `C-513402A COMPESSOR AR INSTRUMENT`,
  `C-513402B COMPESSOR AR INSTRUMENT`,
  `V-513402 VASO AR PARTIDA MOTORES`,
  `FILTROS AR INSTRUMENTAÇÃO F-302A/B`,
  `FILTRO AR INSTRUMENTAÇÃO F-302A`,
  `FILTRO AR INSTRUMENTAÇÃO F-302B`,
  `COMPRESSOR AR INSTRUMENTAÇÃO K-301A/B`,
  `COMP AR INSTRUM K-301A (DESATIVADO)`,
  `COMP AR INSTRUM K-301B (DESATIVADO)`,
  `LINHAS DE AR INSTRUMENTAÇÃO`,
  `VASO PULMÃO AR INSTRUMENTAÇÃO V-301`,
  `FILTRO 301A`,
  `FILTRO 301B`,
  `V-301 - VASO AR INSTRUMENTAÇÃO`,
  `UPGN - SECADORES DE AR V-302A/B`,
  `SECADOR AR INSTRUMENTAÇÃO V-302A`,
  `SECADOR AR INSTRUMENTAÇÃO V-302B`,
  `UPGN - SECADORES DE AR V-303A/B`,
  `SECADOR AR INSTRUMENTAÇÃO V-303A`,
  `SECADOR AR INSTRUMENTAÇÃO V-303B`,
  `SIST. GERAÇÃO DE EMERGÊNCIA - UPGN`,
  `GERADOR DE EMERGÊNCIA DA UPGN`,
  `SIST. CARREGAMENTO DE GLP`,
  `BOMBA DE CARREGAMENTO DE GLP`,
  `BRAÇO CARREGAMENTO GLP (LAGE 1)`,
  `BRAÇO CARREGAMENTO GLP (LAGE-2)`,
  `COMPRESSOR CARREGAMENTO GLP C-6401`,
  `COMPRESSOR CARREGAMENTO GLP C-6402`,
  `LINHA DE CARREGAMENTO GLP P/ VASOS`,
  `PAINEL - CARREGA GLP UPGN`,
  `SISTEMA DE CARREGAMENTO GLP`,
  `SIST. ARMAZENAMENTO DE GLP`,
  `B-630002 REPROCESSAMENTO GLP`,
  `PERMUTADOR CALOR P-630001`,
  `LINHAS DO SISTEMA DE GLP`,
  `VASO ARMAZENAMENTO GLP V-500A`,
  `VASO ARMAZENAMENTO GLP V-500B`,
  `VASO ARMAZENAMENTO GLP V-500C`,
  `VASO ARMAZENAMENTO GLP V-503A`,
  `VASO ARMAZENAMENTO GLP V-503B`,
  `VASO ARMAZENAMENTO GLP V-503C`,
  `BACIA DE CONTENÇÃO - CILINDROS GLP - UPGN`,
  `SIST. ARMAZEN. DE PETRÓLEO - UPGN`,
  `TANQUE C5+/PETRÓLEO - TQ-80257`,
  `SIST. COMPRESSÃO DE GÁS`,
  `AIR COOLER RESERVA (K-201A/B/C) UPGN`,
  `COMPRESSOR GÁS K-201A`,
  `COMPRESSOR GÁS K-201B`,
  `COMPRESSOR GÁS K-201C`,
  `COMPRESSOR GÁS K-202A`,
  `COMPRESSOR GÁS K-202B`,
  `LINHAS SISTEMA COMPRESSÃO GÁS UPGN`,
  `PAINEL - COMPRESSÃO DE GÁS UPGN`,
  `PONTE ROLANTE - COMPRESSORES GÁS - UPGN`,
  `VASO V-225 GÁS NATURAL`,
  `B-621301 BOMBA CARREG GLP`,
  `SIST. FLARE - UPGN`,
  `LN SIST. FLARE: INSTR./DISP.`,
  `SIST. PROCESSAMENTO DE GLP`,
  `VASO ACUMULADOR ODORIZANTE V-522302`,
  `AIRCOOLER - UPGN`,
  `AIR COOLER AC-101 CONDENSADO TOPO T-103`,
  `AIR COOLER AC-102 - (C5+)`,
  `AIR COOLER AC-103 - REGENERAÇÃO GÁS`,
  `AIR COOLER AC-105 - GÁS RESIDUAL`,
  `DETECTORES DE GÁS - UPGN`,
  `PERMUTADORES DE CALOR - UPGN`,
  `PERMUTADOR E-102 - CONDENSADOR REFLUXO`,
  `PERMUTADOR E-103 - REFERVEDOR T-102`,
  `PERMUTADOR E-104 - REFERVEDOR T-103`,
  `PERMUTADOR E-106 - AQ GÁS REGENERAÇÃO`,
  `PERMUTADOR E-107 - PRÉ-AQUEC CARGA GÁS`,
  `PERMUTADOR E-108 - AQUECEDOR ENT LÍQUIDO`,
  `PERMUTADOR E-101A - GÁS`,
  `PERMUTADOR E-101B - GÁS`,
  `PERMUTADOR E-101C - GÁS`,
  `PERMUTADOR E-101B/C - LOR - GÁS V-104`,
  `FILTROS DE GÁS - UPGN`,
  `FILTRO F-101 - GÁS ENTRADA UPGN`,
  `FILTRO F-102 -GÁS APÓS PENEIRA MOLECULAR`,
  `FILTRO F-106 - ÓLEO QUENTE P/ V-108`,
  `FILTRO F-401 SULFATRITE`,
  `FORNO - ÓLEO TERMICO H-101`,
  `CAIXAS DE PASSAGEM - UPGN`,
  `COMPRESSOR TURBO EXPANSOR`,
  `AIR COOLER - ÓLEO LUBRIFICANTE AC-1 (K-101)`,
  `TURBO EXPANDER K-101`,
  `BOMBA ÓLEO LUBRIFICANTE P-1A (K-101)`,
  `BOMBA ÓLEO LUBRIFICANTE P-1B (K-101)`,
  `COMPRESSORES GÁS RESIDUAL K-105A/B`,
  `COMPRESSOR GÁS K-105A`,
  `COMPRESSOR GÁS K-105B`,
  `LINHAS DA UPGN`,
  `BOMBAS DA UPGN`,
  `MED ULTRASÔNICO METANOL (UPGN)`,
  `BOMBA REFLUXO T-101/102 (P-101A)`,
  `BOMBA REFLUXO T-101/102 (P-101B)`,
  `BOMBA DE REFLUXO DEBUTANIZAD P-102A`,
  `BOMBA DE REFLUXO DEBUTANIZAD P-102B`,
  `BOMBA CIRCULAÇÃO ÓLEO QUENTE P-103A`,
  `BOMBA CIRCULAÇÃO ÓLEO QUENTE P-103B`,
  `BOMBA DE INJEÇÃO DE METANOL P-104A`,
  `BOMBA DE INJEÇÃO DE METANOL P-104B`,
  `PAINEL ILUMIN - METANOL/ODORIZA - UPGN`,
  `SISTEMA SULFATREAT`,
  `TORRES DA UPGN`,
  `TORRE - DEMETANIZADORA (T-101)`,
  `TORRE - DEETANIZADORA (T-102)`,
  `TORRE - DEBUTANIZADORA (T-103)`,
  `PENEIRAS MOLECULAR V-103A & V-103B`,
  `PENEIRA MOLECULAR V-103A (VASO PRESSÃO)`,
  `PENEIRA MOLECULAR V-103B (VASO PRESSÃO)`,
  `VASOS DE PRESSÃO - UPGN`,
  `VASO PRESSÃO SP-123A`,
  `VASO PRESSÃO SP-123B`,
  `VASO PRESSÃO V-102 - RECEBE GÁS PILAR`,
  `VASO SEPARADOR V-104 - SUCÇÃO K-101`,
  `VASO V-105 ACUMULADOR LÍQ TORRE T-103`,
  `VASO REGENERAÇÃO DE GÁS V-107`,
  `VASO AQUECEDOR DE ÓLEO V-108`,
  `VASO V-109 - ACUMUL REFLUXO T-101`,
  `VASO PRESSÃO - V-110`,
  `VASO PRESSÃO V-101A - RECEBE GÁS FURADO`,
  `VASO PRESSÃO V-101B - RECEBE GÁS FURADO`,
  `VASOS PLUNGER LIFT`,
  `SIST. SUPERV. (AUTOM. DE SISTEMAS)`,
  `SALA EQUIPAMENTOS DO ABRIGO-05`,
  `PAINÉIS DELTA-V CONTROLE/INTERTRAVAMENTO`,
  `SIST. TRAT. PETRÓLEO - UPGN`,
  `B-6000-100A RECUP CX DRENO OLEOSO UPGN`,
  `B-6000-100B RECUP CX DRENO OLEOSO UPGN`,
  `PSV ALMOXARIF. OPF UPGN/OFICINA EXT`,
  `EPPIR`,
  `SIST. REPRESAS, BARRAGENS E DIQUES`,
  `SIST. REPR. BARR. E DIQUES - EPPIR`,
  `BOMBA HIDRÁULICA PORTÁTIL OP-PIR`,
  `MANOMETROS TESTE HIDROSTÁTICO - M&I`,
  `MANOMETROS TESTE HIDROSTÁTICO - C&M`,
  `SIST. ARMAZ. DE PETRÓLEO - PIR`,
  `PARQUE DE TANCAGEM PETRÓLEO (PIR)`,
  `TQ-631101 TANQUE ARMAZENAM PETRÓLEO PIR`,
  `TQ-631102 TANQUE ARMAZENAM PETRÓLEO PIR`,
  `TQ-631103 TANQUE ARMAZENAM PETRÓLEO PIR`,
  `TQ-631104 TANQUE ARMAZENAM PETRÓLEO PIR`,
  `TQ-631105 TANQUE ARMAZENAM PETRÓLEO PIR`,
  `TQ-631106 TANQUE ARMAZENAM PETRÓLEO PIR`,
  `TQ-631107 TANQUE ARMAZENAM PETRÓLEO PIR`,
  `TQ-631108 TANQUE ARMAZENAM PETRÓLEO PIR`,
  `TQ-631109 TANQUE ARMAZENAM PETRÓLEO PIR`,
  `TRENAS E TI'S DE MEDIÇÃO TANQUES - PILAR`,
  `DIQUES CONTENÇÃO - TANCAGEM - PILAR`,
  `SIST. RESERV. DE ÁGUA (PIR)`,
  `B-511501 - BOMBA LAVAGEM INDUSTRIAL PIR`,
  `SISTEMA DE ARMAZENAMENTO DE ÓLEO`,
  `SIST. COLETA POR DUTOS - MULTIFÁSICO (EPPIR)`,
  `V-122101/02/03/04 VASOS DEPURADORES GÁS`,
  `V-122101 VASO DEPURADOR GÁS`,
  `V-122102 VASO DEPURADOR GÁS`,
  `V-122103 VASO DEPURADOR GÁS`,
  `V-122104 VASO DEPURADOR GÁS`,
  `PSV RESERVA DOS DEPURADORES (V)`,
  `ALMOXARIFADO - PSV DA OP PILAR`,
  `B-122101A BOMBA CARREGAMENTO CARRETAS`,
  `B-122101B BOMBA CARREGAMENTO CARRETAS`,
  `INDICADOR PRESSAO MANIFOLD ÓLEO ESTAÇÃO`,
  `SIST. LANÇADOR/RECEBEDOR PIG`,
  `CAIXAS DE JUNÇÃO/PAINÉIS - SISTEMA ÓLEO`,
  `SEPARADORES DE ÓLEO PILAR`,
  `SG-122101 SEPARADOR`,
  `SG-122104 SEPARADOR ÓLEO`,
  `SG-122105 SEPARADOR ÓLEO`,
  `SG-122106 DEPURADOR GÁS`,
  `SG-122107 SEPARADOR CONDENSADO`,
  `SG-122108 SEPARADOR CONDENSADO`,
  `SG-122109 SEPARADOR ÓLEO/GÁS`,
  `SG-122110 SEPARADOR DE GÁS`,
  `SG-122111 SEPARADOR DE GÁS`,
  `SG-122102A SEPARADOR ÓLEO`,
  `SG-122102B SEPARADOR ÓLEO`,
  `SG-122103A SEPARADOR ÓLEO`,
  `SG-122103B SEPARADOR ÓLEO`,
  `PSV RESERVA DOS SEPARADORES (SG)`,
  `DIQUES CONTENÇÃO - SEPARADORES - PILAR`,
  `DIQUES CONTENÇÃO - MANIFOLD - PILAR`,
  `SIST. ESCOAMENTO PETRÓLEO`,
  `BOMBA A/B TRANSF PETROL P/ TRANSPETRO`,
  `BOMBA A TRANSF PETROL P/ TRANSPETRO`,
  `BOMBA B TRANSF PETROL P/ TRANSPETRO`,
  `INSTALAÇÃO DE ÓLEO (IO) - PILAR`,
  `SALA ACUM. ENEG ELÉTRICA DO ÓLEO`,
  `SALA EQUIPAMENTOS DO ABRIGO-01`,
  `SALA EQUIPAMENTOS DO ABRIGO-02`,
  `SALA EQUIPAMENTOS DO ABRIGO-08`,
  `SALA DE CONTROLE ÓLEO`,
  `SIST. TRATAMENTO PETRÓLEO`,
  `BOMBAS INIBIDOR DE INCRUSTAÇÃO`,
  `BOMBA INIBIDOR INCRUSTAÇÃO B-122102A`,
  `BOMBA INIBIDOR INCRUSTAÇÃO B-122103A`,
  `BOMBA INIBIDOR INCRUSTAÇÃO B-122104A`,
  `BOMBA INIBIDOR INCRUSTAÇÃO B-122105A`,
  `BOMBA INIBIDOR INCRUSTAÇÃO B-122106A`,
  `BOMBA INIBIDOR INCRUSTAÇÃO B-122107A`,
  `BOMBA INIBIDOR INCRUSTAÇÃO B-122108A`,
  `BOMBAS DE TRATAMENTO PILAR`,
  `BOMBA - DOSADORA DESEMULS/DISSOLVAN`,
  `BOMBA TRATAMENTO ÓLEO "A" - PILAR`,
  `BOMBA TRATAMENTO ÓLEO "B"  -PILAR`,
  `BOMBAS DA CAIXA API`,
  `B-533103 - BOMBA DRENO ÁG CX API PILAR`,
  `B-533101A BOMBA RECUP ÓLEO BROL-A CX AP`,
  `B-533101B BOMBA RECUP ÓLEO BROL-B CX API`,
  `B-533102A BOMBA RECUP ÁGUA BRAG-A CX API`,
  `B-533102B BOMBA RECUP ÁGUA BRAG-B CX API`,
  `RE-533101 REDUTOR ROLO RECUP ÓLEO CX API`,
  `VASO DEPURADOR DE GÁS - V-122201`,
  `CHUVEIROS LAVA-OLHOS CX API PILAR`,
  `TRATADOR ÓLEO TO-122201`,
  `TRATADOR ÓLEO TO-122202`,
  `TRATADOR OLEO TO-122203`,
  `TRATADOR ÓLEO TO-122204`,
  `TRATADOR ÓLEO TO-122206 (F. OPER)`,
  `TRATADOR ÓLEO TO-122207 (F. OPER)`,
  `TANQUE DA CAIXA API PILAR`,
  `DIQUES CONTENÇÃO - TRATADORES - PILAR`,
  `DIQUES CONTENÇÃO - CX API/CARREGA- PILAR`,
  `PSV ALMOXARIFADO EPPIR/OFICINA EXT`,
  `TUBULAÇÕES/INSTALAÇÕES - EPPIR`,
  `TERMÔMETROS ALMOXARIFADO - EPPIR`,
  `TRENAS ALMOXARIFADO (EPPIR)`,
  `EST. DE INJEÇÃO DE AG - EPPIR`,
  `BB SIST BACTERICIDA (PIR)`,
  `B-1263002 BOMBA BACTERIC INJ CHOQUE PIR`,
  `B-1263001A BOMBA INJ BACTERIC CHOQUE PIR`,
  `B-1263001B BOMBA INJ BACTERIC CHOQUE PIR`,
  `B-1263003A BOMBA INJ BACTERIC CONTÍN PIR`,
  `BOMBAS BACTERICIDA INJEÇÃO PILAR`,
  `B-1263004A BOMBA ANTI-INCRUSTANTE PIR`,
  `B-1263004B BOMBA ANTI-INCRUSTANTE PIR`,
  `B-1263005A BOMBA ANTIOXIDANTE PIR`,
  `B-1263005B BOMBA ANTIOXIDANTE PIR`,
  `B-1263006A BOMBA POLIELETRÓLITO PIR`,
  `B-1263006B BOMBA POLIELETRÓLITO PIR`,
  `TANQUE TQ-126301`,
  `TANQUE TQ-126302`,
  `TANQUE TQ-126303`,
  `TANQUE TQ-126304`,
  `TANQUE TQ-126305`,
  `TANQUE TQ-126306`,
  `SIST. TANQUES/BOMBAS INJEÇÃO AG`,
  `B-125111 BOMBA INJEÇÃO ÁGUA PRODUZIDA`,
  `B-125112 BOMBA INJEÇÃO ÁGUA PRODUZIDA`,
  `B-125113 BOMBA INJEÇÃO ÁGUA PRODUZIDA`,
  `B-125104A BOMBA DESC ÁGUA PRODUZI BDAG-A`,
  `B-125104B BOMBA DESC ÁGUA PRODUZI BDAG-B`,
  `BOMBAS DESCARTE/INJEÇÃO DE ÁGUA`,
  `B-125107A BOMBA DESCARTE ÁGUA`,
  `B-125107B BOMBA DESCARTE ÁGUA`,
  `B-125107C BOMBA DESCARTE ÁGUA`,
  `B-125108A BOMBA RETROLAVAGEM FILTROS`,
  `B-125108B BOMBA RETROLAVAGEM FILTROS`,
  `B-125109A BOMBA RETROLAVAGEM FILTROS`,
  `B-125109B BOMBA RETROLAVAGEM FILTROS`,
  `B-125110A BOMBA BOOSTER SIST. INJEÇÃO`,
  `B-125110B BOMBA BOOSTER SIST. INJEÇÃO`,
  `B-125110C BOMBA BOOSTER SIST. INJEÇÃO`,
  `B-125111/12/13 EQUIPAMENTOS COMUNS`,
  `B-125102A POLIELETROLITO/DISBUGAN`,
  `B-125102B POLIELETROLITO/DISBUGAN`,
  `B-125103 BOMBA SEQUESTRANTE DE OXIGÊNIO`,
  `B-125101A/B-01 BOMBA RETROLAVAG FILTROS`,
  `B-125101A/B-02 BB RETROLAVAGEM FILTROS`,
  `BOMBA DOSADORA FILTROS LAVAGEM`,
  `FT-125102 FILTRO LAVAGEM SIST. ÁGUA PROD`,
  `FT-125103 FILTRO LAVAGEM SIST. ÁGUA PROD`,
  `FT-125101A FILTRO LAVAGEM SIST ÁGUA PROD`,
  `FT-125101B FILTRO LAVAGEM SIST ÁGUA PROD`,
  `PSV RESERVA FILTRO RETROLAVAGEM PIR`,
  `LINHA DESCARGA DE ÁGUA BDAG-A/B`,
  `LANÇADOR DE PIG BDAG PIR-7`,
  `LINHA DE PRODUÇÃO MANIFOLD DE INJEÇÃO`,
  `LINHA P MANIFOLD INJEÇ - 4 POL-PCW-G11-1015`,
  `LINHA DE PRODUÇÃO PIR-148 LANÇADOR`,
  `LINHA DE PRODUÇÃO PIR-118 LANÇADOR`,
  `LINHA DE PRODUÇÃO PIR-69 LANÇADOR`,
  `LINHA DE PRODUÇÃO PIR-21 LANÇADOR`,
  `RECEBEDOR PIR - 148`,
  `RECEBEDOR PIR - 69`,
  `RECEBEDOR PIR - 118`,
  `RECEBEDOR PIR - 21`,
  `PAINEL - SISTEMA PIR-7`,
  `TANQUE TQ-125103`,
  `TANQUE TQ-125104`,
  `TANQUE TQ-125105`,
  `TANQUE TQ-125106`,
  `TANQUE TQ-125107`,
  `SIST. INJEÇÃO DE ÁGUA`,
  `ANALIZADOR BSW INJEÇÃO AG PROD (DESAT)`,
  `BOMBA INJEÇÃO AG PROD`,
  `SIST. DE TRAT ÁGUA PRODUZIDA`,
  `TUBULAÇÕES/INSTALAÇÕES INJ ÁGUA PILAR`,
  `SIST. COMPR DE GÁS NATURAL`,
  `SIST. ESTOC DE GÁS NATURAL`,
  `SIST. SEPARAÇÃO PRIMÁRIA`,
  `VASO SEPARADOR 01-XRZ-005`,
  `SIST. DISTRIBUIÇÃO ENERGIA ELÉTRICA - FU`,
  `LINHA DISTRIBUIÇÃO 2,40KV - FU`,
  `LINHA DISTRIBUIÇÃO 480KV - FU`,
  `LINHA TRANSMISSÃO  69KV - FU`,
  `PAINEÍS ELÉTRICOS ILUMINAÇÃO FURADO - FU`,
  `SUBESTAÇÃO FURADO 69KV`,
  `EQUIP. SEM TAGS - IEE`,
  `GASODUTO FURADO-ROBALO`,
  `GERAÇÃO ENERGIA TIC ATP-AL FU`,
  `GERAÇÃO ENERGIA TIC - BT/RF 526401 - FU`,
  `PREDIOS DA ESTAÇÃO DE FURADO`,
  `PREDIOS DA ESTAÇÃO - FU`,
  `EQUIP. SEG - FURADO`,
  `MISCELANEA - SEGURANÇA (FURADO)`,
  `SISTEMA DE MANUTENÇÃO (OFICINAS)`,
  `SISTEMA DE MANUTENÇÃO (OFICINAS) - FU`,
  `MAQUINAS OPERATRIZES - FU`,
  `CENTRAIS DE AR - FURADO`,
  `SIST. COMBATE A INCÊNDIO - FU`,
  `BOMBAS COMBATE A INCÊNDIO - FU`,
  `BOMBA ÁGUA ÁREA BCI - FU`,
  `BCI ELÉT - BEMELE - FU`,
  `BCI DIESEL BEMDIE1 - FU`,
  `BCI DIESEL BEMDIE2 - FU`,
  `EXTINTORES - COMBATE A INCÊNDIO FU`,
  `REDE DE HIDRANTE DE COMBATE A INCÊNDIO - FU`,
  `PAINEL ELÉT BCI - FU`,
  `TQ-542001/02 AGUA COMBATE INCEND FU`,
  `GERADORES DE EMERGÊNCIA - FU`,
  `GE-514401 - GERADOR EMERG FUR`,
  `GE-526102 - GERADOR EMERG (TIC)`,
  `GE-526103 - GERADOR EMERG (TIC) JUNQ`,
  `SISTEMA SEPARAÇÃO GÁS/ÓLEO - FU`,
  `SIST. SEPARAÇÃO GÁS/ÓLEO`,
  `DIQUE CONTENÇÃO - FU`,
  `SIST. ARMAZ PETRÓLEO - FU`,
  `PAINEL ELÉT ARMAZ DE PETROLEO - FU`,
  `TANQUES ARMAZ DE PETROLEO - FU`,
  `TANQUE RECEB PETRÓLEO TQ-631101 FU`,
  `TANQUE TESTE POÇO TQ-631102 FU`,
  `TANQUE ARMAZ PETRÓLEO TQ-631103 FU`,
  `TANQUE ARMAZ PETRÓLEO TQ-631104 FU`,
  `TANQUE TESTE POÇO TQ-631105 FU`,
  `TANQUE ARMAZ PETRÓLEO TQ-631106 FU`,
  `TANQUE ARMAZ PETRÓLEO TQ-631107 FU`,
  `TRENAS E TI'S DE MEDIÇÃO TANQUES FURADO`,
  `SIST. COLETA POR DUTOS - MULTIFÁSICO - FU`,
  `BOMBAS - CARREGAMENTO/DESCARREG - FUR`,
  `B-122101 CARREGM ÓLEO CARRS - FU`,
  `B-122102 BOMB DESCARREG AG CARRS - FU`,
  `BOMBA DOSADORAS PRODUTOS P/ SG'S - FU`,
  `VASOS - FU`,
  `V-122101 - FU`,
  `V-122102 - FU`,
  `V-122103 - FU`,
  `INSTALAÇÃO DE ÓLEO (PI) - FU`,
  `RECEBEDOR DE PIG - FU`,
  `RECEBEDOR ALTA - CSMC-17`,
  `RECEBEDOR MÉDIA - CSMC-17`,
  `RECEBEDOR BAIXA - CSMC-17`,
  `SEPARADOR DE GASES - FU`,
  `SEPARADOR DE GÁS SG-122101`,
  `SEPARADOR DE GÁS SG-122102`,
  `SEPARADOR DE GÁS SG-122103`,
  `SEPARADOR DE GÁS SG-122104`,
  `SEPARADOR DE GÁS SG-122105`,
  `SEPARADOR DE GÁS SG-122106`,
  `SIST. ESCOAMENTO DE PETRÓLEO`,
  `BOMBAS TRANSFERÊNCIA PETRÓLEO - FU`,
  `B-621101A-01 BTRF-A (FU)`,
  `B-621101B-01 BTRF-B (FU)`,
  `B-621101C-01 BTRF-C (FU)`,
  `RETIFICADOR PROTEÇÃO CATÓDICA - BTRF (FU)`,
  `SALA ACUM DE ENEG ELÉTRICA/CLP'S (FU)`,
  `SALA EQUIPAMENTOS ABRIGO-02 (FU)`,
  `SALA EQUIPAMENTOS ABRIGO-03 (FU)`,
  `SALA EQUIPAMENTOS ABRIGO-04 (FU)`,
  `SALA EQUIPAMENTOS ABRIGO-05 (FU)`,
  `SALA EQUIPAMENTOS ABRIGO-06 (FU)`,
  `SALA EQUIPAMENTOS ABRIGO-07 (FU)`,
  `SIST. TRATAMENTO DE ÁGUA (FU)`,
  `VASO RETROLAVAGEM V-1251001A TRAT AG`,
  `VASO RETROLAVAGEM V-1251001B TRAT AG`,
  `SIST. TRAT. DE PETRÓLEO`,
  `BOMBAS SIST. TRAT. DE PETRÓLEO - FU`,
  `B-122202 DOSAD. DESEMULS/DISSSOLV`,
  `B-122203A INIBIDOR CORROSÃO - FU/PIR`,
  `BOMBA INIBIDOR CORROSÃO - FU/ROBALO`,
  `B-122201A TRATAMENTO DE ÓLEO - FU`,
  `B-122201B TRATAMENTO DE ÓLEO - FU`,
  `BOMBAS RECUPERAÇÃO CAIXA API - FU`,
  `B-533101A (BROL-A) FU CX API`,
  `B-533101B (BROL-B) FU CX API`,
  `B-533102A (BDAG-A) FU CX API`,
  `B-533102B (BDAG-B) FU CX API`,
  `PAINEL CAIXA API (FU)`,
  `DEPURADOR GÁS - V-122201 (FU)`,
  `TRATADORES DE ÓLEO (FU)`,
  `LN GÁS COMB. P/ TRATADORES ÓLEO (FU)`,
  `TRATADOR DE ÓLEO TO-122201 (FU)`,
  `TRATADOR DE ÓLEO TO-122202 (FU)`,
  `TRATADOR DE ÓLEO TO-122203 (FU)`,
  `TQ-533101 TANQUE CAIXA API (FU)`,
  `PSV ALMOXARIFADO ESTFUR//OFICINA EXT`,
  `EQUIP. SEM TAGS (FU)`,
  `GN-14 - PILAR/FURADO`,
  `SIST. AR COMPRIMIDO`,
  `COMPRESSORES DE AR COMPRIMIDO`,
  `COMPRESSOR AR - ATLAS COPCO GA507 01A`,
  `COMPRESSOR AR - ATLAS COPCO GA507 01B`,
  `COMPRESSOR AR - ATLAS COPCO GA507 01C`,
  `SECADOR "A" DE AR FURADO`,
  `SECADOR "B" DE AR FURADO`,
  `COMPRESSOR AR COMPRIMIDO - C-513402A`,
  `COMPRESSOR AR COMPRIMIDO - C-513402B`,
  `FILTRO DE AR COMPRIMIDO FT-513401 FU`,
  `VASO PULMÃO - V-513401 FURADO`,
  `B-624002 BOMBA LAVAGEM COOLER COMPRES FU`,
  `VASOS DEPURADOR DE GÁS - FURADO`,
  `DEPURADOR DE GÁS - V-624001`,
  `DEPURADOR DE GÁS - V-624002`,
  `DEPURADOR DE GÁS - V-624003`,
  `DEPURADOR DE GÁS - V-624004`,
  `DEPURADOR DE GÁS - V-624005`,
  `DEPURADOR DE GÁS - V-624006`,
  `DEPURADOR DE GÁS - V-624007 SISTEMA GEA`,
  `DEPURADOR DE GÁS - V-624007`,
  `DEPURADOR DE GÁS - V-624008`,
  `DEPURADOR DE GÁS - V-624009`,
  `DETECTORES DE GÁS - FURADO`,
  `C-624002A CFU-05 BAIXA - COMPRESSOR GÁS`,
  `C-624002B CFU-06 BAIXA - COMPRESSOR GÁS`,
  `C-624002C CFU-07 BAIXA - COMPRESSOR GÁS`,
  `C-624001A CFU-08 MÉDIA - COMPRESSOR GÁS`,
  `C-624001B CFU-09 MÉDIA - COMPRESSOR GÁS`,
  `C-624004 - BOOSTER 01 - COMPRESSOR GÁS`,
  `C-624005 - BOOSTER 02 - COMPRESSOR GÁS`,
  `C-624006 - DEMAG - COMPRESSOR GÁS`,
  `C-624003A FU INGERSOLL A - COMPRES GÁS`,
  `C-624003B FU INGERSOLL B - COMPRES GÁS`,
  `C-624003C FU INGERSOLL-C - COMPRES GÁS`,
  `C-624008B MOTOR/COMPRES HP`,
  `COMPUTADORES VAZÃO - FQI (FURADO)`,
  `MANIFOLD/LINHAS COMPRESS GN (FURADO)`,
  `SISTEMA DE LAVA-OLHOS FU/RO/VISCO`,
  `HEADER - COMPRESSÃO GÁS`,
  `MANIFOLD (GEA-05/06/07/08) FURADO`,
  `LN COMPRESSÃO GÁS - PSV'S`,
  `PAINÉIS COMPRESSÃO (FURADO)`,
  `PONTE ROLANTE COMPRESSORES (FURADO)`,
  `SISTEMA FLARE - FURADO`,
  `VASO BLOW DOWN - FURADO`,
  `TOCHA - FURADO`,
  `SIST. INJEÇÃO DE GÁS`,
  `LN INJEÇÃO FU (ALTA)`,
  `LN INJEÇÃO FU (CLASSE 300#)`,
  `LN INJEÇÃO FU (CLASSE 600#)`,
  `UPS-552001/02/03 NO-BREAK (FURADO)`,
  `SIST. TRAT. DE ÁGUA - EPFUR`,
  `SISTEMA ÁGUA P/ COMPRESSÃO (FURADO)`,
  `BOMBA DOSADORA NALCO COMPRESS FURADO`,
  `BOMBA AGUA CASTELO ESTACAO FURADO`,
  `BOMBA "A" ABAST AGUA COOLERS COMP FUR`,
  `BOMBA "B" ABAST AGUA COOLERS COMP FUR`,
  `FILTRO ANTÁCITRO - FT-512101 - FURADO`,
  `UDGN [FUR]`,
  `UDGN [FUR] - B-123301A BOMBA GLICOL`,
  `UDGN [FUR] - B-123301B BOMBA GLICOL`,
  `UDGN [FUR] - FT-123301 A/B FILTROS`,
  `UDGN [FUR] - FT-123302 A/B FILTROS`,
  `UDGN [FUR] - PAINÉIS ELÉTRICOS`,
  `UDGN [FUR] - T-123301 TORRE ABSORVEDORA`,
  `UDGN [FUR] - T-123302 COLUNA DE DESTILAÇÃO`,
  `UDGN [FUR] - T-123303 COLUNA DE STRIPING`,
  `UDGN [FUR] - VASOS`,
  `UDGN [FUR] - V-123301 REFERVEDOR`,
  `UDGN [FUR] - V-123302 VASO DE EXPANSÃO DE GLICOL`,
  `UDGN [FUR] - V-123303 ACUMULADOR DE GLICOL`,
  `UDGN [FUR] - INSTALAÇÃO DE GÁS (IG)`,
  `PSV - ALMOX/OFICINA - COMPRESS FU`,
  `EQUIP. SEM TAGS - COMPRESS FU`,
  `BB DOSADORAS DE INJ ÁGUA - FU`,
  `BOMBA DOSADORAS PROD QUIMICO - FURADO`,
  `B-126301A BACTERICIDA INJEÇÃO FU`,
  `B-126301B BACTERICIDA INJEÇÃO FU`,
  `B-126302A BACTERICIDA INJEÇÃO FU`,
  `B-126302B BACTERICIDA INJEÇÃO FU`,
  `B-126303A ANTI-INCRUSTANTE INJEÇÃO FU`,
  `B-126303B ANTI-INCRUSTANTE INJEÇÃO FU`,
  `B-126304A ANTI-OXICIDANTE INJEÇÃO FU`,
  `B-126304B ANTI-OXICIDANTE INJEÇÃO FU`,
  `B-126305A POLIELETRÓLITO INJEÇÃO FU`,
  `B-126305B POLIELETRÓLITO INJEÇÃO FU`,
  `B-126306A POLIELETRÓLITO INJEÇÃO FU`,
  `B-126306B POLIELETRÓLITO INJEÇÃO FU`,
  `SIST. BOMBEIO DE ÁGUA P/ INJEÇÃO`,
  `BOMBA INJEÇÃO DE ÁGUA 01 - FU`,
  `BOMBA INJEÇÃO DE ÁGUA 02 - FU`,
  `BOMBA INJEÇÃO DE ÁGUA - FU-60`,
  `TANQUES SIST. INJEÇÃO DE ÁGUA DOCE`,
  `BOMBA ALIMENTAÇÃO FILTROS B-1251001A FU`,
  `BOMBA ALIMENTAÇÃO FILTROS B-1251001B FU`,
  `BOMBA INJEÇÃO ÁG SALG (BINAS-1) - FU`,
  `BOMBA INJEÇÃO ÁG SALG (BINAS-2) - FU`,
  `BOMBA INJEÇÃO ÁG SALG (BINAS-3) SCHUMBLE - FU`,
  `BB. RETROLAVAGEM FILTROS B-1251002A - FU`,
  `BB. RETROLAVAGEM FILTROS B-1251002B - FU`,
  `BOMBA BOOSTER B-1251003A - FU`,
  `BOMBA BOOSTER B-1251003B - FU`,
  `BOMBA B-125114 INJEÇÃO DE ÁGUA FU`,
  `FILTROS INJEÇÃO DE ÁGUA FU`,
  `FILTRO INJEÇÃO FT-125101A - FU`,
  `FILTRO INJEÇÃO FT-125101B - FU`,
  `INSTALAÇÃO DE ÁGUA (IA) - FU`,
  `MANIFOLD INJEÇÃO DE ÁGUA LP/RP - FU`,
  `BOMBA DOSADORA SIST. ÁGUA FU`,
  `TANQUE DE FLOTAÇÃO FU`,
  `TANQUE DE ÁGUA DOCE TQ-125102 FU`,
  `TANQUE DE ÁGUA DOCE TQ-125103 FU`,
  `TANQUE DE AGUA TRATADA TQ-1251004 FU`,
  `TANQUE DE RETROLAVAGEM TQ-125106 FU`,
  `TANQUE DE RETROLAVAGEM TQ-125107 FU`,
  `EQUIP. SEM TAGS - FU`,
  `POÇOS FU / FTD`,
  `1-FU-1-AL`,
  `3-FU-2-AL`,
  `1-FTD-1-AL`,
  `3-FU-4-AL`,
  `3-FTD-2-AL (DESATIVADO)`,
  `3-FU-5-AL`,
  `3-FTD-3-AL`,
  `3-FTD-4-AL`,
  `3-FTD-5-AL`,
  `4-FU-3-AL`,
  `3-FU-6-AL`,
  `3-FU-7-AL`,
  `3-FTD-6-AL`,
  `4-FU-8-AL`,
  `3-FU-9-AL`,
  `7-FU-10D-AL`,
  `7-FU-11D-AL`,
  `3-FTD-7-AL`,
  `7-FU-13-AL`,
  `3-FU-12-AL`,
  `3-FTD-8-AL`,
  `3-FTD-9-AL`,
  `7-FU-14-AL`,
  `7-FU-15-AL`,
  `7-FU-16D-AL`,
  `7-FU-17-AL`,
  `7-FU-18-AL`,
  `7-FU-19-AL`,
  `7-FU-20-AL`,
  `4-FU-21-AL`,
  `7-FU-22-AL`,
  `7-FU-23-AL`,
  `7-FU-24-AL`,
  `7-FU-25-AL`,
  `7-FU-26-AL`,
  `7-FU-27-AL`,
  `7-FU-29-AL`,
  `7-FU-28-AL`,
  `7-FU-30-AL`,
  `7-FU-31-AL`,
  `7-FU-32-AL`,
  `7-FU-33-AL`,
  `7-FU-34-AL`,
  `7-FU-35-AL`,
  `4-FU-36-AL`,
  `4-FU-37-AL`,
  `7-FU-38D-AL`,
  `7-FU-39-AL`,
  `7-FU-40-AL`,
  `7-FU-41-AL`,
  `7-FU-42-AL`,
  `7-FU-43-AL`,
  `7-FU-44D-AL`,
  `7-FU-45-AL`,
  `7-FU-46-AL`,
  `7-FU-47D-AL`,
  `7-FU-48-AL`,
  `7-FU-49-AL`,
  `7-FU-50-AL`,
  `7-FU-51-AL`,
  `7-FU-52-AL`,
  `3-FU-53-AL`,
  `7-FU-54-AL (DESATIVADO)`,
  `7-FU-55-AL`,
  `7-FU-56-AL`,
  `7-FU-57-AL`,
  `7-FU-58-AL`,
  `8-FU-59-AL`,
  `8-FU-60-AL`,
  `7-FU-61D-AL`,
  `3-FU-62-AL`,
  `3-FU-63-AL`,
  `3-FU-64-AL`,
  `7-FU-65-AL`,
  `7-FU-66-AL`,
  `7-FU-67-AL`,
  `7-FU-68-AL`,
  `4-FU-69-AL`,
  `7-FU-70-AL`,
  `7-FU-71-AL`,
  `7-FU-72-AL`,
  `7-FU-73-AL (DESATIVADO)`,
  `7-FU-74-AL (DESATIVADO)`,
  `7-FU-75D-AL`,
  `7-FU-76-AL (DESATIVADO)`,
  `7-FU-77-AL (DESATIVADO)`,
  `7-FU-78-AL`,
  `7-FU-79D-AL`,
  `7-FU-80-AL`,
  `3-FU-81-AL`,
  `4-FU-82-AL`,
  `7-FU-83-AL`,
  `7-FU-84-AL`,
  `7-FU-85D-AL`,
  `7-FU-86D-AL`,
  `7-FU-87-AL`,
  `7-FU-88D-AL`,
  `7-FU-89-AL`,
  `7-FU-89A-AL`,
  `3-FU-90-AL`,
  `3-FU-91-AL`,
  `7-FU-92-AL`,
  `7-FU-93-AL`,
  `7-FU-94-AL`,
  `7-FU-95-AL`,
  `7-FU-96-AL`,
  `7-FU-97-AL`,
  `4-FU-98-AL`,
  `1-FP-1-AL`,
  `1-FP-1A-AL`,
  `3-FU-99-AL`,
  `7-FU-100-AL`,
  `7-FU-101-AL`,
  `7-FU-102-AL (DESATIVADO)`,
  `7-FU-103-AL`,
  `7-FU-104-AL`,
  `7-FU-105-AL`,
  `7-FU-106-AL`,
  `7-FU-107-AL`,
  `7-FU-108-AL`,
  `7-FU-109-AL`,
  `1-RFA-1-AL (DESATIVADO)`,
  `7-FU-110D-AL`,
  `7-FU-111D-AL`,
  `7-FU-112-AL`,
  `7-FU-113D-AL`,
  `7-FU-114D-AL`,
  `7-FU-115-AL (DESATIVADO)`,
  `7-FU-116-AL (DESATIVADO)`,
  `7-FU-117-AL`,
  `7-FU-118-AL (DESATIVADO)`,
  `7-FU-119-AL`,
  `7-FU-120-AL`,
  `7-FU-121-AL`,
  `7-FU-122-AL`,
  `7-FU-123-AL`,
  `7-FU-124-AL`,
  `7-FU-125-AL (DESATIVADO)`,
  `7-FU-126-AL`,
  `1-FP-2D-AL`,
  `7-FU-127-AL`,
  `7-FU-128-AL`,
  `4-FU-129-AL`,
  `7-FU-130-AL`,
  `3-FU-131-AL`,
  `9-FU-132-AL`,
  `7-FU-134D-AL`,
  `7-FU-133D-AL`,
  `1-FJU-1D-AL (DESATIVADO)`,
  `7-FU-135D-AL`,
  `7-FU-136D-AL`,
  `7-FU-137-AL`,
  `7-FU-138D-AL`,
  `1-ROT-1-AL (DESATIVADO)`,
  `1-URO-1D-AL (DESATIVADO)`,
  `1-SR-2D-AL (DESATIVADO)`,
  `7-FU-139D-AL`,
  `7-FU-140D-AL`,
  `7-FU-141-AL`,
  `7-FU-142D-AL`,
  `7-FU-143D-AL`,
  `7-FU-144D-AL`,
  `1-FTA-1D-AL (DESATIVADO)`,
  `7-FU-143DA-AL`,
  `7-FU-145D-AL (DESATIVADO)`,
  `7-FU-146D-AL`,
  `1-LBO-1D-AL`,
  `3-FTA-2D-AL (DESATIVADO)`,
  `7-FU-147D-AL`,
  `3-FTA-3-AL (DESATIVADO)`,
  `7-FU-148-AL (DESATIVADO)`,
  `7-FU-150D-AL`,
  `7-FU-152D-AL`,
  `7-FU-151D-AL`,
  `7-FU-153D-AL`,
  `5-MUT-1-AL (DESATIVADO)`,
  `7-FU-155D-AL`,
  `7-FU-154D-AL`,
  `1-SMC-2-AL`,
  `7-FU-157D-AL`,
  `7-FU-158D-AL`,
  `7-FU-159D-AL`,
  `7-FU-160D-AL`,
  `7-FU-161D-AL`,
  `7-FU-162D-AL`,
  `7-FU-165D-AL`,
  `7-FU-166D-AL`,
  `7-FU-163D-AL`,
  `7-FU-164D-AL`,
  `1-BLM-1-AL (DESATIVADO)`,
  `1-VIC-1D-AL (DESATIVADO)`,
  `1-VRC-1D-AL (DESATIVADO)`,
  `6-VIC-2DP-AL (DESATIVADO)`,
  `7-FU-167D-AL`,
  `7-FU-169D-AL`,
  `7-FU-168D-AL`,
  `7-FU-170D-AL`,
  `MANÔMETROS OPERACIONAIS - FURADO-CAMPO`,
  `LINHAS DE PRODUÇÃO - FU`,
  `ESTAÇÃO FURADO - GAS LIFT`,
  `LN PROD - EPFUR`,
  `LINHAS PRODUÇÃO [FUR] - ESTAÇÃO &lt;-&gt; POÇOS`,
  `LINHAS PRODUÇÃO [FUR] - POÇOS &lt;-&gt; POÇOS`,
  `LINHAS PRODUÇÃO [FUR] - POÇOS &lt;-&gt; SATELITE`,
  `RECEBEDOR DE PIG - ENTRONC GN FURADO-ROBALO`,
  `SATÉLITE 06 (FTD 06)`,
  `SATÉLITE FUR-07`,
  `SATÉLITE FUR-11`,
  `SATÉLITE FUR-16`,
  `SATÉLITE FUR-22`,
  `SATÉLITE FUR-40`,
  `SATÉLITE FUR-49`,
  `SATÉLITE FUR-50`,
  `SATÉLITE FUR-51`,
  `SATÉLITE FUR-85`,
  `SATELITES FURADO`,
  `LINHAS: SATELITES &lt;-&gt; ESTAÇÃO FURADO`,
  `LINHAS GL: SATELITES &lt;-&gt; POÇOS FURADO`,
  `LINHAS PO: SATELITES &lt;-&gt; SATELITES FURADO`,
  `SATÉLITE FUR-122`,
  `SATÉLITE FUR-129`,
  `SATÉLITE FUR-137`,
  `ESTAÇÃO DE ANAMBÉ`,
  `SIST. SEG. INDUSTRIAL - ANAMBÉ`,
  `EXTINTORES - COMBATE A INCÊNDIO - ANANBÉ`,
  `INSTALAÇÃO DE ÓLEO (IO)- ANAMBÉ`,
  `TQ-6311 TANQUES ARMAZ/TESTE ANAMBÉ`,
  `SIST. COLETA POR DUTOS - MULTIFÁSICO`,
  `LINHAS GÁS LIFT - POÇOS ANB/FRO`,
  `SG-122101 SEPARADOR DE ÓLEO - POÇO ANB-1`,
  `TQ-1221 - TANQUE ARMAZ/TESTE ANAMBÉ`,
  `TQ-122101 TANQUE ARMAZ/TESTE ANAMBÉ`,
  `TQ-122102 TANQUE ARMAZ/TESTE ANAMBÉ`,
  `TQ-122103 TANQUE ARMAZ/TESTE ANAMBÉ`,
  `EQUIP. SEM TAGS - ANAMBÉ`,
  `POÇOS ANAMBÉ`,
  `1-FRO-3D-AL`,
  `7-ANB-1D-AL`,
  `7-ANB-2D-AL`,
  `7-ANB-3-AL`,
  `7-ANB-4D-AL`,
  `7-ANB-5D-AL (DESATIVADO)`,
  `1-FRO-4D-AL`,
  `7-ANB-6DP-AL`,
  `7-ANB-7D-AL`,
  `3-FRO-5D-AL (DESATIVADO)`,
  `3-FRO-6-AL (DESATIVADO)`,
  `7-ANB-8-AL`,
  `7-ANB-9-AL`,
  `7-ANB-10D-AL`,
  `7-ANB-11-AL`,
  `7-ANB-12D-AL`,
  `7-ANB-13D-AL`,
  `7-ANB-14D-AL`,
  `7-ANB-15D-AL`,
  `7-ANB-16D-AL`,
  `3-ANB-17D-AL`,
  `1-OSMC-1-AL (DESATIVADO)`,
  `LINHAS DE PRODUÇÃO DE ANAMBÉ - SAT 01/03`,
  `CAMPO CIDADE SÃO MIGUEL (DESATIVAD)`,
  `CSMC SIST. COLETA DUTOS - MULTIF | LP/RP`,
  `CSMC - RECEBEDOR DE PIGS (DUPLICIDADE)`,
  `CSMC - SEPARADOR DE GAS (DESATIVADO)`,
  `CSMC - EQUIP. SEM TAGS`,
  `ESTAÇÃO COLETA CIDADE S.MIGUEL CAMPOS`,
  `DIQUE CONTENÇÃO (CSMC-17)`,
  `SIST. COMBATE A INCÊNDIO (CSMC-17)`,
  `INSTALAÇÃO DE ÓLEO (IO) - CSMC-17`,
  `TQ. ARMAZENAMENTO DE PETROLEO - CSMC-17`,
  `SIST. COLETA MULTIFÁSICO - CSMC-17`,
  `VASO DEPURADOR GÁS - CSMC-17`,
  `MANIFOLD GÁS ALTA - PSV (CSMC-17)`,
  `MANIFOLD ALTA - CSMC-17`,
  `MANIFOLD GÁS ALTA - CSMC-17 (PSV)`,
  `PAINEIS - CSMC-17`,
  `RECEBEDOR DE PIGS - CSMC-17`,
  `SEPARADORES DE GÁS - CSMC-17`,
  `SG-122101 SEPARADOR DE GÁS - CSMC-17`,
  `SG-122102 SEPARADOR DE GÁS - CSMC-17`,
  `SG-122103 SEPARADOR DE GÁS - CSMC-17`,
  `TANQUES - CSMC-17`,
  `TQ-122101 TANQUE ARMAZ PETRÓLEO CSMC-17`,
  `TQ-122101 ARMAZ PETRÓLEO - CSMC-17`,
  `TQ-122102P ARMAZ PETRÓLEO - CSMC-17`,
  `TQ-122103P ARMAZ PETRÓLEO - CSMC-17`,
  `TQ-122102 TANQUE ARMAZ PETRÓLEO CSMC-17`,
  `TQ-122102 ARMAZ PETRÓLEO - CSMC-17`,
  `TQ-122103 TANQUE ARMAZ PETRÓLEO CSMC-17`,
  `TQ-122103 CSMC-17 - TANQUE DE TESTE`,
  `EQUIPAMENTOS SEM TAGS (CSMC-17)`,
  `POÇOS CSMC`,
  `1-CSMC-1-AL`,
  `3-CSMC-2-AL`,
  `3-CSMC-3-AL`,
  `3-CSMC-5-AL`,
  `4-CSMC-4-AL`,
  `3-CSMC-6-AL`,
  `3-CSMC-8-AL`,
  `5-CSMC-9-AL`,
  `3-CSMC-7-AL`,
  `3-CSMC-10-AL`,
  `3-CSMC-11-AL`,
  `7-CSMC-12-AL`,
  `7-CSMC-13-AL`,
  `3-CSMC-14-AL`,
  `7-CSMC-15-AL`,
  `7-CSMC-16-AL`,
  `1-SMC-1-AL`,
  `7-CSMC-18-AL`,
  `7-CSMC-19-AL`,
  `7-CSMC-20-AL`,
  `7-CSMC-21-AL (DESATIVADO)`,
  `7-CSMC-22-AL`,
  `7-CSMC-23-AL`,
  `7-CSMC-24-AL`,
  `7-CSMC-25-AL`,
  `4-CSMC-26-AL`,
  `7-CSMC-27-AL`,
  `7-CSMC-28-AL`,
  `7-CSMC-29-AL`,
  `3-CSMC-30-AL`,
  `7-CSMC-31-AL`,
  `7-CSMC-32-AL`,
  `7-CSMC-33-AL (DESATIVADO)`,
  `7-CSMC-34-AL`,
  `7-CSMC-35-AL`,
  `7-CSMC-36-AL`,
  `3-CSMC-37D-AL (INATIVO)`,
  `7-CSMC-38-AL`,
  `7-CSMC-39D-AL`,
  `LINHAS PRODUÇÃO PG/PO - CSMC-17`,
  `CAMPO PRODUÇÃO DE ARAPAÇU`,
  `ÁREA DE ARAPAÇU - ARP`,
  `1-BEL-1-AL`,
  `ESTAÇÃO COLETORA (SMC-12)`,
  `SIST. DE REPR. BARR. E DIQUES (SMC-12)`,
  `SIST. DE COMBATE INCÊNDIO (SMC-12)`,
  `SIST. COLET. DUTOS-MULTIFÁSICO (SMC-12)`,
  `MANIFOLD DE GÁS - SMC-12`,
  `LANÇADOR PIG DE GÁS/ÓLEO - SMC-12`,
  `VASO SG-122101 SMC-12`,
  `VASO SG-122102 SMC-12`,
  `TANQUES ARMAZ PETRÓLEO - SMC-12`,
  `TERMÔMETROS DE MEDIÇÃO SMC-12`,
  `TANQUES SMC-12`,
  `TQ-122101 TANQUE ARMAZ PETRÓLEO SMC-12`,
  `TQ-122103 - SMC-12 - TANQUE`,
  `EQUIP. SEM TAGS - SMC`,
  `POÇOS SMC`,
  `1-SMC-3-AL`,
  `1-SMC-4-AL`,
  `3-SMC-5-AL`,
  `3-SMC-6-AL`,
  `3-SMC-7-AL (DESATIVADO)`,
  `3-SMC-9-AL`,
  `7-SMC-11-AL`,
  `4-SMC-10-AL`,
  `3-SMC-8-AL`,
  `7-SMC-12-AL`,
  `7-SMC-13-AL`,
  `7-SMC-14-AL`,
  `7-SMC-14D-AL`,
  `4-SMC-15-AL`,
  `7-SMC-16-AL`,
  `3-SMC-17-AL`,
  `7-SMC-18-AL`,
  `4-SMC-19-AL`,
  `7-SMC-20-AL`,
  `7-SMC-21-AL`,
  `7-SMC-22-AL`,
  `4-SMC-24-AL`,
  `3-SMC-23-AL`,
  `7-SMC-25-AL`,
  `4-SMC-26-AL`,
  `7-SMC-22A-AL`,
  `7-SMC-27-AL`,
  `7-SMC-28-AL`,
  `7-SMC-29-AL`,
  `7-SMC-30D-AL`,
  `7-SMC-31-AL`,
  `7-SMC-32-AL (DESATIVADO)`,
  `7-SMC-33-AL`,
  `7-SMC-34-AL`,
  `7-SMC-35-AL`,
  `4-SMC-36-AL`,
  `7-SMC-37-AL`,
  `7-SMC-38-AL`,
  `7-SMC-39-AL`,
  `7-SMC-40-AL`,
  `7-SMC-41-AL`,
  `7-SMC-42-AL`,
  `7-SMC-43-AL`,
  `4-SMC-44-AL (DESATIVADO)`,
  `3-SMC-45D-AL`,
  `3-SMC-47D-AL`,
  `3-SMC-48D-AL (DESATIVADO)`,
  `7-SMC-46D-AL (INATIVO)`,
  `7-SMC-49D-AL`,
  `7-SMC-50D-AL (FORA DE OPERAÇÃO)`,
  `LINHAS PRODUÇÃO PO|PG|GL - SMC`,
  `ESTAÇÃO DE PARU`,
  `SIST. INJEÇÃO DE ANTIOXIDANTE`,
  `SIST. INJ. ANTIOXIDANTE - PARU`,
  `SIST. DE REPR. BARR. E DIQUES - PARU`,
  `SIST. AR COMPRIMIDO - PARU`,
  `COMPRESSOR AR "A" - PARU`,
  `COMPRESSOR AR "B" - PARU`,
  `SIST. AR CONDICIONADO - PARU`,
  `SIST. ENERGIA ELÉTRICA - PARU`,
  `GERADOR EMERGÊNCIA - PARU`,
  `GERADOR EMERGÊNCIA TIC - PARU`,
  `LINHA DISTRIBUIÇÃO 13,8KV - PARU`,
  `QUADRO DE DISTRIBUIÇÃO - PARU`,
  `SISTEMA ATERRAMENTO POLO - PARU`,
  `INSTALAÇÃO DE GÁS (IG) - PARU`,
  `SISTEMA BOMBA VISCO B-625001 - PARU`,
  `SG-625001 SEPARADOR GÁS - PARU`,
  `SIST. DE PROCESS. DE LGN - PARU`,
  `SIST. ENERGIA BCO/RETIF - PARU`,
  `EQUIP. SEM TAGS - PARU`,
  `POÇOS - CAMPO DE PARU`,
  `4-ALS-39`,
  `POÇOS - CAMPO DE PIACABUÇU`,
  `PIA-02`,
  `PIA-05`,
  `PIA-22`,
  `PIA-23`,
  `EQUIP. SEM TAGS - CAMPO PIACABUÇU`,
  `7-PIR-228D-AL`,
  `7-PIR-244D-AL`,
  `7-PIR-245D-AL`,
  `7-PIR-248D-AL`,
  `7-PIR-249D-AL`,
  `7-PIR-246D-AL`,
  `7-PIR-247D-AL`,
  `7-PIR-257D-AL`,
  `7-PIR-250D-AL`,
  `7-PIR-253D-AL`,
  `7-PIR-251D-AL`,
  `7-PIR-252D-AL`,
  `7-PIR-259D-AL (INATIVO)`,
  `7-PIR-260D-AL`,
  `7-PIR-256D-AL`,
  `7-PIR-258D-AL`,
  `7-PIR-261D-AL`,
  `7-PIR-262D-AL`,
  `7-PIR-265D-AL`,
  `7-PIR-264D-AL`,
  `7-PIR-255D-AL`,
  `7-PIR-266D-AL (INATIVO)`,
  `7-PIR-263D-AL (INATIVO)`,
  `7-PIR-268D-AL`,
  `7-PIR-254D-AL`,
  `7-PIR-267D-AL`,
  `7-PIR-272D-AL`,
  `7-PIR-270D-AL`,
  `7-PIR-269D-AL`,
  `7-PIR-275D-AL`,
  `7-PIR-273D-AL`,
  `7-PIR-274D-AL`,
  `7-PIR-281DP-AL`,
  `3-PIR-294-AL`,
  `7-PIR-271D-AL`,
  `7-PIR-276D-AL`,
  `4-PIR-292-AL`,
  `7-PIR-277D-AL`,
  `UNIDADE MÓVEL TRATAMENTO PETRÓLEO`,
  `SISTEMA AQUECIMENTO DE ÁGUA - PILAR`,
  `BOMBAS DA UCAQ - PILAR`,
  `UNIDADE CIRCULACAO/AQUECIMENTO DE ÁGUA - PILAR`,
  `EQUIP. SEM TAGS - UCAQ`,
  `UNIDADE DE OPERACOES COM ARAME WIRELINE - PILAR`,
  `EQUIP. SEM TAGS - UNID. OPER. ARAME WIRELINE - PILAR`,
  `POÇOS CAMPOS PILAR/RIO REMÉDIO`,
  `1-NEP-1-AL`,
  `1-PIR-1-AL`,
  `3-PIR-2-AL`,
  `3-PIR-5-AL`,
  `3-PIR-3-AL`,
  `1-RR-1-AL`,
  `1-RSA-1-AL`,
  `3-PIR-7-AL`,
  `4-PIR-4-AL`,
  `4-PIR-6-AL`,
  `7-PIR-8-AL`,
  `4-PIR-9-AL`,
  `7-PIR-10-AL`,
  `7-PIR-11-AL`,
  `7-PIR-12-AL`,
  `3-PIR-13-AL`,
  `7-RR-2-AL`,
  `7-PIR-14-AL`,
  `7-PIR-15-AL`,
  `7-PIR-16-AL`,
  `7-PIR-17-AL`,
  `7-PIR-18-AL`,
  `7-PIR-18A-AL`,
  `7-PIR-19-AL`,
  `7-PIR-20-AL`,
  `7-PIR-21-AL`,
  `7-PIR-23-AL`,
  `7-PIR-22-AL`,
  `7-PIR-24-AL`,
  `3-PIR-25D-AL`,
  `7-PIR-26-AL`,
  `7-PIR-27-AL`,
  `7-PIR-28-AL`,
  `7-PIR-29-AL`,
  `7-PIR-30-AL`,
  `7-RR-3-AL`,
  `7-RR-4-AL`,
  `7-PIR-31-AL`,
  `7-PIR-32-AL`,
  `7-PIR-33-AL`,
  `7-PIR-34-AL`,
  `7-PIR-35D-AL`,
  `7-PIR-36-AL`,
  `7-PIR-37-AL`,
  `7-PIR-38-AL`,
  `7-PIR-39-AL`,
  `7-PIR-40-AL`,
  `7-PIR-41-AL`,
  `4-RR-5-AL`,
  `7-PIR-42-AL`,
  `7-PIR-43-AL`,
  `7-PIR-44-AL`,
  `7-PIR-46-AL`,
  `7-PIR-45-AL`,
  `7-PIR-47-AL`,
  `7-PIR-48-AL`,
  `3-PIR-49-AL`,
  `7-PIR-50-AL (INATIVO)`,
  `7-PIR-51D-AL`,
  `3-PIR-52-AL`,
  `7-PIR-53-AL`,
  `7-PIR-55-AL`,
  `7-PIR-90-AL`,
  `3-PIR-54-AL`,
  `3-PIR-56-AL`,
  `7-PIR-57-AL`,
  `7-PIR-58-AL`,
  `3-PIR-59-AL`,
  `7-PIR-60-AL`,
  `7-PIR-61-AL (INATIVO)`,
  `7-PIR-62-AL`,
  `3-PIR-63-AL`,
  `7-PIR-64-AL`,
  `7-PIR-65-AL`,
  `7-PIR-66-AL`,
  `7-PIR-67-AL`,
  `7-PIR-68-AL`,
  `7-PIR-69-AL`,
  `7-PIR-70-AL`,
  `7-PIR-71-AL`,
  `3-PIR-72-AL`,
  `3-PIR-73D-AL`,
  `7-PIR-74-AL`,
  `7-PIR-75-AL`,
  `7-PIR-76-AL`,
  `7-PIR-77-AL`,
  `3-PIR-78-AL`,
  `7-PIR-79-AL`,
  `7-PIR-80-AL`,
  `3-PIR-81-AL`,
  `7-PIR-83-AL`,
  `7-PIR-82-AL`,
  `7-PIR-84-AL (INATIVO)`,
  `3-PIR-85-AL`,
  `7-PIR-86-AL`,
  `7-PIR-87-AL (INATIVO)`,
  `7-PIR-88-AL`,
  `3-PIR-89-AL`,
  `7-PIR-91-AL`,
  `7-PIR-92-AL`,
  `7-PIR-93-AL`,
  `7-PIR-94-AL (INATIVO)`,
  `7-PIR-95-AL`,
  `7-PIR-96-AL`,
  `7-PIR-99-AL`,
  `7-PIR-97-AL`,
  `7-PIR-98-AL`,
  `7-PIR-100-AL`,
  `7-PIR-101-AL`,
  `7-PIR-102-AL`,
  `7-PIR-103-AL`,
  `7-PIR-104-AL`,
  `7-PIR-105-AL (INATIVO)`,
  `7-PIR-106-AL`,
  `7-PIR-107-AL`,
  `7-PIR-109-AL`,
  `7-PIR-108-AL`,
  `4-PIR-110-AL`,
  `7-PIR-111-AL`,
  `7-PIR-112-AL`,
  `7-PIR-113-AL`,
  `7-PIR-114-AL`,
  `7-PIR-115-AL`,
  `7-PIR-116-AL`,
  `7-PIR-117-AL`,
  `7-PIR-118-AL - INATIVO`,
  `7-PIR-119-AL`,
  `7-PIR-118D-AL`,
  `7-PIR-120-AL`,
  `7-PIR-121D-AL`,
  `7-PIR-122-AL`,
  `7-PIR-123-AL`,
  `7-PIR-122D-AL`,
  `7-PIR-124-AL`,
  `7-PIR-125-AL`,
  `7-PIR-126-AL`,
  `7-PIR-127-AL`,
  `7-PIR-128-AL`,
  `7-PIR-129-AL`,
  `7-PIR-130-AL`,
  `7-PIR-131-AL`,
  `7-PIR-132D-AL`,
  `7-PIR-133-AL`,
  `7-PIR-134D-AL`,
  `8-PIR-135-AL`,
  `7-PIR-136D-AL`,
  `9-PIR-137-AL`,
  `7-PIR-141-AL - INATIVO`,
  `9-PIR-138-AL`,
  `9-PIR-139-AL`,
  `9-PIR-140-AL`,
  `9-PIR-142-AL`,
  `7-PIR-143-AL`,
  `7-PIR-144-AL`,
  `3-PIR-145-AL`,
  `7-PIR-146-AL`,
  `1-RV-1D-AL`,
  `7-PIR-147D-AL`,
  `7-PIR-149-AL`,
  `7-PIR-150D-AL`,
  `7-PIR-148D-AL`,
  `7-PIR-151D-AL`,
  `7-PIR-152D-AL`,
  `7-PIR-153D-AL`,
  `7-PIR-155D-AL`,
  `7-PIR-154D-AL`,
  `7-PIR-156D-AL`,
  `7-PIR-158D-AL`,
  `7-PIR-157D-AL`,
  `7-PIR-159D-AL`,
  `7-PIR-160D-AL`,
  `7-PIR-161D-AL`,
  `7-PIR-162D-AL`,
  `7-PIR-163D-AL`,
  `7-PIR-164D-AL`,
  `9-PIR-165-AL`,
  `7-PIR-166D-AL`,
  `7-PIR-167D-AL`,
  `7-PIR-168D-AL`,
  `7-PIR-169D-AL`,
  `7-PIR-170D-AL`,
  `7-PIR-171D-AL`,
  `7-PIR-172D-AL`,
  `7-PIR-173D-AL`,
  `7-PIR-174D-AL`,
  `7-PIR-177D-AL`,
  `7-PIR-176D-AL`,
  `7-PIR-175D-AL`,
  `7-PIR-179D-AL`,
  `7-PIR-180D-AL`,
  `7-PIR-181D-AL`,
  `7-PIR-182D-AL`,
  `7-PIR-183D-AL`,
  `7-PIR-184D-AL`,
  `7-PIR-178D-AL`,
  `7-PIR-187D-AL`,
  `7-PIR-187DA-AL`,
  `7-PIR-185D-AL`,
  `7-PIR-186D-AL`,
  `7-PIR-188D-AL`,
  `7-PIR-190D-AL`,
  `7-PIR-189D-AL`,
  `7-PIR-191D-AL`,
  `7-PIR-192D-AL`,
  `7-PIR-197D-AL`,
  `7-PIR-195D-AL`,
  `7-PIR-198D-AL`,
  `7-PIR-196D-AL`,
  `7-PIR-193D-AL`,
  `7-PIR-199D-AL`,
  `7-PIR-201D-AL`,
  `7-PIR-194D-AL`,
  `7-PIR-200D-AL`,
  `7-PIR-210D-AL`,
  `7-PIR-203D-AL`,
  `7-PIR-212D-AL`,
  `7-PIR-200DA-AL`,
  `7-PIR-213D-AL`,
  `7-PIR-202D-AL`,
  `7-PIR-211D-AL`,
  `7-PIR-214D-AL`,
  `7-PIR-205D-AL`,
  `7-PIR-209D-AL`,
  `7-PIR-208-AL`,
  `7-PIR-204D-AL`,
  `7-PIR-206D-AL`,
  `7-PIR-207D-AL`,
  `7-PIR-215D-AL`,
  `7-PIR-216D-AL`,
  `7-PIR-217D-AL`,
  `7-PIR-218D-AL`,
  `7-PIR-220D-AL`,
  `7-PIR-219D-AL`,
  `7-PIR-221D-AL`,
  `7-PIR-222D-AL`,
  `7-PIR-223D-AL`,
  `6-PIR-227D-AL`,
  `7-PIR-226D-AL`,
  `7-PIR-224D-AL`,
  `7-PIR-225D-AL`,
  `7-PIR-230D-AL (FORA DE OPERAÇÃO)`,
  `7-PIR-231D-AL`,
  `7-PIR-229D-AL`,
  `7-PIR-237D-AL`,
  `7-PIR-236D-AL`,
  `7-PIR-235D-AL`,
  `7-PIR-234D-AL`,
  `7-PIR-232D-AL`,
  `7-PIR-233D-AL`,
  `7-PIR-238D-AL`,
  `7-PIR-239D-AL`,
  `7-PIR-240D-AL`,
  `7-PIR-241D-AL`,
  `7-PIR-242D-AL`,
  `7-PIR-243D-AL`,
  `7-PIR-279D-AL`,
  `1-LPIR-1D-AL`,
  `1-BAB-1-AL (DESATIVADO)`,
  `1-JUCA-1-AL (DESATIVADO)`,
  `MANGOTES CAMPO - PILAR`,
  `MANÔMETROS OPERACIONAIS - CAMPO PILAR`,
  `LINHAS PRODUÇÃO - PILAR`,
  `LINHAS PRODUÇÃO - EPPIR`,
  `LINHAS PRODUÇÃO | SAT 7 PIR-07 (ÁGUA)`,
  `LINHAS PRODUÇÃO | POÇOS P/ POÇOS PILAR`,
  `LINHAS PRODUÇÃO | SAT 1 - PILAR`,
  `LINHAS PRODUÇÃO | SAT 5 - PILAR`,
  `LINHAS PRODUÇÃO | SAT 7 - PILAR`,
  `LINHAS PRODUÇÃO | SAT 21 - PILAR`,
  `LINHAS PRODUÇÃO | SAT 60 - PILAR`,
  `LINHAS PRODUÇÃO | SAT 66 - PILAR`,
  `LINHAS PRODUÇÃO | SAT 69 - PILAR`,
  `LINHAS PRODUÇÃO | SAT 81 - PILAR`,
  `LINHAS PRODUÇÃO | SAT 92 - PILAR`,
  `LINHAS PRODUÇÃO | SAT 118 - PILAR`,
  `LINHAS PRODUÇÃO | SAT 148 - PILAR`,
  `LINHAS PRODUÇÃO | SAT 158 - PILAR`,
  `LINHAS PRODUÇÃO | SAT 163 - PILAR`,
  `POÇOS - ÁREA DO ALS-32`,
  `1-ALS-32`,
  `1-ALS-52 (DESATIVADO)`,
  `POÇOS CAMPO - FAZENDA PAU BRASIL`,
  `EQUIP. SEM TAGS - FAZ. PAU BRASIL`,
  `1-FAE-1D-AL (DESATIVADO)`,
  `1-FAE-2D-AL (DESATIVADO)`,
  `POÇOS DE JAPUAÇU`,
  `GASODUTO - FURADO/PILAR`,
  `GN-10 - SMC-12/CSMC-17 (baixa)`,
  `GN-10 - SMC-12/CSMC-17 (média)`,
  `GN-8 - SMC-12/CSMC-17 (alta)`,
  `GN-14 - CSMC-17/FURADO (baixa)`,
  `GN-12 - CSMC-17/FURADO (média)`,
  `GN-10 - CSMC-17/FURADO (alta)`,
  `GN-6 - SMC-12/ENTR. FURADO-ROBALO`,
  `GN-8 - PARU(ALS-39)/FURADO-ROBALO`,
  `PE-6 - FURADO/PILAR`,
  `GN-10 - FURADO/ROBALO`,
  `VALV HI-LO Nº01 | GN-10 - FURADO/ROBALO`,
  `VALV HI-LO Nº02 | GN-10 - FURADO/ROBALO`,
  `VALV HI-LO Nº03 | GN-10 - FURADO/ROBALO`,
  `VALV HI-LO Nº04 | GN-10 - FURADO/ROBALO`,
  `VALV HI-LO Nº05 | GN-10 - FURADO/ROBALO`,
  `VALV HI-LO Nº06 | GN-10 - FURADO/ROBALO`,
  `VALV HI-LO Nº07 | GN-10 - FURADO/ROBALO`,
  `VALV HI-LO Nº08 | GN-10 - FURADO/ROBALO`,
  `VALV HI-LO Nº09 | GN-10 - FURADO/ROBALO`,
  `SIST PROTEÇÃO CATÓDICA DUTOS ATP-AL`,
  `GASODUTO - PILAR/ALGÁS`,
  `GASODUTO - PILAR/UPGN - VEMED 10`,
  `GASODUTO - ENVIO P/ GASALP (TAG)`,
  `GASODUTO - CUSTÓDIA`,
  `GASODUTO - INJEÇÃO GÁS P/ SAT-69`,
  `GASODUTO PILAR/PROFERTIL`,
  `GASODUTO PILAR/ATALAIA`,
  `XV-001 | PE-6 - FURADO/PILAR`,
  `XV-002 | PE-6 - FURADO/PILAR`,
  `XV-003 | PE-6 - FURADO/PILAR`,
  `XV-004 | PE-6 - FURADO/PILAR`,
  `XV-005 | PE-6 - FURADO/PILAR`,
  `PE-8 ANAMBÉ/FURADO`,
  `PONTO DE ENTREGA OU RECEPÇÃO DE GÁS NATU`,
  `Sistema de Energia para Telecomunicações`,
  `EQUIPAMENTOS GERÊNCIA SEG PILAR`,
  `Sistema de Represas, Barragem e Diques`,
  `1263-Sist. Bomb. Bactericida Injeç Pilar`,
  `EQUIPAMENTOS GERÊNCIA SEG FURADO`,
  `Sistema de Separação Gás/óleo - SG TOTAL`,
  `Sistema Bombas Dosadoras de Injeção Água`,
  `Sistema de Segurança Industrial (ANB)`,
  `Sistema Injeção de Antioxidante`
];
