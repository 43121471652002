import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  GEA: '1319',
  'Vazão (m³/dia)': '1330',
  'Pressão (kgf/cm²)': '1253',
  'Temperatura (°C)': '1252'
};

const gea: Column[] = tablefy(items);

export default gea;
