import { Column } from 'react-table';
import { tablefy } from '../../../../utils/tablesUtils';

const items: any = {
  Hora: '112',
  Bomba: '1143',
  Status: '1145',
  'Categoria do Evento': '1146',
  Motivo: '1147',
  'Descrição do evento': '1126',
  'N° da Nota SAP': '1148'
};

const ocEmulssionPir: Column[] = tablefy(items);

export default ocEmulssionPir;
