import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Poço: '1656',
  'Atividade Não Realizada': '2111',
  'Motivo da Não Realização': '2112',
  Observação: '712'
};

const activitiesNotFinished: Column[] = tablefy(items);

export default activitiesNotFinished;
