import { useFormContext } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';
import { twMerge } from 'tailwind-merge';
import SelectInput, { SelectOptions } from '../SelectInput';

interface SelectCell {
  name: string;
  disabled?: boolean;
  options: SelectOptions[];
}

export const SelectCell = ({ name, options, disabled }: SelectCell) => {
  const { control } = useFormContext();

  return (
    <span
      className={twMerge(
        'text-[#191919] text-xs whitespace-nowrap w-full h-full relative flex-1'
      )}
    >
      <SelectInput
        control={control}
        name={name}
        options={options || []}
        disabled={disabled}
      />
    </span>
  );
};
