import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Ponto de Medição': '1613',
  TAG: '1614',
  Tipo: '1615'
};

const bdmfGasPilarPoint: Column[] = tablefy(
  items,
  undefined,
  Object.keys(items)
);

export default bdmfGasPilarPoint;
