import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { postGasMeter, postZone } from '../../../services/oilwells.services';

import Input from '../../../components/atoms/Input';
import Button from '../../../components/atoms/Button';
import { queryClient } from '../../../App';
import SelectInput from '../../../components/atoms/SelectInput';
import MdmLayout from '../../../components/templates/MdmLayout';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';

type CreateZonesForm = {
  code: string;
  name?: string;
  acronym?: string;
  oilFieldId: string;
  gasa: number;
  gasn: number;
  glas: number;
  oleo: number;
  cond: number;
};

const CreateZones: React.FC = () => {
  const { fields } = useMdmUtils({
    fields: true
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<CreateZonesForm>();

  const onSubmit = async (data: CreateZonesForm) => {
    try {
      await postZone({
        ...data,
        oilField: { connect: { id: Number(data.oilFieldId) } },
        gasa: data.gasa ? Number(data.gasa) : null,
        gasn: data.gasn ? Number(data.gasn) : null,
        glas: data.glas ? Number(data.glas) : null,
        oleo: data.oleo ? Number(data.oleo) : null,
        cond: data.cond ? Number(data.cond) : null
      });
      await queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === 'zones-adm'
      });

      toast.success('Medidor de gás criado com sucesso');
    } catch (e) {
      toast.error('Erro ao criar medidor de gás');
    }
  };

  return (
    <MdmLayout title={'Criar Zona'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-2 items-center justify-center w-3/4"
      >
        <Input
          label="Código"
          required
          placeholder="Código"
          {...register('code', { required: true })}
        />

        <Input label="Nome" placeholder="Nome" {...register('name')} />

        <Input label="Sigla" placeholder="Sigla" {...register('acronym')} />

        <SelectInput
          label="Campo"
          placeholder="Campo"
          control={control}
          name={'oilFieldId'}
          required
          options={fields?.map((field: any) => ({
            value: field.id,
            label: field.name
          }))}
        />

        <span
          className="
          font-bold
          text-persian-blue-900
          text-lg
          w-full
          flex
          mt-2
        "
        >
          Volume in Situ
        </span>

        <div className="flex gap-4 w-full">
          <Input
            label="Gás Associado"
            required
            placeholder="Gás Associado"
            {...register('gasa', { required: true })}
          />

          <Input
            label="Gás Natural"
            required
            placeholder="Gás Natural"
            {...register('gasn', { required: true })}
          />
        </div>

        <div className="flex gap-4 w-full">
          <Input label="GLAs" placeholder="GLAs" {...register('glas')} />

          <Input
            label="Óleo"
            required
            placeholder="Óleo"
            {...register('oleo', { required: true })}
          />
        </div>

        <Input
          label="Condensado"
          required
          placeholder="Condensado"
          {...register('cond', { required: true })}
        />

        <Button
          className="px-24 h-12 mt-4"
          type="submit"
          title="Salvar"
          disabled={!isValid && !errors}
        />
      </form>
    </MdmLayout>
  );
};

export default CreateZones;
