import { useForm, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';

import Input from '../../../components/atoms/Input';
import Button from '../../../components/atoms/Button';
import { queryClient } from '../../../App';
import MdmLayout from '../../../components/templates/MdmLayout';
import { AxiosError } from 'axios';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import { postOilField } from '../../../services/oilwells.services';
import SelectInput from '../../../components/atoms/SelectInput';
import { mapperOptions } from '../../../utils/mdm';

type CreateOilFieldForm = {
  name: string;
  type: string;
  hub: string;
  oilwells: string;
};

const CreateOilField: React.FC = () => {
  const { constants } = useMdmUtils({
    constants: true
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<CreateOilFieldForm>();

  const onSubmit = async (data: CreateOilFieldForm) => {
    try {
      await postOilField({
        ...data
      });
      await queryClient.invalidateQueries(['oilfields-adm']);

      toast.success('Campo criado com sucesso');
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(e.response?.data.message);
        if (e.status === 409) {
          toast.error('Campo já cadastrado');
        } else {
          toast.error('Erro ao criar campo');
        }
      } else {
        toast.error(
          'Houve um erro ao criar um campo, por favor tente novamente mais tarde'
        );
      }
    }
  };

  return (
    <MdmLayout title={'Criar Campo'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-2 items-center justify-center w-3/4"
      >
        <Input
          label="Nome"
          placeholder="Nome"
          required
          {...register('name', { required: true })}
        />

        <div className="flex flex-row gap-2 w-full">
          <SelectInput
            label="Tipo"
            placeholder="Tipo"
            control={control}
            name={'type'}
            required
            options={mapperOptions(constants?.OilFieldType)}
          />

          <SelectInput
            label="Polo"
            placeholder="Polo"
            control={control}
            name={'hub'}
            required
            options={mapperOptions(constants?.OilFieldHub)}
          />
        </div>

        <Button
          className="px-24 h-12 mt-4"
          type="submit"
          title="Criar"
          disabled={!isValid && !errors}
        />
      </form>
    </MdmLayout>
  );
};

export default CreateOilField;
