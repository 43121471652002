import { useRouteError } from 'react-router-dom';

const ErrorComponent: React.FC = () => {
  const error = useRouteError();
  console.error(error);

  if (
    typeof (error as any)?.message == 'string' &&
    (error as any).message.includes(
      'Failed to fetch dynamically imported module'
    ) &&
    !window.location.href.includes('reload=1')
  ) {
    if (window.location.href.includes('?')) {
      window.location.href = window.location.href + '&reload=1';
    } else {
      window.location.href = window.location.href + '?reload=1';
    }
  }

  return (
    <main className="flex h-screen w-screen flex-col items-center justify-center">
      <h1 className="text-2xl font-bold">
        {(error as any)?.status == 404
          ? 'Página não encontrada'
          : 'Um erro inesperado ocorreu.'}
      </h1>
      <a href="javascript:history.back()" className="hover:underline">
        Voltar
      </a>
    </main>
  );
};

export default ErrorComponent;
