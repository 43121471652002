import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Hora: '112',
  Status: '1270',
  'Nível (cm)': '1272',
  'Volume (m³)': '1273'
};

const tank: Column[] = tablefy(items);

export default tank;
