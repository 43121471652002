import Button from '../../../components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import Table from '../../../components/organisms/Table';
import { HiOutlinePlusSm } from 'react-icons/hi';
import Spinner from '../../../components/atoms/Spinner';
import satelliteColumns from '../../../constants/tableColumns/mdm/satellite';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';

const Satellites = () => {
  const navigate = useNavigate();

  const { satellites, satellitesisLoading: isLoading } = useMdmUtils({
    satellites: true
  });

  const handleNavigateToCreateSatellite = () => {
    navigate('/satellites/create');
  };

  return (
    <>
      <div className="flex flex-col gap-4 py-4 px-6 bg-white rounded-lg mt-4 pb-8 relative">
        <Button
          title="Novo Satélite"
          type="button"
          className="absolute right-14 -top-[56px]"
          icon={<HiOutlinePlusSm className="w-5 h-5" color="white" />}
          onClick={handleNavigateToCreateSatellite}
        />

        <section className="flex justify-between items-center">
          <h1 className="text-gray font-bold text-lg flex">
            Satélites
            {isLoading && <Spinner className="ml-2" size={16} />}
          </h1>
        </section>

        {satellites ? (
          <section className="flex flex-col gap-2">
            <Table columns={satelliteColumns} data={satellites || []} />
          </section>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <Spinner className="mt-4" size={32} />
          </div>
        )}
      </div>
    </>
  );
};

export default Satellites;
