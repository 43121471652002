import Table from '../../../components/organisms/Table';
import Spinner from '../../../components/atoms/Spinner';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import piTagsColumns from '../../../constants/tableColumns/mdm/pi-tags';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { getTags } from '../../../services/oilwells.services';
import { useState } from 'react';
import PageNavigation from '../../../components/molecules/PageNavigation';

const PiTags = () => {
  const [page, setPage] = useState(0);
  const perPage = 15;
  const {
    data: tags,
    isLoading,
    isFetching
  } = useQuery(['tags-adm', page], () => getTags(page, perPage), {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 3, // 3 minutos
    cacheTime: 1000 * 60 * 7, // 7 minutos
    onError: () => {
      toast.error('Erro ao buscar tags');
    }
  });

  return (
    <>
      <div className="flex flex-col gap-4 py-4 px-6 bg-white rounded-lg mt-4 pb-8 relative">
        <section className="flex justify-between items-center">
          <h1 className="text-gray font-bold text-lg flex whitespace-nowrap ">
            Tags PI
            {(isLoading || isFetching) && (
              <Spinner className="ml-2" size={16} />
            )}
          </h1>
        </section>

        {tags ? (
          <section className="flex flex-col gap-2">
            <Table columns={piTagsColumns} data={tags.data || []} />
            <PageNavigation
              currentPage={page}
              setCurrentPage={setPage}
              className="mt-3"
              totalPages={Math.ceil(tags.pagination.item_count / perPage)}
              perPage={perPage}
            />
          </section>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <Spinner className="mt-4" size={32} />
          </div>
        )}
      </div>
    </>
  );
};

export default PiTags;
