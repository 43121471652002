import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const stickCols: Column[] = [
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'Poço',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Campo'} id="Campo" />,
    accessor: 'Campo',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Hora de Lançamento'} id="Hora de Lançamento" />,
    accessor: 'Hora de Lançamento',
    Cell: DateHourCell,
    width: '8%'
  },
  {
    Header: <Header text={'Quantidade'} id="Quantidade" />,
    accessor: 'Quantidade',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Tipo de Bastão'} id="Tipo de Bastão" />,
    accessor: 'Tipo de Bastão',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: (
      <Header
        text={'Pressão antes do bastão (kgf/cm²)'}
        id="Pressão antes do bastão (kgf/cm²)"
      />
    ),
    accessor: 'Pressão antes do bastão (kgf/cm²)',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Hora de abertura'} id="Hora de abertura" />,
    accessor: 'Hora de abertura',
    Cell: DateHourCell,
    width: '8%'
  },
  {
    Header: <Header text={'Abertura utilizada'} id="Abertura utilizada" />,
    accessor: 'Abertura utilizada',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Abertura máxima'} id="Abertura máxima" />,
    accessor: 'Abertura máxima',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: (
      <Header
        text={'Pressão final antes da abertura (kgf/cm²)'}
        id="Pressão final antes da abertura (kgf/cm²)"
      />
    ),
    accessor: 'Pressão final antes da abertura (kgf/cm²)',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: 'Observação',
    Cell: DefaultCell,
    width: '8%'
  }
];

export default stickCols;
