import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Pontos de Captação': '1448',
  'Status - 1º turno': '1111',
  'Status - 2º turno': '1042',
  'Status - 3º turno': '1043',
  'Limite de consumo diário (m³/dia)': '1449',
  'Hidrômetro às 23:59 (m³)': '1452',
  'Volume 24h (m³)': '1453'
};

const statusByTurnWater: Column[] = tablefy(items);

export default statusByTurnWater;
