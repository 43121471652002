import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const cicleInfo: Column[] = [
  {
    Header: <Header text={'Peneira'} id="Peneira" />,
    accessor: '1054',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Situação às 23:59'} id="Situação às 23:59" />,
    accessor: '1055',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Início deste ciclo'} id="Início deste ciclo" />,
    accessor: '1056',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: '712',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default cicleInfo;
