import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const emc: Column[] = [
  {
    Header: <Header text={'EMC'} id="EMC" />,
    accessor: '1060',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Status'} id="Status" />,
    accessor: '1061',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Hora'} id="Hora" />,
    accessor: '112',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default emc;
