import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const bombs: Column[] = [
  {
    Header: <Header text={'Bomba'} id="Bomba" />,
    accessor: '1040',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Horímetro Acumulado'} id="Horímetro Acumulado" />,
    accessor: '1044',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Horímetro do dia'} id="Horímetro do dia" />,
    accessor: '1045',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default bombs;
