import { useRef, useState } from 'react';
import { FiInfo } from 'react-icons/fi';
import Select from 'react-select';
import { useReactToPrint } from 'react-to-print';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import H1 from '../../../components/atoms/H1';
import { PageWrapper } from '../../../components/atoms/PageWrapper';
import { PrintStyle } from '../../../components/atoms/PrintStyle';
import {
  ValueContainer,
  selectInputStyles
} from '../../../components/atoms/Select';
import { SummaryBigCards } from '../../../components/molecules/SummaryBigCards';
import { SideBarDateAndTracker } from '../../../components/organisms/SideBarDateAndTracker';
import Table from '../../../components/organisms/Table';
import { objectify } from '../../../constants/filters/WorflowTests';
import { useBdmfSummary } from '../../../hooks/BDMF/useBdmfSummary';
import { useParams } from '../../../hooks/Common/useParams';
import { BdmfReport } from './BdmfReport';
import { ReportHeader } from '@/components/molecules/ReportHeader';

const BdmfSummary = () => {
  const { date } = useParams();
  const { tracker, currentDate, setParam } = useParams();
  const [hub, setHub] = useState({ label: 'Alagoas', value: 'Alagoas' });

  const {
    summaryBigCards,
    summaryLow,
    summaryMedium,
    reportInfo,
    tables,
    isLoading,
    comments,
    isEmpty,
    awaitingValidation
  } = useBdmfSummary(date, tracker ?? undefined, hub.value);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const allReportsRef = useRef(null);
  const handlePrintAllReports = useReactToPrint({
    content: () => allReportsRef.current
  });

  const trackers =
    hub.value === 'Alagoas'
      ? [
          'pilar-gas',
          'furado-gas',
          'pilar-oleo',
          'furado-oleo',
          'pilar-glp',
          'pilar-custodia'
        ]
      : ['pts-diario', 'pts-custodia'];

  return (
    <>
      <PageWrapper className="p-0 pb-4" ref={allReportsRef}>
        <div
          className="w-full h-full relative pt-4 px-4 bg-background pagebreak"
          ref={componentRef}
        >
          <PrintStyle />
          <img
            src="/origem.png"
            alt="Logo Origem"
            className="w-[203px] only-print -ml-5 resize-none max-w-none"
          />

          <H1>
            <div className="flex gap-3 items-center">
              Relatório Diário de Produção
              {!isLoading && !isEmpty ? (
                <div
                  className={twMerge(
                    'rounded-full py-1 font-medium text-base px-3',
                    awaitingValidation
                      ? 'bg-[#F7FB5E]/40 text-[#B99F19]'
                      : 'bg-[#BAFFD2] text-[#55A16F]'
                  )}
                >
                  {awaitingValidation ? 'Aguardando Validação' : 'Aprovado'}
                </div>
              ) : null}
            </div>
            <div className="flex gap-2">
              <button
                onClick={handlePrintAllReports}
                className="text-base font-medium px-2 py-1 rounded border no-print"
              >
                Imprimir Consolidado
              </button>

              <button
                onClick={handlePrint}
                className="text-base font-medium px-2 py-1 rounded border no-print"
              >
                Imprimir
              </button>
            </div>
          </H1>

          <ReportHeader nestedValues={reportInfo} />

          <h1 className="font-bold text-xl text-primary mb-2 mt-4">
            Resumo de Produção do Polo {hub.value}
          </h1>

          <section className="flex flex-col gap-2">
            <SummaryBigCards data={summaryBigCards} />
          </section>

          <section className="flex flex-col gap-2 mt-2">
            <div className="flex gap-2  flex-wrap">
              {summaryMedium.map((info, idx) => (
                <div
                  className={twMerge(
                    'flex flex-col bg-[#D7DFFF] px-4 py-3 rounded flex-1 md:min-w-[20%] lg:min-w-[16%] min-w-[12rem]',
                    info.disabled ? 'bg-[#DDD]' : ''
                  )}
                  key={info.title + idx}
                >
                  <div className="font-medium text-primary/85 text-lg flex gap-1 items-center">
                    {info.title}

                    {info.info ? (
                      <>
                        <FiInfo
                          color="#66666680"
                          className="flex-none"
                          data-tooltip-id={`alert-${info.title}`}
                        />
                        <ReactTooltip
                          id={`alert-${info.title}`}
                          place="top"
                          variant="dark"
                          style={{ maxWidth: '380px', textAlign: 'center' }}
                          content={
                            info.disabled
                              ? 'Esse dado não está disponível para o Polo Tucano Sul'
                              : info.info
                          }
                        />
                      </>
                    ) : null}
                  </div>

                  <span className="text-xl font-bold text-primary">
                    {(info.disabled || info.value == 'NaN'
                      ? '-'
                      : info.value) || '-'}
                  </span>
                </div>
              ))}
            </div>
          </section>

          <section className="flex flex-col gap-2 mt-2">
            <div className="flex gap-2  flex-wrap">
              {summaryLow.map((info, idx) => (
                <div
                  className={twMerge(
                    'flex flex-col bg-white px-4 py-3 rounded flex-1 md:min-w-[20%] lg:min-w-[16%] min-w-[12rem]',
                    info.disabled ? 'bg-[#DDD]' : ''
                  )}
                  key={info.title + idx}
                >
                  <div className="font-medium text-slate-800 text-lg flex gap-1 items-center">
                    {info.title}

                    {info.info ? (
                      <>
                        <FiInfo
                          color="#66666680"
                          className="flex-none"
                          data-tooltip-id={`alert-${info.title}`}
                        />
                        <ReactTooltip
                          id={`alert-${info.title}`}
                          place="top"
                          variant="dark"
                          style={{ maxWidth: '380px', textAlign: 'center' }}
                          content={
                            info.disabled
                              ? 'Esse dado não está disponível para o Polo Tucano Sul'
                              : info.info
                          }
                        />
                      </>
                    ) : null}
                  </div>

                  <span className="text-xl font-bold text-primary">
                    {(info.disabled || info.value == 'NaN'
                      ? '-'
                      : info.value) || '-'}
                  </span>
                </div>
              ))}
            </div>
          </section>

          {tables.map(it => (
            <div key={it.title} className="break-inside-avoid">
              <h1 className="font-bold text-xl text-primary mb-2 mt-4">
                {it.title}
              </h1>

              <section className="h-[500px] flex rounded-lg bg-white px-4 py-3 scroll-height-auto">
                <div className="flex w-full overflow-y-auto max-h-[600px] scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
                  <Table
                    columns={it.columns}
                    data={it.data}
                    headerClassName="sticky top-0 bg-white z-10"
                  />
                </div>
              </section>
            </div>
          ))}

          <div className="break-inside-avoid">
            <h1 className="font-bold text-xl text-primary mb-2 mt-4">
              Comentários
            </h1>

            <section className="flex flex-col rounded-lg mb-4 gap-4">
              <div className="flex flex-col w-full gap-2 flex-wrap">
                {(hub.value === 'Tucano Sul'
                  ? comments?.tucano
                  : comments?.alagoas
                )?.map(comment => (
                  <section
                    className="flex flex-col gap-2 break-inside-avoid"
                    key={comment.title}
                  >
                    <div className="font-bold text-primary text-lg">
                      {comment.title}
                    </div>

                    <div className="flex w-full flex-wrap gap-2">
                      {comment?.data?.map(it => (
                        <div
                          className="flex flex-col gap-2 flex-1 min-w-[45%] rounded-lg bg-white px-4 py-3"
                          key={it.title + comment.title}
                        >
                          <h1>{it.title}</h1>
                          <span className="text-slate-900 whitespace-pre-wrap">
                            {it.value}
                          </span>
                        </div>
                      ))}
                    </div>
                  </section>
                ))}
              </div>
            </section>
          </div>
        </div>

        {trackers.map(it => (
          <div
            className="flex flex-col w-full pt-4 px-4 only-print bg-background pagebreak h-full"
            key={it}
          >
            <BdmfReport tracker={it} />
          </div>
        ))}

        <SideBarDateAndTracker>
          <div className="flex flex-col gap-1">
            <h3 className="text-sm font-bold text-white">Polo</h3>
            <Select
              className={`w-full rounded-lg font-medium`}
              options={['Alagoas', 'Tucano Sul'].map(objectify)}
              onChange={(val: any) => setHub(val)}
              defaultValue={{
                label: 'Alagoas',
                value: 'Alagoas'
              }}
              placeholder="Selecione..."
              theme={(theme: any) => ({
                ...theme,
                borderRadius: 6,
                spacing: {
                  ...theme.spacing,
                  controlHeight: 34
                },
                colors: {
                  ...theme.colors,
                  primary: '#aabbff',
                  neutral60: '#E5E5E5'
                }
              })}
              styles={selectInputStyles}
              components={{ ValueContainer }}
            />
          </div>
          {hub.value === 'Alagoas' && (
            <div className="flex flex-col gap-1">
              <h3 className="text-sm font-bold text-white">Estação</h3>
              <Select
                className={`w-full rounded-lg font-medium`}
                options={[
                  {
                    label: '-',
                    value: 'All'
                  },
                  ...['Pilar', 'Furado'].map(objectify)
                ]}
                defaultValue={{
                  label: '-',
                  value: 'All'
                }}
                onChange={(val: any) =>
                  setParam({
                    date: currentDate ?? '',
                    tracker: val?.value ?? 'Pilar'
                  })
                }
                placeholder="Selecione..."
                theme={(theme: any) => ({
                  ...theme,
                  borderRadius: 6,
                  spacing: {
                    ...theme.spacing,
                    controlHeight: 34
                  },
                  colors: {
                    ...theme.colors,
                    primary: '#aabbff',
                    neutral60: '#E5E5E5'
                  }
                })}
                styles={selectInputStyles}
                components={{ ValueContainer }}
              />
            </div>
          )}
        </SideBarDateAndTracker>
      </PageWrapper>
    </>
  );
};

export default BdmfSummary;
