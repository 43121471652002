import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const sap: Column[] = [
  {
    Header: <Header text={'Código SAP'} id="Código SAP" />,
    accessor: '1063',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Status'} id="Status" />,
    accessor: '1064',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Solicitante da Nota'} id="Solicitante da Nota" />,
    accessor: '1123',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: '712',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default sap;
