import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { DateHourCell } from '../../../components/atoms/table/HourCell';

const injectionCols: Column[] = [
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'poço',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: () => <Header text={'Hora'} id="112" />,
    accessor: 'Hora',
    Cell: DateHourCell,
    width: '12.5%',
    minWidth: 150
  },
  {
    Header: () => <Header text={'Abertura Máxima'} id="45" />,
    accessor: 'bean_máxima',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => <Header text={'Abertura Utilizada'} id="46" />,
    accessor: 'bean_utilizada',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => <Header text={'Vazão (m³)'} id="803" />,
    accessor: 'vazão',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'Pressão da cabeça (kgf/cm²)'} id="963" />,
    accessor: 'pcabeça',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 200
  },
  {
    Header: () => <Header text={'Pressão da anular A (kgf/cm²)'} id="813" />,
    accessor: 'panulara',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'Pressão da anular B (kgf/cm²)'} id="814" />,
    accessor: 'panularb',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'Pressão da anular C (kgf/cm²)'} id="815" />,
    accessor: 'panularc',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'Pressão de linha (kgf/cm²)'} id="1899" />,
    accessor: 'pdelinha',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'Pressão de injeção (kgf/cm²)'} id="1900" />,
    accessor: 'pinjecao',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'Pressão de Satélite (kgf/cm²)'} id="3094" />,
    accessor: 'psat',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'Vazão da bomba (m³/d)'} id="1901" />,
    accessor: 'vbomba',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'Rotação da bomba (%)'} id="2149" />,
    accessor: 'rbomba',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'Observação'} id="712" />,
    accessor: 'observação',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  }
];

export default injectionCols;
