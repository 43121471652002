import { Column } from 'react-table';
import { tablefy } from '../../../../utils/tablesUtils';

const items: any = {
  Satélites: '958',
  LT: '504',
  L4: '505',
  L6: '506',
  L8: '507'
};

const satPir0010: Column[] = tablefy(items);

export default satPir0010;
