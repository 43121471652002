import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/atoms/Button';
import SelectInputUncontrolled from '../../../components/atoms/SelectInputUncontrolled';
import Spinner from '../../../components/atoms/Spinner';
import PageNavigation from '../../../components/molecules/PageNavigation';
import Table from '../../../components/organisms/Table';
import oilwellZoneAllocation from '../../../constants/tableColumns/mdm/oilwellZonesAllocation';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import { getAllOilwellZoneAllocation } from '../../../services/oilwells.services';

const OilwellZoneAllocation = () => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const perPage = 15;

  const handleGetAllocation = async () => {
    try {
      const response = await getAllOilwellZoneAllocation({
        skip: perPage * (currentPage - 1),
        take: perPage,
        search
      });
      return response;
    } catch (error) {
      toast.error('Erro ao buscar zonas');
    }
  };

  const { data, isLoading } = useQuery(
    ['oilwells-zones-adm', perPage, currentPage, search],
    handleGetAllocation,
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 3, // 3 minutos
      cacheTime: 1000 * 60 * 7 // 7 minutos
    }
  );

  const { oilwells } = useMdmUtils({
    oilwells: true
  });

  const handleNavigateToCreateOilWellAllocation = () => {
    navigate('/oilwell-zone-allocation/create');
  };

  return (
    <>
      <div className="flex flex-col gap-4 py-4 px-6 bg-white rounded-lg mt-4 pb-8 relative">
        <Button
          title="Nova alocação"
          type="button"
          className="absolute right-14 -top-[56px]"
          icon={<HiOutlinePlusSm className="w-5 h-5" color="white" />}
          onClick={handleNavigateToCreateOilWellAllocation}
        />

        <section className="flex justify-between items-center">
          <h1 className="text-gray font-bold text-lg flex">
            Percentual de Apropriação por Fluido
            {isLoading && <Spinner className="ml-2" size={16} />}
          </h1>

          <SelectInputUncontrolled
            onChange={(e: any) => {
              navigate(`/oilwell-zone-allocation/${e.value}`);
            }}
            options={oilwells?.data?.map((item: any) => ({
              value: item.id,
              label: item.name
            }))}
            placeholder="Editar por Poço"
            className="w-72 min-w-72"
          />
        </section>

        {!data && (
          <div className="flex flex-col items-center justify-center">
            <Spinner className="mt-4" size={32} />
          </div>
        )}

        {data?.data && (
          <section className="flex flex-col gap-2">
            <Table columns={oilwellZoneAllocation} data={data.data || []} />
            <PageNavigation
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              className="mt-3"
              totalPages={Math.ceil(data.total / perPage)}
              perPage={perPage}
            />
          </section>
        )}
      </div>
    </>
  );
};

export default OilwellZoneAllocation;
