import { Column } from 'react-table';
import water from '../../tableColumns/bdoView/water';

export const bdoWater: (
  | { type: 'label'; id: number; name: string }
  | {
      type: 'grid';
      id: number;
      columns: Column[];
      name?: string;
      value?: string;
    }
  | {
      type: 'input' | 'date' | 'number' | 'custom';
      id: number;
      name: string;
      value?: string;
      field?: string;
    }
)[] = [
  {
    id: 1339,
    type: 'grid',
    columns: water
  }
];
