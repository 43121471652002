import { Column } from 'react-table';
import temperature from '../../tableColumns/bdoEP/temperature';
import tank from '../../tableColumns/bdoEP/tank';
import nr13 from '../../tableColumns/bdoEC/nr13';
import turn from '../../tableColumns/bdoEC/turn';
import tankLevel from '../../tableColumns/bdoEP/tankLevel';
import water from '../../tableColumns/bdoView/water';
import hourInjection from '../../tableColumns/bdoEP/hourInjection';
import production from '../../tableColumns/bdoView/production';
import ocTank from '../../tableColumns/bdoEP/ocTank';
import oilwellTest from '../../tableColumns/bdoEP/oilwellTest';
import ocValve from '../../tableColumns/bdoEP/ocValve';
import ocChemicals from '../../tableColumns/bdoEP/ocChemicals';
import ocFilters from '../../tableColumns/bdoEP/ocFilters';
import ocBombs from '../../tableColumns/bdoEP/ocBombs';
import ocEmulssion from '../../tableColumns/bdoEP/ocEmulssion';
import ocDep from '../../tableColumns/bdoEP/ocDep';
import ocSg from '../../tableColumns/bdoEP/ocSg';
import ocUdgn from '../../tableColumns/bdoEP/ocUdgn';
import ocHilo from '../../tableColumns/bdoEP/ocHilo';
import ocXv from '../../tableColumns/bdoEP/ocXv';
import ocFlare from '../../tableColumns/bdoEP/ocFlare';
import pressureHilo from '../../tableColumns/bdoEP/pressureHilo';
import pressureXv from '../../tableColumns/bdoEP/pressureXv';
import chemicalLevels from '../../tableColumns/bdoEP/chemicalLevels';
import regFlare from '../../tableColumns/bdoEP/regFlare';
import udgnBomb from '../../tableColumns/bdoEP/udgnBomb';
import gea from '../../tableColumns/bdoEP/gea';
import rasp from '../../tableColumns/bdoEP/rasp';
import sgInjection from '../../tableColumns/bdoEP/sgInjection';
import oilRecieve from '../../tableColumns/bdoEP/oilRecieve';
import oilTransp from '../../tableColumns/bdoEP/oilTransp';
import controlTrash from '../../tableColumns/bdoEP/controlTrash';
import transfer from '../../tableColumns/bdoEP/transfer';
import seal from '../../tableColumns/bdoEP/seal';
import manifold from '../../tableColumns/bdoEP/manifold';

export const bdoProdFu: (
  | { type: 'label'; id: number; name: string; size?: 'h1' | 'h2' }
  | {
      type: 'grid';
      id: number;
      columns: Column[];
      name: string;
      value?: string;
    }
  | {
      type: 'input' | 'date' | 'number' | 'custom';
      id: number;
      name: string;
      value?: string;
      field?: string;
    }
)[] = [
  {
    id: 245,
    name: 'Emissor',
    type: 'input'
  },
  {
    id: 256,
    name: 'Situação dos Tanques',
    type: 'label'
  },
  {
    id: 257,
    name: 'Temperatura ambiente (ºC)',
    type: 'grid',
    columns: temperature
  },
  {
    id: 335,
    name: 'Registro dos Níveis',
    type: 'label'
  },
  {
    id: 1295,
    name: 'TQ-125105 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 1296,
    name: 'TQ-125106 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 1297,
    name: 'TQ-125107 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 557,
    name: 'TQ-125101 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 560,
    name: 'TQ-631101 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 561,
    name: 'TQ-631103 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 562,
    name: 'TQ-631104 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 564,
    name: 'TQ-631105 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 382,
    name: 'TQ-631106 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 563,
    name: 'TQ-631107 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 483,
    name: 'Volumes dos Tanques',
    type: 'label'
  },
  {
    id: 549,
    name: 'TQ-125101 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 550,
    name: 'TQ-125101 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 513,
    name: 'TQ-631101 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 514,
    name: 'TQ-631101 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 517,
    name: 'TQ-631103 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 518,
    name: 'TQ-631103 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 491,
    name: 'TQ-631104 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 490,
    name: 'TQ-631104 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 485,
    name: 'TQ-631107 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 484,
    name: 'TQ-631107 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 1499,
    name: 'Registro dos Níveis dos Tanques de Teste',
    type: 'label'
  },
  {
    id: 1503,
    name: 'TQ-631105 - Nível',
    type: 'grid',
    columns: tankLevel
  },
  {
    id: 1504,
    name: 'TQ-631106 - Nível',
    type: 'grid',
    columns: tankLevel
  },
  {
    id: 512,
    name: 'Volumes dos Tanques de Teste',
    type: 'label'
  },
  {
    id: 489,
    name: 'TQ-631105 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 488,
    name: 'TQ-631105 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 487,
    name: 'TQ-631106 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 486,
    name: 'TQ-631106 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 1306,
    name: 'Água Produzida',
    type: 'label'
  },
  {
    id: 1340,
    name: 'Volume Disponível para Armazenagem (m³)',
    type: 'input'
  },
  {
    id: 1307,
    name: 'Injeção horária (m³/d)',
    type: 'grid',
    columns: hourInjection
  },
  {
    id: 1339,
    name: 'Cálculos Automáticos - Água',
    type: 'grid',
    columns: water
  },
  {
    id: 521,
    name: 'Cálculo da Produção de Tanques Fiscais',
    type: 'label'
  },
  {
    id: 522,
    name: 'Volume Total Produzido (m³/dia)',
    type: 'input'
  },
  {
    id: 526,
    name: '',
    type: 'grid',
    columns: production
  },
  {
    id: 1523,
    name: 'Teste de Poço e de Vazão',
    type: 'label'
  },
  {
    id: 1527,
    name: '',
    type: 'grid',
    columns: oilwellTest
  },
  {
    id: 258,
    name: 'Registro de Ocorrências em Equipamentos',
    type: 'label'
  },
  {
    id: 1199,
    name: 'Tanques',
    type: 'grid',
    columns: ocTank
  },
  {
    id: 1200,
    name: 'Válvulas – Comportas de passagem de água pluvial',
    type: 'grid',
    columns: ocValve
  },
  {
    id: 1201,
    name: 'Produtos Químicos',
    type: 'grid',
    columns: ocChemicals
  },
  {
    id: 1202,
    name: 'Filtros',
    type: 'grid',
    columns: ocFilters
  },
  {
    id: 1203,
    name: 'Bombas',
    type: 'grid',
    columns: ocBombs
  },
  {
    id: 1204,
    name: 'Tratadores de emulsão',
    type: 'grid',
    columns: ocEmulssion
  },
  {
    id: 1208,
    name: 'Depuradores',
    type: 'grid',
    columns: ocDep
  },
  {
    id: 1209,
    name: 'SGs',
    type: 'grid',
    columns: ocSg
  },
  {
    id: 1205,
    name: 'UDGN',
    type: 'grid',
    columns: ocUdgn
  },
  {
    id: 1287,
    name: 'Hi-Lo',
    type: 'grid',
    columns: ocHilo
  },
  {
    id: 1343,
    name: 'XVs',
    type: 'grid',
    columns: ocXv
  },
  {
    id: 1341,
    name: 'Flare',
    type: 'grid',
    columns: ocFlare
  },
  {
    id: 1311,
    name: "Hi-Lo's",
    type: 'label'
  },
  {
    id: 1479,
    name: '',
    type: 'grid',
    columns: pressureHilo
  },
  {
    id: 1312,
    name: "XV's",
    type: 'label'
  },
  {
    id: 1480,
    name: '',
    type: 'grid',
    columns: pressureXv
  },
  {
    id: 1561,
    name: 'Situação da Bomba de Combate à Incêndio',
    type: 'label'
  },
  {
    id: 594,
    name: 'Situação',
    type: 'input'
  },
  {
    id: 1509,
    name: 'Nível (%)',
    type: 'input'
  },
  {
    id: 1508,
    name: 'Horas de Operação',
    type: 'input'
  },
  {
    id: 1191,
    name: 'Produtos Químicos',
    type: 'label'
  },
  {
    id: 1187,
    name: 'Níveis dos Produtos Químicos',
    type: 'grid',
    columns: chemicalLevels
  },
  {
    id: 1233,
    name: 'Flare',
    type: 'label'
  },
  {
    id: 1347,
    name: 'Registros - Flare',
    type: 'grid',
    columns: regFlare
  },
  {
    id: 1236,
    name: 'UDGN',
    type: 'label'
  },
  {
    id: 1237,
    name: '',
    type: 'grid',
    columns: udgnBomb
  },
  {
    id: 1238,
    name: "GEA's",
    type: 'label'
  },
  {
    id: 1320,
    name: 'Vazão total (m³/h) - GEA',
    type: 'input'
  },
  {
    id: 1481,
    name: '',
    type: 'grid',
    columns: gea
  },
  {
    id: 1192,
    name: 'Lançamento e Recebimento de Raspadores',
    type: 'label'
  },
  {
    id: 1221,
    name: '',
    type: 'grid',
    columns: rasp
  },
  {
    id: 366,
    name: 'Injetores de Desemulsificante',
    type: 'label'
  },
  {
    id: 1411,
    name: 'SG-122103 - Injeção (cm³/min)',
    type: 'grid',
    columns: sgInjection
  },
  {
    id: 1412,
    name: 'SG-122104 - Injeção (cm³/min)',
    type: 'grid',
    columns: sgInjection
  },
  {
    id: 1413,
    name: 'SG-122105 - Injeção (cm³/min)',
    type: 'grid',
    columns: sgInjection
  },
  {
    id: 1178,
    name: 'Preenchimento de Livros na NR13',
    type: 'label'
  },
  {
    id: 1179,
    name: '',
    type: 'grid',
    columns: nr13
  },
  {
    id: 372,
    name: 'Recebimentos',
    type: 'label'
  },
  {
    id: 932,
    name: 'Óleo',
    type: 'label',
    size: 'h2'
  },
  {
    id: 903,
    name: 'Dados de Recebimento',
    type: 'grid',
    columns: oilRecieve
  },
  {
    id: 1511,
    name: 'Dados de Transporte - Recebimentos',
    type: 'grid',
    columns: oilTransp
  },
  {
    id: 933,
    name: 'Resíduos',
    type: 'label'
  },
  {
    id: 478,
    name: '',
    type: 'grid',
    columns: controlTrash
  },
  {
    id: 397,
    name: 'Transferências',
    type: 'label'
  },
  {
    id: 1505,
    name: 'Volume Transferido (m³) - Pilar',
    type: 'input'
  },
  {
    id: 942,
    name: '',
    type: 'grid',
    columns: transfer
  },
  {
    id: 429,
    name: 'Controle de Lacres',
    type: 'label'
  },
  {
    id: 618,
    name: '',
    type: 'grid',
    columns: seal
  },
  {
    id: 440,
    name: 'Alinhamentos',
    type: 'label'
  },
  {
    id: 1259,
    name: '',
    type: 'grid',
    columns: manifold
  },
  {
    id: 479,
    name: 'Eventos (Passagem de Turno)',
    type: 'label'
  },
  {
    id: -3,
    name: 'Turno 01 - 00h às 07h',
    type: 'label'
  },
  {
    id: 492,
    name: 'Responsável',
    type: 'input'
  },
  {
    id: 480,
    name: '',
    type: 'grid',
    columns: turn
  },
  {
    id: -2,
    name: 'Turno 02 - 07h às 19h',
    type: 'label'
  },
  {
    id: 493,
    name: 'Responsável',
    type: 'input'
  },
  {
    id: 481,
    name: '',
    type: 'grid',
    columns: turn
  },
  {
    id: -1,
    name: 'Turno 03 - 19h às 23h59h',
    type: 'label'
  },
  {
    id: 494,
    name: 'Responsável',
    type: 'input'
  },
  {
    id: 482,
    name: '',
    type: 'grid',
    columns: turn
  }
];
