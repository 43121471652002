export const trackerTypeEnum: { [id: number]: string } = {
  8: 'Estação de Produção EPPIR',
  11: 'Estação de Produção EPFU',
  12: 'Campo - Equipe PIR',
  13: 'Campo - Supervisório PIR',
  14: 'Campo - Equipe FU',
  15: 'Campo - Supervisório FU',
  16: 'UPGN PIR Supervisório',
  18: 'Compressão ECFU',
  19: 'Compressão ECPIR',
  20: 'UPGN PIR Área',
  21: 'UPGN PIR - GLP',
  41: 'BDO Tucano - Campo',
  43: 'BDO Tucano - Manutenção',
  44: 'BDO Tucano - Supervisório'
};

const loc = {
  pir: 'Pilar',
  fur: 'Furado'
};

export const trackerLocation: { [id: number]: string } = {
  8: loc.pir,
  11: loc.fur,
  12: loc.pir,
  13: loc.pir,
  14: loc.fur,
  15: loc.fur,
  16: loc.pir,
  19: loc.pir,
  18: loc.fur,
  20: loc.pir,
  21: loc.pir
};

export enum integrityFormTracker {
  'Teste de DHSV' = 30,
  'Teste de Surgência' = 31,
  'Teste de Árvore de Natal' = 32,
  'Prontuário de Poço' = 33
}

export enum bdoTsTrackers {
  'BDO Tucano - Manutenção' = 43,
  'BDO Tucano - Campo' = 41,
  'BDO Tucano - Supervisório' = 44
}
