import React from 'react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  required?: boolean;
  isError?: boolean;
  className?: string; // Allow custom class overrides
}
const Checkbox: React.FC<CheckboxProps> = forwardRef<
  HTMLInputElement,
  CheckboxProps
>(({ label, required, isError, className, ...rest }, ref) => {
  return (
    <div className="flex items-center gap-2">
      <input
        type="checkbox"
        className="scale-125"
        {...rest}
        // checked={!!rest.value}
        ref={ref}
        id={rest.name}
      />
      {label && (
        <label htmlFor={rest.name} className="text-slate-700">
          {label}
        </label>
      )}
      {required && <span className="text-red-500 ml-2">*</span>}
    </div>
  );
});

export default Checkbox;
