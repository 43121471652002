import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Hora: '112',
  Evento: '1294',
  Duto: '1196',
  'N° do lacre': '842',
  Status: '1197',
  'Condição da Parafina': '844',
  Operador: '836',
  Observação: '712'
};

const rasp: Column[] = tablefy(items);

export default rasp;
