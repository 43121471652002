import {
  InteractionRequiredAuthError,
  InteractionStatus
} from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Login from '../../pages/Login';
import { checkSSO } from '../../utils/msal';
import Header from '../molecules/Header';
import Sidebar from '../molecules/SideBar';
import * as Sentry from '@sentry/react';

export const AuthTemplate = () => {
  const { instance, inProgress } = useMsal();
  const [isAuth, setIsAuth] = useState(false);

  const accessTokenRequest = {
    scopes: ['api://cc9de33b-0dff-4458-b352-e813186cdcfa/Portal.User'],
    account: instance.getAllAccounts()[0]
  };

  const user = useAccount();

  useEffect(() => {
    if (!isAuth && inProgress === InteractionStatus.None) {
      checkSSO()
        .then(() => setIsAuth(true))
        .catch(e => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
        });
    }
  }, [inProgress, isAuth, instance]);

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        email: user.username
      });
    }
  }, [inProgress, user]);

  return isAuth ? (
    <div className="flex h-screen w-screen bg-background">
      <Sidebar />
      <div className="flex flex-col w-full h-full max-h-screen overflow-auto overflow-x-hidden bg-background py-3 px-4">
        <Header />
        <Outlet />
      </div>
    </div>
  ) : (
    <Login isLoading redirectHome={false} />
  );
};
