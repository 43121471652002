import { useFormContext } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';
import { twMerge } from 'tailwind-merge';

interface InputProps {
  name: string;
  defaultValue?: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const InputCell = ({
  name,
  className,
  disabled,
  defaultValue,
  onChange
}: InputProps) => {
  const {
    register,
    setValue,
    formState: { dirtyFields }
  } = useFormContext();

  const [data, index, key] = name.split('.');

  return (
    <span
      className={twMerge(
        'text-[#191919] text-xs whitespace-nowrap w-full h-full relative flex-1'
      )}
    >
      <input
        {...register(name, {
          value: defaultValue
        })}
        type="text"
        autoFocus
        onChange={e => {
          register(name).onChange(e);
          onChange?.(e);
        }}
        disabled={disabled}
        className={twMerge(
          'bg-transparent h-8 w-full text-center rounded bg-white border border-[#D8D8D8] active:border-[#cbcbcb]',
          dirtyFields?.[data]?.[index]?.[key] && 'bg-persian-blue-100',
          className ?? ''
        )}
      />

      {!disabled && dirtyFields?.[data]?.[index]?.[key] && (
        <button
          className="bg-slate-50 hover:bg-slate-100 p-1 rounded-full absolute transition-colors right-2 -top-1"
          type="button"
          onClick={() => {
            setValue(name, defaultValue ?? '');
          }}
        >
          <IoClose color="#666" />
        </button>
      )}
    </span>
  );
};
