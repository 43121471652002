import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Hora Início': '527',
  'Hora Fim': '528',
  Local: '2745',
  'Atividades ': '2732',
  Atividades: '797',
  'Classificação da Atividade': '1628',
  Solicitante: '1629'
};

const activitiesRegistryM: Column[] = tablefy(items);

export default activitiesRegistryM;
