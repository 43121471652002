import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react';
import Login from '../../pages/Login';
import { AuthTemplate } from './AuthTemplate';

const RootLayout = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <AuthTemplate />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Login redirectHome={false} />
      </UnauthenticatedTemplate>
    </>
  );
};

export default RootLayout;
