import { useAccount } from '@azure/msal-react';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { Header } from '../../../components/atoms/table/Header';
import { DateHourCell } from '../../../components/atoms/table/HourCell';
import { SelectCell } from '../../../components/atoms/table/SelectCell';
import { objectify } from '../../filters/WorflowTests';

const priority = [
  {
    Header: () => <Header text={'N° OM'} id="N° OM" />,
    accessor: 'ordem',
    Cell: DefaultCell,
    width: '1%'
  },
  {
    Header: () => <Header text={'Nota'} id="Nota" />,
    accessor: 'nota',
    Cell: DefaultCell,
    width: '1%'
  },
  {
    Header: () => <Header text={'Descrição Breve'} id="Descrição Breve" />,
    accessor: 'descricao',
    Cell: DefaultCell,
    width: '20%'
  },
  {
    Header: () => <Header text={'Estação'} id="Estação" />,
    accessor: 'estacao',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Equipamento'} id="Equipamento" />,
    accessor: 'equipamento',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Data Base Início'} id="Data Base Início" />,
    accessor: 'concl_desejada',
    Cell: (e: any) => <DateHourCell {...e} dateFormat="dd/MM/yyyy" />
  },
  {
    Header: () => <Header text={'Priorização'} id="Priorização" />,
    accessor: 'priority',
    Cell: ({ row: { id, values }, value }: any) => {
      const user = useAccount();
      const userCanEdit =
        user?.idTokenClaims?.roles?.includes('Portal.Admin') ||
        user?.idTokenClaims?.roles?.includes('Portal.PiopAdmin');

      return (
        <SelectCell
          name={`data.${id}.prioridade`}
          options={['Alta', 'Média', 'Baixa'].map(objectify)}
          key={values.ordem}
          disabled={userCanEdit ? false : !!value}
        />
      );
    }
  }
];

export default priority;
