import { addDays, format, parseISO } from 'date-fns';
import { workPermitFilterType } from '../state/workPermit.filters';
import { getIssuesRedmine } from './utils.services';

export const getWorkPermitByStatusIds = async (
  status_id: number[],
  offset = 0,
  limit = 13, // NEVER CHANGE TO 15
  filters: workPermitFilterType = {
    description: [],
    emitGroup: [],
    highRisk: [],
    timeRange: {
      startDate: null,
      endDate: null
    },
    activity: [],
    order: undefined,
    type: [],
    matter: [],
    executionArea: []
  },
  invertDates = false
) => {
  const { data } = await getIssuesRedmine({
    project_id: 8,
    status_id: status_id.join('|'),
    offset: offset * limit,
    limit,
    sort: `cf_1863${invertDates ? ':desc' : ''},id:desc`,
    include: 'parent',
    cf_1859: filters.description.join('|') || null,
    cf_1860: filters.executionArea.join('|') || null,
    cf_1861: filters.emitGroup.join('|') || null,
    cf_1873: filters.type.join('|') || null,
    cf_1862: filters.order ? `~${filters.order}` : null,
    cf_1946:
      filters.highRisk.length == 1
        ? filters.highRisk[0] == 'Sim'
          ? '*'
          : '!*'
        : null,
    cf_1863:
      filters.timeRange?.startDate && filters.timeRange?.endDate
        ? `><${filters.timeRange.startDate}T03:00:00|${
            filters.timeRange.startDate === filters.timeRange.endDate
              ? format(
                  addDays(parseISO(filters.timeRange.endDate), 1),
                  'yyyy-MM-dd'
                )
              : filters.timeRange.endDate
          }T03:00:00`
        : undefined,
    cf_1865: filters.activity.join('|') || null,
    cf_2056: filters.matter.join('|') || null
  });

  return data;
};
