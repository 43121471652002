import { twMerge } from 'tailwind-merge';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  icon?: React.ReactNode;
  className?: string;
  classNameSpan?: string;
}

const Button: React.FC<ButtonProps> = ({
  title,
  icon,
  className,
  classNameSpan,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={twMerge(
        `
          flex items-center justify-center gap-2
          rounded-3xl 
          bg-primary hover:bg-primary-dark 
          transition-all duration-200
          text-white
          px-4 py-2
          disabled:bg-[#bddaff] disabled:hover:bg-[#bddaff]
          disabled:color-[#1648df]
          disabled:cursor-not-allowed
          `,
        className ?? ''
      )}
    >
      {icon}
      <span className={classNameSpan}>{title}</span>
    </button>
  );
};

export default Button;
