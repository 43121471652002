import Button from '@/components/atoms/Button';
import ModalConfirmation from '@/components/molecules/ConfirmationModal';
import api from '@/services/apiPortal';
import { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import EmptyMessage from '../../components/atoms/EmptyMessage';
import H1 from '../../components/atoms/H1';
import { PageWrapper } from '../../components/atoms/PageWrapper';
import Spinner from '../../components/atoms/Spinner';
import TabsItem from '../../components/atoms/TabsItem';
import PageNavigation from '../../components/molecules/PageNavigation';
import SideFilter from '../../components/molecules/SideFilter';
import Table from '../../components/organisms/Table';
import { pressureAlertFilters } from '../../constants/filters/AlertFilters';
import pressureAlertCols from '../../constants/tableColumns/alerts/pressureAlerts';
import usePressureAlerts from '../../hooks/Alerts/usePressureAlerts';
import { PressureAlertsAtom } from '../../state/alerts.atom';
import { useAccount } from '@azure/msal-react';

export const AlertsPressures = () => {
  const [tab, setTab] = useState(0);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [alertFields, setAlertFields] = useState<any>([]);
  const filterButtons = ['24H', '48H', '7D'].map((it, idx) => ({
    label: it,
    onClick: () => {
      setTab(idx);
    }
  }));

  const {
    data,
    isFetching,
    isLoading,
    handleFilter,
    perPage,
    currentPage,
    setCurrentPage
  } = usePressureAlerts(tab);

  const componentRef = useRef(null);
  const methods = useForm({
    shouldUnregister: false
  });

  const user = useAccount();

  const hasPermissionToSendEmail =
    user?.idTokenClaims?.roles?.includes('Portal.SuperAdmin') ||
    user?.idTokenClaims?.roles?.includes('Portal.AlertAnalyst');

  const formRef = useRef<HTMLFormElement>(null);

  const onSubmit = async (formData: any) => {
    const body = {
      data: Object.entries(formData)
        .filter(([key, value]) => !!value && !key.includes('remove'))
        .map(e => e[1]),
      hour: data?.currentHour ?? null
    };

    try {
      await api.post('/oilwells/alerts/send-email', body);
      toast.success('Email enviado com sucesso');
    } catch (e) {
      toast.error('Erro ao enviar email');
    }

    methods.reset();
  };

  return (
    <PageWrapper className={'p-0'}>
      <a
        className="w-fit absolute right-16 -top-[40px]"
        href={
          'https://pi.origemenergia.com:5535/PIVision/#/Displays/1290/POÇOS'
        }
        target="_blank"
      >
        <Button title="Dados PI Vision" type="button" />
      </a>
      <div
        className="w-full flex flex-col relative pt-4 px-2 rounded-lg bg-white mt-2 pb-6"
        ref={componentRef}
      >
        <img
          src="/origem.png"
          alt="Logo Origem"
          className="w-[203px] resize-none max-w-none -ml-5 only-print"
        />

        <H1 className="px-4 whitespace-nowrap">
          <span className="flex gap-4">
            Análise de Pressão
            {isFetching ? <Spinner size={18} /> : null}
          </span>
          <div className="flex gap-2">
            {Object.values(filterButtons).map((it, idx) => (
              <TabsItem key={it.label} {...it} menu={tab} idx={idx} />
            ))}
          </div>
        </H1>
        {isLoading ? (
          <Spinner size={24} />
        ) : (
          <section className="flex flex-col rounded-lg px-2 py-3 pb-0 bg-white">
            <FormProvider {...methods}>
              <form
                className="w-full"
                onSubmit={methods.handleSubmit(onSubmit)}
                ref={formRef}
              >
                <div className="flex w-full overflow-y-auto max-h-[calc(100vh-220px)] scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
                  {!data || data?.data?.length < 1 ? (
                    <EmptyMessage
                      message="Sem dados disponíveis"
                      className="min-h-[2rem] w-min"
                    />
                  ) : (
                    <Table
                      columns={
                        hasPermissionToSendEmail
                          ? pressureAlertCols
                          : pressureAlertCols.slice(1)
                      } // Se não tiver permissão para enviar email, apaga a coluna de checkbox
                      data={data?.data}
                      headerClassName="sticky top-0 bg-white z-10"
                    />
                  )}
                </div>
                {hasPermissionToSendEmail && (
                  <Button
                    disabled={!methods.formState.isDirty}
                    title="Enviar emails"
                    type="button"
                    onClick={() => {
                      setModalDeleteIsOpen(true);
                      setAlertFields(
                        Object.entries(methods.getValues())
                          .filter(
                            ([key, value]) => !!value && !key.includes('remove')
                          )
                          .map(([key, value]) => value)
                      );
                    }}
                    className="absolute bottom-4"
                  />
                )}
                {modalDeleteIsOpen && (
                  <ModalConfirmation
                    confirmAction={() => {
                      if (formRef.current) {
                        formRef.current?.dispatchEvent(
                          new Event('submit', {
                            cancelable: true,
                            bubbles: true
                          })
                        );
                      }
                    }}
                    title="Enviar alerta por email"
                    description="Ao confirmar esses alertas serão enviado por email:"
                    confirmButtonClassName="bg-emerald-600 hover:bg-emerald-400"
                    isOpen={true}
                    setIsOpen={() => setModalDeleteIsOpen(!modalDeleteIsOpen)}
                  >
                    <div className="flex w-full px-4 py-2 gap-2 flex-wrap max-h-[50vh] overflow-y-auto">
                      {alertFields.map((it: any) => (
                        <span
                          key={`${it.oilwell}/${it.day}/${it.interval}`}
                          className="font-medium w-fit bg-background text-primary text-xs px-5 py-2 rounded-full hover:bg-backgrond-btn-hover transition-colors"
                        >
                          <strong>{it.oilwell}</strong> | {it.day} |{' '}
                          {it.interval}
                        </span>
                      ))}
                    </div>
                  </ModalConfirmation>
                )}
              </form>
            </FormProvider>

            {!!data && (
              <PageNavigation
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                className="mt-2"
                totalPages={Math.ceil(data?.meta?.count / perPage)}
                perPage={perPage}
              />
            )}
          </section>
        )}
      </div>

      <SideFilter
        atom={PressureAlertsAtom}
        filters={pressureAlertFilters}
        applyChanges={handleFilter}
      />
    </PageWrapper>
  );
};
