import { twMerge } from 'tailwind-merge';

interface TextFieldProps {
  name: string;
  value: string | string[];
  key?: string;
  isCol?: boolean;
  isFullWidth?: boolean;
  className?: string;
  valueClassName?: string;
  labelClassName?: string;
}

export const TextField = ({
  name,
  value,
  isCol,
  className,
  valueClassName,
  labelClassName,
  isFullWidth
}: TextFieldProps) => {
  let displayedValue = value;
  if (typeof value === 'object') {
    displayedValue = value?.join(', ');
  }

  return (
    <div
      className={twMerge(
        `flex w-[calc(50%-2rem)] p-1`,
        isCol ? 'flex flex-col' : 'gap-1',
        isFullWidth ? 'w-full' : '',
        className ?? ''
      )}
    >
      <span className={twMerge('font-bold text-body', labelClassName)}>
        {name}:
      </span>
      <span
        className={twMerge(
          'text-gray ml-1 whitespace-pre-wrap',
          valueClassName
        )}
      >
        {displayedValue ? displayedValue : '-'}
      </span>
    </div>
  );
};
