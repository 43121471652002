export enum integrityFormStatus {
  'Preenchimento do Formulário' = 58,
  'Revisão do Formulário' = 62,
  'Correção do Formulário' = 63,
  'Formulário Conc. Validado' = 64,
  'Formulário Conc. Invalidado' = 65,
  'Formulário Programado' = 70,
  'Formulário Cancelado' = 71
}

export const piopStatus = {
  'Aguardando Priorização': 77,
  'Delineamento da OM': 78,
  'Detalhamento das Pendências': 80,
  'Verificação de Pendências': 81,
  'Impactos na Produção': 82,
  'Aguardando Simultaneidade/Gerenciamento de Recurso': 83,
  'Simultaneidade Aprovada': 84,
  'OM Cancelada': 85,
  'OM Realizada': 86,
  'OM Concluída': 87,
  'Aguardando Resolução da(s) Pendências': 88,
  'Distribuição/Detalhamento de Recursos': 89,
  'Aguardando Comissão de Priorização Semanal': 90,
  'Aguardando Análise da Comissão de Operação': 91
} as const;

export const allBpmsStatus: { [key: number]: string } = {
  18: 'Nova Solicitação de Teste',
  19: 'Solicitação em Análise',
  21: 'Solicitação Aprovada',
  28: 'Novo Teste',
  13: 'Replanejamento',
  23: 'Teste Programado',
  26: 'Teste Cancelado',
  24: 'Alinhamento Campo',
  27: 'Envio da Cromatografia',
  17: 'Parametrização do teste',
  7: 'Preenchimento Boletim',
  8: 'Revisão do Boletim',
  16: 'Upload do Boletim',
  10: 'Validação CProd',
  100: 'Validação Elevação',
  15: 'Invalidado - CPROD',
  11: 'Validação Produção',
  14: 'Invalidado - Produção',
  12: 'Teste Concluído',
  20: 'Solicitação Cancelada',
  29: 'Preenchimento do Boletim',
  30: 'Revisão do Boletim - Polo',
  31: 'Aprovação do Boletim',
  32: 'Revisão do Boletim - Matriz',
  35: 'Correção do Boletim - Polo',
  33: 'Upload do Boletim - Matriz',
  34: 'Boletim Concluído',
  36: 'Preenchimento do BDO',
  37: 'Revisão do BDO',
  38: 'BDO Concluído',
  40: 'BDM Validação',
  41: 'BDM Revisão',
  39: 'BDM Concluído',
  42: 'Preenchimento de Carregamento',
  43: 'Preenchimento de PCS',
  44: 'Validação Produção (Em espera)',
  45: 'Requisição da PT',
  61: 'Correção da PT',
  46: 'Elaboração Emitente',
  67: 'Elaboração Coemitente',
  68: 'Validação Liderança',
  69: 'Validação QSMS',
  47: 'Emissão em Campo',
  48: 'Aprovação Téc. Segurança',
  74: 'Renovação Requisitante',
  75: 'Renovação Emitente',
  50: 'Execução do Serviço',
  51: 'Quitação Emitente',
  72: 'Quitação Coemitente',
  52: 'Quitação Téc. Segurança',
  54: 'Reprogramação da PT',
  56: 'PT Cancelada',
  53: 'PT Concluída',
  55: 'Renovação da PT',
  49: 'Assinatura Executor',
  66: 'Execução da Atividade',
  58: 'Preenchimento do Formulário',
  62: 'Revisão do Formulário',
  63: 'Correção do Formulário',
  64: 'Formulário Conc. Validado',
  65: 'Formulário Conc. Invalidado',
  70: 'Formulário Programado',
  71: 'Formulário Cancelado',
  73: 'Nova Solicitação',
  77: 'Aguardando Priorização',
  78: 'Delineamento da OM',
  99: 'Programação',
  89: 'Distribuição de Recursos',
  80: 'Detalhamento das Pendências',
  81: 'Verificação de Pendências',
  88: 'Aguardando Resolução da(s) Pendências',
  82: 'Impactos na Produção',
  90: 'Aguardando Comissão Semanal',
  91: 'Aguardando Análise da Comissão de Operação',
  83: 'Aguardando Simultaneidade',
  84: 'Simultaneidade Aprovada',
  85: 'OM Cancelada',
  86: 'OM Realizada',
  87: 'OM Concluída',
  92: 'Solicitação',
  93: 'Validação',
  103: 'Concluída',
  95: 'Solicitação',
  94: 'Aguardando Aprovação',
  96: 'Delineamento de Atividades Extras',
  101: 'Cancelada',
  104: 'Concluída',
  97: 'Editar Tudo',
  102: 'BDM Comercial',
  105: 'Revisão do Boletim - Supervisório',
  106: 'Aprovação do Boletim - Terceiro',
  107: 'Aprovação do Boletim - Fiscal Origem',
  108: 'Aprovação do Boletim - Engenheiro Origem',
  109: 'Correção do Boletim',
  110: 'Em elaboração',
  111: 'Em validação',
  112: 'Cargo Revisado',
  113: 'Cargo Completo',
  114: 'Reprovado',
  115: 'Descrição Insuficiente',
  116: 'Enviar para Aprovação',
  117: 'Solicitação Negada',
  118: 'Enviar para Enquadramento',
  119: 'Cargo Desativado',
  120: 'Solicitação Rejeitada',
  121: 'Em Processamento',
  122: 'Novo Boletim',
  123: 'Nova Solicitação de Operação',
  124: 'Operação Programada',
  125: 'Confirmação da USL',
  126: 'Validação do Boletim',
  127: 'Replanejamento da Operação',
  128: 'Operação Abortada',
  131: 'Solicitação Completa',
  132: 'Solicitação incompleta',
  133: 'Descrição completa',
  134: 'Solicitação reprovada',
  137: 'Revisão Fiscal',
  138: 'Revisão Engenharia',
  141: 'Novo SITOP',
  139: 'Preenchimento do SITOP',
  140: 'SITOP Concluído'
};
