import { format } from 'date-fns';
import { useState } from 'react';
import { BiPencil } from 'react-icons/bi';
import { BsTrash3Fill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { queryClient } from '../../../App';
import SquareButton from '../../../components/atoms/SquareButton';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { Header } from '../../../components/atoms/table/Header';
import ModalConfirmation from '../../../components/molecules/ConfirmationModal';
import { deleteOilwellZoneAllocation } from '../../../services/oilwells.services';
import { NumberCell } from '../../../utils/tablesUtils';

export const TimeCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs whitespace-nowrap">
      {value ? format(new Date(value), 'dd/MM/yyyy') : '-'}
    </span>
  );
};

const oilwellZoneAllocation = [
  {
    Header: () => <Header text={'ID'} id="id" />,
    accessor: 'id',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Poço'} id="oilwell" />,
    accessor: 'oilwell.name',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Zona'} id="zone" />,
    accessor: 'zone.code',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Sigla da Zona'} id="acronym" />,
    accessor: 'zone.acronym',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        text={'Apropriação Óleo'}
        id="approprietionOil"
        className="text-center"
      />
    ),
    accessor: 'approprietionOil',
    Cell: NumberCell
  },
  {
    Header: () => (
      <Header
        text={'Apropriação Condensado'}
        id="approprietionCondensed"
        className="text-center"
      />
    ),
    accessor: 'approprietionCondensed',
    Cell: NumberCell
  },
  {
    Header: () => (
      <Header
        text={'Apropriação GNA Associado'}
        id="approprietionAssociatedGas"
        className="text-center"
      />
    ),
    accessor: 'approprietionAssociatedGas',
    Cell: NumberCell
  },
  {
    Header: () => (
      <Header
        text={'Apropriação GNA Não Associado'}
        id="approprietionNaturalGas"
        className="text-center h-auto"
      />
    ),
    accessor: 'approprietionNaturalGas',
    Cell: NumberCell
  },
  {
    Header: () => (
      <Header
        text={'Apropriação Água'}
        id="approprietionWater"
        className="text-center"
      />
    ),
    accessor: 'approprietionWater',
    Cell: NumberCell
  },
  {
    Header: () => (
      <Header
        text={'Injeção Água'}
        id="injectionWater"
        className="text-center"
      />
    ),
    accessor: 'injectionWater',
    Cell: NumberCell
  },
  {
    Header: () => (
      <Header text={'Injeção Gás'} id="injectionGas" className="text-center" />
    ),
    accessor: 'injectionGas',
    Cell: NumberCell
  },
  {
    Header: () => <Header text={'Data de Atualização'} id="updatedAt" />,
    accessor: 'updatedAt',
    Cell: TimeCell
  },
  {
    Header: () => <Header text={'Ações'} id="actions" />,
    accessor: 'actions',
    Cell: (props: any) => {
      const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
      const handleDeleteZone = async () => {
        try {
          await deleteOilwellZoneAllocation(props.row.original.id);
          toast.success('Zona deletada com sucesso');
          queryClient.invalidateQueries({
            predicate: query => query.queryKey[0] === 'oilwells-zones-adm'
          });
        } catch {
          toast.error('Erro ao deletar alocação');
        }
      };

      return (
        <>
          <div className="flex gap-2">
            <SquareButton
              className="bg-red hover:bg-red/75"
              onClick={() => setModalDeleteIsOpen(old => !old)}
              icon={<BsTrash3Fill color="white" size={16} />}
            />
            <SquareButton
              onClick={() =>
                window.open(
                  `/oilwell-zone-allocation/${props.row.original.oilwell.id}`,
                  '_blank'
                )
              }
              icon={<BiPencil color="white" size={16} />}
            />
          </div>

          {modalDeleteIsOpen && (
            <ModalConfirmation
              confirmAction={handleDeleteZone}
              title="Deletar alocação"
              description="Tem certeza que deseja deletar essa alocação? Essa é uma ação permanente."
              confirmButtonClassName="bg-emerald-600 hover:bg-emerald-400"
              isOpen={true}
              setIsOpen={() => setModalDeleteIsOpen(!modalDeleteIsOpen)}
            />
          )}
        </>
      );
    }
  }
];

export default oilwellZoneAllocation;
