import { format, parseISO } from 'date-fns';
import { twMerge } from 'tailwind-merge';
import { InputCell } from '../../../components/atoms/table/InputCell';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { SelectCell } from '../../../components/atoms/table/SelectCell';
import { injectionTypes } from '../../filters/OilWellsTable';

export const TimeCell = ({ value }: any) => {
  if (!value || value == 'present') return <span>-</span>;

  return (
    <span className="text-[#191919] text-xs whitespace-nowrap">
      {value ? format(parseISO(value.replace('Z', '')), 'dd/MM/yyyy') : '-'}
    </span>
  );
};

const TotalComponent = (value: string) => {
  return (
    <span
      className={twMerge(
        'font-bold text-center text-base w-full block',
        Number(value) != 100 && Number(value) != 0 ? 'text-red' : ''
      )}
    >
      {value}
    </span>
  );
};

const oilwellZoneAllocationEdit = [
  {
    Header: () => <Header text={'ID'} id="id" className="" />,
    accessor: 'id',
    Cell: ({ value }: any) => {
      if (value == 'Total') return <span className="font-bold">{value}</span>;
      return (
        <span className="text-[#191919] text-xs whitespace-nowrap w-full">
          {value}
        </span>
      );
    }
  },
  {
    Header: () => <Header text={'Poço'} id="oilwell" />,
    accessor: 'oilwell.name',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Zona'} id="zone" />,
    accessor: 'zone.code',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Sigla da Zona'} id="acronym" />,
    accessor: 'zone.acronym',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        text={'Tipo de Injeção'}
        id="injectionType"
        className="text-center"
      />
    ),
    accessor: 'injectionType',
    Cell: ({ row: { id, values }, value }: any) => {
      if (values.id == 'Total') return TotalComponent(value);
      return (
        <SelectCell
          options={injectionTypes}
          name={`data.${id}.injectionType`}
        />
      );
    }
  },
  {
    Header: () => (
      <Header
        text={'Apropriação Óleo'}
        id="approprietionOil"
        className="text-center"
      />
    ),
    accessor: 'approprietionOil',
    Cell: ({ row: { id, values }, value }: any) => {
      if (values.id == 'Total') return TotalComponent(value);
      return <InputCell name={`data.${id}.approprietionOil`} />;
    }
  },
  {
    Header: () => (
      <Header
        text={'Apropriação Condensado'}
        id="approprietionCondensed"
        className="text-center"
      />
    ),
    accessor: 'approprietionCondensed',
    Cell: ({ row: { id, values }, value }: any) => {
      if (values.id == 'Total') return TotalComponent(value);
      return <InputCell name={`data.${id}.approprietionCondensed`} />;
    }
  },
  {
    Header: () => (
      <Header
        text={'Apropriação GNA Associado'}
        id="approprietionAssociatedGas"
        className="text-center"
      />
    ),
    accessor: 'approprietionAssociatedGas',
    Cell: ({ row: { id, values }, value }: any) => {
      if (values.id == 'Total') return TotalComponent(value);
      return <InputCell name={`data.${id}.approprietionAssociatedGas`} />;
    }
  },
  {
    Header: () => (
      <Header
        text={'Apropriação GNA Não Associado'}
        id="approprietionNaturalGas"
        className="text-center h-auto"
      />
    ),
    accessor: 'approprietionNaturalGas',
    Cell: ({ row: { id, values }, value }: any) => {
      if (values.id == 'Total') return TotalComponent(value);
      return <InputCell name={`data.${id}.approprietionNaturalGas`} />;
    }
  },
  {
    Header: () => (
      <Header
        text={'Apropriação Água'}
        id="approprietionWater"
        className="text-center"
      />
    ),
    accessor: 'approprietionWater',
    Cell: ({ row: { id, values }, value }: any) => {
      if (values.id == 'Total') return TotalComponent(value);
      return <InputCell name={`data.${id}.approprietionWater`} />;
    }
  },
  {
    Header: () => (
      <Header
        text={'Injeção Água'}
        id="injectionWater"
        className="text-center"
      />
    ),
    accessor: 'injectionWater',
    Cell: ({ row: { id, values }, value }: any) => {
      if (values.id == 'Total') return TotalComponent(value);
      return <InputCell name={`data.${id}.injectionWater`} />;
    }
  },
  {
    Header: () => (
      <Header text={'Injeção Gás'} id="injectionGas" className="text-center" />
    ),
    accessor: 'injectionGas',
    Cell: ({ row: { id, values }, value }: any) => {
      if (values.id == 'Total') return TotalComponent(value);
      return <InputCell name={`data.${id}.injectionGas`} />;
    }
  },
  {
    Header: () => <Header text={'Data de Aplicação'} id="aplication date" />,
    accessor: 'applicationDate',
    Cell: TimeCell
  }
];

export default oilwellZoneAllocationEdit;
