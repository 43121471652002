import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const EchometerCols: Column[] = [
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'Poço',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Campo'} id="Campo" />,
    accessor: 'Campo',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Tipo de Nível'} id="Tipo de Nível" />,
    accessor: 'Tipo de Nível',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Local de Medição'} id="Local de Medição" />,
    accessor: 'Local de Medição',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Atividade'} id="Atividade" />,
    accessor: 'Atividade',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Pcoluna'} id="Pcoluna" />,
    accessor: 'Pcoluna',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Plinha'} id="Plinha" />,
    accessor: 'Plinha',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'PanularA'} id="PanularA" />,
    accessor: 'PanularA',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: (
      <Header text={'Nível antes do ciclo (m)'} id="Nível antes do ciclo (m)" />
    ),
    accessor: 'Nível antes do ciclo (m)',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: (
      <Header
        text={'Nível 10 min após o ciclo (m)'}
        id="Nível 10 min após o ciclo (m)"
      />
    ),
    accessor: 'Nível 10 min após o ciclo (m)',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: (
      <Header
        text={'Nível 20 min após o ciclo (m)'}
        id="Nível 20 min após o ciclo (m)"
      />
    ),
    accessor: 'Nível 20 min após o ciclo (m)',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: (
      <Header
        text={'Nível 30 min após o ciclo (m)'}
        id="Nível 30 min após o ciclo (m)"
      />
    ),
    accessor: 'Nível 30 min após o ciclo (m)',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: (
      <Header
        text={'Nível 40 min após o ciclo (m)'}
        id="Nível 40 min após o ciclo (m)"
      />
    ),
    accessor: 'Nível 40 min após o ciclo (m)',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: (
      <Header
        text={'Nível 50 min após o ciclo (m)'}
        id="Nível 50 min após o ciclo (m)"
      />
    ),
    accessor: 'Nível 50 min após o ciclo (m)',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: (
      <Header
        text={'Nível 60 min após o ciclo (m)'}
        id="Nível 60 min após o ciclo (m)"
      />
    ),
    accessor: 'Nível 60 min após o ciclo (m)',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: <Header text={'Observação'} id="Observação" />,
    accessor: 'Observação',
    Cell: DefaultCell,
    minWidth: 120
  }
];

export default EchometerCols;
