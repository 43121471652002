import { useQuery } from '@tanstack/react-query';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import ActiveLink from '../../components/atoms/ActiveLink';
import { getDrillingBdos } from '../../services/bdos.services';

const BDO: React.FC = () => {
  const { id } = useParams();

  const { data: hasDrillingBdo } = useQuery({
    queryKey: ['Drilling', id],
    queryFn: async () =>
      (await getDrillingBdos(1, 1, id as string, '*')).issues.length === 1
  });

  const subMenuItems = [
    {
      path: `/home/oilwell-info/${id}/summary`,
      label: 'Resumo'
    },
    {
      path: `/home/oilwell-info/${id}/tests`,
      label: 'Testes'
    },
    {
      path: `/home/oilwell-info/${id}/pressures`,
      label: 'Pressões'
    },
    {
      path: `/home/oilwell-info/${id}/flow-rate`,
      label: 'Injeção'
    },
    {
      path: `/home/oilwell-info/${id}/opening-closing`,
      label: 'Abertura e Fechamento'
    },
    {
      path: `/home/oilwell-info/${id}/equips`,
      label: 'Atividades Operacionais'
    },
    {
      path: `/home/oilwell-info/${id}/rip-control`,
      label: 'Formulários INP'
    },
    {
      path: `/home/oilwell-info/${id}/drilling`,
      label: 'Perfuração'
    },
    {
      path: `/home/oilwell-info/${id}/slickline`,
      label: 'Slickline'
    }
    // { path: `/home/oilwell-info/${id}/alerts`, label: "Alertas" },
  ].filter(it => (it.label === 'Perfuração' ? hasDrillingBdo : true));

  return (
    <div className="h-[calc(100%-78.6px)] pb-4">
      <ul className="flex gap-1 text-slate-700 mt-2">
        {subMenuItems.map(item => (
          <ActiveLink
            to={item.path}
            key={item.path}
            className="px-4 py-1 rounded bg-white hover:bg-slate-50 "
          >
            {item.label}
          </ActiveLink>
        ))}
      </ul>
      <div
        className={twMerge(
          'flex flex-col mt-2 bg-white rounded-[10px] py-4 h-full',
          ['summary', 'slickline', 'drilling']
            .map(it => `/home/oilwell-info/${id}/${it}`)
            .includes(useLocation().pathname)
            ? 'bg-transparent py-0'
            : ''
        )}
      >
        <section className="w-full h-[full] overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full ">
          <Outlet />
        </section>

        {/* <SideFilter
          atom={BDOFiltersAtom}
          filters={BDOFilters}
          applyChanges={() => {}}
        /> */}
      </div>
    </div>
  );
};

export default BDO;
