import { Column } from 'react-table';
import { tablefy } from '../../../../utils/tablesUtils';

const items: any = {
  Hora: '112',
  Tanques: '1139',
  TAG: '663',
  'Lacre Rompido': '510',
  'Lacre Atual': '582',
  'Tipo de Válvula': '1594',
  Motivo: '664',
  Responsável: '665',
  Observação: '712'
};

const sealPir: Column[] = tablefy(items);

export default sealPir;
