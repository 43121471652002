import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Nome: 'name',
  Data: 'date'
};

const refreshTimes: Column[] = tablefy(items);

export default refreshTimes;
