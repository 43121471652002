import { twMerge } from 'tailwind-merge';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { Header } from '../../../components/atoms/table/Header';

const weekly = [
  {
    Header: () => <Header text={'Título'} id="Id" />,
    accessor: 'title',
    Cell: ({
      value,
      row: {
        original: { issue_id }
      }
    }: any) => {
      return (
        <a
          className="text-primary text-xs font-bold"
          href={`${import.meta.env.VITE_REDMINE_URL}/issues/${issue_id}`}
          target="_blank"
        >
          {value?.replace('OM - SAP - ', '')?.replace('OM - ', '')}
        </a>
      );
    },
    width: '6%'
  },
  {
    Header: () => <Header text={'Estação'} id="Texto Breve" />,
    accessor: 'station',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Equipamento'} id="Equipamento" />,
    accessor: 'equipment',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Disciplina'} id="Disciplina" />,
    accessor: 'discipline',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Texto Breve'} id="Texto Breve" />,
    accessor: 'description',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        text={'Texto Breve Descrição da Operação'}
        id="Texto Breve Descrição da Operação"
      />
    ),
    accessor: 'descriptionOp',
    Cell: DefaultCell,
    width: '26%'
  },
  {
    Header: () => <Header text={'Data Base Fim'} id="Inicio Programado" />,
    accessor: 'dataBaseEnd',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Inicio Programado'} id="Inicio Programado" />,
    accessor: 'startScheduled',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Número da PT'} id="relations" />,
    accessor: 'relations',
    Cell: ({ value }: any) => {
      return (
        <a
          className={twMerge(
            'text-primary text-xs font-bold',
            value?.to_id ? 'underline' : 'text-gray'
          )}
          href={value?.to_id ? `/home/work-permit/${value?.to_id}` : '#'}
          target="_blank"
        >
          {value?.subject?.replace('PT - ', '')}
        </a>
      );
    }
  },
  {
    Header: () => <Header text={'Status da PT'} id="PT Status" />,
    accessor: 'relations.status',
    Cell: DefaultCell
  }
];

export default weekly;
