import { equipment } from '../arrays/equipment';
import { objectify } from './WorflowTests';

export const PiopFilters = [
  {
    key: 'station',
    name: 'Estação',
    values: [
      'FURADO - CAMPO',
      'FURADO - ESTAÇÃO',
      'FURADO - O&M COMPRESSÃO',
      'FURADO - SUBESTAÇÃO',
      'PILAR - CAMPO',
      'PILAR - ESTAÇÃO',
      'PILAR - FLUIDOS',
      'PILAR - LABORATÓRIO',
      'PILAR - O&M COMPRESSÃO',
      'PILAR - P16',
      'PILAR - SUBESTAÇÃO',
      'UPGN'
    ]
      .sort((a, b) => a.localeCompare(b))
      .map(objectify)
  },
  {
    key: 'equipment',
    name: 'Equipamentos',
    values: equipment.sort((a, b) => a.localeCompare(b)).map(objectify)
  },
  {
    key: 'priority',
    name: 'Prioridade',

    values: ['Alta', 'Média', 'Baixa'].map(objectify)
  },
  {
    key: 'discipline',
    name: 'Disciplina',
    values: [
      'ANDAIME',
      'AUTOMACAO',
      'C&M',
      'CALDEIRARIA',
      'CIP',
      'ELETRICA',
      'FABRICAÇÃO  - PILAR',
      'FABRICAÇÃO - FURADO',
      'INSPECAO',
      'INSPEÇÃO',
      'INSPECAO - CALIBRAÇÃO',
      'INSPECAO - QUALIDADE',
      'INSPECAO - SPIE',
      'INSTRUMENTACAO',
      'JATEAMENTO PIPE SHOP',
      'LAVAGEM INDUSTRIAL',
      'MAN. CIVIL',
      'MAN. PREDIAL',
      'MAN. SERVIÇOS GERAIS',
      'MECANICA',
      'MECANICA CONFIABILIDADE',
      'MECANICA DE POÇOS',
      'MEDIÇÃO FISCAL',
      'O&M - INSTRUMENTACAO',
      'O&M - MECANICA',
      'OPERACAO',
      'PARADA',
      'PINTURA',
      'PLANEJAMENTO',
      'SEG',
      'TERRAPLANAGEM'
    ].map(objectify)
  }
];
