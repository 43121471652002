import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Data/Hora da Medição': '2643',
  'Vazão Instantânea (m³/d)': '2645'
};

const querera: Column[] = tablefy(items, undefined, undefined, undefined, [
  'Vazão Instantânea (m³/d)'
]);

export default querera;
