import { FaCircleInfo } from 'react-icons/fa6';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

interface HeaderProps extends React.HTMLAttributes<HTMLHeadingElement> {
  text: string;
  id: string;
  secondary?: boolean;
  terciary?: boolean;
  fourthary?: boolean;
  infoClassName?: string;
  info?: string;
}

export const Header: React.FC<HeaderProps> = ({
  text,
  id,
  secondary,
  terciary,
  fourthary,
  info,
  infoClassName,
  ...rest
}) => {
  return (
    <h1
      {...rest}
      id={id}
      key={id}
      className={twMerge(
        `flex text-primary text-start pb-3 text-xs px-3 py-1 h-10 items-center`,
        secondary ? 'bg-primary text-white rounded justify-center' : '',
        terciary ? 'h-8 text-primary bg-primary/10 rounded-sm' : '',
        fourthary ? 'bg-primary text-white rounded' : '',
        rest.className ?? ''
      )}
    >
      {text}
      {info ? (
        <span
          className={twMerge('ml-1', infoClassName)}
          data-tooltip-id={`alert-${id}`}
        >
          <FaCircleInfo color="#999" />
        </span>
      ) : null}

      <ReactTooltip
        id={`alert-${id}`}
        variant="dark"
        positionStrategy="fixed"
        style={{
          textAlign: 'left',
          whiteSpace: 'pre-wrap',
          zIndex: 9999
        }}
        offset={20}
        content={info}
      />
    </h1>
  );
};
