import { Column } from 'react-table';
import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const glcCols: Column[] = [
  {
    Header: <Header text={'Poço'} id="Poço" />,
    accessor: 'Poço',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: () => <Header text={'Data'} id="Data" />,
    accessor: 'Data',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 150
  },
  {
    Header: () => <Header text={'Atividade'} id="Atividade" />,
    accessor: 'Atividade',
    Cell: DefaultCell,
    minWidth: 120,
    width: '12.5%'
  },
  {
    Header: () => (
      <Header
        text={'Pressão de cabeça (kgf/cm²)'}
        id="Pressão de cabeça (kgf/cm²)"
      />
    ),
    accessor: 'Pressão de cabeça (kgf/cm²)',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 120
  },
  {
    Header: () => (
      <Header
        text={'Pressão de linha (kgf/cm²)'}
        id="Pressão de linha (kgf/cm²)"
      />
    ),
    accessor: 'Pressão de linha (kgf/cm²)',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => <Header text={'Ciclagem (min/s)'} id="Ciclagem (min/s)" />,
    accessor: 'Ciclagem (min/s)',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  },
  {
    Header: () => (
      <Header
        text={'Pressão de injeção(kgf/cm²)'}
        id="Pressão de injeção(kgf/cm²)"
      />
    ),
    accessor: 'Pressão de injeção(kgf/cm²)',
    Cell: DefaultCell,
    width: '12.5%',
    minWidth: 160
  }
];

export default glcCols;
