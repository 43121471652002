import { object, array, string, nullable } from '@recoiljs/refine';
import { atom } from 'recoil';
import { urlSyncEffect } from 'recoil-sync/';

export interface workPermitFilterType {
  description: string[];
  emitGroup: string[];
  highRisk: string[];
  timeRange: {
    startDate: string | null;
    endDate: string | null;
  };
  type: string[];
  activity: string[];
  matter: string[];
  order?: string;
  executionArea: string[];
}

export const workPermitAtom = atom<any>({
  key: 'workPermit',
  default: {
    description: [],
    emitGroup: [],
    highRisk: [],
    order: '',
    executionArea: [],
    type: [],
    timeRange: {
      startDate: null,
      endDate: null
    },
    activity: [],
    matter: []
  },
  effects: [
    urlSyncEffect({
      refine: object({
        description: array(string()),
        emitGroup: array(string()),
        executionArea: array(string()),
        highRisk: array(string()),
        type: array(string()),
        activity: array(string()),
        timeRange: object({
          startDate: nullable(string()),
          endDate: nullable(string())
        }),
        matter: array(string()),
        order: nullable(string())
      }),
      history: 'push'
    })
  ]
});
