import React, { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { toast } from 'react-toastify';

import { WorkflowList } from '../organisms/WorkflowList';

import { getWorkPermitByStatusIds } from '../../services/pt.services';
import { workPermitFilterType } from '../../state/workPermit.filters';
import { formatWorkPermit } from '../../utils/workPermit';
import TestWorkflowCard from './TestWorkflowCard';
import WorkflowPhaseModal from './WorkflowPhaseModal';

export type WorkPermitDataProps = {
  name: string;
  id: number;
  tags: (
    | string
    | {
        text: string;
        className: string;
      }
  )[];
  expectedTestDate?: Date;
  endTestDate?: Date;
  obsIsPending?: string;
  createdAt: Date;
  status: number;
};

interface WorkPermitPhaseCardProps {
  title: string;
  phase: {
    name: string;
    ids: number[];
    invertDates?: boolean;
    dateLabel?: string;
  };
  filters?: workPermitFilterType;
}

const WorkPermitPhaseCard: React.FC<WorkPermitPhaseCardProps> = ({
  title,
  phase,
  filters
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [testsData, setTestsData] = useState<WorkPermitDataProps[]>([]);
  const [page, setPage] = useState<number>(0);
  const { ids } = phase;

  const handleGetTests = async () => {
    try {
      return await getWorkPermitByStatusIds(
        ids,
        page,
        undefined,
        filters,
        phase?.invertDates
      );
    } catch (e: any) {
      toast.error('Erro ao buscar testes');
    }
  };

  const { data, isLoading, isFetching } = useQuery(
    ['workflowTests', ids, page, filters],
    handleGetTests,
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    setPage(0);
  }, [filters]);

  useEffect(() => {
    if (data && page === 0) {
      setTestsData(formatWorkPermit(data.issues, ids));
    } else if (data && !isFetching) {
      setTestsData(old => [...old, ...formatWorkPermit(data.issues, ids)]);
    }
  }, [data, page, isFetching]);

  const handleLoadMore = () => setPage(old => old + 1);
  const totalCount = data?.total_count ?? 0;

  const handleExpandList = () => {
    setIsOpen(old => !old);
  };

  return (
    <>
      <WorkflowList
        data={testsData}
        isLoading={isLoading}
        isFetching={isFetching}
        totalCount={totalCount}
        handleLoadMore={handleLoadMore}
        handleExpandList={handleExpandList}
        title={title}
        phase={phase}
        item={TestWorkflowCard}
        emptyMessage="Nenhuma PT nessa etapa"
      />

      {isOpen && (
        <WorkflowPhaseModal
          title={title}
          tests={testsData}
          isOpen={isOpen}
          hasShortText
          className="max-w-3xl"
          phase={phase}
          setIsOpen={() => setIsOpen(old => !old)}
          setLoadMore={handleLoadMore}
          isLoading={isLoading || isFetching}
          totalCount={data?.total_count || 0}
        />
      )}
    </>
  );
};

export default WorkPermitPhaseCard;
