import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Hora: '112',
  'Tipo de Resíduo': '934',
  'Volume (m³)': '173',
  Origem: '500',
  Destino: '499',
  Motorista: '497',
  Placa: '498'
};

const controlTrash: Column[] = tablefy(items);

export default controlTrash;
