import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/atoms/Button';
import Spinner from '../../../components/atoms/Spinner';
import PageNavigation from '../../../components/molecules/PageNavigation';
import Table from '../../../components/organisms/Table';
import volumeInPlaceCols from '../../../constants/tableColumns/mdm/volumeInPlace';
import { getAllZones } from '../../../services/oilwells.services';
import SearchInput from '../../../components/molecules/SearchInput';

const Zones = () => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const perPage = 15;

  const handleGetZones = async () => {
    try {
      const response = await getAllZones({
        skip: perPage * (currentPage - 1),
        take: perPage,
        search: search !== '' ? search : null
      });
      return response;
    } catch (error) {
      toast.error('Erro ao buscar zonas');
    }
  };

  const { data, isLoading } = useQuery(
    ['zones-adm', perPage, currentPage, search],
    handleGetZones,
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 3, // 3 minutos
      cacheTime: 1000 * 60 * 7 // 7 minutos
    }
  );

  const handleNavigateToCreateOilWell = () => {
    navigate('/zones/create');
  };

  return (
    <>
      <div className="flex flex-col gap-4 py-4 px-6 bg-white rounded-lg mt-4 pb-8 relative">
        <Button
          title="Nova zona"
          type="button"
          className="absolute right-14 -top-[56px]"
          icon={<HiOutlinePlusSm className="w-5 h-5" color="white" />}
          onClick={handleNavigateToCreateOilWell}
        />

        <section className="flex justify-between items-center">
          <h1 className="text-gray font-bold text-lg flex">
            Zonas
            {isLoading && <Spinner className="ml-2" size={16} />}
          </h1>

          <SearchInput
            value={search}
            onChange={e => {
              setSearch(e.target.value);
              setCurrentPage(1);
            }}
          />
        </section>

        {!data && (
          <div className="flex flex-col items-center justify-center">
            <Spinner className="mt-4" size={32} />
          </div>
        )}

        {data?.data && (
          <section className="flex flex-col gap-2">
            <Table columns={volumeInPlaceCols} data={data.data || []} />
            <PageNavigation
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              className="mt-3"
              totalPages={Math.ceil(data.total / perPage)}
              perPage={perPage}
            />
          </section>
        )}
      </div>
    </>
  );
};

export default Zones;
