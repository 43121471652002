interface HeaderProps extends React.HTMLAttributes<HTMLHeadingElement> {
  text: string;
  id: string;
}

const Header: React.FC<HeaderProps> = ({ text, id, ...rest }) => {
  return (
    <h1
      {...rest}
      key={id}
      className={`text-primary text-start pb-3 ${rest.className ?? ''}`}
    >
      {text}
    </h1>
  );
};

const DefaultCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs whitespace-nowrap w-full">
      {value || '-'}
    </span>
  );
};

const piTagsColumns = [
  {
    Header: () => <Header text={'ID'} id="id" />,
    accessor: 'id',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Nome'} id="name" />,
    accessor: 'tag',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Descrição'} id="description" />,
    accessor: 'description',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Campo'} id="campo" />,
    accessor: 'oilfield',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Tipo'} id="data_type" />,
    accessor: 'data_type',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Unidade'} id="engunits" />,
    accessor: 'engunits',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Medidor de gás'} id="gas_meter" />,
    accessor: 'gas_meter',
    Cell: DefaultCell
  }
];

export default piTagsColumns;
