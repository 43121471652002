import { Header } from '../../../components/atoms/table/Header';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';

const ptCols = (field: string | null) => [
  {
    Header: <Header text={'N° PT'} id="N° PT" />,
    accessor: '841',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Poço/SAT'} id="poço" />,
    accessor: field == 'Furado' ? '1522' : '1519',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Tipo de Serviço'} id="837" />,
    accessor: '837',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Área Executante'} id="838" />,
    accessor: '838',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Nome do Emitente'} id="1493" />,
    accessor: '1493',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Nome do Executante'} id="840" />,
    accessor: '840',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Observação'} id="712" />,
    accessor: '712',
    Cell: DefaultCell,
    width: '8%'
  }
];

export default ptCols;
