import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { Header } from '../../../components/atoms/table/Header';
import { NumberCellFC } from '../../../components/atoms/table/NumberCell';

const bdmfSummaryColumns = [
  {
    Header: () => <Header secondary text={'Gás'} id="gás" />,
    accessor: 'name',
    columns: [
      {
        Header: () => <Header text={'Campo'} id="hub" />,
        accessor: 'hub',
        Cell: DefaultCell
      },
      {
        Header: () => <Header text={'Ponto de Medição'} id="measurePoint" />,
        accessor: 'measurePointGas',
        Cell: DefaultCell
      },
      {
        Header: () => <Header text={'Volume (m³)'} id="volume" />,
        accessor: 'volumeGas',
        Cell: NumberCellFC(2)
      }
    ]
  },
  {
    Header: () => (
      <Header text={'Movimentação de Líquidos'} id="liquid" secondary />
    ),
    accessor: 'liquid',
    columns: [
      {
        Header: () => <Header text={'Campo'} id="hub" />,
        accessor: 'hubLiquid',
        Cell: DefaultCell
      },
      {
        Header: () => <Header text={'Ponto de Medição'} id="measurePoint" />,
        accessor: 'measurePointLiquid',
        Cell: DefaultCell
      },
      {
        Header: () => <Header text={'Volume (m³)'} id="volume" />,
        accessor: 'volumeLiquid',
        Cell: NumberCellFC(2)
      }
    ]
  }
];

export default bdmfSummaryColumns;
