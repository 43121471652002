import { FaMagnifyingGlassChart } from 'react-icons/fa6';
import {
  GiAztecCalendarSun,
  GiGoat,
  GiTank,
  GiWaterPolo
} from 'react-icons/gi';
import { HiFolder } from 'react-icons/hi';
import BdoCard from '../../components/molecules/BdoCard';
import { format, subDays } from 'date-fns';
import { FaCalendarAlt, FaRegClock } from 'react-icons/fa';

type RedirectOption = {
  label: string;
  value: string;
};

type RedirectItem = {
  to: string;
  title: string;
  icon: any;
  iconSize?: number;
  className?: string;
  description?: string;
  options?: RedirectOption[];
  disabled?: boolean;
};

type CardType = {
  title: string;
  redirect: RedirectItem[];
  className?: string;
};

const BdmfMenu: React.FC = () => {
  const yesterday = format(subDays(new Date(), 1), 'yyyy/MM/dd');

  const cards: CardType[] = [
    {
      title: 'Visão Geral',
      redirect: [
        {
          to: `/home/bdmf/summary?date=${yesterday}`,
          title: 'Visão geral BDMs',
          icon: import.meta.env.DEV ? GiGoat : HiFolder,
          description: 'Visão gerencial dos BDMs do dia.'
        }
      ]
    },
    {
      title: 'Relatórios Padrão RTM',
      redirect: [
        {
          to: '/home/bdmf/reports',
          title: 'BDM Gás',
          icon: import.meta.env.DEV
            ? GiAztecCalendarSun
            : FaMagnifyingGlassChart,
          iconSize: 14,
          description:
            'Relatório diário da produção fiscal de gás nas estações de Pilar, Furado e Tucano Sul.',
          options: [
            {
              label: 'Pilar',
              value: `/home/bdmf/reports?tracker=pilar-gas&date=${yesterday}`
            },
            {
              label: 'Furado',
              value: `/home/bdmf/reports?tracker=furado-gas&date=${yesterday}`
            },
            {
              label: 'Tucano Sul',
              value: `/home/bdmf/reports?tracker=pts-diario&date=${yesterday}`
            }
          ]
        },
        {
          to: `/home/bdmf/reports?tracker=pilar-glp&date=${yesterday}`,
          title: 'BDM GLP',
          className:
            'from-persian-blue-400 to-persian-blue-300 text-persian-blue-900',
          icon: import.meta.env.DEV ? GiTank : HiFolder,
          description:
            'Relatório diário da produção fiscal de GLP na estação de Pilar',
          options: []
        },
        {
          to: `/home/bdmf/reports?tracker=pilar-custodia&date=${yesterday}`,
          title: 'Transferência de Custódia',
          className:
            'from-persian-blue-400 to-persian-blue-300 text-persian-blue-900',
          icon: import.meta.env.DEV ? GiWaterPolo : HiFolder,
          description:
            'Relatório diário da produção fiscal de Custódia na estação de Pilar.',
          options: [
            {
              label: 'Pilar',
              value: `/home/bdmf/reports?tracker=pilar-custodia&date=${yesterday}`
            },
            {
              label: 'Tucano Sul',
              value: `/home/bdmf/reports?tracker=pts-custodia&date=${yesterday}`
            }
          ]
        },
        {
          to: '/home/bdmf/reports',
          title: 'BDM Óleo',
          className:
            'from-persian-blue-400 to-persian-blue-300 text-persian-blue-900',
          icon: import.meta.env.DEV ? GiWaterPolo : HiFolder,
          description:
            'Relatório diário de transferência de custódia de produção fiscal de Óleo nas estações de Pilar, Furado.',
          options: [
            {
              label: 'Pilar',
              value: `/home/bdmf/reports?tracker=pilar-oleo&date=${yesterday}`
            },
            {
              label: 'Furado',
              value: `/home/bdmf/reports?tracker=furado-oleo&date=${yesterday}`
            }
          ]
        }
      ],
      className: 'row-span-2 w-[550px]'
    },
    {
      title: 'Indicadores',
      className: 'row-start-2',
      redirect: [
        {
          to: '/home/bdmf/reports',
          title: 'Alocação diária',
          className:
            'from-persian-blue-400 to-persian-blue-300 text-persian-blue-900',
          icon: FaCalendarAlt,
          description: 'Alocação diária de Óleo, Gás, Gás Lift e Água ',
          iconSize: 14,
          options: [
            {
              label: 'Pilar',
              value: `/home/bdmf/daily-allocation?tracker=pilar&date=${yesterday}`
            },
            {
              label: 'Furado',
              value: `/home/bdmf/daily-allocation?tracker=furado&date=${yesterday}`
            }
          ]
        },
        {
          to: '/home/bdmf/refresh-times',
          title: 'Atualização das views',
          description: 'Hora da útima atualização dos dados do BMP',
          className:
            'from-persian-blue-400 to-persian-blue-300 text-persian-blue-900',
          icon: FaRegClock,
          iconSize: 14
        }
      ]
    }
  ];

  return (
    <div className="w-full h-[calc(100%-45.6px)] flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full">
      <div className="grid grid-cols-2 grid-rows-2 gap-4 w-fit m-auto">
        {cards.map(card => (
          <BdoCard
            key={card.title}
            title={card.title}
            redirect={card?.redirect}
            className={card?.className}
            disabled={!card?.redirect}
          />
        ))}
      </div>
    </div>
  );
};

export default BdmfMenu;
