import { Column } from 'react-table';
import nr13 from '../../tableColumns/bdoEC/nr13';
import turn from '../../tableColumns/bdoEC/turn';
import chemicalLevels from '../../tableColumns/bdoEP/chemicalLevels';
import controlTrash from '../../tableColumns/bdoEP/controlTrash';
import hourInjection from '../../tableColumns/bdoEP/hourInjection';
import geaPir from '../../tableColumns/bdoEP/pir/gea';
import mani01 from '../../tableColumns/bdoEP/pir/maniGNA01';
import mani02 from '../../tableColumns/bdoEP/pir/maniGNA02';
import maniPirOil from '../../tableColumns/bdoEP/pir/maniPirOil';
import ocBombsPir from '../../tableColumns/bdoEP/pir/ocBombsPir';
import ocChemicalsPir from '../../tableColumns/bdoEP/pir/ocChemicalsPir';
import ocDepPir from '../../tableColumns/bdoEP/pir/ocDepPir';
import ocEmulssionPir from '../../tableColumns/bdoEP/pir/ocEmulssionPir';
import ocFiltersPir from '../../tableColumns/bdoEP/pir/ocFiltersPir';
import ocHiloPir from '../../tableColumns/bdoEP/pir/ocHiloPir';
import ocSgPir from '../../tableColumns/bdoEP/pir/ocSgPir';
import ocTanksPir from '../../tableColumns/bdoEP/pir/ocTanksPir';
import ocValvePir from '../../tableColumns/bdoEP/pir/ocValvePir';
import ocXvPir from '../../tableColumns/bdoEP/pir/ocXvPir';
import oilwellTestPir from '../../tableColumns/bdoEP/pir/oilwellTestPir';
import satPir0010 from '../../tableColumns/bdoEP/pir/satPir0010';
import sealPir from '../../tableColumns/bdoEP/pir/seal';
import tankLevelPir from '../../tableColumns/bdoEP/pir/tankLevelPir';
import transferPir from '../../tableColumns/bdoEP/pir/transfer';
import transferEmul from '../../tableColumns/bdoEP/pir/transferEmul';
import transport from '../../tableColumns/bdoEP/pir/transport';
import transportEmul from '../../tableColumns/bdoEP/pir/transportEmul';
import pressureHilo from '../../tableColumns/bdoEP/pressureHilo';
import pressureXv from '../../tableColumns/bdoEP/pressureXv';
import rasp from '../../tableColumns/bdoEP/rasp';
import tank from '../../tableColumns/bdoEP/tank';
import temperature from '../../tableColumns/bdoEP/temperature';
import production from '../../tableColumns/bdoView/production';
import water from '../../tableColumns/bdoView/water';

export const bdoProdPir: (
  | { type: 'label'; id: number; name: string; size?: 'h1' | 'h2' }
  | {
      type: 'grid';
      id: number;
      columns: Column[];
      name: string;
      value?: string;
    }
  | {
      type: 'input' | 'date' | 'number' | 'custom';
      id: number;
      name: string;
      value?: string;
      field?: string;
    }
)[] = [
  {
    id: 245,
    name: 'Emissor',
    type: 'input'
  },
  {
    id: 256,
    name: 'Situação dos Tanques',
    type: 'label'
  },
  {
    id: 257,
    name: 'Temperatura ambiente (ºC)',
    type: 'grid',
    columns: temperature
  },
  {
    id: 335,
    name: 'Registro dos Níveis',
    type: 'label'
  },
  {
    id: 1295,
    name: 'TQ-125105 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 1296,
    name: 'TQ-125106 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 1297,
    name: 'TQ-125107 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 562,
    name: 'TQ-631104 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 564,
    name: 'TQ-631105 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 382,
    name: 'TQ-631106 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 563,
    name: 'TQ-631107 - nível (cm)',
    type: 'grid',
    columns: tank
  },
  {
    id: 483,
    name: 'Volumes dos Tanques',
    type: 'label'
  },
  {
    id: 574,
    name: 'TQ-125105 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 576,
    name: 'TQ-125105 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 1475,
    name: 'TQ-125106 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 1476,
    name: 'TQ-125106 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 1477,
    name: 'TQ-125107 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 1478,
    name: 'TQ-125107 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 491,
    name: 'TQ-631104 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 490,
    name: 'TQ-631104 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 489,
    name: 'TQ-631105 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 488,
    name: 'TQ-631105 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 487,
    name: 'TQ-631106 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 486,
    name: 'TQ-631106 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 485,
    name: 'TQ-631107 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 484,
    name: 'TQ-631107 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 1499,
    name: 'Registro dos Níveis dos Tanques de Teste',
    type: 'label'
  },
  {
    id: 1498,
    name: 'TQ-631101 - Nível',
    type: 'grid',
    columns: tankLevelPir
  },
  {
    id: 1500,
    name: 'TQ-631102 - Nível',
    type: 'grid',
    columns: tankLevelPir
  },
  {
    id: 1501,
    name: 'TQ-631103 - Nível',
    type: 'grid',
    columns: tankLevelPir
  },
  {
    id: 1502,
    name: 'TQ-631108 - Nível',
    type: 'grid',
    columns: tankLevelPir
  },
  {
    id: 512,
    name: 'Volumes dos Tanques de Teste',
    type: 'label'
  },
  {
    id: 513,
    name: 'TQ-631101 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 514,
    name: 'TQ-631101 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 515,
    name: 'TQ-631102 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 516,
    name: 'TQ-631102 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 517,
    name: 'TQ-631103 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 518,
    name: 'TQ-631103 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 519,
    name: 'TQ-631108 - Volume Utilizado (m³)',
    type: 'input'
  },
  {
    id: 520,
    name: 'TQ-631108 - Volume Disponível (m³)',
    type: 'input'
  },
  {
    id: 1306,
    name: 'Água Produzida',
    type: 'label'
  },
  {
    id: 1340,
    name: 'Volume Disponível para Armazenagem (m³)',
    type: 'input'
  },
  {
    id: 1307,
    name: 'Injeção horária (m³/d)',
    type: 'grid',
    columns: hourInjection
  },
  {
    id: 1339,
    name: 'Cálculos Automáticos - Água',
    type: 'grid',
    columns: water
  },
  {
    id: 521,
    name: 'Cálculo da Produção de Tanques Fiscais',
    type: 'label'
  },
  {
    id: 526,
    name: 'Cálculo da Produção',
    type: 'grid',
    columns: production
  },
  {
    id: 522,
    name: 'Volume Total Produzido (m³/dia)',
    type: 'input'
  },
  {
    id: 1523,
    name: 'Teste de Poço e de Vazão',
    type: 'label'
  },
  {
    id: 1533,
    name: '',
    type: 'grid',
    columns: oilwellTestPir
  },
  {
    id: 258,
    name: 'Registro de Ocorrências em Equipamentos',
    type: 'label'
  },
  {
    id: 1149,
    name: 'Tanques',
    type: 'grid',
    columns: ocTanksPir
  },
  {
    id: 1152,
    name: 'Válvulas – Comportas de passagem de água pluvial',
    type: 'grid',
    columns: ocValvePir
  },
  {
    id: 1151,
    name: 'Produtos Químicos',
    type: 'grid',
    columns: ocChemicalsPir
  },
  {
    id: 1153,
    name: 'Filtros',
    type: 'grid',
    columns: ocFiltersPir
  },
  {
    id: 1154,
    name: 'Bombas',
    type: 'grid',
    columns: ocBombsPir
  },
  {
    id: 1155,
    name: 'Tratadores de emulsão',
    type: 'grid',
    columns: ocEmulssionPir
  },
  {
    id: 1183,
    name: 'Depuradores',
    type: 'grid',
    columns: ocDepPir
  },
  {
    id: 1185,
    name: 'SGs',
    type: 'grid',
    columns: ocSgPir
  },
  {
    id: 1309,
    name: 'Hi-Lo',
    type: 'grid',
    columns: ocHiloPir
  },
  {
    id: 1310,
    name: 'XVs',
    type: 'grid',
    columns: ocXvPir
  },
  {
    id: 1311,
    name: "Hi-Lo's",
    type: 'label'
  },
  {
    id: 1313,
    name: '',
    type: 'grid',
    columns: pressureHilo
  },
  {
    id: 1312,
    name: "XV's",
    type: 'label'
  },
  {
    id: 1318,
    name: '',
    type: 'grid',
    columns: pressureXv
  },
  {
    id: 1561,
    name: 'Situação da Bomba de Combate à Incêndio',
    type: 'label'
  },
  {
    id: 594,
    name: 'Situação',
    type: 'input'
  },
  {
    id: 1509,
    name: 'Nível (%)',
    type: 'input'
  },
  {
    id: 1508,
    name: 'Horas de Operação',
    type: 'input'
  },
  {
    id: 1191,
    name: 'Produtos Químicos',
    type: 'label'
  },
  {
    id: 1187,
    name: 'Níveis dos Produtos Químicos',
    type: 'grid',
    columns: chemicalLevels
  },
  {
    id: 1192,
    name: 'Lançamento e Recebimento de Raspadores',
    type: 'label'
  },
  {
    id: 1194,
    name: '',
    type: 'grid',
    columns: rasp
  },
  {
    id: 1238,
    name: 'GEA’s',
    type: 'label'
  },
  {
    id: 1320,
    name: 'Vazão total (m³/h) - GEA',
    type: 'input'
  },
  {
    id: 1249,
    name: '',
    type: 'grid',
    columns: geaPir
  },
  {
    id: 366,
    name: 'Injetores de Desemulsificante',
    type: 'label'
  },
  {
    id: 371,
    name: 'Manifold de Óleo',
    type: 'grid',
    columns: maniPirOil
  },
  {
    id: 1178,
    name: 'Preenchimento de Livros na NR13',
    type: 'input'
  },
  {
    id: 1179,
    name: '',
    type: 'grid',
    columns: nr13
  },
  {
    id: 372,
    name: 'Recebimentos',
    type: 'label'
  },
  {
    id: 932,
    name: 'Emulsão',
    type: 'label'
  },
  {
    id: 376,
    name: 'Dados de Recebimento',
    type: 'grid',
    columns: transferEmul
  },
  {
    id: 907,
    name: 'Dados de Transporte - Recebimentos',
    type: 'grid',
    columns: transportEmul
  },
  {
    id: 910,
    name: 'Volume Total de Emulsão Recebido',
    type: 'label'
  },
  {
    id: 911,
    name: 'Furado (m³)',
    type: 'input'
  },
  {
    id: 913,
    name: 'C5+ (m³)',
    type: 'input'
  },
  {
    id: 912,
    name: 'Petrosynergy (m³)',
    type: 'input'
  },
  {
    id: 914,
    name: 'Outros (m³)',
    type: 'input'
  },
  {
    id: 915,
    name: 'Volume Total Recebido (m³)',
    type: 'input'
  },
  {
    id: 933,
    name: 'Resíduos',
    type: 'label'
  },
  {
    id: 478,
    name: '',
    type: 'grid',
    columns: controlTrash
  },
  {
    id: 593,
    name: 'Furado (Água)',
    type: 'label'
  },
  {
    id: 592,
    name: 'Volume Recebido (m³) - Furado',
    type: 'input'
  },
  {
    id: 397,
    name: 'Transferências',
    type: 'label'
  },
  {
    id: 908,
    name: '',
    type: 'grid',
    columns: transferPir
  },
  {
    id: 909,
    name: 'Dados de Transporte - Transferências',
    type: 'grid',
    columns: transport
  },
  {
    id: 916,
    name: 'Volume Total Transferido',
    type: 'label'
  },
  {
    id: 917,
    name: 'TAMAC - Custódia (m³)',
    type: 'input'
  },
  {
    id: 918,
    name: 'Fiscal (Carreta) (m³)',
    type: 'input'
  },
  {
    id: 935,
    name: 'TAMAC - Fiscal (m³)',
    type: 'input'
  },
  {
    id: 936,
    name: 'Custódia (Carreta) (m³)',
    type: 'input'
  },
  {
    id: 919,
    name: 'Sonda (m³)',
    type: 'input'
  },
  {
    id: 920,
    name: 'Outros - Transferidos (m³)',
    type: 'input'
  },
  {
    id: 921,
    name: 'Volume Total Transferido (m³)',
    type: 'input'
  },
  {
    id: 429,
    name: 'Controle de Lacres',
    type: 'label'
  },
  {
    id: 434,
    name: '',
    type: 'grid',
    columns: sealPir
  },
  {
    id: 440,
    name: 'Alinhamentos',
    type: 'label'
  },
  {
    id: 957,
    name: 'Satélites e PIR-0010',
    type: 'grid',
    columns: satPir0010
  },
  {
    id: 448,
    name: 'Manifold de Gás',
    type: 'label'
  },
  {
    id: 931,
    name: 'Manifold de Gás (GNA-02)',
    type: 'grid',
    columns: mani02
  },
  {
    id: 1323,
    name: 'Manifold de Gás (GNA-01)',
    type: 'grid',
    columns: mani01
  },
  {
    id: 479,
    name: 'Eventos (Passagem de Turno)',
    type: 'label'
  },
  {
    id: -3,
    size: 'h2',
    name: 'Turno 01 - 00h às 07h',
    type: 'label'
  },
  {
    id: 492,
    name: 'Responsável',
    type: 'input'
  },
  {
    id: 480,
    name: '',
    type: 'grid',
    columns: turn
  },
  {
    id: -2,
    size: 'h2',
    name: 'Turno 02 - 07h às 19h',
    type: 'label'
  },
  {
    id: 493,
    name: 'Responsável',
    type: 'input'
  },
  {
    id: 481,
    name: '',
    type: 'grid',
    columns: turn
  },
  {
    id: -1,
    size: 'h2',
    name: 'Turno 03 - 19h às 23h59h',
    type: 'label'
  },
  {
    id: 494,
    name: 'Responsável',
    type: 'input'
  },
  {
    id: 482,
    name: '',
    type: 'grid',
    columns: turn
  }
];
